import React, {Component} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerPatient } from '../../actions/authActions'
import axios from 'axios';
import apiUrl from '../../config/config';

import TopNav from '../../components/common/TopNav';
import SideBar from '../../components/common/SideBar';


class AddPatient extends Component {
  constructor() {
    super();
    this.state = {
        menus : [
            {title:'Doctors List',url:'/doctors-list'},
            {title:'Verifiers List',url:'/verifiers-list'},
            {title:'Patient List',url:'/admin-home'},
        ],
        profileLink : 'admin-profile',
        name: '',
              phone: '',
              email: '',
              gender: 'M',
              password: '',
              password2: '',
              doctorid:'',
              terms: true,
              dob:'',
              height:'',
              weight:'',
              weight_unit:'Kg',
              hospital:'',
              ip_op:'',
              doctors: [],
              errors: {},
    };

  }

    onChange = e => {
        var err = this.state.errors;
        delete err[e.target.name];
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = e => {
        e.preventDefault();

        const newUser = {
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          gender: this.state.gender,
          password: this.state.password,
          password2: this.state.password2,
          doctorid: this.state.doctorid,
          terms: this.state.terms,
          dob:this.state.dob,
          height:this.state.height,
          weight:this.state.weight,
          weight_unit:this.state.weight_unit,
          hospital:this.state.hospital,
          ip_op:this.state.ip_op,
        };
        this.props.registerPatient(newUser,this.props.history,'/admin-home');
    }

    componentDidMount() {
        axios
          .get(apiUrl+'api/users/alldoctors')
          .then(res => {
            this.setState({ ['doctors']: res.data })
          })
          .catch(err => {
            this.setState({ ['doctors']: [] })
          });
    }

    componentWillReceiveProps(nextProps) { 
        if(nextProps.errors) {
            this.setState({errors: nextProps.errors});
        } 
    }

  render() {

    const { errors,doctors } = this.state;

    let doctorsList = doctors.length > 0
        && doctors.map((item, i) => {
        return (
            <option key={i} value={item._id}>{item.name}</option>
        )
    }, this);

    return (
        <div className="wrapper theme-1-active box-layout pimary-color-green">
        <TopNav profileLink={this.state.profileLink}/>
        <SideBar menus={this.state.menus}/>
        <div  className="right-sidebar-backdrop"></div>
        <div className="page-wrapper">
            <div className="container-fluid">
                <div className="row heading-bg">
                    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <h5 className="txt-dark">Add Patient</h5>
                    </div>
                    <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                      <ol className="breadcrumb">
                        <li><a href="index.html">Home </a></li>
                        <li><a href="#"><span>Patients</span></a></li>
                      </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-default card-view">
                            <div className="panel-heading">
                                <div className="clearfix">
                                    <a href="/admin-home">
                                        <button type="button" className="btn btn-success"><i className="fa fa-arrow-left"></i> Patients List</button>
                                    </a>
                                </div>
                            </div>
                            <div className="panel-wrapper collapse in">

                                <div className="panel-body">
                                    <div className="table-wrap">
                                        <div className="table-responsive col-md-12">
                                              <form method="post" id="signup_form" role="form" onSubmit={this.onSubmit}>
                                                <div className="form-group">
                                                    <label className="control-label mb-10" htmlFor="exampleInputName_1">Username</label>
                                                    <input 
                                                        type="text" 
                                                        className={classnames('form-control',{
                                                            'errors' : errors.name
                                                        })} 
                                                        name="name" 
                                                        required="" 
                                                        id="name" 
                                                        onChange={this.onChange}
                                                        value={this.state.name}
                                                        placeholder="Username" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label mb-10" htmlFor="exampleInputName_11">PhoneNumber</label>
                                                    <input 
                                                        type="number" 
                                                        className={classnames('form-control',{
                                                            'errors' : errors.phone
                                                        })} 
                                                        name="phone" 
                                                        required="" 
                                                        id="phone" 
                                                        onChange={this.onChange}
                                                        value={this.state.phone}
                                                        placeholder="Phone" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label mb-10" htmlFor="exampleInputEmail_2">Email address</label>
                                                    <input 
                                                        type="text" 
                                                        className={classnames('form-control',{
                                                            'errors' : errors.email
                                                        })} 
                                                        name="email" 
                                                        required="" 
                                                        id="email" 
                                                        onChange={this.onChange}
                                                        value={this.state.email}
                                                        placeholder="Enter email" />
                                                </div>

                                                <div className="form-group">
                                                    <label className="pull-left control-label mb-10" htmlFor="exampleInputpwd_2">Password</label>
                                                    <input 
                                                        type="password" 
                                                        className={classnames('form-control',{
                                                            'errors' : errors.password
                                                        })} 
                                                        name="password" 
                                                        data-minlength="6" 
                                                        required="" 
                                                        id="password" 
                                                        placeholder="Enter password" 
                                                        onChange={this.onChange}
                                                        value={this.state.password}
                                                    />
                                                    <div className="help-block">Minimum of 6 characters</div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="pull-left control-label mb-10" htmlFor="exampleInputpwd_3">Confirm Password</label>
                                                    <input 
                                                        type="password" 
                                                        className={classnames('form-control',{
                                                            'errors' : errors.password2
                                                        })} 
                                                        required="" 
                                                        name="password2"
                                                        id="password2" 
                                                        placeholder="Enter password" 
                                                        onChange={this.onChange}
                                                        value={this.state.password2}
                                                    />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                                <div className="registrationFormAlert" id="divCheckPasswordMatch"></div>
                                                <div className="form-group">
                                                    <label className="control-label mb-10" htmlFor="exampleInputEmail_23">Doctor</label>
                                                    <select 
                                                        name="doctorid" 
                                                        className={classnames('form-control',{
                                                            'errors' : errors.doctorid
                                                        })} 
                                                        value={this.state.doctorid}
                                                        onChange={this.onChange}
                                                        id="doctor">
                                                        <option value="null">Choose Doctor</option>
                                                        {doctorsList}
                                                   </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label mb-10" htmlFor="exampleInputEmail_2">Date of Birth</label>
                                                    <div className="input-group date">
                                                        <input 
                                                            type="text" 
                                                            className={classnames('form-control',{
                                                                'errors' : errors.dob
                                                            })} 
                                                            value={this.state.dob}
                                                            onChange={this.onChange}
                                                            name="dob" 
                                                            id="datepicker" />
                                                        <span className="input-group-addon">
                                                            <span className="fa fa-calendar"></span>

                                                    </span></div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label mb-10" htmlFor="height">Height</label>
                                                    <input 
                                                        className={classnames('form-control',{
                                                            'errors' : errors.height
                                                        })}  
                                                        onChange={this.onChange}
                                                        value={this.state.height}
                                                        type="number" 
                                                        name="height" 
                                                        required="" 
                                                        id="height" 
                                                        placeholder="Enter Height in cm" />

                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label mb-10" htmlFor="weight">Weight</label>
                                                    <div className="input-group date"> 
                                                    <input 
                                                        className={classnames('form-control',{
                                                            'errors' : errors.weight
                                                        })}  
                                                        onChange={this.onChange}
                                                        value={this.state.weight}
                                                        type="number" 
                                                        name="weight" 
                                                        required="" 
                                                        id="weight" 
                                                        placeholder="Enter Weight in Kg" />
                                                        <span className="input-group-addon">
                                                            <span>
                                                              <select 
                                                                className={classnames('form-control',{
                                                                    'errors' : errors.weight_unit
                                                                })} 
                                                                id="sel-weight-type"
                                                                name="weight_unit" 
                                                                className="form-control" 
                                                                value={this.state.weight_unit}
                                                                onChange={this.onChange}
                                                                >
                                                                    <option select="selected">Kg</option>
                                                                    <option>Lbs</option>
                                                                </select>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label mb-10" htmlFor="hospital">Hospital Name</label>
                                                    <input 
                                                        className={classnames('form-control',{
                                                            'errors' : errors.hospital
                                                        })}  
                                                        onChange={this.onChange}
                                                        value={this.state.hospital}
                                                        type="text" 
                                                        name="hospital" 
                                                        required="" 
                                                        id="hospital" 
                                                        placeholder="Enter Hospital Name" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label mb-10" htmlFor="ip_op">IPOP Number</label>
                                                    <input 
                                                        className={classnames('form-control',{
                                                            'errors' : errors.ip_op
                                                        })}  
                                                        onChange={this.onChange}
                                                        value={this.state.ip_op}
                                                        type="text" 
                                                        name="ip_op" 
                                                        required="" 
                                                        id="ip_op" 
                                                        placeholder="Enter IPOP Number" />
                                                </div>
                                                
                                                <div className="form-group mb-30">
                                                    <label className="control-label mb-10 text-left">Gender</label>
                                                    <div className="radio radio-primary">
                                                        <input 
                                                            type="radio" 
                                                            name="gender" 
                                                            id="radio1" 
                                                            value="M" 
                                                            checked={true} 
                                                            onChange={this.onChange}
                                                        />
                                                        <label htmlFor="radio1">
                                                            M
                                                        </label>
                                                    </div>
                                                    <div className="radio radio-primary">
                                                        <input 
                                                            type="radio" 
                                                            name="gender" 
                                                            id="radio2" 
                                                            value="F" 
                                                            onChange={this.onChange}
                                                        />
                                                        <label htmlFor="radio2">
                                                            F
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="form-group text-center">                
                                                    <div className="btnshow">
                                                        <button type="submit" className="btn btn-info btn-success btn-rounded">sign Up</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}

AddPatient.propTypes = {
    registerPatient: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps, { registerPatient })(withRouter(AddPatient));