import React, {Component} from 'react';
import axios from 'axios';
import $ from "jquery";
import apiUrl from '../../config/config';

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'


class SummaryTab extends Component {

    constructor() {
        super();
        this.state = {
            menus : [
                {},
            ],
            nsr:'',
            st:'',
            sb:'',
            sp:'',
            pac:'',
            pvc:'',
            vt:'',
            afib:'',
            pab:'',
            irrr:'',
            irpr:'',
            sqt:'',
            lqt:'',
            un:'',
            nsr_per:'',
            st_per:'',
            sb_per:'',
            sp_per:'',
            pac_per:'',
            pvc_per:'',
            vt_per:'',
            afib_per:'',
            pab_per:'',
            irrr_per:'',
            irpr_per:'',
            sqt_per:'',
            lqt_per:'',
            un_per:'',
            activity_mfe: '',
            activity_mfe_period: '',
            activity_smfe: '',
            activity_smfe_period: '',
            activity_tmfe: '',
            activity_tmfe_period: '',
            inactivity_mfe: '',
            inactivity_mfe_period: '',
            inactivity_smfe: '',
            inactivity_smfe_period: '',
            inactivity_tmfe: '',
            inactivity_tmfe_period: '',
            name:'',
            device_id:'',
            gender:'',
            age:'',
            weight:'',
            height:'',
            dob:'',
            diabetes:'',
            hyper_tension:'',
            hyper_lipidemia:'',
            thyroid:'',
            drug_history:'',
            smoking:'',
            cardiac_surgery:'',
            table:''
        };

    }

  componentDidMount(){

    var userid = this.props.userid;

    function get_age(born, now) {
      var birthday = new Date(now.getFullYear(), born.getMonth(), born.getDate());
      if (now >= birthday) 
        return now.getFullYear() - born.getFullYear();
      else
        return now.getFullYear() - born.getFullYear() - 1;
    }

    axios
      .get(apiUrl+'api/common/getdailydeatils?userid='+userid)
      .then(res => {
        this.setState({
          table: res.data
        });
      })
      .catch(err => {
        console.log(err);
      });

    axios
      .get(apiUrl+'api/patients/details?userid='+userid)
      .then(res => {

        var data = res.data;
        var fdate = data.dob.split('T');
        var now = new Date();
        var birthdate = fdate[0].split("-");
        var dob = birthdate[2]+"-"+(birthdate[1])+"-"+birthdate[0]
        var born = new Date(birthdate[0],(birthdate[1]-1),birthdate[2]);
        var age=get_age(born,now); 

        this.setState({
          name: data.name,
          device_id: data.device_id,
          weight: data.weight+" "+data.weight_unit,
          height: data.height,
          diabetes:data.diabetes,
          hyper_tension:data.hyper_tension==true ? 'True' : 'False',
          hyper_lipidemia:data.hyper_lipidemia==true ? 'True' : 'False',
          thyroid:data.thyroid,
          drug_history:data.drug_history,
          smoking:data.smoking==true ? 'True' : 'False',
          cardiac_surgery:data.cardiac_surgery==true ? 'True' : 'False',
          gender:data.gender=="M" ? 'Male' : 'Female',
          dob:dob,
          age:age
        });
      })
      .catch(err => {
        console.log(err);
      });

      
      axios
        .get(apiUrl+'api/common/getPatientTotalDetails?userid='+userid)
        .then(res => {
          var active_arith_array= [];
          var active_arith_toarray= [];
          active_arith_toarray = res.data.active_arrhythmia.split(',');
          active_arith_array.shift();
          var act_arry_total = 0;
          var active_arith_garray = [];
          //console.log(active_arith_toarray);
          //active_arith_array['tes']=1;
          active_arith_array['Sinus Tachycardia'] = parseInt(active_arith_toarray[1]);
          active_arith_array['Sinus Bradycardia'] = parseInt(active_arith_toarray[2]);
          active_arith_array['Sinus Pause'] = parseInt(active_arith_toarray[3]);
          active_arith_array['PAC'] = parseInt(active_arith_toarray[4]);
          active_arith_array['PVC'] = parseInt(active_arith_toarray[5]);
          active_arith_array['VT'] = parseInt(active_arith_toarray[6]);
          active_arith_array['A Fib'] = parseInt(active_arith_toarray[7]);
          active_arith_array['P Absent'] = parseInt(active_arith_toarray[8]);
          active_arith_array['Irregular RR'] = parseInt(active_arith_toarray[9]);
          active_arith_array['Long QT'] = parseInt(active_arith_toarray[10]);
          active_arith_array['Short QT'] = parseInt(active_arith_toarray[11]);
          active_arith_array['Irregular PR'] = parseInt(active_arith_toarray[12]);

          var active_arith_array_sortable = [];
          for (var arr in active_arith_array) {
              active_arith_array_sortable.push([arr, active_arith_array[arr]]);
              act_arry_total = act_arry_total+active_arith_array[arr];
              active_arith_garray.push([parseInt(active_arith_array[arr])]);
          }

          active_arith_array_sortable.sort(function(a, b) {
              return a[1] - b[1];
          });
          active_arith_array_sortable.reverse();

          var actArrhy=Highcharts.chart('activityarrhythmia', {
            chart: {
                type: 'bar',
                height:'50%',
             width:'400'
            },
            
            exporting: { enabled: false },
            credits: {
              enabled: false
           },
            title: {
                text: ''
            },
              // colors:  ['#98FB98', '#3EFFDE', '#FFD14F', '#FF5B05', '#4950EB', '#FF45DC', '#A4C3FF', '#BBEB8F', '#FCDF85','#7C2F09','#CFFF0F','#F0A7A1','#AEB9BD','#A96BFF'],
              colors: ['#AEB9BD','#CFFF0F','#F0A7A1','#7C2F09','#FCDF85','#BBEB8F','#A4C3FF', '#FF45DC','#4950EB','#FF5B05','#FFD14F','#3EFFDE','#98FB98'],
            xAxis: {
                categories: ['IPR','SQT','LQT','IRR','PAB','AT', 'VT', 'PVC','PAC','SP','SB','ST','NOR' ],
                 labels: {
              style: {
                color: 'black',
                fontSize: '12px'
              },
              visible: false
            }

            },
            yAxis: {
               gridLineWidth: 0,
                min: 0,
                title: {
                    text: ''
                },
                visible: false,
                    // labels: {
                    //     enabled: false
                    // }
                    style: {
                color: 'black',
                fontSize: '12px'
              }
            },
            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                   // stacking: 'normal',
                   colorByPoint: true,
                     pointPadding: 0,
                    groupPadding: 0,
                    shadow:false,
                    borderWidth:0,
                    dataLabels:{
                        enabled:true,
                        formatter: function() {
                           // return '<span style="font-size:16px;color:black;">' + this.y+'</span> ';
                         var pcnt = (this.y / act_arry_total) * 100;
                            return '<span style="font-size:12px;color:black;">'+ Highcharts.numberFormat(pcnt) +'</span> %';
                        }
                    }
                }
            },
            series: [{
                 showInLegend: false,  
                     data: active_arith_garray.reverse()
            }]
          }); 



          var inactive_arith_array= [];
          var inactive_arith_toarray= [];
          inactive_arith_toarray = res.data.inactive_arrhythmia.split(',');
          var inact_arry_total = 0;
          var inactive_arith_garray = [];

          inactive_arith_array.shift();
          //console.log(active_arith_toarray);
          //active_arith_array['tes']=1;
          inactive_arith_array['Sinus Tachycardia'] = parseInt(inactive_arith_toarray[1]);
          inactive_arith_array['Sinus Bradycardia'] = parseInt(inactive_arith_toarray[2]);
          inactive_arith_array['Sinus Pause'] = parseInt(inactive_arith_toarray[3]);
          inactive_arith_array['PAC'] = parseInt(inactive_arith_toarray[4]);
          inactive_arith_array['PVC'] = parseInt(inactive_arith_toarray[5]);
          inactive_arith_array['VT'] = parseInt(inactive_arith_toarray[6]);
          inactive_arith_array['A Fib'] = parseInt(inactive_arith_toarray[7]);
          inactive_arith_array['P Absent'] = parseInt(inactive_arith_toarray[8]);
          inactive_arith_array['Irregular RR'] = parseInt(inactive_arith_toarray[9]);
          inactive_arith_array['Long QT'] = parseInt(inactive_arith_toarray[10]);
          inactive_arith_array['Short QT'] = parseInt(inactive_arith_toarray[11]);
          inactive_arith_array['Irregular PR'] = parseInt(inactive_arith_toarray[12]);

          var inactive_arith_array_sortable = [];
          for (var arr in inactive_arith_array) {
              inactive_arith_array_sortable.push([arr, inactive_arith_array[arr]]);
              inact_arry_total = inact_arry_total+inactive_arith_array[arr];
              inactive_arith_garray.push([parseInt(inactive_arith_array[arr])]);
          }

          inactive_arith_array_sortable.sort(function(a, b) {
              return a[1] - b[1];
          });
          inactive_arith_array_sortable.reverse();

          var actArrhy=Highcharts.chart('inactivityarrhythmia', {
            chart: {
                type: 'bar',
                height:'50%',
             width:'400'
            },
            
            exporting: { enabled: false },
            credits: {
              enabled: false
           },
            title: {
                text: ''
            },
              // colors:  ['#98FB98', '#3EFFDE', '#FFD14F', '#FF5B05', '#4950EB', '#FF45DC', '#A4C3FF', '#BBEB8F', '#FCDF85','#7C2F09','#CFFF0F','#F0A7A1','#AEB9BD','#A96BFF'],
              colors: ['#AEB9BD','#CFFF0F','#F0A7A1','#7C2F09','#FCDF85','#BBEB8F','#A4C3FF', '#FF45DC','#4950EB','#FF5B05','#FFD14F','#3EFFDE','#98FB98'],
            xAxis: {
                categories: ['IPR','SQT','LQT','IRR','PAB','AT', 'VT', 'PVC','PAC','SP','SB','ST','NOR' ],
                 labels: {
              style: {
                color: 'black',
                fontSize: '12px'
              },
              visible: false
            }

            },
            yAxis: {
               gridLineWidth: 0,
                min: 0,
                title: {
                    text: ''
                },
                visible: false,
                    // labels: {
                    //     enabled: false
                    // }
                    style: {
                color: 'black',
                fontSize: '12px'
              }
            },
            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                   // stacking: 'normal',
                   colorByPoint: true,
                     pointPadding: 0,
                    groupPadding: 0,
                    shadow:false,
                    borderWidth:0,
                    dataLabels:{
                        enabled:true,
                        formatter: function() {
                           // return '<span style="font-size:16px;color:black;">' + this.y+'</span> ';
                         var pcnt = (this.y / inact_arry_total) * 100;
                            return '<span style="font-size:12px;color:black;">'+ Highcharts.numberFormat(pcnt) +'</span> %';
                        }
                    }
                }
            },
            series: [{
                 showInLegend: false,  
                     data: inactive_arith_garray.reverse()
            }]
          }); 

          this.setState({
              activity_mfe: active_arith_array_sortable[0][0],
              activity_mfe_period: active_arith_array_sortable[0][1],
              activity_smfe: active_arith_array_sortable[1][0],
              activity_smfe_period: active_arith_array_sortable[1][1],
              activity_tmfe: active_arith_array_sortable[2][0],
              activity_tmfe_period: active_arith_array_sortable[2][1],
              inactivity_mfe: inactive_arith_array_sortable[0][0],
              inactivity_mfe_period: inactive_arith_array_sortable[0][1],
              inactivity_smfe: inactive_arith_array_sortable[1][0],
              inactivity_smfe_period: inactive_arith_array_sortable[1][1],
              inactivity_tmfe: inactive_arith_array_sortable[2][0],
              inactivity_tmfe_period: inactive_arith_array_sortable[2][1],
           });      

          

          var active_array = res.data.activity.split(',');
           var iactive_array = [];
           var datatotal= 0;
           for (var arr in res.data.activity.split(',')) {
            iactive_array.push(parseInt(active_array[arr]));
            datatotal = datatotal+parseInt(active_array[arr]);
           }

           var actTime=Highcharts.chart('activitytime', {
            chart: {
                type: 'bar',
                height:'50%',
                width:'400'
            },
            exporting: { enabled: false },
             credits: {
              enabled: false
           },
           colors:  ['#f09c67', '#f7e0a3', '#f1e4e4', '#98FB98'],
            title: {
                text: ''
            },

            xAxis: {
             gridLineWidth: 0,

                categories: ['Intense', 'Moderate', 'Low', 'Inactivity'],
              //   gridLineColor: '#d3d3d30f',
           //minorGridLineWidth: 0,
                labels: {
              style: {
                color: 'black',
                   fontSize: '12px'
              }, gridLineWidth: 0,
               visible: false

            }
            },
            yAxis: {
                
                min: 0,
                title: {
                    text: ''
                },
                 visible: false,
                // labels: {
                //         enabled: false
                //     },
               //  gridLineColor: '#d3d3d30f',
                    gridLineWidth: 0,
                 //minorGridLineWidth: 0,
                    style: {
               color: 'black',
                   fontSize: '12px'
              }
            },
            
            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                   // stacking: 'normal',
                    //  pointPadding: 0.08,
                    // groupPadding: 0,
                    colorByPoint: true,
                    shadow:false,
                    borderWidth:0,
                    dataLabels:{
                        enabled:true,
                        formatter: function() {
                         // return '<span style="font-size:16px;color:black;">' + this.y/100 +'</span> %';
                        var pcnt = (this.y / datatotal) * 100;
                            return '<span style="font-size:12px;color:black;">'+ Highcharts.numberFormat(pcnt) +'</span> %';
                        }
                    }
                }
            },


            series: [{
                 showInLegend: false,  
                     data: iactive_array.reverse()
                     // colors:  ['#f09c67', '#f7e0a3', '#f1e4e4', '#98FB98'],
                     //series.data.push({ y:data, color: '#f09c67', '#f7e0a3', '#f1e4e4', '#98FB98' });
            }]
          }); 
        
        })
        .catch(err => {
            console.log(err);
        });

      var pausedata=[],pvcdata=[],seriesData=[],sdnndataArray=[]; 
      axios
        .get(apiUrl+'api/common/getGraphplot?userid='+userid)
        .then(res => { 
          //console.log(res.data.data);
          for (var i = 0; i < res.data.data.length; i++) {
             //console.log(res.data.data[i]);   
             sdnndataArray = res.data.data[i].split(",");
             console.log(sdnndataArray);
              pausedata.push(parseInt(sdnndataArray[6]));
              pvcdata.push(parseInt(sdnndataArray[5]));
              seriesData.push(sdnndataArray[0]+'/'+sdnndataArray[1]);
          }

          var pvcChart=Highcharts.chart('pvcChart', {
            chart: {
              type: 'column',
              height:'50%',
              width:'400',
              styledMode: false
            },
            exporting: { enabled: false },
            credits: {
              enabled: false
            },
            title: {
                text: ''
            },
            xAxis: {
              min:0,
              max:10,
              scrollbar: {
                enabled: true,     
              },
              gridLineWidth: 0,   
              allowDecimals:false,
              labels: {
                  step: 1,
                  tickInterval:1,
              style: {
                color: 'black',
                   fontSize: '12px'
              }, 
               formatter: function() { return seriesData[this.value];},

              }
            },
            yAxis: {
              min: 0,
              title: {
                text: ''
              },
              visible: true,
              labels: {
                enabled: true,       
              },
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              style: {
                color: 'black',
                fontSize: '12px'
              }
            },
            plotOptions: {
              series: {
                colorByPoint: false,
                shadow:false,
                borderWidth:0,
              }
            },

            series: [{
              showInLegend: false,  
              data: pvcdata
            }]
          });

          var pauseChart=Highcharts.chart('pauseChart', {
            chart: {
              type: 'column',
              height:'50%',
              width:'400'
            },
            exporting: { enabled: false },
            credits: {
              enabled: false
            },
            title: {
                text: ''
            },

            xAxis: {
              min:0,
              max:10,
              scrollbar: {
                enabled: true,
              },   
              allowDecimals:false,
              labels: {
                step: 1,
                tickInterval:1,
                style: {
                  color: 'black',
                  fontSize: '12px'
                }, gridLineWidth: 0,
                visible: false,
               formatter: function() { return seriesData[this.value];},
              }
            },
            yAxis: {             
              min: 0,
              title: {
                text: ''
              },
              visible: true,
              labels: {
                enabled: true,      
              },
              gridLineWidth: 0,
              style: {
                color: 'black',
                fontSize: '12px'
              }
            },
            
            plotOptions: {
              series: {
                colorByPoint: false,
                shadow:false,
                borderWidth:0,                
              }
            },
            series: [{
              showInLegend: false,  
              data: pausedata 
            }]
          });


        })
        .catch(err => {
          console.log(err);
        });



  }

  componentWillReceiveProps(nextProps) { 
    var normal_full_data=0, sinus_full_data=0, sinus_brady_full_data=0,sinus_pause_full_data=0,pac_full_data=0,pvc_full_data=0,vt_full_data=0,afib_full_data=0,pab_full_data=0,irrr_full_data=0,irpr_full_data=0,sqt_full_data=0,lqt_full_data=0,un_full_data=0;
    var nsr_per,st_per,sb_per,sp_per,pac_per,pvc_per,vt_per,afib_per,pab_per,irrr_per,irpr_per,sqt_per,lqt_per,un_per;
    if(nextProps.rpeaks) {
      var dataout =  nextProps.rpeaks;
      if(dataout.length>0) {
        for (var i = 0; i < dataout.length; i++) {
          if(dataout[i]['arrhythmia']=='Normal Sinus Rhythm') {
            normal_full_data=normal_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Sinus Tachycardia') {
            sinus_full_data=sinus_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Sinus Bradycardia') {
            sinus_brady_full_data=sinus_brady_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Sinus Pause') {
            sinus_pause_full_data=sinus_pause_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='PAC') {
            pac_full_data=pac_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='PVC') {
            pvc_full_data=pvc_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Ventricular Tachycardia'){
            vt_full_data=vt_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Afib') {
            afib_full_data=afib_full_data+1;
          } 
          else if(dataout[i]['arrhythmia']=='P absent') {
            pab_full_data=pab_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Irregular RR') {
            irrr_full_data=irrr_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Irregular PR') {
            irpr_full_data=irpr_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Short QT') {
            sqt_full_data=sqt_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Prolonged QT') {
            lqt_full_data=lqt_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Undiagnosed') {
            un_full_data=un_full_data+1;
          }
        }

        nsr_per=((normal_full_data/dataout.length)*100);
        st_per=((sinus_full_data/dataout.length)*100);
        sb_per=((sinus_brady_full_data/dataout.length)*100);
        sp_per=((sinus_pause_full_data/dataout.length)*100);
        pac_per=((pac_full_data/dataout.length)*100);
        pvc_per=((pvc_full_data/dataout.length)*100);
        vt_per=((vt_full_data/dataout.length)*100);
        afib_per=((afib_full_data/dataout.length)*100);
        pab_per=((pab_full_data/dataout.length)*100);
        irrr_per=((irrr_full_data/dataout.length)*100);
        irpr_per=((irpr_full_data/dataout.length)*100);
        sqt_per=((sqt_full_data/dataout.length)*100);
        lqt_per=((lqt_full_data/dataout.length)*100);
        un_per=((un_full_data/dataout.length)*100);

        this.setState({
          nsr:normal_full_data,
          st:sinus_full_data,
          sb:sinus_brady_full_data,
          sp:sinus_pause_full_data,
          pac:pac_full_data,
          pvc:pvc_full_data,
          vt:vt_full_data,
          afib:afib_full_data,
          pab:pab_full_data,
          irrr:irrr_full_data,
          irpr:irpr_full_data,
          sqt:sqt_full_data,
          lqt:lqt_full_data,
          un:un_full_data,
          nsr_per:nsr_per,
          st_per:st_per,
          sb_per:sb_per,
          sp_per:sp_per,
          pac_per:pac_per,
          pvc_per:pvc_per,
          vt_per:vt_per,
          afib_per:afib_per,
          pab_per:pab_per,
          irrr_per:irrr_per,
          irpr_per:irpr_per,
          sqt_per:sqt_per,
          lqt_per:lqt_per,
          un_per:un_per,
        });

      }
           Highcharts.chart('chart_div_full', {
              chart: {
                  plotBackgroundColor: null,
                  plotBorderWidth: null,
                  plotShadow: false,
                  type: 'pie',
                  height: '60%',
              },
              title: {
                  text: ' '
              },
              credits: {
                  enabled: false
              },
              tooltip: {
                  pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
              },
              plotOptions: {
                  pie: {
                      allowPointSelect: true,
                      cursor: 'pointer',
                      dataLabels: {
                          enabled: true,
                          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                      }
                  }
              },
              series: [{
                  name: 'Brands',
                  colorByPoint: true,
                  data: [{
                      name: 'Normal Sinus Rhythm',
                      y: nsr_per,
                      sliced: false,
                      selected: true,
                      color:'#98FB98'
                  }, {
                      name: 'Sinus Bradycardia',
                      y: sb_per,
                      color:'#FFD14F'
                  }, {
                      name: 'Sinus Pause',
                      y: sp_per,
                      color:'#FF5B05'
                  }, {
                      name: 'Ventricular Tachycardia',
                      y: vt_per,
                      color:'#F0A7A1'
                  }, {
                      name: 'PAC',
                      y: pac_per,
                      color:'#4950EB'
                  }, {
                      name: 'PVC',
                      y: pvc_per,
                      color:'#FF45DC'
                  }, {
                      name: 'Short QT',
                      y: sqt_per,
                      color:'#CFFF0F'
                  }, {
                      name: 'Long QT',
                      y: lqt_per,
                      color:'#7C2F09'
                  }, {
                      name: 'Undiagnosed',
                      y: un_per,
                      color:'#FCDF85'
                  }, {
                      name: 'Irregular PR',
                      y: irpr_per,
                      color:'#BBEB8F'
                  }, {
                      name: 'Irregular RR',
                      y: irrr_per,
                      color:'#A4C3FF'
                  }, {
                      name: 'P Absent',
                      y: pab_per,
                      color:'#AEB9BD'
                  }, {
                      name: 'Sinus Tachycardia',
                      y: st_per,
                      color:'#3EFFDE '
                  }, {
                      name: 'AFib',
                      y: afib_per,
                      color:'#A96BFF'
                  }]
              }]
          });
    }
  }

  render() {
    return (
      <div className="summary" id="summary">
       <div className="">
          <div className="row">
             <div className="col-md-6">
                <div className="">
                   <div id="collapseExample1" className="panel-body ">
                      <div className="panel panel-success ">
                         <div className="panel-heading text-center" aria-expanded="true" data-toggle="collapse" href="#Events">Patient Details </div>
                         <div className="panel-body" id="Events">
                            <div className="row" id="summaryPatinetDetails">
                               <div className="col-md-6 col-xs-6 col-sm-6 ">
                                  <strong>Name <span>:</span></strong> {this.state.name}<br/><br/>
                                  <strong>Patient Id <span>:</span></strong> {this.state.device_id}<br/><br/>
                                  <strong>Gender <span>:</span></strong>   {this.state.gender}<br/><br/>
                                  <strong>Age <span>:</span></strong> {this.state.age}<br/><br/>
                                  <strong>Weight <span>:</span> </strong> {this.state.weight}<br/><br/>
                                  <strong>Height <span>:</span> </strong> {this.state.height}<br/><br/>
                                  <strong>Date of Birth <span>:</span> </strong> {this.state.dob}<br/><br/>
                               </div>
                               <div className="col-md-6 col-xs-6 col-sm-6">
                                  <strong>Diabetes <span>:</span> </strong> {this.state.diabetes}<br/><br/>
                                  <strong>Hypertension <span>:</span> </strong> {this.state.hyper_tension}<br/><br/>
                                  <strong>Hyperlipidemia <span>:</span> </strong> {this.state.hyper_lipidemia}<br/><br/>
                                  <strong>Thyroid <span>:</span> </strong>{this.state.thyroid} <br/><br/>
                                  <strong>Drug History <span>:</span> </strong> {this.state.drug_history}<br/><br/>
                                  <strong>Smoking Habit <span>:</span> </strong> {this.state.smoking} <br/><br/>
                                  <strong>Cardiac Surgery <span>:</span> </strong> {this.state.cardiac_surgery} <br/><br/>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             <div className="col-md-6">
                <div id="chart_div_full"></div>
             </div>
          </div>
       </div>
       <table id="tablePreview table1" className="table  table-hover table-bordered table-responsive">
          <thead>
             <tr>
                <th>ARRHYTHMIAS</th>
                <th>BEATS</th>
                <th>PERCENTAGE</th>
             </tr>
          </thead>
          <tbody id="full_data" >
             <tr>
                <th scope="row">Normal Sinus rhythm</th>
                <td>{this.state.nsr}</td>
                <td>{this.state.nsr_per}</td>
             </tr>
             <tr>
                <th scope="row">Sinus Tachycardia</th>
                <td>{this.state.st}</td>
                <td>{this.state.st_per}</td>
             </tr>
             <tr>
                <th scope="row">Sinus Bradycardia</th>
                <td>{this.state.sb}</td>
                <td>{this.state.sb_per}</td>
             </tr>
             <tr>
                <th scope="row">Sinus Pause</th>
                <td>{this.state.sp}</td>
                <td>{this.state.sp_per}</td>
             </tr>
             <tr>
                <th scope="row">PAC</th>
                <td>{this.state.pac}</td>
                <td>{this.state.pac_per}</td>
             </tr>
             <tr>
                <th scope="row">PVC</th>
                <td>{this.state.pvc}</td>
                <td>{this.state.pvc_per}</td>
             </tr>
             <tr>
                <th scope="row">Ventricular Tachycardia</th>
                <td>{this.state.vt}</td>
                <td>{this.state.vt_per}</td>
             </tr>
             <tr>
                <th scope="row">AFib</th>
                <td>{this.state.afib}</td>
                <td>{this.state.afib_per}</td>
             </tr>
          </tbody>
       </table>
       <div className="panel panel-info">
          <div id="" className="panel-body ">
             <div className="panel panel-success ">
                <div className="panel-heading collapsed" aria-expanded="false" data-toggle="collapse" href="#Events">CARDIAC EVENT DURING ACTIVITY </div>
                <div className="panel-body" id="Events">
                   <div className="col-md-12">
                      <div className="col-md-6">
                         <p>Most Frequent Event        
                            <span>:</span> {this.state.activity_mfe} 
                         </p>
                         <p> Second Most Frequent Event         
                            <span> : </span> {this.state.activity_smfe}
                         </p>
                         <p> Third Most Frequent Event         
                            <span>:</span>{this.state.activity_tmfe}
                         </p>
                      </div>
                      <div className="col-md-6">
                         <p>Episodes <span>:</span>  {this.state.activity_mfe_period}</p>
                         <p>Episodes<span>:</span> {this.state.activity_smfe_period}</p>
                         <p>Episodes <span>:</span>  {this.state.activity_tmfe_period}</p>
                      </div>
                   </div>
                </div>
             </div>
          </div>
          <div id="" className="panel-body ">
             <div className="panel panel-success">
                <div className="panel-heading collapsed" aria-expanded="false" data-toggle="collapse" href="#Events">CARDIAC EVENT DURING INACTIVITY</div>
                <div className="panel-body" id="Events">
                   <div className="col-md-12">
                   </div>
                   <div className="col-md-6">
                      <p>Most Frequent Event        
                         <span>:</span> {this.state.inactivity_mfe} 
                      </p>
                      <p> Second Most Frequent Event         
                         <span> : </span> {this.state.inactivity_smfe}
                      </p>
                      <p> Third Most Frequent Event         
                         <span>:</span>{this.state.inactivity_tmfe}
                      </p>
                   </div>
                   <div className="col-md-6">
                      <p>Episodes <span>:</span>  {this.state.inactivity_mfe_period}</p>
                      <p>Episodes<span>:</span> {this.state.inactivity_smfe_period}</p>
                      <p>Episodes <span>:</span>  {this.state.inactivity_tmfe_period}</p>
                   </div>
                </div>
             </div>
          </div>
       </div>
       <div className="row">
          <div className="col-md-4">
             <h4>Activity Time </h4>
             <div id="activitytime" ></div>
             <br/><br/><br/>
          </div>
          <div className="col-md-4">
             <h4>Activity Arrhythmia </h4>
             <div id="activityarrhythmia"><br/><br/><br/>
             </div>
          </div>
          <div className="col-md-4">
             <h4>InActivity Arrhythmia </h4>
             <div id="inactivityarrhythmia" ></div>
             <br/><br/><br/>
          </div>
       </div>
       <div className="row">
          <div className="col-md-4">
             <h4>PVC</h4>
             <div id="pvcChart" ></div>
             <br/><br/><br/>
          </div>
          <div className="col-md-4">
             <h4>Pause</h4>
             <div id="pauseChart"><br/><br/><br/>
             </div>
          </div>
       </div>
       <div className="row">
          <div className="col-md-12">
            <table id="tablePreview table1" className="table  table-hover table-bordered table-responsive">
              <tbody>
                <tr>
                  <th colSpan="2">Event</th>
                  <th rowSpan="1">No Of Events</th>
                  <th rowSpan="1">Single</th>
                  <th rowSpan="1">Couplet</th>
                  <th rowSpan="1">Triplet</th>
                  <th rowSpan="1">Max-HR</th>
                  <th rowSpan="1">Min-HR</th>
                  <th rowSpan="1">Run (&gt; 3 beats)</th>
                </tr>
                {
                  Object.keys(this.state.table).map((val,key) => {
                    var currentpvc = this.state.table[val].pac.split(',');
                    return  <tr key={key}>
                              {val == 0 &&
                                 <td rowSpan={this.state.table.length}>PVC</td>
                              }
                              <td>Day {key+1}</td>
                              <td>{currentpvc[0]}</td>
                              <td>{currentpvc[10]}</td>
                              <td>{currentpvc[11]}</td>
                              <td>{currentpvc[12]}</td>
                              <td>{currentpvc[14]}</td>
                              <td>{currentpvc[15]}</td>
                              <td>{currentpvc[13]}</td>
                            </tr>;
                  })
                }
                {
                  Object.keys(this.state.table).map((val,key) => {
                    var currentpac = this.state.table[val].pac.split(',');
                    return  <tr key={key}>
                              {val == 0 &&
                                 <td rowSpan={this.state.table.length}>PAC</td>
                              }
                              <td>Day {key+1}</td>
                              <td>{currentpac[0]}</td>
                              <td>{currentpac[10]}</td>
                              <td>{currentpac[11]}</td>
                              <td>{currentpac[12]}</td>
                              <td>{currentpac[14]}</td>
                              <td>{currentpac[15]}</td>
                              <td>{currentpac[13]}</td>
                            </tr>;
                  })
                }
               </tbody>
            </table>
          </div>
       </div>
    </div>
    )
  }
}

export default SummaryTab;