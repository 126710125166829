import React, {Component} from 'react';
import classnames from 'classnames';
import axios from 'axios';
import apiUrl from '../../config/config';



class CommonForgetPassword extends Component {

    constructor() {
        super();
        this.state = {
            email:'',
            phone:'',
            otp:'',
            div:'fpw',
            tab:'email',
            errors: {}
        };
    }

    onChange = e => {
        var err = this.state.errors;
        delete err[e.target.name];
        this.setState({ [e.target.name]: e.target.value });
    }

    changeTab = e => {
      console.log(e.target.id);
      if(e.target.id == '_id_email') {
        this.setState({ tab : 'email'});
      } else if(e.target.id == '_id_phone') {
        this.setState({ tab : 'phone'});
      }
    }

    onSubmit = e => { 
      e.preventDefault();
      this.setState({errors: {}});
      const data = {
        email: this.state.email,
      };
      axios
        .post(apiUrl+'api/users/forgetPassword',data)
        .then(res => {
          console.log(res.data);   
          if(res.data=='mailsent')  {
            alert('Reset password link send to your email');
          }
        })
        .catch(err => {
          this.setState({errors: err.response.data});
          console.log(err.response.data);
        });
    }

    onSubmitPhone = e => { 
      e.preventDefault();
      this.setState({errors: {}});
      const data = {
        phone: this.state.phone,
      };
      axios
        .post(apiUrl+'api/users/forgetPasswordOtp',data)
        .then(res => {
          console.log(res.data);   
          this.setState({ div : 'otp'});
        })
        .catch(err => {
          this.setState({errors: err.response.data});
          console.log(err.response.data);
        });
    }

    onOtpSubmit = e => { 
      e.preventDefault();
      this.setState({errors: {}});
      const data = {
        otp: this.state.otp,
      };
      console.log(data);
      axios
        .post(apiUrl+'api/users/verifyOpt',data)
        .then(res => {
          console.log(res.data); 
          window.location.replace("https://web.mybiocalculus.com/reset-password?token="+res.data);  
        })
        .catch(err => {
          this.setState({errors: err.response.data});
          console.log(err.response.data);
        });
    }

    render() {
      const { errors } = this.state;
      
      return (
        <div className="wrapper pa-0">
           <div className="page-wrapper pa-0 ma-0 auth-page">
              <div className="container-fluid">
                 <div className="table-struct full-width full-height">
                    <div className="table-cell vertical-align-middle auth-form-wrap">
                       <div className="auth-form  ml-auto mr-auto no-float">
                          {this.state.div === "fpw" &&
                            <div className="row">
                               <div className="col-sm-12 col-xs-12">
                                  <div className="sp-logo-wrap text-center pa-0 mb-30">
                                     <a href="https://app.mybiocalculus.com/Login/adminlogin">
                                     <img className="brand-img mr-10" src="https://app.mybiocalculus.com/assets/dist/img/logo.png" alt="brand" />
                                     <span className="brand-text">Biocalculus</span>
                                     </a>
                                  </div>
                                  <div className="mb-30">
                                     <h3 className="text-center txt-dark mb-10">Need help with your password?</h3>
                                     <h6 className="text-center txt-grey nonecase-font">Enter the email or phone number you use for Biocalculus, and we’ll help you create a new password.</h6>
                                  </div>
                                  <p className="errorBox">{errors.notFound}</p>
                                  <div className="row">
                                    <div className="col-sm-6 col-xs-6">
                                      <input type="radio" 
                                        name="site_name" 
                                        onClick={this.changeTab}
                                        id="_id_email"
                                        checked={this.state.tab === 'email'}/> Email
                                    </div>
                                    <div className="col-sm-6 col-xs-6">
                                      <input type="radio" 
                                        name="site_name" 
                                        onClick={this.changeTab}
                                        id="_id_phone"
                                        checked={this.state.tab === 'phone'}/> Phone
                                    </div>
                                  </div>
                                  <br/>
                                  {this.state.tab === "email" &&
                                    <div className="form-wrap">
                                       <form name="reset_form" id="reset_form" onSubmit={this.onSubmit}>
                                          <div className="form-group">
                                             <label className="control-label mb-10" htmlFor="exampleInputEmail_2">Email address</label>
                                             <input 
                                                type="email" 
                                                className={classnames('form-control',{
                                                    'errors' : errors.email
                                                })} 
                                                id="email" 
                                                name="email" 
                                                onChange={this.onChange}
                                                value={this.state.email}
                                                placeholder="Enter email" 
                                                required
                                             />
                                          </div>
                                          <div className="form-group text-center">
                                             <button type="submit" id="reset_password" className="btn btn-info btn-success btn-rounded">Submit</button>
                                          </div>
                                       </form>
                                    </div>
                                  }
                                  {this.state.tab === "phone" &&
                                    <div className="form-wrap">
                                       <form name="reset_form" id="reset_form" onSubmit={this.onSubmitPhone}>
                                          <div className="form-group">
                                             <label className="control-label mb-10" htmlFor="exampleInputEmail_2">Phone Number</label>
                                             <input 
                                                type="number" 
                                                min="0"
                                                className={classnames('form-control',{
                                                    'errors' : errors.phone
                                                })} 
                                                id="phone" 
                                                name="phone" 
                                                onChange={this.onChange}
                                                value={this.state.phone}
                                                placeholder="Enter phone" 
                                                required
                                             />
                                          </div>
                                          <div className="form-group text-center">
                                             <button type="submit" id="reset_password" className="btn btn-info btn-success btn-rounded">Submit</button>
                                          </div>
                                       </form>
                                    </div>
                                  }
                               </div>
                            </div>
                          }
                          {this.state.div === "otp" &&
                            <div className="row">
                              <div className="col-sm-12 col-xs-12">
                                <div className="sp-logo-wrap text-center pa-0 mb-30">
                                    <img className="brand-img mr-10" src="https://app.mybiocalculus.com/assets/dist/img/logo.png" alt="brand" />
                                    <span className="brand-text">Biocalculus</span>
                                </div>
                                <p className="errorBox">{errors.otp}</p>
                                <div className="form-wrap">
                                  <form name="reset_form" id="reset_form" onSubmit={this.onOtpSubmit}>
                                    <div className="form-group">
                                      <label className="control-label mb-10" htmlFor="">Enter OTP</label>
                                      <input 
                                        type="number" 
                                        min="0"
                                        className={classnames('form-control',{
                                          'errors' : errors.otp
                                        })} 
                                        id="otp" 
                                        name="otp" 
                                        onChange={this.onChange}
                                        value={this.state.otp}
                                        placeholder="Enter OTP" 
                                      />
                                    </div>
                                    <div className="form-group text-center">
                                      <button type="submit" id="reset_password" className="btn btn-info btn-success btn-rounded">Submit</button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          }
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
      )
    }
}



export default CommonForgetPassword;