import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { registerUser } from '../../actions/authActions'


class SignUp extends Component {
    constructor() {
        super();
        this.state = {
          name: '',
          phone: '',
          email: '',
          gender: 'M',
          address: '',
          password: '',
          password2: '',
          terms: false,
          errors: {}
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/doctor-home');
        }
    }

    componentWillReceiveProps(nextProps) { 
  	    if(nextProps.errors) {
            this.setState({errors: nextProps.errors});
        } 
    }

    onChange = e => {
        var err = this.state.errors;
        delete err[e.target.name];
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = e => {
        e.preventDefault();

        const newUser = {
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          gender: this.state.gender,
          address: this.state.address,
          password: this.state.password,
          password2: this.state.password2,
          terms: this.state.terms,
          type: 'doctor'
        };
        
        this.props.registerUser(newUser,this.props.history,'/login');
    }

    termsClick = e => { 
        this.setState({ terms : this.state.terms == true?false:true });
    }

  render() {
    const { errors } = this.state;

    return (
    <div className="wrapper pa-0">
    	<header className="sp-header">
    		<div className="sp-logo-wrap pull-left">
    			<a href="/">
    				<img className="brand-img mr-10" src="dist/img/logo.png" alt="brand"/>
    				<span className="brand-text">Biocalculus</span>
    			</a>
    		</div>
    		<div className="form-group mb-0 pull-right">
    			<a className="inline-block btn btn-info btn-success btn-rounded btn-outline txt-signup-link" href="/login">Sign In</a>
    		</div>
    		<div className="clearfix"></div>
    	</header>
    	
        <div className="modal fade" id="terms" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
           <div className="modal-dialog modal-dialog-scrollable" role="document">
              <div className="modal-content">
                 <div className="modal-header">
                    <center>
                       <h3 className="modal-title" id="myModalLabel">TERMS AND CONDITIONS</h3>
                    </center>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                 </div>
                 <div className="modal-body">
                    <p className="termsP">Welcome to Biocalculus! We recommend you to have a careful read on our  TERMS AND CONDITIONS   to use our Website, Product and Services.
                    </p>
                    <p className="termsP">MAKE SURE YOU READ AND UNDERSTAND THE TERMS AND CONDITIONS COMPREHENSIVELY. THE TERMS AND CONDITIONS MENTIONED BELOW ADMINISTER THE VISITORS (‘YOU’) ON HOW TO FOLLOW TO THE RULES GOVERNING THE WEBSITE.  THE WEBSITE IS OWNED AND SUPPORTED BY WAFERCHIPS TECHNO SOLUTIONS PVT LTD.(“WE”, “US”, “OUR”) . 
                    </p>
                    <p className="termsP">
                       BY VISITING THE WEBSITE AND USING ITS SERVICES, YOU AGREE TO CONFORM TO THE TERMS AND ARE BOUND BY THE CONDITIONS. IF YOU DISAGREE, PLEASE DO NOT USE THE WEBSITE ANY LONGER. THESE TERMS DO NOT APPLY TO ANY OTHER WEBSITES.
                    </p>
                    <p className="termsP">
                       THIS STATEMENT WAS DRAFTED ON 02 APRIL 2019 AND WAS LAST UPDATED ON 27 MAY 2019. WE ADVISE YOU TO REVIEW AND UNDERSTAND THE LATEST VERSION OF TERMS AND CONDITIONS EACH TIME YOU MAKE A VISIT TO THE WEBSITE OR USE ANY OF OUR SERVICES.
                    </p>
                    <p>
                       THIS WEBSITES USES COOKIES. TO KNOW FURTHER, READ<a href="#" data-toggle="modal" data-target="#cookiepolicy"><span className="txt-primary"> OUR COOKIE POLICY</span></a>
                    </p>
                    <h4>USER CONTENT</h4>
                    <p className="termsP">THE INFORMATION ON THE WEBSITE CONSTITUTES THE INFORMATION UPLOADED BY ITS USERS ALONG WITH DETAILED INFORMATION ON OUR PRODUCTS AND SERVICES WHICH BENEFITS OUR USERS. THE RESOURCES ON THE PAGES ARE LISTED ONLY FOR EDUCATIONAL PURPOSE. NO FINAL CONCLUSION SHOULD BE DRAWN FROM THEM. IT IS ADVISED TO CONSULT YOUR HEALTHCARE PHYSICIAN TO HAVE A COGNITIVE ANALYSIS. NEVER TAKE ANY INFORMATION ON THE WEBSITE AS A SUBSTITUTE FOR YOUR PHYSICIAN’S VIEW. YOUR DIAGNOSIS AND TREATMENT MUST BE BASED ON CONCLUSIONS VERIFIED BY YOUR PRACTITIONER. </p>
                    <h4>INFORMATION ON THE WEBSITE</h4>
                    <p className="termsP">THE INFORMATION YOU SUBMIT ON THE WEBSITE IN THE FORM OF TEXT, IMAGE, AUDIO OR VIDEO IS LABELED AS USER CONTENT. YOUR QUERIES, COMMENTS AND FEEDBACKS WILL ALSO BE CONSIDERED SO. ANY SPECIFIC INFORMATION YOU PROVIDE MUST BE ACCURATE AND UP-TO-DATE. YOU ARE NOT ALLOWED TO SHARE ANY DATA THAT IS ILLEGAL, OFFENSIVE OR HARASSING. BY AGREEING TO THE TERMS AND CONDITIONS, YOU GRANT BIOCALCULUS WORLDWIDE, ABSOLUTE, ROYALTY-FREE, IRREVOCABLE LICENSE TO USE, MODIFY, TRANSMIT, REPRODUCE, AND DISTRIBUTE DATA (OTHER THAN CLASSIFIED AS PERSONAL DATA IN<a href="#" data-toggle="modal" data-target="#privacypolicy"><span className="txt-primary"> PRIVACY POLICY</span></a>) AND ALSO AGREE WE ARE FREE TO USE THEM FOR ANY PURPOSE. </p>
                    <h4>REGISTRATION AND PASSWORDS</h4>
                    <p className="termsP">
                       IN ORDER TO GAIN ACCESS TO FULL CONTENT ON THE WEBSITE, YOU MUST REGISTER AND CREATE AN ACCOUNT FOR WHICH YOU WILL BE ASKED TO PROVIDE YOUR PERSONAL DETAILS. ONLY VALID DETAILS MUST BE ENTERED. A PASSWORD IS REQUIRED TO COMPLETE THE REGISTRATION PROCESS. WE ARE NOT RESPONSIBLE FOR ANY ACTIVITIES UNDER YOUR ACCOUNT. CONFIDENTIALITY OF THE PASSWORD IS TO BE MAINTAINED BY THE USER.
                    </p>
                    <h4>USER RESTRICTION</h4>
                    <p className="termsP">THE WEBSITE IS NOT DESIGNATED TO BE OPERATED BY CHILDREN UNDER THE AGE OF 18 YEARS. BY VISITING THE WEBSITE AND USING OUR SERVICES, YOU AGREE TO BE 18 YEARS OF AGE. IF NOT, PLEASE RESTRICT YOURSELVES FROM FURTHER USAGE. </p>
                    <h4>COPYRIGHTS AND TRADEMARKS</h4>
                    <p className="termsP">ALL RIGHTS RELATED TO THE CONTENT IN THE WEBSITE LIES WITH THE COMPANY. WE OWN THE LABELS AND NAMES USED FOR OUR PRODUCTS AND SERVICES. ALL OUR CONTENT IS COPYRIGHTED UNDER THE COPYRIGHT ACT, 1957. YOU ARE NOT ALLOWED TO COPY, ALTER OR PUBLISH ANY CONTENT IN THIS WEBSITE ON AN ALIEN PLATFORM. DOWNLOADING THE BROCHURE AND LEAFLETS FOR PERSONAL USE IS ALLOWED. YOU ARE BOUND TO DOWNLOAD THE CONTENT ALONG WITH COPYRIGHTS STATEMENTS ONLY. IF FOUND WITH USAGE OF ANY TRADEMARKS AS MENTIONED AGAINST THE TERMS AND CONDITIONS, IT IS DULY NOTED AS A VIOLATION AND CAN INVOLVE LEGAL CHARGES. </p>
                    <p className="termsP">YOU ARE BARRED FROM CONDUCTING ANY OF THE FOLLOWING ACTIVITIES:</p>
                    <ul className="termsP">
                       <li>  COLLECTING DATA FROM THE WEBSITE FOR MARKETING OR PROMOTIONAL PURPOSES</li>
                       <li>   REDISTRIBUTING OR PUBLISHING DATA TO WHICH YOU DO NOT HAVE LICENSE TO.</li>
                    </ul>
                    <h4> LICENSING</h4>
                    <p className="termsP">BIOCALCULUS RESERVE SOLE RIGHTS IN ANY UNDERTAKINGS OF ADAPTING, TRANSLATING AND PUBLISHING THE DATA IN THE WEBSITE. WE GRANT YOU A RESTRICTED, NON TRANSFERABLE, REVOCABLE LICENSE TO INSTALL THE APPLICATION IN YOUR DEVICES AND BENEFIT FROM OUR SERVICES.  YOU ARE ENDOWED WITH ALL NECESSARY LICENSE, RIGHTS AND CONSENT OF THE USER CONTENT.</p>
                    <h4> WARRANTIES</h4>
                    <p className="termsP">ALL PARTICULARS AND INFORMATION POSTED ON THIS WEBSITE IS PRESENTED TO YOU ON AN “AS IS”, “AS AVAILABLE” BASIS, WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED. WE PROVIDE NO WARRANTY REGARDING THE VALIDITY, DEPENDABILITY OR ACCURACY OF THE CONTENT ON THE WEBSITE. WE DO NOT PROVIDE WARRANTY TO ANY MEDICAL CONCLUSIONS DRAWN BASED ON THE MATERIAL AVAILABLE IN THE WEBSITE. </p>
                    <p>ANY KIND OF UNINTERRUPTED USAGE OR ERROR FREE ACCESS IS NOT GUARANTEED BY BIOCALCULUS. YOU ARE ENTITLED TO DOWNLOAD ANY PIECE OF INFORMATION FROM THE WEBSITE AT YOUR OWN RISK.</p>
                    <h4>PROHIBITED USAGE</h4>
                    <p className="termsP">WE MAINTAIN ALL OUR RECORDS DUTIFULLY. YOU ARE PROHIBITED FROM ANY OF THE FOLLOWING ACTIVITIES:</p>
                    <ul className="termsP">
                       <li>COMMITTING ACTIONS THAT DAMAGE THE FUNCTIONING OF THE WEBSITE</li>
                       <li> USING CONTENT ON THE WEBSITE TO CAUSE HARM OR HARASSMENT TO ANY THIRD PARTY</li>
                       <li>PUBLISHING CONTENT THAT IS UNLAWFUL OR ILLEGAL</li>
                       <li>HOSTING DATA THAT TRANSMITS VIRUS TO THE SYSTEM OF USERS.</li>
                    </ul>
                    <h4> CHANGES</h4>
                    <p className="termsP">ALL TERMS AND CONDITIONS ARE SUBJECT TO CHANGES PERIODICALLY. YOU ACCEPT THE MOST RECENT VERSION OF STATEMENTS BY USING THE WEBSITE. IT IS ADVISED TO READ AND REVIEW THE CHANGES EVERY TIME. </p>
                    <h4> LAW AND JURISDICTION</h4>
                    <p className="termsP">THE TERMS AND CONDITIONS ARE LEGALIZED IN ACCORDANCE WITH THE LAWS OF DISTRICT COURT, KOLLAM. BY USING THIS WEBSITE, YOU AGREE TO FOLLOW THE PROPER JURISDICTION AND GUIDELINES FOR ANY DISPUTE WITH BIOCALCULUS OR IN ANY WAY RELATING TO THE WEBSITE.</p>
                    <h4> LIABILITIES</h4>
                    <p className="termsP">UNDER NO CIRCUMSTANCES BIOCALCULUS WILL BE LIABLE TO YOU FOR ANY DAMAGE OR LOSS (WHETHER INCIDENTAL, CONSEQUENTIAL OR PUNITIVE) RESULTING FROM THE DIRECT OR INDIRECT USAGE OF THE CONTENTS OF THE WEBSITE. THIS CLAIM ALSO COVERS ANY DAMAGES LEADING TO LOSS OF MONEY, BUSINESS, PERSONAL INJURY OR DEATH. THIS IS BASED ON YOUR AGREEMENT TO WARRANTY AND LEGAL STATEMENTS.</p>
                 </div>
                 <div className="modal-footer">
                    <button type="button" className="btn btn-primary btn-rounded" data-dismiss="modal">Close</button>
                 </div>
              </div>
           </div>
        </div>

        <div className="modal fade" id="cookiepolicy" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
           <div className="modal-dialog modal-dialog-scrollable" role="document">
              <div className="modal-content">
                 <div className="modal-header">
                    <center>
                       <h3 className="modal-title" id="myModalLabel">Cookie policy</h3>
                    </center>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                 </div>
                 <div className="modal-body">
                    <p className="termsP">Cookie policy tells you how the website of Biocalculus uses cookies and your consent on cookie policy.</p>
                    <br>
                    </br>
                    <p  className="termsP">Cookies are text files placed in the browser files of your computer when you visit. The cookies may be stored on your computer. Cookies permit to access limited information to recognize your devices and the choices you make while browsing through the website which can customize your experience. They collect only non personal information. You can always control the cookies. You are free to delete or block the cookies by adjusting the settings of your web browser, but please note that some parts of our website might not functioning effectively when you do so.</p>
                 </div>
                 <div className="modal-footer">
                    <button type="button" className="btn btn-primary btn-rounded" data-dismiss="modal">Close</button>
                 </div>
              </div>
           </div>
        </div>

        <div className="modal fade" id="privacypolicy" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
           <div className="modal-dialog modal-dialog-scrollable" role="document">
              <div className="modal-content">
                 <div className="modal-header">
                    <center>
                       <h3 className="modal-title" id="myModalLabel">PRIVACY STATEMENT</h3>
                    </center>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                 </div>
                 <div className="modal-body">
                    <p  className="termsP">Welcome to Biocalculus! We recommend you to have a careful read on our privacy statement 
                    </p>
                    <p  className="termsP">We understand that privacy is important to you. Your privacy and trust is important to us. This privacy statement (“policy”) provides you information on how Biocalculus (“we”, “us”, “our”) collects, handles and uses information we gather we collect from the Biocalcululs devices. This privacy statement was drafted on 04 April 2019 and  we may make changes at any point. It is advised to read the privacy statement every time you visit the website.
                    </p>
                    <p  className="termsP">
                       The information we collect is used to improve or enhance user experience and in working towards providing a customized usage to the customer. 
                    </p>
                    <h4>Introduction</h4>
                    <p  className="termsP">The privacy statement describes how we use, protect, store and share the information gathered as you benefit from the services of Biocalculus through our website, device and mobile application. By using this website, you agree to the terms of the website. </p>
                    <p className="termsP"><i>Personal Information:</i> Any information that is related directly to your identity such as name, e-mail address, contact number, gender, height, weight and birth date which you voluntarily provide while you fill up any forms available in the website. Also, other details such as medications or medical conditions which you choose to enter while signing up through the application installed in your devices. </p>
                    <p  className="termsP">No personal information will be collected if you only browse through the website.</p>
                    <p  className="termsP"><i>Non Personal Information:</i>  Non-personal information does not specifically reveal your identity. It may consist of information collected from your web browser, the internet domain or the date and time of access of website. Non personal information is used to improve the website and serve its customers better.</p>
                    <h4>How do we use your information?</h4>
                    <p  className="termsP">The information provided may be used: </p>
                    <ul  className="termsP">
                       <li>  For the purpose of processing and analyzing in order to improve the services and products provided to you  </li>
                       <li> For the purpose of processing and analyzing in order to improve the services and products provided to you  </li>
                       <li>For the purpose of studies and research to upgrade accessible content</li>
                       <li> To connect with you to inform about personalized features</li>
                       <li>To keep records of personal information </li>
                       <li>For the purpose of promotional marketing by sending timely updates on new products, services and features</li>
                    </ul>
                    <h4> How do we store your information and long we keep it?</h4>
                    <p  className="termsP">All your information is stored follows regulated standards and specialized codes are allocated to which third parties do not have access. The identifier tags lies with the researcher who uses it to make deeper analysis and studies. The information is kept as long as the researcher desires and it serves the purpose unless violated by law.</p>
                    <h4>
                    How do we protect your information?
                    </h4>
                    <p  className="termsP">We believe security is critical. We do not share any of your personal data to a third party for advertisement, promotion or any similar purpose. A vendor may use your shipping details or contact details for delivering our product, but we do not authorize them to use the data or disclose the details under strict agreements.</p>
                    <h4>How do we share your information?</h4>
                    <p  className="termsP">Your data will be shared with third parties that provide us services of web and processing. We work meticulously to prevent any misuse of your data by affiliates associated with us. We suggest you to understand privacy statements of third party servers too.</p>
                    <p className="termsP">We may disclose any personal data where we may be obliged to do so by law. We may be compelled to share information if you violate any of our terms. </p>
                    <h4> Your access to add or delete information</h4>
                    <p  className="termsP">We do not collect any data without your consent. Any data found so can be asked to delete. We will take necessary steps from our side to remove the data collected improperly. In some cases, additional details may be required for personalized cases, we might contact you asking for the same or you can contact us for providing the information. The details may be then added. </p>
                    <h4>Children’s privacy </h4>
                    <p  className="termsP">We believe in keeping children’s data secured. We do not ask for data of children below the age of 18 yrs. If any such information is noticed, we will take steps to remove them or you may bring it to our notice.</p>
                    <h4>Your Privacy Rights </h4>
                    <p  className="termsP">You will have the following rights: </p>
                    <ul  className="termsP">
                       <li>  Right to decide what information has to be entered at the initial stage</li>
                       <li>  Complete access to any information provided by you at any time</li>
                       <li> Update any incorrect or outdated information added</li>
                       <li> Delete any information that is irrelevant</li>
                       <li> Subscribe or unsubscribe promotional materials and services at any time</li>
                    </ul>
                    <h4> Changes to the policy</h4>
                    <p  className="termsP">Any policy changes will be notified in the home page of the website. We serve all rights to make policy changes at any time. You are required to review the privacy statements periodically. Any change that involves processing your personal data will be conveyed to you. </p>
                    <h3> Contact us</h3>
                    <p className="termsP">For any questions or clarifications regarding the privacy statement or cookie policy, feel free to reach us through ‘contact us’ details on the website.</p>
                 </div>
                 <div className="modal-footer">
                    <button type="button" className="btn btn-primary btn-rounded" data-dismiss="modal">Close</button>
                 </div>
              </div>
           </div>
        </div>
        
    	<div className="page-wrapper pa-0 ma-0 auth-page">
    		<div className="container-fluid">
    			<div className="table-struct full-width full-height">
    				<div className="table-cell vertical-align-middle auth-form-wrap">
    					<div className="auth-form  ml-auto mr-auto no-float">
    						<div className="row">
    							<div className="col-sm-12 col-xs-12">
                                    <div className="mb-30">
                                        <h3 className="text-center txt-dark mb-10">Sign up as Doctor</h3>
                                        <h6 className="text-center nonecase-font txt-grey">Enter your details below</h6>
                                    </div>
                                    <div className="form-wrap">
                                        <form method="post" id="signup_form" role="form" onSubmit={this.onSubmit}>
                                            <div className="form-group">
                                                <label className="control-label mb-10" htmlFor="exampleInputName_1">Name</label>
                                                <input 
                                                    type="text" 
                                                    className={classnames('form-control',{
                                                        'errors' : errors.name
                                                    })} 
                                                    name="name" 
                                                    required="" 
                                                    id="name" 
                                                    onChange={this.onChange}
                                                    value={this.state.name}
                                                    placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label mb-10" htmlFor="exampleInputName_11">Phone Number</label>
                                                <input 
                                                    type="number" 
                                                    min="0"
                                                    className={classnames('form-control',{
                                                        'errors' : errors.phone
                                                    })} 
                                                    name="phone" 
                                                    required="" 
                                                    id="phone" 
                                                    onChange={this.onChange}
                                                    value={this.state.phone}
                                                    placeholder="Enter Phone" />
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label mb-10" htmlFor="exampleInputEmail_2">Email address</label>
                                                <input 
                                                    type="text" 
                                                    className={classnames('form-control',{
                                                        'errors' : errors.email
                                                    })} 
                                                    name="email" 
                                                    required="" 
                                                    id="email" 
                                                    onChange={this.onChange}
                                                    value={this.state.email}
                                                    placeholder="Enter email address" />
                                            </div>
                                            <div className="form-group mb-30">
                                                <label className="control-label mb-10 text-left">Gender</label>
                                                <div className="radio radio-primary">
                                                    <input 
                                                        type="radio" 
                                                        name="gender" 
                                                        id="radio1" 
                                                        value="M" 
                                                        checked={true} 
                                                        onChange={this.onChange}
                                                    />
                                                    <label htmlFor="radio1">
                                                        M
                                                    </label>
                                                </div>
                                                <div className="radio radio-primary">
                                                    <input 
                                                        type="radio" 
                                                        name="gender" 
                                                        id="radio2" 
                                                        value="F" 
                                                        onChange={this.onChange}
                                                    />
                                                    <label htmlFor="radio2">
                                                        F
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label mb-10" htmlFor="exampleInputName_11">Address</label>
                                                <textarea 
                                                    className="form-control" 
                                                    name="address" 
                                                    id="address"
                                                    onChange={this.onChange}
                                                    value={this.state.address}
                                                > 
                                                </textarea>
                                            </div>
                                            <div className="form-group">
                                                <label className="pull-left control-label mb-10" htmlFor="exampleInputpwd_2">Password</label>
                                                <input 
                                                    type="password" 
                                                    className={classnames('form-control',{
                                                        'errors' : errors.password
                                                    })} 
                                                    name="password" 
                                                    data-minlength="6" 
                                                    required="" 
                                                    id="password" 
                                                    placeholder="Enter password" 
                                                    onChange={this.onChange}
                                                    value={this.state.password}
                                                />
                                                <div className="help-block">Minimum of 6 characters</div>
                                            </div>
                                            <div className="form-group">
                                                <label className="pull-left control-label mb-10" htmlFor="exampleInputpwd_3">Confirm Password</label>
                                                <input 
                                                    type="password" 
                                                    className={classnames('form-control',{
                                                        'errors' : errors.password2
                                                    })} 
                                                    required="" 
                                                    name="password2"
                                                    id="password2" 
                                                    placeholder="Confirm Password" 
                                                    onChange={this.onChange}
                                                    value={this.state.password2}
                                                />

                                            </div>
                                            <div className="registrationFormAlert" id="divCheckPasswordMatch" ></div>

                                            <div className="form-group">
                                                <div 
                                                    className={classnames('checkbox checkbox-primary pr-10 pull-left',{
                                                            'checkbox_errors' : errors.terms
                                                        })}
                                                >
                                                    <input 
                                                        id="checkbox_2" 
                                                        required="" 
                                                        type="checkbox"
                                                        onClick={this.termsClick} 
                                                        value={this.state.password2}
                                                    />                                           
                                                    <label htmlFor="checkbox_2"> I agree to all <a href="#" data-toggle="modal" data-target="#terms"><span className="txt-primary"> Terms &amp; Conditions</span></a></label>                                            
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                            <div 
                                                className={classnames('mb-30 error-container',{
                                                                'error-container-show' : errors.exists
                                                            })}
                                            >
                                                <h6 className="text-center nonecase-font txt-red">{errors.exists}</h6>
                                            </div>
                                            <div className="form-group text-center">
                                                <div className="btnshow">
                                                    <button type="submit" className="btn btn-info btn-success btn-rounded">sign Up</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div> 
    						</div>
    					</div>
    				</div>
    			</div>
    		</div>
    	</div>
    </div>
    )
  }
}

SignUp.propTypes = {
    registerUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps, { registerUser })(withRouter(SignUp));