import React, { Component } from 'react';
import ReactModal from 'react-modal';
import apiUrl from '../../config/config';
import axios from 'axios';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Loader from 'react-loader-spinner'

  class AddModalReport extends Component {
    // ... skip
      constructor() {
    super();
    this.state = {

        showModalReportComplete: false,
        showModalNewComplete:false,
        showModalReportSpinnerComplete:false,
        startProp:'',
        userid:'',
        optionsnewone:[],
        optionsnewoneend:[],
        start_generate:'',
        end_generate:''
    
    };
this.handleCloseModalReportComplete = this.handleCloseModalReportComplete.bind(this);
this.handleCloseModalReportSpinnerNewComplete = this.handleCloseModalReportSpinnerNewComplete.bind(this);
this.handleOpenModalReportSpinnerNewComplete = this.handleOpenModalReportSpinnerNewComplete.bind(this);
  }
  onChange = e => {
    console.log(e.target.name);
    console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  }
componentDidMount() {
    ReactModal.setAppElement('body');
    window.analyzeLink='';
 }

 componentWillReceiveProps(nextProps)
 {
  this.state.userid=nextProps.userid;
  this.state.startProp=nextProps.start;
  var instance = nextProps.inst;
  
  var technologyList=[];
  var technologyListEnd=[];
  var optionnew=[];
  var optionnewend=[];
  var i=0,j=0;
  if(nextProps.isOpen==true){
    Object.keys(this.props.treemenu).map((val,key) => {
        this.props.treemenu[val].map((value,key1)=>{
            var MainTime = value['date'].split('T');
            var time = MainTime[1].split('+');
            
             optionnew[i] =  val+" "+time[0];
             optionnewend[i] =  val+" "+time[0];
            // technologyList.push(<option value={ val+" "+time[0]}>{ val+" "+time[0]}</option>)
            i=i+1;
                              
        
        })
     });
     
     optionnew=optionnew.sort();
     this.setState({start_generate: optionnew[0]});

     for(j=0;j<optionnew.length;j++){
        technologyList.push(<option value={optionnew[j]}>{ optionnew[j]}</option>)
     }
     
     optionnewend=optionnewend.sort(function(a, b){return b-a});
     this.setState({end_generate: optionnewend[0]});
     for(j=0;j<optionnewend.length;j++){
        technologyListEnd.push(<option value={optionnewend[j]}>{ optionnewend[j]}</option>)
     }
 
    

                           this.setState({optionsnewone: technologyList});
                           this.setState({optionsnewoneend: technologyListEnd});
                         
                           this.state.showModalReportComplete=nextProps.isOpen;
                          
  }
 }
  handleCloseModalReportComplete () {
    this.setState({ showModalReportComplete: false });
    this.setState({showModalReportComplete: false});
    this.props.onSelectModalReport();
  }

  handleCloseModalNewComplete () {
    console.log('handleCloseModalNewComplete');
    this.setState({ showModalNewComplete: false });
  }

  handleCloseModalReportSpinnerNewComplete () {
    console.log('handleCloseModalNewComplete');
    this.setState({ showModalReportSpinnerComplete: false });
  }

  handleOpenModalReportSpinnerNewComplete () {
    console.log('handleCloseModalNewComplete');
    this.setState({ showModalReportSpinnerComplete: true });
  }

  handleOpenModalSpinnerNew () {
    console.log('handleCloseModalNewComplete');
    this.setState({ showModalReportSpinnerComplete: true });
  }

  // New format report model

  openGeneratemodal(e)
  {
      this.setState({"showModalReportComplete":false});
    this.handleOpenModalReportSpinnerNewComplete();
   // encodeURIComponent( val+" "+time[0])
    var userid = this.state.userid;
    var start_generate = this.state.start_generate.replace(/ /g,"%20");;
    var end_generate = this.state.end_generate.replace(/ /g,"%20");;
    console.log('link','https://web.mybiocalculus.com/html/welcome/generate_new/?userid=' + userid + '&&start=' + start_generate + '&&end=' + end_generate);
    axios
        .get('https://web.mybiocalculus.com/html/welcome/generate_new/?userid=' + userid + '&&start=' + start_generate + '&&end=' + end_generate)
        .then(res => {
          console.log(res.data);    
          //window.location.href = '/';
          this.handleCloseModalReportSpinnerNewComplete(); 
          this.handleCloseModalReportComplete();
          window.open('http://report.mybiocalculus.com/?userid='+this.state.userid, '_blank').focus(); 
        })
        .catch(err => {
            console.log(err);
            this.handleCloseModalReportSpinnerNewComplete();
            this.handleCloseModalReportComplete();
        });
  }

  // Old format report model
  openGeneratemodalNew(e)
  {
    this.setState({"showModalReportComplete":false});
    this.handleOpenModalReportSpinnerNewComplete();
   // encodeURIComponent( val+" "+time[0])
    var userid = this.state.userid;
    var start_generate = this.state.start_generate.replace(/ /g,"%20");;
    var end_generate = this.state.end_generate.replace(/ /g,"%20");;
    console.log('link','https://web.mybiocalculus.com/html/welcome/generate_new/?userid=' + userid + '&&start=' + start_generate + '&&end=' + end_generate);
    axios
        .get('https://web.mybiocalculus.com/html/welcome/generate_new/?userid=' + userid + '&&start=' + start_generate + '&&end=' + end_generate)
        .then(res => {
          console.log(res.data);    
          //window.location.href = '/';
          this.handleCloseModalReportSpinnerNewComplete(); 
          //https://web.mybiocalculus.com/assets/dist/user_records/61027a3d22c9f30b98519c48/Report61027a3d22c9f30b98519c48.pdf
          window.open('https://web.mybiocalculus.com/assets/dist/user_records/'+this.state.userid+'/Report'+this.state.userid+'.pdf', '_blank').focus(); 
        })
        .catch(err => {
            console.log(err);
            this.handleCloseModalReportSpinnerNewComplete();
        });
  }
// select report format
    analyzeDataset(e) { 
      var userid = this.state.userid;
            var databaseid = this.state.clickdata;
    /*  if(e.currentTarget.id=='ai')
    {
        console.log('Select',e.currentTarget.id);
        
        window.analyzeLink='/home/ubuntu/analysis_codes/ai_codes/full_analyze.py';
    }
    else if(e.currentTarget.id=='hybrid')
    {
        console.log('Select',e.currentTarget.id);
        window.analyzeLink='/home/ubuntu/analysis_codes/classic_peak/full_analyze.py';
    }
    else if(e.currentTarget.id=='classical')
    {
        console.log('Select',e.currentTarget.id);
        window.analyzeLink='/home/ubuntu/analysis_codes/classical_code/full_analyze.py';
    }
    else if(e.currentTarget.id=='temp1')
    {
        console.log('Select',e.currentTarget.id);
        window.analyzeLink='/home/ubuntu/analysis_codes/ai_codes/classic_negpeak/full_analyze.py';
    }
    else if(e.currentTarget.id=='temp2')
    {
        console.log('Select',e.currentTarget.id);
        window.analyzeLink='/home/ubuntu/analysis_codes/pvc_model/full_analyze.py';
    }
    console.log('window.analyzeLink',window.analyzeLink);
    console.log('uid',userid);
    console.log('uid',databaseid);*/
      if(window.analyzeLink!='')
      {
    var userid = this.state.userid;
    var databaseid = this.state.clickdata;
    e.currentTarget.innerHTML="Generating Reports.....";
    //const userid = this.props.match.params.userid;
    this.handleCloseModalNewComplete();
    this.handleOpenModalSpinnerNew();
    axios
        .get('https://web.mybiocalculus.com/html/welcome/analyzeAll/?userid=' + userid + '&&type=' + window.analyzeLink)
        .then(res => {
          console.log(res.data);    
          //window.location.href = '/'; 
          this.handleCloseModalReportSpinnerNewComplete(); 

        })
        .catch(err => {
            console.log(err);
            this.handleCloseModalReportSpinnerNewComplete();
        });
        
      }
  }
   /*  _onSelect(e)
  {
  
    if(e.value=='RPeaks')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/rpeaks_codes/full_analyze.py';
    }
    else if(e.value=='Classic')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classic_peak/full_analyze.py';
    }
    else if(e.value=='Classical')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classical_code/full_analyze.py';
    }
    else if(e.value=='Classic Negative')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classic_negpeak/full_analyze.py';
    }
    else if(e.value=='AI')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/ai_codes/full_analyze.py';
    }
    else if(e.value=='AIcodes')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/saroja_aicodes/full_analyze.py';
    }
   
  }
*/
    render(){
     /* const imageList = [{
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}, {
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}, {
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}, {
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
},{
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}, {
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}];*/
      const customStyles = {
  content : {
    background: "transparent",
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};
      const customStylesNew = {
  content : {
    
    top: "38%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    padding: "20px",
    height: "45%",
    width: "35%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};
   /*const options = [
         'RPeaks', 'Classic', 'Classical','Classic Negative', 'AI','AIcodes'
        ];
         const defaultOption = options[0];
    */    
  // console.log(this.state.showModalReportComplete)
      return(
           
          <React.Fragment>
          <ReactModal
          isOpen={this.state.showModalReportSpinnerComplete}

         style={customStyles}
          
        >
         <Loader
         type="Puff"
         color="#00BFFF"
         height={100}
         width={100}
       
 
      />
      <span>Analyzing...</span>
        </ReactModal>
            <ReactModal
          isOpen={this.state.showModalReportComplete}

         style={customStyles}
          contentLabel="Example Modal"
        >        
              <React.Fragment>
             
                  <div className="blog-pop-bg"></div>
                  <div className="blog-pop-box">
                  <button className="pull-right closeBtn" onClick={this.handleCloseModalReportComplete}>&#10006;</button>          
                      <div className="fea pull-left" style={{"marginRight":"50px","background":"white"}}>
                         <table>
                             <tr>
                                 <td>
                                        <button style={{"border":"none"}}><img onClick={this.openGeneratemodal.bind(this)}src="../../../../../dist/img/new_report.png" alt=""/></button>
                                        <button style={{"border":"none"}}><img onClick={this.openGeneratemodalNew.bind(this)}src="../../../../../dist/img/Old_Report.png" alt=""/></button>
                                 </td>
                             </tr>
                             <tr>
                                 <td>
                                   
                                 <select name="start_generate"  onChange= {this.onChange} style={{'color':'black','width':'50%'}}>{this.state.optionsnewone}</select>
                                 <select name="end_generate" onChange= {this.onChange} style={{'color':'black','width':'50%'}}> {this.state.optionsnewoneend}</select>
                                </td>
                                </tr>
                          
                          </table>
                        
                      </div>
                      
                     <br/>
                      
                      
                  </div>
              
               </React.Fragment>
          
        </ReactModal>

            
 </React.Fragment>
        
      )
    }
  }

  export default AddModalReport;