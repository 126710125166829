import React, {Component} from 'react';
import axios from 'axios';
import apiUrl from '../../config/config';
import classnames from 'classnames';
import $ from "jquery";

import TopNav from '../../components/common/TopNav';
import SideBar from '../../components/common/SideBar';

class UserHome extends Component {
  constructor() {
    super();
    this.state = {
        menus : [
            {},
        ],
        profileLink : 'user-profile',
        patients : [],
        smoking:'yes',      
        diabetes:'Na',
        af:'yes',
        hyper_tension:'yes',
        hyper_lipidemia:'yes',
        indication:'',
        userid:''
    };
  }

  componentWillReceiveProps(nextProps) { 
  	
  }

  componentDidMount(){
    axios
      .get(apiUrl+'api/patients/getAdditionalInfo')
      .then(res => {
        console.log(res.data);
        this.setState({ userid: res.data._id });
        if(res.data.diabetes){
          this.props.history.push('/user-chart')
        } else {
          $('#h_info_btn').click();
        }
      })
      .catch(err => {

      });  
  }

  onSubmitInfo = e => {
    e.preventDefault();

    const info = {
      userid:this.state.userid,
      smoking: this.state.smoking,
      diabetes: this.state.diabetes,
      af: this.state.af,
      hyper_tension: this.state.hyper_tension,
      hyper_lipidemia: this.state.hyper_lipidemia,
      indication: this.state.indication
    };

    console.log(info);
    axios
      .post(apiUrl+'api/patients/updateInfo',info)
      .then(res => {
        console.log(res.data);
        $( ".closePopup" ).click();
      })
      .catch(err => {
          console.log(err); 
      });
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }



  render() {
    if(localStorage.jwtToken==undefined) { window.location.href = '/'; }
    const { errors } = this.state;
    return (
        <div className="wrapper theme-1-active box-layout pimary-color-green">
        <TopNav profileLink={this.state.profileLink}/>
        <SideBar menus={this.state.menus} />
        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper">
            <div className="container-fluid">
                <div id="editInfoModal" className="modal fade" role="dialog">
                  <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title text-center">Additional Information</h4>
                        </div>
                        <form method="post" onSubmit={this.onSubmitInfo}>
                            <div className="modal-body">
                                <div className="row row-sm-offset">
                                    <br/>
                                    <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >Smoking Habits</label>
                                           <select 
                                           name="smoking" 
                                           className='form-control'
                                           value={this.state.smoking}
                                           onChange={this.onChange}
                                           >
                                               <option value="yes">Yes</option>
                                               <option value="no">No</option>
                                           </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >Diabetes</label>
                                           <select 
                                           name="diabetes" 
                                           className='form-control'
                                           value={this.state.diabetes}
                                           onChange={this.onChange}
                                           >
                                               <option value="Na">Na</option>
                                               <option value="Type 1">Type 1</option>
                                               <option value="Type 2">Type 2</option>
                                           </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-sm-offset">
                                    <br/>
                                    <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >Atrial Fibrillation</label>
                                           <select 
                                           name="smoking" 
                                           className='form-control'
                                           value={this.state.af}
                                           onChange={this.onChange}
                                           >
                                               <option value="yes">Yes</option>
                                               <option value="no">No</option>
                                           </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >Hypertension</label>
                                           <select 
                                           name="diabetes" 
                                           className='form-control'
                                           value={this.state.hyper_tension}
                                           onChange={this.onChange}
                                           >
                                               <option value="yes">Yes</option>
                                               <option value="no">NO</option>
                                           </select>
                                        </div>
                                    </div>
                                </div>   
                                <div className="row row-sm-offset">
                                    <br/>
                                    <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >Hyperlipidemia</label>
                                           <select 
                                           name="hyper_lipidemia" 
                                           className='form-control'
                                           value={this.state.hyper_lipidemia}
                                           onChange={this.onChange}
                                           >
                                               <option value="yes">Yes</option>
                                               <option value="no">No</option>
                                           </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >Indication</label>
                                           <input 
                                               type="text" 
                                               className='form-control'
                                               name="indication" 
                                               onChange={this.onChange}
                                               value={this.state.indication} 
                                           />
                                        </div>
                                    </div>
                                </div>     
                            </div>
                            <div className="modal-footer">
                                 <div className="row row-sm-offset">
                                    <div className="col-md-6 multi-horizontal">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                    <div className="col-md-6 multi-horizontal text-left">
                                        <button id="" type="button" className="btn btn-default closePopup" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                  </div>
                </div>
                <div className="row heading-bg">
                    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    </div>
                    <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                      <ol className="breadcrumb">
                        <li><a href="index.html">Home </a></li>
                        <li><a href="#"><span>Details</span></a></li>
                      </ol>
                    </div>
                </div>
                <div className="row">
                  <button id="h_info_btn" className="displayNone" data-toggle="modal" data-target="#editInfoModal">Test</button>
                </div>
            </div>
        </div>
    </div>
    )
  }
}


export default UserHome;