import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { updatePatinetProfile } from '../../actions/authActions';
import axios from 'axios';
import apiUrl from '../../config/config';

import TopNav from '../../components/common/TopNav';
import SideBar from '../../components/common/SideBar';

class UserProfile extends Component {


  constructor() {
    super();
    this.state = {
        menus : [
            {title:'Home',url:'/user-home'},
        ],
        profileLink : 'user-profile',
        name: '',
        phone: '',
        email: '',
        gender: 'M',
        dob:'',
        height:'',
        weight:'',
        weight_unit:'Kg',
        hospital:'',
        ip_op:'',
        id:'',
        userid:'',
        errors: {},
    };
  }

    componentDidMount() {
        axios
            .get(apiUrl+'api/patients/profile')
            .then(res => {        
                console.log(res.data);
                this.setState({
                  name: res.data.name,
                  phone: res.data.phone,
                  email: res.data.email,
                  gender: res.data.gender,
                  height: res.data.height,
                  weight: res.data.weight,
                  hospital: res.data.hospital,
                  ip_op: res.data.ip_op,
                  dob: res.data.dob,
                  id: res.data._id,
                  userid:res.data._id,
                });
                if(res.data.smoking==true){
                    this.setState({ smoking: 'yes' });
                } else if(res.data.smoking==false) {
                    this.setState({ smoking: 'no' });
                }
                if(res.data.af==true){
                    this.setState({ af: 'yes' });
                } else if(res.data.af==false) {
                    this.setState({ af: 'no' });
                }
                if(res.data.hyper_tension==true){
                    this.setState({ hyper_tension: 'yes' });
                } else if(res.data.hyper_tension==false) {
                    this.setState({ hyper_tension: 'no' });
                }
                if(res.data.hyper_lipidemia==true){
                    this.setState({ hyper_lipidemia: 'yes' });
                } else if(res.data.hyper_lipidemia==false) {
                    this.setState({ hyper_lipidemia: 'no' });
                }
                this.setState({ diabetes: res.data.diabetes });
                this.setState({ indication: res.data.indication });
            })
            .catch(err => {
            });
    }

    componentWillReceiveProps(nextProps) { 
        console.log(nextProps);
        if(nextProps.errors) {
            this.setState({errors: nextProps.errors});
        } 
    }

    onChange = e => {
        var err = this.state.errors;
        delete err[e.target.name];
        console.log(e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = e => {
        e.preventDefault();

        const user = {
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          gender: this.state.gender,
          doctorid: this.state.doctorid,
          terms: this.state.terms,
          dob:this.state.dob,
          height:this.state.height,
          weight:this.state.weight,
          hospital:this.state.hospital,
          ip_op:this.state.ip_op,
          smoking:'yes',      
          diabetes:'Na',
          af:'yes',
          hyper_tension:'yes',
          hyper_lipidemia:'yes',
          indication:'',
        };
        this.props.updatePatinetProfile(user,this.props.history);
    }

  onSubmitInfo = e => {
    e.preventDefault();

    const info = {
      userid:this.state.userid,
      smoking: this.state.smoking,
      diabetes: this.state.diabetes,
      af: this.state.af,
      hyper_tension: this.state.hyper_tension,
      hyper_lipidemia: this.state.hyper_lipidemia,
      indication: this.state.indication
    };

    console.log(info);
    axios
      .post(apiUrl+'api/patients/updateInfo',info)
      .then(res => {
        console.log(res.data);
        window.location.reload();
      })
      .catch(err => {
          console.log(err); 
      });
  }

  render() {
    if(localStorage.jwtToken==undefined) { window.location.href = '/'; }
    const { errors,patients } = this.state;


    return (
        <div className="wrapper theme-1-active box-layout pimary-color-green">
        <TopNav profileLink={this.state.profileLink}/>
        <SideBar menus={this.state.menus}/>
        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper">
            <div className="container-fluid">
                <div className="row heading-bg">
                    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <h5 className="txt-dark">Profile</h5>
                    </div>
                    <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                      <ol className="breadcrumb">
                        <li><a href="index.html">Home </a></li>
                        <li><a href="#"><span>Profile</span></a></li>
                      </ol>
                    </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <div className="panel panel-default card-view">
                      <div className="panel-wrapper collapse in">
                        <div className="panel-body">
                          <div className="panel-wrapper">
                           <div className="panel-body">
                              <div  className="pills-struct">
                                <ul role="tablist" className="nav nav-pills" id="myTabs_6">
                                  <li  className="active" role="presentation">
                                    <a  data-toggle="tab" id="chart_tab_2" role="tab" href="#pProfile" aria-expanded="false">Profile</a>
                                  </li>  
                                  <li role="presentation" className="">
                                    <a  data-toggle="tab" id="chart_tab_3" role="tab" href="#pProfileInfo" aria-expanded="false">Additional Inforamtion</a>
                                  </li>
                                </ul>
                                <div className="clearfix"></div>
                                <div className="tab-content" id="myTabContent_6">
                                  <div  id="pProfile" className="tab-pane fade active in" role="tabpanel">
                                    <div className="form-wrap mt-40">
                                     <form name="profile_form" action="" id="profile_form" method="POST" onSubmit={this.onSubmit}>
                                        <div className="row">
                                           <div className="col-md-6 col-sm-12">
                                              <div className="form-group">
                                                    <label className="control-label mb-10">Name</label>
                                                    <input 
                                                            type="text" 
                                                            className={classnames('form-control',{
                                                                'errors' : errors.name
                                                            })} 
                                                            name="name" 
                                                            required="" 
                                                            id="name" 
                                                            onChange={this.onChange}
                                                            value={this.state.name}
                                                            placeholder="Username" />
                                                </div>
                                           </div>
                                           <div className="col-md-6 col-sm-12">
                                              <div className="form-group">
                                                 <label className="control-label mb-10 text-left">Email</label>
                                                 <input 
                                                            type="text" 
                                                            className={classnames('form-control',{
                                                                'errors' : errors.email
                                                            })} 
                                                            name="email" 
                                                            required="" 
                                                            id="email" 
                                                            onChange={this.onChange}
                                                            value={this.state.email}
                                                            placeholder="Enter email" />
                                              </div>
                                           </div>
                                           <div className="col-md-6 col-sm-12">
                                              <div className="form-group">
                                                 <label className="control-label mb-10 text-left">Mobile Number</label>
                                                 <input 
                                                            type="number" 
                                                            className={classnames('form-control',{
                                                                'errors' : errors.phone
                                                            })} 
                                                            name="phone" 
                                                            required="" 
                                                            id="phone" 
                                                            onChange={this.onChange}
                                                            value={this.state.phone}
                                                            placeholder="Phone" />
                                              </div>
                                           </div>
                                           <div className="col-md-6 col-sm-12">
                                              <div className="form-group">
                                                 <label className="control-label mb-10">Height</label>
                                                 <input 
                                                        className={classnames('form-control',{
                                                            'errors' : errors.height
                                                        })}  
                                                        onChange={this.onChange}
                                                        value={this.state.height}
                                                        type="number" 
                                                        name="height" 
                                                        required="" 
                                                        id="height" 
                                                        placeholder="Height in cm" />
                                              </div>
                                           </div>
                                           <div className="col-md-6 col-sm-12">
                                              <div className="form-group">
                                                 <label className="control-label mb-10">Weight</label>
                                                 <input 
                                                        className={classnames('form-control',{
                                                            'errors' : errors.weight
                                                        })}  
                                                        onChange={this.onChange}
                                                        value={this.state.weight}
                                                        type="number" 
                                                        name="weight" 
                                                        required="" 
                                                        id="weight" 
                                                        placeholder="Enter Weight" />
                                              </div>
                                           </div>
                                           <div className="col-md-6 col-sm-12">
                                              <div className="form-group">
                                                 <label className="control-label mb-10">Hospital Name</label>
                                                 <input 
                                                        className={classnames('form-control',{
                                                            'errors' : errors.hospital
                                                        })}  
                                                        onChange={this.onChange}
                                                        value={this.state.hospital}
                                                        type="text" 
                                                        name="hospital" 
                                                        required="" 
                                                        id="hospital" 
                                                        placeholder="Enter Hospital Name" />
                                              </div>
                                           </div>
                                           <div className="col-md-6 col-sm-12">
                                              <div className="form-group">
                                                 <label className="control-label mb-10">IPOP Number</label>
                                                 <input 
                                                        className={classnames('form-control',{
                                                            'errors' : errors.ip_op
                                                        })}  
                                                        onChange={this.onChange}
                                                        value={this.state.ip_op}
                                                        type="text" 
                                                        name="ip_op" 
                                                        required="" 
                                                        id="ip_op" 
                                                        placeholder="Enter IPOP Number" />
                                              </div>
                                           </div>
                                           <div className="col-md-6 col-sm-12">
                                              <div className="form-group">
                                                 <label className="control-label mb-10">Date of Birth</label>
                                                 <div className="input-group date">
                                                        <input 
                                                            type="text" 
                                                            className={classnames('form-control',{
                                                                'errors' : errors.dob
                                                            })} 
                                                            value={this.state.dob}
                                                            onChange={this.onChange}
                                                            name="dob" 
                                                            id="datepicker" />
                                                        <span className="input-group-addon">
                                                            <span className="fa fa-calendar"></span>

                                                    </span></div>
                                              </div>
                                           </div>
                                           <div className="col-md-6 col-sm-12">
                                              <div className="form-group mb-30">
                                                 <label className="control-label mb-10 text-left">Gender</label>
                                                 <div className="radio radio-primary">
                                                    <input 
                                                    type="radio" 
                                                    name="gender" 
                                                    id="radio1" 
                                                    value="M" 
                                                    checked={this.state.gender === "M"} 
                                                    onChange={this.onChange}
                                                    />
                                                    <label htmlFor="radio1">
                                                    M
                                                    </label>
                                                 </div>
                                                 <div className="radio radio-primary">
                                                    <input 
                                                    type="radio" 
                                                    name="gender" 
                                                    id="radio2" 
                                                    value="F" 
                                                    checked={this.state.gender === "F"}
                                                    onChange={this.onChange}
                                                    />
                                                    <label htmlFor="radio2">
                                                    F
                                                    </label>
                                                 </div>
                                              </div>
                                           </div>
                                           <div className="col-md-6 col-sm-12">
                                              <div className="form-group mb-0">
                                                 <div className="   col-sm-10">
                                                    <button type="submit" className="btn btn-success"  id="profile_add"><span className="btn-text">Update</span></button>
                                                 </div>
                                              </div>
                                           </div>
                                        </div>
                                     </form>
                                    </div>
                                  </div>
                                  <div  id="pProfileInfo" className="tab-pane fade" role="tabpanel">
                                    <form method="post" onSubmit={this.onSubmitInfo}>
                                      <div className="modal-body">
                                          <div className="row row-sm-offset">
                                              <br/>
                                              <div className="col-md-6 multi-horizontal">
                                                  <div className="form-group">
                                                     <label className="form-control-label mbr-fonts-style display-7" >Smoking Habits</label>
                                                     <select 
                                                     name="smoking" 
                                                     className='form-control'
                                                     value={this.state.smoking}
                                                     onChange={this.onChange}
                                                     >
                                                         <option value="yes">Yes</option>
                                                         <option value="no">No</option>
                                                     </select>
                                                  </div>
                                              </div>
                                              <div className="col-md-6 multi-horizontal">
                                                  <div className="form-group">
                                                     <label className="form-control-label mbr-fonts-style display-7" >Diabetes</label>
                                                     <select 
                                                     name="diabetes" 
                                                     className='form-control'
                                                     value={this.state.diabetes}
                                                     onChange={this.onChange}
                                                     >
                                                         <option value="Na">Na</option>
                                                         <option value="Type 1">Type 1</option>
                                                         <option value="Type 2">Type 2</option>
                                                     </select>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row row-sm-offset">
                                              <br/>
                                              <div className="col-md-6 multi-horizontal">
                                                  <div className="form-group">
                                                     <label className="form-control-label mbr-fonts-style display-7" >Atrial Fibrillation</label>
                                                     <select 
                                                     name="smoking" 
                                                     className='form-control'
                                                     value={this.state.af}
                                                     onChange={this.onChange}
                                                     >
                                                         <option value="yes">Yes</option>
                                                         <option value="no">No</option>
                                                     </select>
                                                  </div>
                                              </div>
                                              <div className="col-md-6 multi-horizontal">
                                                  <div className="form-group">
                                                     <label className="form-control-label mbr-fonts-style display-7" >Hypertension</label>
                                                     <select 
                                                     name="diabetes" 
                                                     className='form-control'
                                                     value={this.state.hyper_tension}
                                                     onChange={this.onChange}
                                                     >
                                                         <option value="yes">Yes</option>
                                                         <option value="no">NO</option>
                                                     </select>
                                                  </div>
                                              </div>
                                          </div>   
                                          <div className="row row-sm-offset">
                                              <br/>
                                              <div className="col-md-6 multi-horizontal">
                                                  <div className="form-group">
                                                     <label className="form-control-label mbr-fonts-style display-7" >Hyperlipidemia</label>
                                                     <select 
                                                     name="hyper_lipidemia" 
                                                     className='form-control'
                                                     value={this.state.hyper_lipidemia}
                                                     onChange={this.onChange}
                                                     >
                                                         <option value="yes">Yes</option>
                                                         <option value="no">No</option>
                                                     </select>
                                                  </div>
                                              </div>
                                              <div className="col-md-6 multi-horizontal">
                                                  <div className="form-group">
                                                     <label className="form-control-label mbr-fonts-style display-7" >Indication</label>
                                                     <input 
                                                         type="text" 
                                                         className='form-control'
                                                         name="indication" 
                                                         onChange={this.onChange}
                                                         value={this.state.indication} 
                                                     />
                                                  </div>
                                              </div>
                                          </div>     
                                      </div>
                                      <div className="">
                                           <div className="row row-sm-offset">
                                              <div className="col-md-12 text-right">
                                                  <button type="submit" className="btn btn-success">Submit</button>
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                                  </div>
                                </div>
                              </div>
                              
                           </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}

UserProfile.propTypes = {
    updatePatinetProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps, { updatePatinetProfile })(withRouter(UserProfile));