import React, {Component} from 'react'
import axios from 'axios';
import apiUrl from '../../config/config';

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import TopNav from '../../components/common/TopNav';
import SideBar from '../../components/common/SideBar';

class HeartRateTab extends Component {

  constructor() {
    super();
    this.state = {
      menus : [
        {},
      ],
      min_hr:'',
      max_hr:'',
      avg_heartrate:'',
      total_beatscount:'',
      total_analyzed_mins:'',
      duration:'',
      sdnn:'',
      rmssd:'',
      pnni_50:'',
      sdnni:'',
      sdann:'',
      vet:'',
      vpt:'',
      vrt:'',
      max_pause:''
    };

  }

  onChange = e => {
    var err = this.state.errors;
    delete err[e.target.name];
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    var userid = this.props.userid;
    axios
      .get(apiUrl+'api/common/getPatientTotalDetails?userid='+userid)
      .then(res => {  
        var pvcArray = res.data.pvc.split(",");
        this.setState({
          'vet':pvcArray[0]>0?pvcArray[0]:0
        });
        this.setState({
          'vpt':pvcArray[5]>0?pvcArray[5]:0
        });
        this.setState({
          'vrt':pvcArray[7]>0?pvcArray[7]:0
        });
        var sinus_pauseArray = res.data.sinus_pause.split(",");
        this.setState({
          'max_pause':sinus_pauseArray[5]>0?pvcArray[5]:0
        });
        this.setState({
          min_hr:res.data.min_hr,
          max_hr:res.data.max_hr,
          avg_heartrate:res.data.avg_heartrate,
          avg_heartrate:res.data.avg_heartrate,
          total_analyzed_mins:res.data.total_analyzed_mins,
          duration:res.data.duration,
          sdnn:res.data.sdnn,
          sdnni:res.data.sdnni,
          pnni_50:res.data.pnni_50,
          rmssd:res.data.rmssd,
          sdann:res.data.sdann
        });
      })
      .catch(err => {
        console.log(err);
      });

    var sdnndata=[],pNNdata=[],seriesData=[],sdnndataArray=[],rmssddata=[]; 
    axios
      .get(apiUrl+'api/common/getGraphplot?userid='+userid)
      .then(res => { 
        //console.log(res.data.data);
        for (var i = 0; i < res.data.data.length; i++) {
           //console.log(res.data.data[i]);   
           sdnndataArray = res.data.data[i].split(",");
           console.log(sdnndataArray);
            sdnndata.push(parseInt(sdnndataArray[2]));
            rmssddata.push(parseInt(sdnndataArray[3]));
            pNNdata.push(parseInt(sdnndataArray[4]));
            seriesData.push(sdnndataArray[0]+'/'+sdnndataArray[1]);
        }

        var sdnnChart=Highcharts.chart('sdnnChart', {
          chart: {
            type: 'column',
            height:'50%',
            width:'400',
            styledMode: false
          },
          exporting: { enabled: false },
          credits: {
            enabled: false
          },
          title: {
              text: ''
          },
          xAxis: {
            min:0,
            max:10,
            scrollbar: {
              enabled: true,     
            },
            gridLineWidth: 0,   
            allowDecimals:false,
            labels: {
                step: 1,
                tickInterval:1,
            style: {
              color: 'black',
                 fontSize: '12px'
            }, 
             formatter: function() { return seriesData[this.value];},

            }
          },
          yAxis: {
            min: 0,
            title: {
              text: ''
            },
            visible: true,
            labels: {
              enabled: true,       
            },
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            style: {
              color: 'black',
              fontSize: '12px'
            }
          },
          plotOptions: {
            series: {
              colorByPoint: false,
              shadow:false,
              borderWidth:0,
            }
          },

          series: [{
            showInLegend: false,  
            data: sdnndata
          }]
        });

        var rmssd=Highcharts.chart('rmssdChart', {
          chart: {
            type: 'column',
            height:'50%',
            width:'400'
          },
          exporting: { enabled: false },
          credits: {
            enabled: false
          },
          title: {
              text: ''
          },

          xAxis: {
            min:0,
            max:10,
            scrollbar: {
              enabled: true,
            },   
            allowDecimals:false,
            labels: {
              step: 1,
              tickInterval:1,
              style: {
                color: 'black',
                fontSize: '12px'
              }, gridLineWidth: 0,
              visible: false,
             formatter: function() { return seriesData[this.value];},
            }
          },
          yAxis: {             
            min: 0,
            title: {
              text: ''
            },
            visible: true,
            labels: {
              enabled: true,      
            },
            gridLineWidth: 0,
            style: {
              color: 'black',
              fontSize: '12px'
            }
          },
          
          plotOptions: {
            series: {
              colorByPoint: false,
              shadow:false,
              borderWidth:0,                
            }
          },
          series: [{
            showInLegend: false,  
            data: rmssddata 
          }]
        });

        var pNNChart=Highcharts.chart('pNNChart', {
          chart: {
            type: 'column',
            height:'50%',
            width:'400',
            styledMode: false
          },
          exporting: { enabled: false },
          credits: {
            enabled: false
          },
          title: {
              text: ''
          },
          xAxis: {
            min:0,
            max:10,
            scrollbar: {
              enabled: true,     
            },
            gridLineWidth: 0,   
            allowDecimals:false,
            labels: {
                step: 1,
                tickInterval:1,
            style: {
              color: 'black',
                 fontSize: '12px'
            }, 
             formatter: function() { return seriesData[this.value];},

            }
          },
          yAxis: {
            min: 0,
            title: {
              text: ''
            },
            visible: true,
            labels: {
              enabled: true,       
            },
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            style: {
              color: 'black',
              fontSize: '12px'
            }
          },
          plotOptions: {
            series: {
              colorByPoint: false,
              shadow:false,
              borderWidth:0,
            }
          },

          series: [{
            showInLegend: false,  
            data: pNNdata
          }]
        });

      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="panel-wrapper collapse in">
       <div className="panel panel-info">
          <div className="panel panel-success">
             <div className="panel-heading text-center" aria-expanded="true" data-toggle="collapse" href="#Events">HEART RATE</div>
             <div className="panel-body" id="Events">
                <div className="row">
                   <div className="col-xs-4 col-sm-4 text-center">
                      <strong>Minimum Heart Rate(HR)<span>:</span> </strong>{this.state.min_hr} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                   </div>
                   <div className="col-xs-4 col-sm-4 text-center">
                      <strong>Maximum Heart Rate(HR)<span>:</span></strong> {this.state.max_hr} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                   </div>
                   <div className="col-xs-4 col-sm-4 text-center">
                      <strong>Average Heart Rate(HR)<span>:</span> </strong> {this.state.avg_heartrate}<br/><br/>
                   </div>
                </div>
                <div className="row">
                   <div className="col-xs-4 col-sm-4 text-center">
                      <strong>Analyzed beats<span>:</span></strong>{this.state.total_beatscount} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                   </div>
                   <div className="col-xs-4 col-sm-4 text-center">
                      <strong id="analyzed">Analyzed minutes<span id="analyzed1">:</span></strong> {this.state.total_analyzed_mins} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                   </div>
                   <div className="col-xs-4 col-sm-4 text-center">
                      <strong id="ecgm">ECG monitoring period<span>:</span> </strong> {this.state.duration} 
                   </div>
                </div>
             </div>
          </div>
          <div className="panel panel-success">
             <div className="panel-heading text-center" aria-expanded="true" data-toggle="collapse" href="#Events">HEART RATE VARIABILITY</div>
             <div className="panel-body" id="Events">
                <div className="row">
                   <div className="col-sm-12 text-center">
                      <strong>SDNN-24-Hour: </strong> {this.state.sdnn} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>rMSSD: </strong> {this.state.rmssd}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>pNN50: </strong> {this.state.pnni_50}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>SDNN Index: </strong>{this.state.sdnni} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>SDANN Index: </strong>{this.state.sdann} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                  
                   </div>
                </div>
             </div>
          </div>
          <div className="panel panel-success">
             <div className="panel-heading text-center" aria-expanded="true" data-toggle="collapse" href="#Events">VENTRICULAR ECTOPY</div>
             <div className="panel-body" id="Events">
                <div className="row">
                   <div className="col-sm-12 text-center">
                      <strong>VE Total: </strong> {this.state.vet}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>V-Pair total: </strong>{this.state.vpt}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>V-Run total: </strong> {this.state.vrt}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                  
                   </div>
                </div>
             </div>
          </div>
          <div className="panel panel-success">
             <div className="panel-heading text-center" aria-expanded="true" data-toggle="collapse" href="#Events">PAUSES</div>
             <div className="panel-body" id="Events">
                <div className="row">
                   <div className="col-sm-12 text-center">
                      <strong>Pauses in excess of 2.50 seconds </strong><br/>
                      <strong>Max pause: </strong> {this.state.max_pause}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                  
                   </div>
                </div>
             </div>
          </div>
          <div className="panel panel-success">
             <div className="panel-heading text-center" aria-expanded="true" data-toggle="collapse" href="#Events">HOURLY ANALYSIS</div>
             <div className="panel-body" id="Events">
                <div className="row">
                   <div className="col-md-4">
                      <h6 className="text-center">SDNN(ms) </h6>
                      <div id="sdnnChart" ></div>
                      <br/><br/><br/>
                   </div>
                   <div className="col-md-4">
                      <h6 className="text-center">rMSSD(ms)</h6>
                      <div id="rmssdChart"><br/><br/><br/>
                      </div>
                   </div>
                   <div className="col-md-4">
                      <h6 className="text-center">pNN50(%)</h6>
                      <div id="pNNChart" ></div>
                      <br/><br/><br/>
                   </div>
                </div>
                <div className="row">
                   <div className="col-md-2"></div>
                   <div className="col-md-4">
                      <h4 className="text-center">Lorentz Plot - All beats</h4>
                      <img className="graph_image" src="https://app.mybiocalculus.com/assets/dist/user_records/5/lorentz_allbeats.png" />
                   </div>
                   <div className="col-md-4">
                      <h4 className="text-center">Lorentz Plot - Normal Beats</h4>
                      <img className="graph_image" src="https://app.mybiocalculus.com/assets/dist/user_records/5/lorentz_normalbeats.png" />
                   </div>
                </div>
             </div>
          </div>
       </div>
      </div>
    )
  }
}

export default HeartRateTab;