import React, {Component} from 'react';
import axios from 'axios';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDoctor } from '../../actions/authActions'
import apiUrl from '../../config/config';
import { MDBDataTable } from 'mdbreact';

import TopNav from '../../components/common/TopNav';
import SideBar from '../../components/common/SideBar';


class DoctorsList extends Component {
  constructor() {
    super();
    this.state = {
        menus : [
            {title:'Doctors List',url:'/doctors-list'},
            {title:'Verifiers List',url:'/verifiers-list'},
            {title:'Patient List',url:'/admin-home'},
        ],
        profileLink : 'admin-profile',
        docotors : [],
        data: ['foo', 'bar', 'baz'],
        name:'',
        email:'',
        phone:'',
        address:'',
        doctorId:'',
        errors: {},
        data:{}
    };
    this.selectItem = this.selectItem.bind(this);
  }

  selectItem(el){
    var data = el.currentTarget.value;
    data = JSON.parse(data);
    console.log(data);
    this.setState({name: data.name});
    this.setState({email: data.email});
    this.setState({phone: data.phone});
    this.setState({address: data.address});
    this.setState({doctorId: data._id});
  }

  onSubmit = e => {
        e.preventDefault();

        const doctor = {
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          address: this.state.address,
          id: this.state.doctorId
        };
        //console.log(Doctor);
        this.props.updateDoctor(doctor,this.props.history,'doctors-list');
  }

  componentDidMount(){
    axios
        .get(apiUrl+'api/users/alldoctors')
        .then(res => {        
            this.setState({
              docotors: res.data
            });

            const data = {
              columns: [
                {
                  label: 'SL NO',
                  field: 'slno',
                  sort: 'asc',
                  width: 50
                },
                {
                  label: 'NAME',
                  field: 'name',
                  sort: 'asc',
                  width: 100
                },
                {
                  label: 'PHONE',
                  field: 'phone',
                  sort: 'asc',
                  width: 150
                },
                {
                  label: 'Action',
                  field: 'action',
                  sort: 'disabled',
                  width: 200
                }
              ],
              rows: []
            };
            var onClick = this.selectItem.bind(this);
            res.data.map(function(docotor,i) {
                data.rows.push({
                    slno: i+1,
                    name:docotor.name,
                    phone: docotor.phone,
                    action: <button onClick={onClick} value={JSON.stringify(docotor)} type="button"  className="btn btn-warning" data-toggle="modal" data-target="#myModal">Edit</button>
                });
            })
            this.setState({data:data});
        })
        .catch(err => {
        });

  }

  componentWillReceiveProps(nextProps) { 
        if(nextProps.errors) {
            this.setState({errors: nextProps.errors});
        } 
  }

  onChange = e => {
        var err = this.state.errors;
        delete err[e.target.name];
        this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;   

    return (
        <div className="wrapper theme-1-active box-layout pimary-color-green">
        <TopNav profileLink={this.state.profileLink}/>
        <SideBar menus={this.state.menus} />
        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper">
            <div className="container-fluid">
                <div id="myModal" className="modal fade" role="dialog">
                  <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Edit </h4>
                        </div>
                        <form method="post" action="" id="" onSubmit={this.onSubmit}>
                            <div className="modal-body">
                                    
                                    <div className="form-group">
                                        <label for="email">Name:</label>
                                        <input 
                                                    type="text" 
                                                    className={classnames('form-control',{
                                                        'errors' : errors.name
                                                    })} 
                                                    name="name" 
                                                    required="" 
                                                    id="name" 
                                                    onChange={this.onChange}
                                                    value={this.state.name}
                                                    placeholder="Username" />
                                    </div>
                                    <div className="form-group">
                                        <label for="pwd">Email:</label>
                                        <input 
                                            type="text" 
                                            className={classnames('form-control',{
                                                'errors' : errors.email
                                            })} 
                                            name="email" 
                                            required="" 
                                            id="email" 
                                            onChange={this.onChange}
                                            value={this.state.email}
                                            placeholder="Enter email" />
                                    </div>
                                    <div className="form-group">
                                        <label for="pwd">Address:</label>
                                        <textarea 
                                            className="form-control" 
                                            name="address" 
                                            id="address"
                                            onChange={this.onChange}
                                            value={this.state.address}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="pwd">phone:</label>
                                        <input 
                                            type="number" 
                                            className={classnames('form-control',{
                                                'errors' : errors.phone
                                            })} 
                                            name="phone" 
                                            required="" 
                                            id="phone" 
                                            onChange={this.onChange}
                                            value={this.state.phone}
                                            placeholder="Phone" />
                                    </div>
                            </div>
                            <div className="modal-footer">
                                  <button type="submit" className="btn btn-success">Submit</button>
                                  <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </form>
                    </div>

                  </div>
                </div>
                <div className="row heading-bg">
                    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <h5 className="txt-dark">Doctors List</h5>
                    </div>
                    <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                      <ol className="breadcrumb">
                        <li><a href="index.html">Home </a></li>
                        <li><a href="#"><span>Doctors</span></a></li>
                      </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-default card-view">
                            <div className="panel-heading">
                                <div className="pull-left">
                                    <h6 onClick={this.loadDoctor} className="panel-title txt-dark">Doctors List</h6>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="panel-wrapper collapse in">
                                <div className="panel-body">
                                    <MDBDataTable
                                      noBottomColumns
                                      data={this.state.data}
                                    />
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}

DoctorsList.propTypes = {
    registerPatient: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps, { updateDoctor })(withRouter(DoctorsList));