import React, {Component} from 'react';
import axios from 'axios';
import apiUrl from '../../config/config';

class DoctorFindingsTab extends Component {

  constructor() {
    super();
    this.state = {
      findings:"",
      userid:''
    };
  }

  onSubmit = e => {
    e.preventDefault();

    const settings = {
      userid:this.state.userid,
      findings:this.state.findings
    };

    console.log(settings);
    axios
      .post(apiUrl+'api/common/updateFindings',settings)
      .then(res => {
          alert('Updated') 
      })
      .catch(err => {
          console.log(err); 
      });
  }  

  componentDidMount(){
    var userid = this.props.userid;
    this.setState({ userid: userid });
    axios
      .get(apiUrl+'api/common/getPatientFindings?userid='+userid)
      .then(res => {  
        if(res.data.findings){
          this.setState({
            findings:res.data.findings
          })
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  onChange = e => {
    var err = this.state.errors;
    this.setState({ 'findings': e.target.value });
  }
        
  render() {
    var userid = this.props.userid;
    var databaseid = this.props.databaseid.replace(/:/g, '_');
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-default card-view">
            <div className="panel-wrapper collapse in">
              <div className="panel-body">
                <h2>Doctor Findings</h2>
                <br/>
                <p>{this.state.findings}</p>
              </div>
            </div>
          </div>
        </div>
      </div> 
    )
  }
}

export default DoctorFindingsTab;