import React, {Component} from 'react';
import axios from 'axios';
import apiUrl from '../../config/config';
import { MDBDataTable } from 'mdbreact';

import TopNav from '../../components/common/TopNav';
import SideBar from '../../components/common/SideBar';

class VerifiersList extends Component {
  constructor() {
    super();
    this.state = {
        menus : [
            {title:'Doctors List',url:'/doctors-list'},
            {title:'Verifiers List',url:'/verifiers-list'},
            {title:'Patient List',url:'/admin-home'},
        ],
        profileLink : 'admin-profile',
        verifiers : []
    };
  }

  componentWillReceiveProps(nextProps) { 
  	
  }

  componentDidMount(){
    axios
        .get(apiUrl+'api/users/allverifiers')
        .then(res => {       
        console.log(res.data); 
            this.setState({
              verifiers: res.data
            });
        })
        .catch(err => {
        });
  }


  render() {

    const data = {
      columns: [
        {
          label: 'SL NO',
          field: 'slno',
          sort: 'asc',
          width: 50
        },
        {
          label: 'NAME',
          field: 'name',
          sort: 'asc',
          width: 100
        },
        {
          label: 'PHONE',
          field: 'phone',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Action',
          field: 'action',
          sort: 'disabled',
          width: 200
        }
      ],
      rows: []
    };

    this.state.verifiers.map(function(verifier,i) {
        data.rows.push({
            slno: i+1,
            name:verifier.name,
            phone: verifier.phone,
            action: <a href={'/edit-varifier/'+verifier._id}>
                        <button type="button" className="btn btn-warning">Edit</button>
                    </a>
        });
    }) 

    return (
        <div className="wrapper theme-1-active box-layout pimary-color-green">
        <TopNav profileLink={this.state.profileLink}/>
        <SideBar menus={this.state.menus} />
        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper">
            <div className="container-fluid">
                <div className="row heading-bg">
                    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <h5 className="txt-dark">Verifiers List</h5>
                    </div>
                    <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                      <ol className="breadcrumb">
                        <li><a href="index.html">Home </a></li>
                        <li><a href="#"><span>Verifiers</span></a></li>
                      </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-default card-view">
                            <div className="panel-heading">
                                <div className="clearfix">
                                    <a href="/add-varifier">
                                        <button type="button" className="btn btn-success"><i className="fa fa-plus"></i> Add Verifier</button>
                                    </a>
                                </div>
                            </div>
                            <div className="panel-wrapper collapse in">
                                <div className="panel-body">
                                    <MDBDataTable
                                      noBottomColumns
                                      data={data}
                                    />
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}


export default VerifiersList;