import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import socketIOClient from "socket.io-client";
import axios from 'axios';
/*const socket = socketIOClient('http://edit.mybiocalculus.com:5000');*/
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    float:"left",
     height: "42px",
     marginLeft:"9%"
  },
  wrapper: {
  
    position: "relative"
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  linearProgress: {
    position: "absolute",
    top: 0,
    width: "155px",
    height: "100%",
    opacity: 0.4,
    borderRadius: 4
  }
}));

export default function CircularIntegration(props) {
  const socket=props.socket;
   var anl_btn_txt = 'Analyze';
   var anl_btn_class = 'btn-danger';
    const [loading, setLoading] = React.useState(false);
    const [idVal, setPatientID] = React.useState(" ");
    const [pro, setPro] = React.useState("0%");
  const [success, setSuccess] = React.useState(false);
  socket.on('connect', function(){


   
    console.log('connected')
  });

    socket.on('connect_error', function(err){console.log(err)});
    socket.on('data', function(data){

      var res = data.split("#");
      //console.log("progress",data);
      /*newstat.setState({"loading":true});*/
      if(res[0]==props.id)
      {
      setPatientID(res[0]);  
      setPro(res[1]+"-"+res[2]+"%"); 
      setLoading(true);
      if(parseInt(res[2])>=100)
      {

        
         setPro("Completed");
         //socket.emit('data',props.id+"#"+"R"+100)
         anl_btn_txt="Completed"
         //socket.disconnect();
         //window.location.href = '/';
         //setLoading(false);

      }
      //socket.disconnect();
    }
      //console.log(data)
    });
    socket.on('disconnect', function(){console.log('disconnected')});
  const classes = useStyles();

  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success
  });

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
  React.useEffect( () => {
        console.log('counter updated');
    }, [props.counter])

 function retrieve(e)
  {
     //console.log('Retrieve',e.currentTarget.id);
  
                var userid = e.currentTarget.id;
                
               
                if(e.currentTarget.innerHTML=="Retrieve")
                {
                if (window.confirm('Do you want to retrieve the data from backup?')) {
                  e.currentTarget.innerHTML="Retrieving";
             axios
                .get('https://web.mybiocalculus.com/html/Welcome/backupData/' + userid)
                .then(res => {
                    if (res.data != null) {
                        console.log(res.data);
                    } else {
                        console.log('nothing');
                    }
                })
                .catch(err => {
                    console.log(err);
                });
                

                 }
               }
               else
               {

               }
            
    
    
  }


                
                
               if(props.bc_stat==0||props.bc_stat==undefined) {
                  anl_btn_txt = 'Analyzed';
                  anl_btn_class= 'btn-success';
                }
                else if(props.bc_stat==1){
                   anl_btn_txt = 'Retrieve';
                   anl_btn_class= 'btn-danger';
                }
                 else if(props.bc_stat==2){
                   anl_btn_txt = 'Retrieving';
                   anl_btn_class= 'btn-success';
                }
  return (

    <div className={classes.root}>
      <div className={classes.wrapper}>
      { props.id==idVal ?(
       <button type="button" id={props.id}    className={"btn mr-20 "+anl_btn_class} >{pro}</button>
       )
       :
       (<button type="button" id={props.id} onClick={props.bc_stat==1?retrieve:null}  className={"btn mr-20 "+anl_btn_class}>{anl_btn_txt}</button>
       )}
       

     
      </div>
    </div>
  );
}
