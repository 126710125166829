import React, {
    Component
} from 'react';
import classnames from 'classnames';
import axios from 'axios';
import apiUrl from '../../config/config';
import $ from "jquery";
import {
    connect
} from 'react-redux';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types';
import TopNav from '../../components/common/TopNav';

import BeatsTabVerifier from '../verifier/BeatsTabVerifier';

import SettingsTab from '../../components/graph/SettingsTab';

import SideBarGraph from '../verifier/UnanalysedSideBarGraph';
import AddFindingsTab from '../../components/graph/AddFindingsTab';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const deviceFrequency = 15000;
let graphColor = '';
let gainMin = -0.0005;
let gainMax = 0.0015;
let clickContainerIndex = 0;
let chartCount = 0;
let pageIndex = 0;
window.tab = 'beat';
require('highcharts-multicolor-series')(Highcharts);

class DoctorUnanalysed extends Component {

    constructor() {
        super();
        /********* State Variables*************/


        this.state = {
            menus: [{
                title: 'Patient List',
                url: '/doctor-home'
            }, ],
            profileLink: '/doctor-profile',
            treemenu: [],
            clickedPI: '', //used in updateState()
            rpeaks: [], //For Summar Tab
            databaseid: '',
            reoprtLink: '',
            reportLinkStatus: 0,
            reportStatus: '',
            images: {},
            dblink: '', // check whether it is same as the previous databaseid
            nextDbId: '',
            prevDbId: '',
            fullVerifyStatus: 100,
            chart: '',
            result3: [],
            charts: [],
            nsrarr: [],
            starr: [],
            unarr: [],
            sbarr: [],
            vtarr: [],
            sparr: [],
            lqtarr: [],
            sqtarr: [],
            irrrarr: [],
            irprarr: [],
            afarr: [],
            pacarr: [],
            pvcarr: [],
            paarr: [],
            defarr: [],
            selectedChart: 0, //Check Whether it is needed
            mainEcgArray: [],
            mainActivityArray: [],
            datasetMnt: 0, //Check whether we need a state variable for this
            currentTab: 'chart_tab_2',
            start: 0,
            end: 9,
            limitScrollStart: 1,
            limitScrollEnd: 1,
            peakStart: 0,
            graphMarker: {},
            patientMenuTree: {},
            verifyCheck: '',
            patientReportStatus: '',
            scrollStatus: '', //Remove this variable's usages then delete this
            summaryData: '',
            keyIntegrationStatus: 0
        };



        /********* State Variables*************/

        this.updateState = this.updateState.bind(this); //Exeutes On Double click on Event Image
        this.selectImage = this.selectImage.bind(this);
        this.updateRpeaks = this.updateRpeaks.bind(this); //saves peakvalues to state variable
        this.generateReport = this.generateReport.bind(this); //Executes on Generate Report Button
        this.mailReport = this.mailReport.bind(this); //Mails Report
        this.newDataClick = this.newDataClick.bind(this); //For navigation to Next/Previous database.Currently displayed None
        this.updateMainChartODC = this.updateMainChartODC.bind(this); //Exeutes On Double click on Event Image within updateState()
        this.recategorizeAfterImage = this.recategorizeAfterImage.bind(this);
    }

    setTab(e) {
        var currentTab = e.currentTarget.id;
        this.setState({
            currentTab: currentTab
        });
    }

    
    selectImage(e) {
        
         var folder = window.eventclass;
        console.log("folder");
        console.log(e.currentTarget.className.split(" ")[3]);
        if(e.currentTarget.className.split(" ")[3]==folder)
        {
            if(e.currentTarget.childNodes[1].checked == true)
            {
            e.currentTarget.childNodes[1].checked = false;
        }
        else
        {
             e.currentTarget.childNodes[1].checked = true;
        }
            console.log(e.currentTarget.childNodes[1].checked);
       
        }
        else{

            e.currentTarget.childNodes[1].checked = false;
        }
        
    }

    updateState(e) {
        window.tab = 'event';


        var clickedPI = e.currentTarget.id.toString();
        var clickedPI_ = clickedPI.substring(0, 2) + ':' + clickedPI.substring(2, 4) + ':' + clickedPI.substring(4, 6);
        var userid = this.props.match.params.userid;
        var databaseid = this.props.match.params.databaseid;
        var usSelf = this;

        if (typeof databaseid === 'undefined') {
            databaseid = "0";
        }
        const data = {
            userid: userid,
            databaseid: databaseid,
            clickedPI_: clickedPI_
        };
        console.log('clickedPI_', clickedPI_);
        axios
            .post(apiUrl + 'api/common/getRpeakIndex', data)
            .then(response => {
                var clickedPI = response.data.rpeakindex;
                //console.log("clickedPI",clickedPI);
                console.log('clickedPI', clickedPI);
                document.getElementById('chart_tab_2').click();
                if (clickedPI > 15000) {
                    var stripIndex = Math.floor(clickedPI / 15000);
                } else {
                    var stripIndex = 0;
                }
                 
                
                window.chartid = 0;

                window.stripXValue = parseInt(clickedPI) - (stripIndex * 15000);
                var start = stripIndex,
                    end = stripIndex + 9;
                    console.log('starts', start);
                    console.log('prevStart',window.prevStart);
                     window.container=start;
                usSelf.setState({
                    start: start,
                    end: end

                });

               
                setTimeout(function() {
                    if(window.prevStart<=start)
                    {
                        if($('#selWrap').position().top -  $('#container' + start).position().top   !=0)
                        {      $('#selWrap').scrollTop(0);
                              $('#selWrap').scrollTop(  $('#container' + start).position().top -$('#selWrap').position().top );
                       
                        window.prevStart=start;
                         }
                  
                    }
                    else if(window.prevStart>start)
                    {

                          $('#selWrap').scrollTop(0);
                         $('#selWrap').scrollTop(  $('#container' + start).position().top -$('#selWrap').position().top );
                          window.prevStart=start;
                    }


                }, 1000);

            })
            .catch(err =>
                console.log(err)
            );
    }

    updateMainChartODC(starts, ends) {

       

        var userid = userid;
        var mainEcgArray, mainActivityArray = [];
        var result, result2, result3, colorArray = [];
        var chart;
        var parr = [],
            tarr = [],
            qarr = [],
            rarr = [],
            sarr = [],
            ponarr = [],
            poffarr = [],
            tonarr = [],
            toffarr = [],
            jarr = [];
        var peakColorArray = [];
        peakColorArray['Undiagnosed'] = '#FCDF85';
        peakColorArray['Normal Sinus Rhythm'] = '#98FB98';
        peakColorArray['Sinus Tachycardia'] = '#3EFFDE';
        peakColorArray['PAC'] = '#4950EB';
        peakColorArray['PVC'] = '#FF45DC';
        peakColorArray['P absent'] = '#AEB9BD';
        peakColorArray['Sinus Bradycardia'] = '#CFFF0F';
        peakColorArray['Sinus Pause'] = '#FF5B05';
        peakColorArray['Irregular RR'] = '#A4C3FF';
        peakColorArray['Ventricular Tachycardia'] = '#F0A7A1';
        peakColorArray['Irregular PR'] = '#BBEB8F';
        peakColorArray['Short QT'] = '#FFD14F';
        peakColorArray['Prolonged QT'] = '#7C2F09';
        peakColorArray['AFib'] = '#A96BFF';

        function chunkPeakArray(peakArray, start = 0) {
            //console.log(peakArray);
            let presults = [];
            var minIndex = 0;
            var breakVal = (deviceFrequency * (window.pageIndex + 1));
            var z;
            var temRpeakindex;

            presults.push([]);
            for (var i = 0; i < peakArray.length; i++) {
                if (window.pageIndex * deviceFrequency < peakArray[i].rpeakindex) {
                    if (peakArray[i].rpeakindex > breakVal) {
                        presults.push([]);
                        minIndex = minIndex + 1;
                        breakVal = breakVal + deviceFrequency;
                    }
                    /*console.log(peakArray[i].rpeakindex);
                    console.log(minIndex);
                    console.log(peakArray[i].rpeakindex);
                    console.log(deviceFrequency);*/
                    temRpeakindex = parseInt(peakArray[i].rpeakindex) - ((minIndex + window.pageIndex) * deviceFrequency);
                    presults[minIndex].push({
                        x: temRpeakindex,
                        y: parseFloat(0.8),
                        color: peakColorArray[peakArray[i].arrhythmia],
                        beat: parseInt(peakArray[i].indexcounter),
                        hr: parseInt(peakArray[i].heartrate),
                        rr: parseInt(peakArray[i].rr_interval),
                        arrhythmia: peakArray[i].arrhythmia,
                        peaktime: peakArray[i].peaktime
                    });
                }
            }
            return presults;
        }

       
            

            var sliceStart = 0;
            var sliceEnd = sliceStart + 15001;
            var sliceActStart = 0;
            var sliceActEnd = sliceActStart + 61;
            var currentEcgArray = this.ecgValues.slice(sliceStart, sliceEnd);
            var currentActArray = this.activityValues.slice(sliceActStart, sliceActEnd);
            
           
            //console.log(sliceStart+" "+sliceEnd);
            var tempChart;
            var colorArray = ['#98FB98', '#f1e4e4', '#f7e0a3', '#f09c67'];


            

            /* if (val - 500 <= 0) {
               this.state.chart.xAxis[0].setExtremes(val, val + 1000, true, false);
             } else {
               this.state.chart.xAxis[0].setExtremes(val - 500, val + 500);
             }*/


            var acti = 125;
            var actarr = [];
            var tttarr1 = [];

            for (var i = 0; i < currentActArray.length; i++) {
                actarr.push({
                    x: acti,
                    y: 0.5,
                    color: colorArray[currentActArray[i]]
                });
                acti = acti + 250;
            }
           

            var prevChart = window.prevChart;
            if (window.beatArr.length > 0 && window.container != window.prevChart) {
                for (var i = 0; i < window.beatArr.length; i++) {
                    var j = window.container * deviceFrequency;
                    var xBeat = parseInt(window.beatArr[i]) - j;


                    console.log('xBeat', xBeat);
                    chart.xAxis[0].removePlotBand('plotband-' + xBeat);
                }
                //document.getElementById("beatSlected").value='[]';
                // beatArr=[];
            }


            var currentDataSet = [];
            var self = this;

           

            this.state.chart.series[0].setData(currentEcgArray, false);
            this.state.chart.series[1].setData(actarr, false);
            this.state.chart.series[2].setData(this.state.result3[0], true);
            //console.log(this.state.parr);
           /* this.state.chart.series[3].update({
                data: this.state.parr[starts],
            }, false);
            this.state.chart.series[4].update({
                data: this.state.qarr[starts],
            }, false);
            this.state.chart.series[5].update({
                data: this.state.rarr[starts],
            }, false);
            this.state.chart.series[6].update({
                data: this.state.sarr[starts],
            }, false);
            this.state.chart.series[7].update({
                data: this.state.tarr[starts],
            }, false);
            this.state.chart.series[8].update({
                data: this.state.ponarr[starts],
            }, false);
            this.state.chart.series[9].update({
                data: this.state.poffarr[starts],
            }, false);
            this.state.chart.series[10].update({
                data: this.state.tonarr[starts],
            }, false);
            this.state.chart.series[11].update({
                data: this.state.toffarr[starts],
            }, false);
            this.state.chart.series[12].update({
                data: this.state.jarr[starts],
            }, true);
            this.state.chart.series[12].update({
                data: [],
            }, true);*/

            console.log('upmoc', window.prevChart);
            var chart1 = this.state.charts[0];
            var tempChart = this.state.charts[window.prevChart];

            
            var lineWidth = 2

            if (this.state.charts[window.prevChart] != undefined) {
                if (this.state.charts[window.prevChart].draggablePlotLine != undefined) {
                    this.state.charts[window.prevChart].draggablePlotLine.destroy();
                }
            }

            this.state.charts[0].draggablePlotLine = this.state.charts[0].renderer.rect(this.state.charts[0].xAxis[0].toPixels(window.stripXValue) - this.state.charts[0].xAxis[0].toPixels(25), 10, 50, 40, 5)
                .attr({
                    'stroke-width': 2,
                    opacity: 0.5,
                    fill: 'grey',
                    zIndex: 3
                })
                .add();
            this.state.chart.xAxis[0].setExtremes(window.stripXValue - 500, window.stripXValue + 500, true, false);
            console.log("A I umcoc");

            if (this.state.charts[0].container.onmousemove != undefined) {
                this.state.charts[0].container.onmousemove = function(e) {
                    return false;
                }
            }
            var mainCChart = this.state.chart;
            this.state.charts[0].container.onmousemove = function(e) {
                if (chart1.draggablePlotLine.drag) {

                    let normalizedEvent = chart1.pointer.normalize(e),
                        extremes = {
                            left: chart1.plotLeft,
                            right: chart1.plotLeft + chart1.plotWidth
                        };

                    if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                        var pointValue = chart1.xAxis[0].toValue(e.chartX);
                        if (pointValue - 500 <= 0) {
                            mainCChart.xAxis[0].setExtremes(pointValue, pointValue + 1000, true, false);
                        } else {
                            mainCChart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                        }
                        chart1.draggablePlotLine.attr({
                            x: e.chartX
                        })
                    }
                }
            }

            this.state.charts[0].draggablePlotLine.element.onmousedown = function() {
                chart1.draggablePlotLine.drag = true;
            }

            this.state.charts[0].draggablePlotLine.element.onmouseup = function() {
                chart1.draggablePlotLine.drag = false;
            }

            
        
    }

    updateRpeaks(data) {
        this.setState({
            rpeaks: data
        })
    }

    



    generateReport(e) {
        const userid = this.props.match.params.userid;
        e.currentTarget.innerHTML = "PLease Wait...";
        //
        axios
            .get('https://web.mybiocalculus.com/html/welcome/generateReportTest/' + userid)
            .then(res => {

            })
            .catch(err => {

            });
    }

    mailReport(e) {

        const userid = this.props.match.params.userid;
        axios
            .get(apiUrl + 'api/users/mailReport?userid=' + userid)
            .then(res => {

                alert('Mail send successfully')
            })
            .catch(err => {
                console.log(err)
                alert('Something went wrong')
            });
    }

    splitFn(value) {
        var res = value.split("_");
        var name = res[0].substring(0, 4) + '-' + res[0].substring(4, 6) + '-' + res[0].substring(6, 8) + ' :: ' + res[1].substring(0, 2) + ':' + res[1].substring(2, 4) + ':' + res[1].substring(4, 6);
        res[0] = name;
        return res;
    }

    resetGraph(e) {
        var it = 0;
        var currentChart = this.state.charts;
        var currentNsrarr = this.state.defarr;
        var myRGVar = setInterval(function() {
            currentChart[it].series[0].setData(currentNsrarr[it], true);
            it = it + 1;
            if (it > currentNsrarr.length - 1) {
                clearInterval(myRGVar);
            }

        }, 10);
    }

    colorGraph(e) {
        var selector = e.currentTarget.value;
        window.sel = selector;
        if (selector == 'Normal Sinus Rhythm') {
            var it = 0;
            var currentChart = this.state.charts;
            var currentNsrarr = this.state.nsrarr;
            graphColor = 'nsrarr';
            var myCGVar = setInterval(function() {
                currentChart[it].series[0].setData(currentNsrarr[it], true);
                it = it + 1;
                if (it > currentNsrarr.length - 1) {
                    clearInterval(myCGVar);
                }

            }, 10);
        } else if (selector == 'Sinus Tachycardia') {
            var it = 0;
            var currentChart = this.state.charts;
            var currentStarr = this.state.starr;
            graphColor = 'starr';
            var myCGVar = setInterval(function() {
                currentChart[it].series[0].setData(currentStarr[it], true);
                it = it + 1;
                if (it > currentStarr.length - 1) {
                    clearInterval(myCGVar);
                }

            }, 10);
        } else if (selector == 'Sinus Bradycardia') {
            var it = 0;
            var currentChart = this.state.charts;
            var currentSbarr = this.state.sbarr;
            graphColor = 'sbarr';
            var myCGVar = setInterval(function() {
                currentChart[it].series[0].setData(currentSbarr[it], true);
                it = it + 1;
                if (it > currentSbarr.length - 1) {
                    clearInterval(myCGVar);
                }

            }, 10);
        } else if (selector == 'Sinus Pause') {
            var it = 0;
            var currentChart = this.state.charts;
            var currentSparr = this.state.sparr;
            graphColor = 'sparr';
            var myCGVar = setInterval(function() {
                currentChart[it].series[0].setData(currentSparr[it], true);
                it = it + 1;
                if (it > currentSparr.length - 1) {
                    clearInterval(myCGVar);
                }

            }, 10);
        } else if (selector == 'PAC') {
            var it = 0;
            var currentChart = this.state.charts;
            var currentPacarr = this.state.pacarr;
            graphColor = 'pacarr';
            var myCGVar = setInterval(function() {
                currentChart[it].series[0].setData(currentPacarr[it], true);
                it = it + 1;
                if (it > currentPacarr.length - 1) {
                    clearInterval(myCGVar);
                }

            }, 10);
        } else if (selector == 'PVC') {
            var it = 0;
            var currentChart = this.state.charts;
            var currentPvcarr = this.state.pvcarr;
            graphColor = 'pvcarr';
            var myCGVar = setInterval(function() {
                currentChart[it].series[0].setData(currentPvcarr[it], true);
                it = it + 1;
                if (it > currentPvcarr.length - 1) {
                    clearInterval(myCGVar);
                }

            }, 10);
        } else if (selector == 'Ventricular Tachycardia') {
            var it = 0;
            var currentChart = this.state.charts;
            var currentVtcarr = this.state.vtarr;
            graphColor = 'vtarr';
            var myCGVar = setInterval(function() {
                currentChart[it].series[0].setData(currentVtcarr[it], true);
                it = it + 1;
                if (it > currentVtcarr.length - 1) {
                    clearInterval(myCGVar);
                }

            }, 10);
        } else if (selector == 'AFib') {
            var it = 0;
            var currentChart = this.state.charts;
            var currentAfcarr = this.state.afarr;
            graphColor = 'afarr';
            var myCGVar = setInterval(function() {
                currentChart[it].series[0].setData(currentAfcarr[it], true);
                it = it + 1;
                if (it > currentAfcarr.length - 1) {
                    clearInterval(myCGVar);
                }

            }, 10);
        } else if (selector == 'P Absent') {
            var it = 0;
            var currentChart = this.state.charts;
            var currentPacarr = this.state.paarr;
            graphColor = 'paarr';
            var myCGVar = setInterval(function() {
                currentChart[it].series[0].setData(currentPacarr[it], true);
                it = it + 1;
                if (it > currentPacarr.length - 1) {
                    clearInterval(myCGVar);
                }

            }, 10);
        } else if (selector == 'Irregular RR') {
            var it = 0;
            var currentChart = this.state.charts;
            var currentIrrrarr = this.state.irrrarr;
            graphColor = 'irrrarr';
            var myCGVar = setInterval(function() {
                currentChart[it].series[0].setData(currentIrrrarr[it], true);
                it = it + 1;
                if (it > currentIrrrarr.length - 1) {
                    clearInterval(myCGVar);
                }

            }, 10);
        } else if (selector == 'Irregular PR') {
            var it = 0;
            var currentChart = this.state.charts;
            var currentIrprarr = this.state.irprarr;
            graphColor = 'irprarr';
            var myCGVar = setInterval(function() {
                currentChart[it].series[0].setData(currentIrprarr[it], true);
                it = it + 1;
                if (it > currentIrprarr.length - 1) {
                    clearInterval(myCGVar);
                }

            }, 10);
        } else if (selector == 'Short QT') {
            var it = 0;
            var currentChart = this.state.charts;
            var currentSqtarr = this.state.sqtarr;
            graphColor = 'sqtarr';
            var myCGVar = setInterval(function() {
                currentChart[it].series[0].setData(currentSqtarr[it], true);
                it = it + 1;
                if (it > currentSqtarr.length - 1) {
                    clearInterval(myCGVar);
                }

            }, 10);
        } else if (selector == 'Prolonged QT') {
            var it = 0;
            var currentChart = this.state.charts;
            var currentPaarr = this.state.paarr;
            graphColor = 'paarr';
            var myCGVar = setInterval(function() {
                currentChart[it].series[0].setData(currentPaarr[it], true);
                it = it + 1;
                if (it > currentPaarr.length - 1) {
                    clearInterval(myCGVar);
                }

            }, 10);
        } else if (selector == 'Undiagnosed') {
            var it = 0;
            var currentChart = this.state.charts;
            var currentUnarr = this.state.unarr;
            graphColor = 'unarr';
            var myCGVar = setInterval(function() {
                currentChart[it].series[0].setData(currentUnarr[it], true);
                it = it + 1;
                if (it > currentUnarr.length - 1) {
                    clearInterval(myCGVar);
                }

            }, 10);
        }

    };

    addBeatToReport(e) {
        var beats = window.beatArr;
        var databaseid = this.state.databaseid;
        var userid = this.props.match.params.userid;
        if (beats.length > 0) {

            const data = {
                databaseid: databaseid,
                beats: beats,
                userid: userid
            };
            axios
                .post(apiUrl + 'api/common/addBeatToReport', data)
                .then(res => {
                    var currentChart = window.prevChart;
                    var minus_val = (parseInt(window.pageIndex) + parseInt(window.prevChart)) * deviceFrequency;
                    for (var i = 0; i < beats.length; i++) {
                        this.state.chart.xAxis[0].removePlotBand('plotband-' + (beats[i] - minus_val))
                    }
                    window.beatArr = []
                })
                .catch(err => {
                    console.log(err)
                });
        }
    }
    generateEvents(e) {

        var databaseid = this.state.databaseid;
        var userid = this.props.match.params.userid;
        e.currentTarget.innerHTML = "PLease Wait...";
        var element=e.currentTarget;
        axios
            .get('https://web.mybiocalculus.com/html/welcome/generateEventTest/?userid=' + userid + '&&databaseid=' + databaseid)
            .then(res => {
                console.log('generated res',res);
                element.innerHTML = "Generate Events";
                //alert("events generated");
            })
            .catch(err => {
                console.log(err);

            });
    }
    listenScrollEvent(e) {

        var databaseid = this.state.databaseid;
        var userid = this.props.match.params.userid;
        var dis = this;
        var ee = e.target;

        var element_height = 53.6833333;
        var positive = Math.abs(ee.scrollTop);
        var divided = positive / element_height;
        console.log('ee.scrollTop',ee.scrollTop);



        if (divided != 0) {
            var round = Math.floor(divided);
        } else {
            var round = 0;
        }



        if (window.timer !== null) {
            clearTimeout(window.timer);
        }
        window.timer = setTimeout(function() {




            var height = document.getElementById("selWrap").scrollHeight;
            console.log(height);
            console.log('positive', positive);
            console.log('divided', Math.floor(positive / (height / window.sc)));
            round = Math.floor(positive / (height / window.sc));
            var start = round;
            var end = round + 9;
            dis.setState({
                start: start,
                end: end,

            });


            dis.renderNewSmallCharts();

        }, 500);

    }
    recategorize(e) {
        var beats = window.beatArr;
        var databaseid = this.state.databaseid;
        var userid = this.props.match.params.userid;
        var arrhythmia = e.currentTarget.value;
        if (beats.length > 0) {

            const data = {
                databaseid: databaseid,
                beats: beats,
                arrhythmia: arrhythmia,
                userid: userid
            };
            console.log('beats', beats);
            axios
                .post(apiUrl + 'api/common/updateVerifierArrhythmia', data)
                .then(res => {
                    console.log('beats', beats);
                    for (var i = 0; i < window.beatArr.length; i++) {
                        /* var j  = (parseInt(window.pageIndex)+parseInt(window.prevChart))*deviceFrequency; */
                        /*if (window.realcId != 0) {
                            var j = window.realcId * deviceFrequency;
                        } else {*/
                            var j = parseInt(window.container) * deviceFrequency;
                        //}
                        var xBeat = parseInt(window.beatArr[i]) - j;


                        //console.log('window.realcId', window.realcId);
                        this.state.chart.xAxis[0].removePlotBand('plotband-' + xBeat);
                    }
                    var stripvalue_resume = parseInt(window.container);
                    var xvalue_resume = beats[beats.length - 1];
                    axios
                        .get(apiUrl + 'api/common/updateResumeInfo?databaseid=' + databaseid + '&userid=' + userid + '&stripvalue_resume=' + stripvalue_resume + '&xvalue_resume=' + xvalue_resume)
                        .then(res => {

                        })
                        .catch(err => {

                        });
                    window.beatArr = []
                })
                .catch(err => {
                    console.log(err)
                });
        }
    }
    recategorizeAfterImage(arr) {
        var beats = window.beatArr;
        var databaseid = this.state.databaseid;
        var userid = this.props.match.params.userid;

        if (beats.length > 0) {

            const data = {
                databaseid: databaseid,
                beats: beats,
                arrhythmia: arr,
                userid: userid
            };
            console.log('beats data', data);
            axios
                .post(apiUrl + 'api/common/updateVerifierArrhythmia', data)
                .then(res => {
                    console.log('beats res', res.data);

                    window.beatArr = []
                })
                .catch(err => {
                    console.log(err)
                });
        }
    }

    verify(e) {
        var databaseid = this.state.databaseid;
        var userid = this.props.match.params.userid;
        const data = {
            databaseid: databaseid,
            userid: userid
        };
        axios
            .post(apiUrl + 'api/common/verifyArrhythmia', data)
            .then(res => {
                $("#verfiyBtn").css("display", "none");
                this.checkFullVerify();
            })
            .catch(err => {
                console.log(err)
            });
    }

    gainBtn(e) {
        var buttonId = e.currentTarget.id;
        var gainValue = document.getElementById('gainValue').value;
        var it = 0;
        var charts = this.state.charts;
        var arCount = this.state.datasetMnt - 1;
        if (gainValue == 1) {
            gainMin = -0.0005;
            gainMax = 0.0015;
        } else if (gainValue == 2) {
            gainMin = -0.0005;
            gainMax = 0.0008;
        } else if (gainValue == 3) {
            gainMin = -0.0005;
            gainMax = 0.0005;
        }

        var scaleUp = setInterval(function() {
            charts[it].update({
                yAxis: [{
                    min: gainMin,
                    max: gainMax
                }],
            })
            it = it + 1;
            if (it > arCount) {
                clearInterval(scaleUp);
            }
        }, 5);
    }

    checkFullVerify() {
        axios
            .get(apiUrl + 'api/common/checkFullVerify?userid=' + this.props.match.params.userid)
            .then(res => {
                this.setState({
                    fullVerifyStatus: res.data
                })
            })
            .catch(err => {
                console.log(err)
            });
    }
    renderResumeChart(userid, dbId, cont, strip_val) {

        var newThis = this,
            val, colorArray = [];
        window.chartid = 0;
        window.container = cont; //container id stored in global variable
        console.log('window.container', window.container);
        window.stripXValue = strip_val - (cont * 15000); //got the location in the xaxis
        
        /*************Remove Plotband***********************/

        if (window.beatArr.length > 0 && window.chartid != window.prevChart) {
            for (var i = 0; i < window.beatArr.length; i++) {
                var j = window.container * deviceFrequency;
                var xBeat = parseInt(window.beatArr[i]) - j;


                console.log('xBeat', xBeat);
                window.chart.xAxis[0].removePlotBand('plotband-' + xBeat);
            }
            //document.getElementById("beatSlected").value='[]';
            // beatArr=[];
        }


        /*************Remove Plotband***********************/
        if (newThis.state.charts[window.container]) {
            val = newThis.state.charts[window.container].xAxis[0].toValue(window.stripXValue);

        }


        //console.log(selfNew.state.mainEcgArray);
        var sliceStart = cont * deviceFrequency;
        var sliceEnd = sliceStart + deviceFrequency + 1;
        var sliceActStart = window.container * 60;
        var sliceActEnd = sliceActStart + 61;
        console.log('window.stripXValue', strip_val);
        /*console.log('sliceStart',sliceEnd);
        console.log('mainEcgArray Two',newThis.state.mainEcgArray);*/
        var ecgArray = newThis.state.mainEcgArray.slice(0, 15001);
        var curActArray = newThis.state.mainActivityArray.slice(0, 61);


        if (val > ecgArray.length) {
            val = ecgArray.length - 500;
        }




        var acti = 125;
        var actarr = [];
        var tttarr1 = [];
        /*console.log('container',window.container);
        console.log(newThis.state.rarr[window.dataArrInd]);*/



        for (var i = 0; i < curActArray.length; i++) {
            actarr.push({
                x: acti,
                y: 0.5,
                color: colorArray[curActArray[i]]
            });
            acti = acti + 250;
        }
        
        console.log('testResult3', newThis.state.result3);
        //console.log(result3[window.container]);
        newThis.state.chart.series[0].setData(ecgArray, false);
        newThis.state.chart.series[1].setData(actarr, false);
        newThis.state.chart.series[2].setData(newThis.state.result3[0], false);
       /* newThis.state.chart.series[3].update({
            data: newThis.state.parr[window.container],
        }, false);
        newThis.state.chart.series[4].update({
            data: newThis.state.qarr[window.container],
        }, false);
        newThis.state.chart.series[5].update({
            data: newThis.state.rarr[window.container],
        }, false);
        newThis.state.chart.series[6].update({
            data: newThis.state.sarr[window.container],
        }, false);
        newThis.state.chart.series[7].update({
            data: newThis.state.tarr[window.container],
        }, false);
        newThis.state.chart.series[8].update({
            data: newThis.state.ponarr[window.container],
        }, false);
        newThis.state.chart.series[9].update({
            data: newThis.state.poffarr[window.container],
        }, false);
        newThis.state.chart.series[10].update({
            data: newThis.state.tonarr[window.container],
        }, false);
        newThis.state.chart.series[11].update({
            data: newThis.state.toffarr[window.container],
        }, false);
        newThis.state.chart.series[12].update({
            data: newThis.state.jarr[window.container],
        }, true);*/




        if (newThis.state.charts[window.prevChart] != undefined) {
            if (newThis.state.charts[window.prevChart].draggablePlotLine != undefined) {
                newThis.state.charts[window.prevChart].draggablePlotLine.destroy();
            }
        }
        var chart1 = newThis.state.charts[0];

        window.prevChart = 0;

        var lineWidth = 2




        chart1.draggablePlotLine = chart1.renderer.rect(newThis.state.charts[0].xAxis[0].toPixels(strip_val) - newThis.state.charts[0].xAxis[0].toPixels(25), 10, 50, 40, 5)
            .attr({
                'stroke-width': 2,
                opacity: 0.5,
                fill: 'grey',
                zIndex: 3
            })
            .add();
        newThis.state.chart.xAxis[0].setExtremes(strip_val - 500, strip_val + 500, true, false);



        if (newThis.state.charts[0].container.onmousemove != undefined) {
            newThis.state.charts[0].container.onmousemove = function(e) {
                return false;
            }
        }

        newThis.state.charts[0].container.onmousemove = function(e) {
            if (chart1.draggablePlotLine != undefined) {
                if (chart1.draggablePlotLine.drag) {

                    let normalizedEvent = chart1.pointer.normalize(e),
                        extremes = {
                            left: chart1.plotLeft,
                            right: chart1.plotLeft + chart1.plotWidth
                        };

                    if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                        var pointValue = chart1.xAxis[0].toValue(e.chartX);
                        if (pointValue - 500 <= 0) {
                            newThis.state.chart.xAxis[0].setExtremes(0, pointValue + 1000, true, false);
                        } else {
                            newThis.state.chart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                        }
                        chart1.draggablePlotLine.attr({
                            x: e.chartX
                        })
                    }
                }
            }

        }

        newThis.state.charts[0].draggablePlotLine.element.onmousedown = function() {
            chart1.draggablePlotLine.drag = true;
        }

        newThis.state.charts[0].draggablePlotLine.element.onmouseup = function() {
            chart1.draggablePlotLine.drag = false;
        }


    }


    renderNewChart(userid, dbId, step, start = 0, end = 9) {


        var arr = [],
            charts = [],
            arr2 = [],
            peakarr = [],
            barClick = 0,
            pointValue, tempChart, tempCont = 0;
        var nsrarr = [],
            starr = [],
            unarr = [],
            sbarr = [],
            vtarr = [],
            sparr = [],
            lqtarr = [],
            sqtarr = [],
            irrrarr = [],
            irprarr = [],
            afarr = [],
            pacarr = [],
            pvcarr = [],
            paarr = [],
            beatArr = [],
            defarr = [],
            plotband = [],
            piec;
        var maximumval;
        var b;
        var js_array = [];
        var chart;
        var minVal, maxVal, val, val2;
        var parr = [],
            tarr = [],
            qarr = [],
            rarr = [],
            sarr = [],
            ponarr = [],
            poffarr = [],
            tonarr = [],
            toffarr = [],
            jarr = [];
        var result3, colorArray = [];
        var rpeakArray = [];
        /*  var markerAarray = [],*/
        var result4 = [];
        var peakColorArray = [];
        peakColorArray['Undiagnosed'] = '#FCDF85';
        peakColorArray['Normal Sinus Rhythm'] = '#98FB98';
        peakColorArray['Sinus Tachycardia'] = '#3EFFDE';
        peakColorArray['PAC'] = '#4950EB';
        peakColorArray['PVC'] = '#FF45DC';
        peakColorArray['P absent'] = '#AEB9BD';
        peakColorArray['Sinus Bradycardia'] = '#CFFF0F';
        peakColorArray['Sinus Pause'] = '#FF5B05';
        peakColorArray['Irregular RR'] = '#A4C3FF';
        peakColorArray['Ventricular Tachycardia'] = '#F0A7A1';
        peakColorArray['Irregular PR'] = '#BBEB8F';
        peakColorArray['Short QT'] = '#FFD14F';
        peakColorArray['Prolonged QT'] = '#7C2F09';
        peakColorArray['AFib'] = '#A96BFF';

        var userid = userid;
        var databaseid = dbId;
        var selfNew = this;
        var mainEcgArray, mainActivityArray = [];
        var datasetMnt = 0;

        //Split the array in chunks with given size
        function chunkArray(myArray, chunk_size) {
            let results = [];

            while (myArray.length) {
                results.push(myArray.splice(0, chunk_size))
            }

            return results;
        };

        function chunkPeakArray(peakArray, start = 0) {
            let presults = [];
            var minIndex = 0;
            var breakVal = (start + 1) * deviceFrequency;
            var z;
            var temRpeakindex
            presults.push([]);
            for (var i = 0; i < peakArray.length; i++) {
                if (start * deviceFrequency < peakArray[i].rpeakindex) {
                    if (peakArray[i].rpeakindex > breakVal) {
                        presults.push([]);
                        minIndex = minIndex + 1;
                        breakVal = breakVal + deviceFrequency;
                    }
                    temRpeakindex = parseInt(peakArray[i].rpeakindex) - ((minIndex + start) * deviceFrequency);
                    presults[minIndex].push({
                        x: temRpeakindex,
                        y: parseFloat(0.8),
                        color: peakColorArray[peakArray[i].arrhythmia],
                        beat: parseInt(peakArray[i].indexcounter),
                        hr: parseInt(peakArray[i].heartrate),
                        rr: parseInt(peakArray[i].rr_interval),
                        arrhythmia: peakArray[i].arrhythmia,
                        peaktime: peakArray[i].peaktime
                    });
                }
            }
            return presults;
        }

        function chunkMarkerArray(markArrayX, markArrayY, start = 0) {

            var ppeakIndex = markArrayX.split(',');

            var ppeakAmp = markArrayY.split(',');
            let mresults = [];

            var minIndex = 0;
            var breakVal = (start + 1) * deviceFrequency;
            var z;
            var temindex;
            mresults.push([]);
            for (var i = 0; i < ppeakIndex.length; i++) {
                if (ppeakIndex[i] > start * deviceFrequency) {
                    if (ppeakIndex[i] > breakVal) {
                        mresults.push([]);
                        minIndex = minIndex + 1;
                        breakVal = breakVal + deviceFrequency;
                    }
                    temindex = parseInt(ppeakIndex[i]) - ((minIndex + start) * deviceFrequency);
                    mresults[minIndex].push([temindex, parseFloat(ppeakAmp[i])]);
                }
            }
            return mresults;
        }

        function clickFunction(event, that) {
            
            var containerId = that.renderTo.id; //got the Container ID
            console.log('containerId',);
            
            window.container = parseInt(containerId.replace('container', ''));
           window.chartid= window.container - selfNew.state.start;
            console.log('window.container', window.container);
            window.stripXValue = event.chartX; //got the location in the xaxis
            


            //var beats = JSON.parse(document.getElementById("beatSlected").value);

            //console.log(beatArr);


            /*************Remove Plotband***********************/

            if (window.beatArr.length > 0 && window.chartid != window.prevChart) {
                for (var i = 0; i < window.beatArr.length; i++) {
                    var j =  (parseInt(window.container)) * deviceFrequency;
                    var xBeat = parseInt(window.beatArr[i]) - j;


                    console.log('xBeat', xBeat);
                    chart.xAxis[0].removePlotBand('plotband-' + xBeat);
                }
                //document.getElementById("beatSlected").value='[]';
                // beatArr=[];
            }


            /*************Remove Plotband***********************/


            val = charts[window.container].xAxis[0].toValue(event.chartX);




            //console.log(selfNew.state.mainEcgArray);
            var sliceStart = window.container * deviceFrequency;
            var sliceEnd = sliceStart + deviceFrequency + 1;
            var sliceActStart = window.container * 60;
            var sliceActEnd = sliceActStart + 61;
            /*console.log('sliceStart',sliceStart);
            console.log('sliceStart',sliceEnd);*/
            var ecgArray = selfNew.state.mainEcgArray.slice(sliceStart, sliceEnd);
            var curActArray = selfNew.state.mainActivityArray.slice(sliceActStart, sliceActEnd);


            if (val > ecgArray.length) {
                val = ecgArray.length - 500;
            }




            var acti = 125;
            var actarr = [];
            var tttarr1 = [];
            /*console.log('container',window.container);
            console.log(selfNew.state.rarr[window.dataArrInd]);*/



            for (var i = 0; i < curActArray.length; i++) {
                actarr.push({
                    x: acti,
                    y: 0.5,
                    color: colorArray[curActArray[i]]
                });
                acti = acti + 250;
            }
            //console.log(ecgArray);
            //console.log(result3[window.container]);
            chart.series[0].setData(ecgArray, false);
            chart.series[1].setData(actarr, false);
            chart.series[2].setData(selfNew.state.result3[window.chartid], false);
            /*chart.series[3].update({
                data: selfNew.state.parr[window.container],
            }, false);
            chart.series[4].update({
                data: selfNew.state.qarr[window.container],
            }, false);
            chart.series[5].update({
                data: selfNew.state.rarr[window.container],
            }, false);
            chart.series[6].update({
                data: selfNew.state.sarr[window.container],
            }, false);
            chart.series[7].update({
                data: selfNew.state.tarr[window.container],
            }, false);
            chart.series[8].update({
                data: selfNew.state.ponarr[window.container],
            }, false);
            chart.series[9].update({
                data: selfNew.state.poffarr[window.container],
            }, false);
            chart.series[10].update({
                data: selfNew.state.tonarr[window.container],
            }, false);
            chart.series[11].update({
                data: selfNew.state.toffarr[window.container],
            }, false);
            chart.series[12].update({
                data: selfNew.state.jarr[window.container],
            }, true);
            chart.series[12].update({
                data: [],
            }, true);*/



            if (charts[window.prevChart] != undefined) {
                if (charts[window.prevChart].draggablePlotLine != undefined) {
                    charts[window.prevChart].draggablePlotLine.destroy();
                }
            }
            var chart1 = that;

            window.prevChart = window.chartid;

            var lineWidth = 2

            chart1.draggablePlotLine = chart1.renderer.rect(event.chartX - charts[window.chartid].xAxis[0].toPixels(25), 10, 50, 40, 5)
                .attr({
                    'stroke-width': 2,
                    opacity: 0.5,
                    fill: 'grey',
                    zIndex: 3
                })
                .add();
            console.log("a i clickFunction");

            if (charts[0].container.onmousemove != undefined) {
                charts[0].container.onmousemove = function(e) {
                    return false;
                }
            }

            charts[window.chartid].container.onmousemove = function(e) {
                if (chart1.draggablePlotLine != undefined) {
                    if (chart1.draggablePlotLine.drag) {

                        let normalizedEvent = chart1.pointer.normalize(e),
                            extremes = {
                                left: chart1.plotLeft,
                                right: chart1.plotLeft + chart1.plotWidth
                            };

                        if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                            pointValue = chart1.xAxis[0].toValue(e.chartX);
                            if (pointValue - 500 <= 0) {
                                chart.xAxis[0].setExtremes(0, pointValue + 1000, true, false);
                            } else {
                                chart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                            }
                            chart1.draggablePlotLine.attr({
                                x: e.chartX
                            })
                        }
                    }
                }
            }

            charts[window.chartid].draggablePlotLine.element.onmousedown = function() {
                chart1.draggablePlotLine.drag = true;
            }

            charts[window.chartid].draggablePlotLine.element.onmouseup = function() {
                chart1.draggablePlotLine.drag = false;
            }
            if (val - 500 <= 0) {
                chart.xAxis[0].setExtremes(0, val + 1000, true, false);
            } else {
                chart.xAxis[0].setExtremes(val - 500, val + 500);
            }
        }


        function renderSmallCharts(clickPointIndex = 0) {
            var i = 0;
            var t = 0;

            var stcolor = "black",
                uncolor = "black",
                sbcolor = "black",
                vtcolor = "black",
                spcolor = "black",
                lqcolor = "black",
                sqcolor = "black",
                irrrcolor = "black",
                irprcolor = "black",
                afcolor = "black",
                paccolor = "black",
                pvccolour = "black",
                pacolour = "black",
                defcolour = "black";
            var nsrcolor = "black";

            var currentDataSet = [];

            for (var j = 0; j < window.sc; j++) {
                var newDiv = document.createElement('div');
                newDiv.id = 'container' + j;
                newDiv.style = 'min-width: 200px; height: 60px; margin: 0 auto';
                var newSpan = document.createElement('div');
                newSpan.id = 'span' + j;
                newSpan.style = 'color:#000; height: 21px;';

                /**/

                document.getElementById('selWrap').appendChild(newSpan);
                document.getElementById('selWrap').appendChild(newDiv);
            }

            var myVar = setInterval(function() {
                var sliceStartI = i * deviceFrequency;
                var sliceEndI = sliceStartI + deviceFrequency + 1;
                //console.log('selfNew.state.mainEcgArray',selfNew);
                currentDataSet = selfNew.state.mainEcgArray.slice(sliceStartI, sliceEndI);




                var arrayIndex = 0;
                var k = 0;
                nsrarr.push([]);
                starr.push([]);
                unarr.push([]);
                sbarr.push([]);
                vtarr.push([]);
                sparr.push([]);
                lqtarr.push([]);
                sqtarr.push([]);
                irrrarr.push([]);
                irprarr.push([]);
                afarr.push([]);
                pacarr.push([]);
                pvcarr.push([]);
                paarr.push([]);
                defarr.push([]);

                if (selfNew.state.result3[i]) {

                    var time1 = document.createTextNode(selfNew.state.result3[i][0].peaktime);
                    document.getElementById('span' + i).appendChild(time1);
                }





                //console.log('MainArrayWin',window.mainArray);

                charts[i] = new Highcharts.Chart('container' + i, {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        height: 4 + '%',
                        resetZoomButton: {
                            theme: {
                                display: 'none'
                            },
                        },
                        events: {
                            click: function(e) {
                                /* Do something with the event */

                                clickFunction(e, this); // ...which means you can access it from inside the click handler function
                            }
                        },
                        zoomType: '',
                    },

                    navigator: {
                        enabled: false
                    },

                    xAxis: [{
                        min: 0,
                        max: deviceFrequency,
                        gridLineWidth: 0,
                        minorGridLineWidth: 0,
                        minorTicks: false,
                        labels: {
                            enabled: false
                        },
                        title: {
                            enabled: false
                        },
                        visible: false

                    }],
                    yAxis: [{
                        min: gainMin,
                        max: gainMax,
                        gridLineWidth: 0,
                        minorGridLineWidth: 0,
                        minorTicks: false,
                        labels: {
                            enabled: false
                        },
                        title: {
                            enabled: false
                        },
                    }],
                    rangeSelector: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            turboThreshold: deviceFrequency,
                        },
                    },
                    title: {
                        text: ''
                    },

                    series: [{
                        type: 'coloredline',
                        data: currentDataSet,
                        color: 'black',
                        allowPointSelect: false,
                        animation: false,
                        enableMouseTracking: false,
                        lineWidth: 1,
                        marker: {
                            enabled: false
                        },
                        shadow: false,
                        stickyTracking: false,
                        showInLegend: false
                    }]
                });

                if (graphColor !== '')
                    charts[i].series[0].setData(eval(graphColor)[i], true);
                if (i == 0) {
                    clickContainerIndex = clickPointIndex;
                    window.stripXValue = 100;
                    window.container = 0;
                    window.chartid = 0;
                    window.pageIndex = 0;

                }

                window.prevChart = clickContainerIndex;
                if (i == clickContainerIndex) {
                    charts[i].draggablePlotLine = charts[i].renderer.rect(charts[i].xAxis[0].toPixels(50) - charts[i].xAxis[0].toPixels(25), 10, 50, 40, 5)
                        .attr({
                            'stroke-width': 2,
                            opacity: 0.5,
                            fill: 'grey',
                            zIndex: 3
                        })
                        .add();
                    tempChart = charts[i];
                    console.log("added inside renderSmallCharts");
                    if (charts[i].container.onmousemove != undefined) {
                        charts[i].container.onmousemove = function(e) {
                            return false;
                        }
                    }
                    var stripIndex = i;
                    charts[stripIndex].container.onmousemove = function(e) {
                        if (charts[stripIndex].draggablePlotLine.drag) {

                            let normalizedEvent = charts[stripIndex].pointer.normalize(e),
                                extremes = {
                                    left: charts[stripIndex].plotLeft,
                                    right: charts[stripIndex].plotLeft + charts[stripIndex].plotWidth
                                };

                            if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                                var pointValue = charts[stripIndex].xAxis[0].toValue(e.chartX);
                                if (pointValue - 500 <= 0) {
                                    chart.xAxis[0].setExtremes(0, pointValue + 1000, true, false);
                                } else {
                                    chart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                                }
                                charts[stripIndex].draggablePlotLine.attr({
                                    x: e.chartX
                                })
                            }
                        }
                    }
                    charts[stripIndex].draggablePlotLine.element.onmousedown = function() {
                        charts[stripIndex].draggablePlotLine.drag = true;
                    }

                    charts[stripIndex].draggablePlotLine.element.onmouseup = function() {
                        charts[stripIndex].draggablePlotLine.drag = false;
                    }
                }
                i = i + 1;
                console.log('datasetMnt', datasetMnt);
                if (i > datasetMnt - 1) {
                    clearInterval(myVar);
                }
            }, 5);
        }

        // Function to plot the background graph
        function renderMainChart() {

            maximumval = 1000;
            b = 0;

            // Create the chart
            chart = new Highcharts.StockChart('tt_container', {
                exporting: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                chart: {

                    type: 'coloredline',
                    height: 35 + '%',

                    resetZoomButton: {
                        theme: {
                            display: 'none'
                        },

                    },
                    
                    zoomType: '',
                },

                navigator: {
                    enabled: false
                },

                xAxis: [{
                    min: 0, //setting min to 0
                    max: 1000,
                    events: {

                        setExtremes: function(e) {
                            //val = e.min + 500; 
                            if (e.min <= 500) {
                                val = e.min;
                            } else {
                                val = e.min + 500;
                            }
                            tempChart = charts[window.prevChart];
                            if (tempChart != undefined) {
                                if (tempChart.draggablePlotLine != undefined) {
                                    val2 = tempChart.xAxis[0].toPixels(val);


                                    tempChart.draggablePlotLine.attr({
                                        x: val2

                                    })



                                }
                            }
                        },
                    },
                    scrollbar: {
                        enabled: true
                    },
                    labels: {
                        enabled: false
                    },

                    tickInterval: 50,
                    minorTicks: true,
                    minorTickInterval: 10,
                    gridLineWidth: 1,
                    gridLineColor: '#D3D3D3'

                }],
                yAxis: [{
                        min: 0,
                        max: 10,
                        reversed: true,
                        gridLineWidth: 0,
                        minorGridLineWidth: 0,
                        minorTicks: false,

                        labels: {
                            enabled: true
                        },
                        title: {
                            enabled: false
                        },
                        visible: false

                    },
                    {
                        min: -0.002,
                        max: 0.002,
                        tickPositions: [-0.0020, -0.0015, -0.0010, -0.0005, 0.0000, 0.0005, 0.0010, 0.0015, 0.0020],
                        tickInterval: 0.0005,
                        gridLineWidth: 3,
                        minorGridLineWidth: 1,
                        minorTicks: true,
                        minorTickInterval: 0.0001,

                        gridLineWidth: 2,
                        gridLineColor: '#D3D3D3',
                        labels: {
                            enabled: false
                        },
                        title: {
                            enabled: false
                        },

                    },
                    {
                        min: 0,
                        max: 10,
                        gridLineWidth: 0,
                        minorGridLineWidth: 0,
                        minorTicks: false,

                        labels: {
                            enabled: true
                        },
                        title: {
                            enabled: false
                        },
                        visible: false

                    }
                ],
                rangeSelector: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        turboThreshold: 15500,
                        colorByPoint: false
                    },

                },

                title: {
                    text: ''
                },

                series: [{


                    data: [],
                    yAxis: 1,
                    xAxis: 0,
                    color: 'black',
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    },

                    allowPointSelect: false,
                    animation: false,
                    enableMouseTracking: false,
                    lineWidth: 1,
                    marker: {
                        enabled: false
                    },
                    shadow: false,
                    stickyTracking: false,
                    showInLegend: false


                }]
            });
            chart.showLoading(' Please wait while the ECG is being loaded  <i class="fa fa-spin fa-spinner"></i>');
            chart.addSeries({

                yAxis: 0,

                type: 'column',

                data: [],
                pointWidth: 400,
                inverted: true,
                tooltip: {
                    enabled: false
                },
                states: {

                    inactive: {
                        opacity: 1
                    }
                },

                allowPointSelect: false,
                animation: false,
                enableMouseTracking: false,
                lineWidth: 1,

                shadow: false,
                stickyTracking: false,
                showInLegend: false

            }, true);
            chart.addSeries({
                dataLabels: [{
                    enabled: true,
                    inside: true,
                    align: 'center',
                    format: 'B:{point.beat}; HR:{point.hr}; RR:{point.rr}'
                    //format: 'HR:{point.hr}; RR:{point.rr}'
                }],
                yAxis: 2,
                type: 'column',
                turboThreshold: 10000,
                data: [],
                pointWidth: 250,
                tooltip: {
                    enabled: false
                },
                point: {
                    events: {
                        click: function() {

                        },


                    }
                },
                states: {

                    inactive: {
                        opacity: 1
                    }
                },
                allowPointSelect: false,
                animation: false,
                enableMouseTracking: false,
                marker: {
                    enabled: false
                },

                showInLegend: false
            }, true);

            chart.addSeries({
                name: 'P Peak',
                yAxis: 1,
                data: [],
                lineWidth: 0,
                marker: {
                    enabled: true,
                    radius: 4,
                    symbol: 'triangle'
                },
                tooltip: {
                    enabled: false
                },
                states: {
                    hover: {
                        lineWidthPlus: 0
                    },
                    inactive: {
                        opacity: 1
                    }
                },
                enableMouseTracking: false,
            }, true);
            chart.addSeries({
                name: 'Q Peak',
                yAxis: 1,
                data: [],

                lineWidth: 0,
                marker: {
                    enabled: true,
                    radius: 4,

                    symbol: 'circle'
                },

                tooltip: {

                    enabled: false

                },
                states: {
                    hover: {
                        lineWidthPlus: 0

                    },
                    inactive: {
                        opacity: 1
                    }
                },
                enableMouseTracking: false,
            }, true);
            chart.addSeries({
                name: 'R Peak',
                yAxis: 1,
                data: [],

                lineWidth: 0,
                marker: {
                    enabled: true,
                    radius: 4,

                    symbol: 'circle'
                },

                tooltip: {

                    enabled: false

                },
                states: {
                    hover: {
                        lineWidthPlus: 0

                    },
                    inactive: {
                        opacity: 1
                    }
                },
                enableMouseTracking: false,
            }, true);
            chart.addSeries({
                name: 'S Peak',
                yAxis: 1,
                data: [],

                lineWidth: 0,
                marker: {
                    enabled: true,
                    radius: 4,

                    symbol: 'circle'
                },

                tooltip: {

                    enabled: false

                },
                states: {
                    hover: {
                        lineWidthPlus: 0

                    },
                    inactive: {
                        opacity: 1
                    }
                },
                enableMouseTracking: false,
            }, true);
            chart.addSeries({
                name: 'T Peak',
                yAxis: 1,
                data: [],

                lineWidth: 0,
                marker: {
                    enabled: true,
                    radius: 4,

                    symbol: 'circle'
                },

                tooltip: {

                    enabled: false

                },
                states: {
                    hover: {
                        lineWidthPlus: 0

                    },
                    inactive: {
                        opacity: 1
                    }
                },
                enableMouseTracking: false,
            }, true);
            chart.addSeries({
                name: 'P On Peak',
                yAxis: 1,
                data: [],

                lineWidth: 0,
                marker: {
                    enabled: true,
                    radius: 4,

                    symbol: 'circle'
                },

                tooltip: {

                    enabled: false

                },
                states: {
                    hover: {
                        lineWidthPlus: 0

                    },
                    inactive: {
                        opacity: 1
                    }
                },
                enableMouseTracking: false,
            }, true);
            chart.addSeries({
                name: 'P Off Peak',
                yAxis: 1,
                data: [],

                lineWidth: 0,
                marker: {
                    enabled: true,
                    radius: 4,

                    symbol: 'circle'
                },

                tooltip: {

                    enabled: false

                },
                states: {
                    hover: {
                        lineWidthPlus: 0

                    },
                    inactive: {
                        opacity: 1
                    }
                },
                enableMouseTracking: false,
            }, true);
            chart.addSeries({
                name: 'T On Peak',
                yAxis: 1,
                data: [],

                lineWidth: 0,
                marker: {
                    enabled: true,
                    radius: 4,

                    symbol: 'circle'
                },

                tooltip: {

                    enabled: false

                },
                states: {
                    hover: {
                        lineWidthPlus: 0

                    },
                    inactive: {
                        opacity: 1
                    }
                },
                enableMouseTracking: false,
            }, true);
            chart.addSeries({
                name: 'T Off Peak',
                yAxis: 1,
                data: [],

                lineWidth: 0,
                marker: {
                    enabled: true,
                    radius: 4,

                    symbol: 'circle'
                },

                tooltip: {

                    enabled: false

                },
                states: {
                    hover: {
                        lineWidthPlus: 0

                    },
                    inactive: {
                        opacity: 1
                    }
                },
                enableMouseTracking: false,
            }, true);
            chart.addSeries({
                name: 'J Peak',
                yAxis: 1,
                data: [],

                lineWidth: 0,
                marker: {
                    enabled: true,
                    radius: 4,

                    symbol: 'circle'
                },

                tooltip: {

                    enabled: false

                },
                states: {
                    hover: {
                        lineWidthPlus: 0

                    },
                    inactive: {
                        opacity: 1
                    }
                },
                enableMouseTracking: false,
            }, true);
            chart.addSeries({
                name: 'Place Holder',
                yAxis: 1,
                data: [],

                lineWidth: 0,
                marker: {
                    enabled: true,
                    radius: 4,

                    symbol: 'circle'
                },

                tooltip: {

                    enabled: false

                },
                states: {
                    hover: {
                        lineWidthPlus: 0

                    },
                    inactive: {
                        opacity: 1
                    }
                },
                enableMouseTracking: false,
            }, true);

            return chart;
        };


        if (step == 2) {
            this.state.chart.destroy();
            this.setState({
                databaseid: databaseid
            });
        }

        document.getElementById('selWrap').innerHTML = "";

        axios
            .get('https://web.mybiocalculus.com:5000/api/common/getfolderfiles?userid=' + userid + '&databaseid=' + databaseid)
            .then(res => {
                console.log('Images', res.data[0]);
                this.setState({
                    images: res.data[0]
                });
                this.setState({
                    dblink: res.data[1]
                });

            })
            .catch(err => {
                console.log(err);
            });
        /*this.setState({
            minutes_no: "0"
        });*/
        var mainchat = renderMainChart();


        this.setState({
            result3: []
        });
        this.setState({
            defarr: []
        });
        this.setState({
            nsrarr: []
        });
        this.setState({
            starr: []
        });
        this.setState({
            sbarr: []
        });
        this.setState({
            sparr: []
        });
        this.setState({
            pacarr: []
        });
        this.setState({
            pvcarr: []
        });
        this.setState({
            vtarr: []
        });
        this.setState({
            afarr: []
        });
        this.setState({
            paarr: []
        });
        this.setState({
            irrrarr: []
        });
        this.setState({
            irprarr: []
        });
        this.setState({
            sqtarr: []
        });
        this.setState({
            paarr: []
        });
        this.setState({
            unarr: []
        });


        var setEcgData = res => {
            // console.log('main', res.data);
            for (var i = this.state.start; i < res.data.length; i++) {
                window.mainArray[i].push(res.data[i]);
            }
            //console.log('window.mainArray', window.mainArray);

            if (!this.preEcgValues) {
                this.preEcgValues = res.data;

                this.ecgValues = res.data.map(e => e[0]).reduce((a, b) => a.concat(b));
                this.activityValues = res.data.map(e => e[1]).reduce((a, b) => a.concat(b));
                this.peakValues = res.data.map(e => e[2]).reduce((a, b) => a.concat(b));
            } else {

                if (this.state.scrollStatus === 'next') {
                    this.preEcgValues = this.preEcgValues.filter(
                        (e, i) => (i > this.state.limitScrollStart - 1)
                    );

                    this.preEcgValues = this.preEcgValues.concat(res.data)
                } else if (this.state.scrollStatus === 'prev') {
                    this.preEcgValues = this.preEcgValues.filter(
                        (e, i) => i < (this.state.limitScrollStart - 1)
                    );
                    this.preEcgValues = res.data.concat(this.preEcgValues)
                }
                this.ecgValues = this.preEcgValues.map(e => e[0]).reduce((a, b) => a.concat(b));
                this.activityValues = this.preEcgValues.map(e => e[1]).reduce((a, b) => a.concat(b));
                this.peakValues = this.preEcgValues.map(e => e[2]).reduce((a, b) => a.concat(b));

            }

            /*console.log("preEcgValues",this.preEcgValues);
            console.log("this.ecgValues.length",this.ecgValues.length);*/
            datasetMnt = Math.floor(this.ecgValues.length / deviceFrequency)
            //console.log("datasetMnt",datasetMnt);
            if (this.ecgValues.length % deviceFrequency > 0) {
                datasetMnt += 1;
            }

            /*mainEcgArray = this.ecgValues;
            mainActivityArray = this.activityValues;*/
            this.updateRpeaks(this.peakValues);
            var graphPointIndex = 0;
            if (this.state.scrollStatus === 'next') {
                if (clickContainerIndex > 2) {
                    graphPointIndex = parseInt(clickContainerIndex) - 2;
                }
            } else if (this.state.scrollStatus === 'prev') {
                graphPointIndex = 4;
                if (clickContainerIndex < 2) {
                    graphPointIndex = parseInt(clickContainerIndex) + 2;
                }
            }

/*
            result3 = chunkPeakArray(this.peakValues, this.state.peakStart);
            window.result3 = result3;
            this.setState({
                result3: result3
            });
            console.log('state result3', this.state.result3);*/
            var acti = 125;
            var counteri = 249;
            var counter = 0;
            var t = 0;
            var actarr = [];
            colorArray = ['#98FB98', '#f1e4e4', '#f7e0a3', '#f09c67']


            var sliceActStart = graphPointIndex * window.sc;
            var sliceActEnd = sliceActStart + window.sc + 1;
            var curDataArray = this.activityValues.slice(sliceActStart, sliceActEnd);
            // console.log(this.ecgValues.slice(sliceStart, sliceEnd));


            for (var i = 0; i < curDataArray.length; i++) {
                actarr.push({
                    x: acti,
                    y: 0.5,
                    color: colorArray[curDataArray[i]]
                });
                acti = acti + 250;
            }

            chart.hideLoading();
            var sliceStart = graphPointIndex * deviceFrequency;
            var sliceEnd = sliceStart + deviceFrequency + 1;
            // console.log('ECG Valuess',this.ecgValues.slice(sliceStart, sliceEnd));
            chart.series[0].setData(this.ecgValues.slice(sliceStart, sliceEnd), true);

            chart.series[1].setData(actarr, true);
        /*    chart.series[2].setData(result3[graphPointIndex], true);*/

            renderSmallCharts(graphPointIndex);


            this.setState({
                datasetMnt: datasetMnt
            });
            this.setState({
                mainEcgArray: this.ecgValues
            });
            //console.log('mainOne', this.state.mainEcgArray);
            this.setState({
                mainActivityArray: this.activityValues
            });
           /* this.setState({
                result3: result3
            });*/

            this.setState({
                charts: charts
            });
            this.setState({
                chart: chart
            });
            this.setState({
                defarr: defarr
            });
            this.setState({
                nsrarr: nsrarr
            });
            this.setState({
                starr: starr
            });
            this.setState({
                sbarr: sbarr
            });
            this.setState({
                sparr: sparr
            });
            this.setState({
                pacarr: pacarr
            });
            this.setState({
                pvcarr: pvcarr
            });
            this.setState({
                vtarr: vtarr
            });
            this.setState({
                afarr: afarr
            });
            this.setState({
                parr: parr
            });
            this.setState({
                irrrarr: irrrarr
            });
            this.setState({
                irprarr: irprarr
            });
            this.setState({
                sqtarr: sqtarr
            });
            this.setState({
                paarr: paarr
            });
            this.setState({
                unarr: unarr
            });



        }

        //this.state.charts[0].destroy();

        for (var k = 0; k < this.state.charts.length; k++) {
            if (this.state.charts[k] != undefined) {
                //console.log('chart length',this.state.charts);
                this.state.charts[k].destroy();
            }
        }
        /*this.setState({
            minutes_no: start.toString()

        });*/
        //console.log('charts New',this.props.auth.user.minutes_no);
        /* start=parseInt(this.props.auth.user.minutes_no);
         end=parseInt(this.props.auth.user.minutes_no)+4;*/
        axios
            .get(apiUrl + 'api/common/getEcgNewData?start=' + start + '&end=9' + '&userid=' + userid + '&databaseid=' + databaseid)
            .then(

                setEcgData
            )
            .catch(err => {});

/*        var setGraphMarker = res => {
            this.state.graphMarker = res;

            var clickPointIndex = 0;
            if (this.state.scrollStatus === 'next') {
                if (clickContainerIndex > 2) {
                    clickPointIndex = parseInt(clickContainerIndex) - 2;
                }
            } else if (this.state.scrollStatus === 'prev') {
                clickPointIndex = 4;
                if (clickContainerIndex < 2) {
                    clickPointIndex = parseInt(clickContainerIndex) + 2;
                }
            }
            var graphPointIndex = pageIndex * 3;
            parr = chunkMarkerArray(res.data.ppeak_index, res.data.ppeak_amp, graphPointIndex);

            chart.series[3].update({
                data: parr[clickPointIndex],
            }, true);
            this.setState({
                parr: parr
            });
            tarr = chunkMarkerArray(res.data.tpeak_index, res.data.tpeak_amp, graphPointIndex);
            chart.series[7].update({
                data: tarr[clickPointIndex],
            }, true);
            this.setState({
                tarr: tarr
            });
            ponarr = chunkMarkerArray(res.data.pon_index, res.data.pon_amp, graphPointIndex);
            chart.series[8].update({
                data: ponarr[clickPointIndex],
            }, true);
            this.setState({
                ponarr: ponarr
            });
            qarr = chunkMarkerArray(res.data.q_index, res.data.q_amp, graphPointIndex);
            chart.series[4].update({
                data: qarr[clickPointIndex],
            }, true);
            this.setState({
                qarr: qarr
            });
            rarr = chunkMarkerArray(res.data.rpeak_index, res.data.rpeak_amp, graphPointIndex);
            chart.series[5].update({
                data: rarr[clickPointIndex],
            }, true);
            this.setState({
                rarr: rarr
            });
            sarr = chunkMarkerArray(res.data.s_index, res.data.s_amp, graphPointIndex);
            chart.series[6].update({
                data: sarr[clickPointIndex],
            }, true);
            this.setState({
                sarr: sarr
            });
            poffarr = chunkMarkerArray(res.data.poff_index, res.data.poff_amp, graphPointIndex);
            chart.series[9].update({
                data: poffarr[clickPointIndex],
            }, true);
            this.setState({
                poffarr: poffarr
            });
            tonarr = chunkMarkerArray(res.data.ton_index, res.data.ton_amp, graphPointIndex);
            chart.series[10].update({
                data: tonarr[clickPointIndex],
            }, true);
            this.setState({
                tonarr: tonarr
            });
            toffarr = chunkMarkerArray(res.data.toff_index, res.data.toff_amp, graphPointIndex);
            chart.series[11].update({
                data: toffarr[clickPointIndex],
            }, true);
            this.setState({
                toffarr: toffarr
            });
            jarr = chunkMarkerArray(res.data.j_index, res.data.j_amp, graphPointIndex);
            chart.series[12].update({
                data: jarr[clickPointIndex],
            }, true);
            this.setState({
                jarr: jarr
            });
            chart.series[13].update({
                data: [],
            }, true);
            // console.log(this.state.rarr);
        }
        if (Object.keys(this.state.graphMarker).length > 0) {
            setGraphMarker(this.state.graphMarker)
        } else {
            axios
                .get(apiUrl + 'api/common/getGraphMarker?userid=' + userid + '&databaseid=' + databaseid)
                .then(
                    setGraphMarker
                )
                .catch(err => {});
        }*/



        const data = {
            databaseid: dbId,
            userid: userid
        };
        var setVerifyCheck = res => {
            this.state.verifyCheck = res;
            if (res.data == 0) {
                $("#verfiyBtn").css("display", "inline-block");
            } else if (res.data == 1) {
                $("#verfiyBtn").css("display", "none");
            }
        }
        if (Object.keys(this.state.verifyCheck).length > 0) {
            setVerifyCheck(this.state.verifyCheck)
        } else {
            axios
                .post(apiUrl + 'api/common/checkVerify', data)
                .then(
                    setVerifyCheck
                )
                .catch(err => {
                    console.log(err)
                });
        }

    }

    nextBtnLink(userid, dbid) {
        axios
            .get(apiUrl + 'api/common/getNext?userid=' + userid + '&databaseid=' + dbid)
            .then(res => {
                if (res.data != null) {
                    document.getElementById("graphNext").style.display = 'inline-block';
                    this.setState({
                        nextDbId: res.data.ecgvalue_id
                    })
                } else {
                    document.getElementById("graphNext").style.display = 'none';
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    prevBtnLink(userid, dbid) {
        axios
            .get(apiUrl + 'api/common/getPrev?userid=' + userid + '&databaseid=' + dbid)
            .then(res => {
                if (res.data != null) {
                    document.getElementById("graphPrev").style.display = 'inline-block';
                    this.setState({
                        prevDbId: res.data.ecgvalue_id
                    })
                } else {
                    document.getElementById("graphPrev").style.display = 'none';
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    newDataClick(e) {
        var buttonId = e.currentTarget.id;
        var databaseid;
        var userid = this.props.match.params.userid;
        if (buttonId == 'nextBtn') {
            databaseid = this.state.nextDbId;
        } else if (buttonId == 'prevBtn') {
            databaseid = this.state.prevDbId;
        }
        document.getElementById("graphPrev").style.display = 'none';
        document.getElementById("graphNext").style.display = 'none';
        this.renderNewChart(userid, databaseid, 2);
        this.nextBtnLink(userid, databaseid);
        this.prevBtnLink(userid, databaseid);
    }


    componentDidMount() {
        
        window.container = 0;
        window.resumeFlag = 0;
        window.eventclass = 'all';
        window.selectAll = 0;
        window.beatArr = [];
        window.timer = null;
        window.chartid = 0;
        window.mainArray = [];
        window.prevStart=0;


        //window.addEventListener('beforeunload', this.keepOnPage);
        this.callDataAPIs();
        if (!this.state.keyIntegrationStatus) {
            this.keyIntegration();
            this.state.keyIntegrationStatus = 1;
        }


    }

    keyIntegration() {
        $(document).keydown((e) => {
            //console.log('key down');
            if (this.state.currentTab == 'chart_tab_2') {
                let chart = this.state.chart.xAxis[0]
                let extremes = chart.getExtremes()
                let zoomLevel = extremes.max - extremes.min
                switch (e.which) {
                    case 65:
                    case 37: // left
                        var minL = extremes.min - zoomLevel;
                        var maxL = extremes.min;
                        if (minL <= 0) {
                            maxL = 1000
                            minL = 0
                        }
                        if (extremes.min == 0) {
                            var e = $.Event("keydown");

                            // e.which is used to set the keycode
                            e.which = 38; // it is down
                            $("body").trigger(e);
                        } else {
                            chart.setExtremes(minL, maxL)
                        }
                        break;

                    case 87:
                    case 38: // up
                        //this.handleTopScroll();

                        break;

                    case 68:
                    case 39: // right
                        var min = extremes.max;
                        var max = extremes.max + zoomLevel;
                        var stripIndex = window.prevChart;
                        var sliceStart = stripIndex * deviceFrequency;
                        var sliceEnd = sliceStart + deviceFrequency + 1;
                        var currentEcgArray = this.state.mainEcgArray.slice(sliceStart, sliceEnd);
                        if ((max) >= deviceFrequency) {
                            max = deviceFrequency - 1
                            min = deviceFrequency - 1000
                        } else if ((max) > currentEcgArray.length) {
                            max = currentEcgArray.length;
                            min = currentEcgArray.length - zoomLevel;
                        }
                        if (extremes.max == deviceFrequency - 1) {
                            var e = $.Event("keydown");

                            // e.which is used to set the keycode
                            e.which = 40; // it is down
                            $("body").trigger(e);
                        } else {
                            chart.setExtremes(min, max)
                        }
                        break;

                    case 83:
                    case 40: // down   
                        this.handleBottomScroll();

                        break;

                    default:
                        return; // exit this handler for other keys
                }
                e.preventDefault(); // prevent the default action (scroll / move caret)
            }
        });
    }


    recategorizeImg(e) {
        var checkedArray = [];
        var userid = this.props.match.params.userid;
        var databaseid = this.state.dblink;
        var folder = e.currentTarget.value;
        console.log(folder);
        var self = this;
        var clickedPI_;
        var value1;
        const data = [];
        var i = 0;
        $("input:checkbox[name=test]:checked").each(function() {
            checkedArray.push($(this).val());
            this.checked = false;
            var clickedPI = this.id.toString();
            clickedPI = clickedPI.split("_");
            clickedPI_ = clickedPI[1].substring(0, 2) + ':' + clickedPI[1].substring(2, 4) + ':' + clickedPI[1].substring(4, 6);
            data[i] = {
                userid: userid,
                databaseid: databaseid,
                clickedPI_: clickedPI_
            };
            i = i + 1;

            window.beatArr.push(clickedPI[2].split(".")[0]);
            value1 = this.value.toString();



        });

        if (folder == 'un') {
            value1 = 'Undiagnosed'
        } else if (folder == 'nsr') {
            value1 = 'Normal Sinus Rhythm'
        } else if (folder == 'sb') {
            value1 = 'Sinus Bradycardia'
        } else if (folder == 'st') {
            value1 = 'Sinus Tachycardia'
        } else if (folder == 'pac') {
            value1 = 'PAC'
        } else if (folder == 'pvc') {
            value1 = 'PVC'
        } else if (folder == 'vt') {
            value1 = 'Ventricular Tachycardia'
        } else if (folder == 'af') {
            value1 = 'AFib'
        } else if (folder == 'sp') {
            value1 = 'Sinus Pause'
        } else if (folder == 'pa') {
            value1 = 'P Absent'
        } else if (folder == 'irr') {
            value1 = 'Irregular RR'
        } else if (folder == 'ipr') {
            value1 = 'Irregular PR'
        } else if (folder == 'sqt') {
            value1 = 'Short QT'
        } else if (folder == 'lqt') {
            value1 = 'Prolonged QT'
        }

        self.recategorizeAfterImage(value1)

        if (checkedArray.length > 0) {
            const data = {
                userid: userid,
                databaseid: databaseid,
                checkedArray: checkedArray,
                folder: folder
            }

            axios
                .post('https://web.mybiocalculus.com:5000/api/common/reCategorize', data)
                .then(res => {
                    axios
                        .get('https://web.mybiocalculus.com:5000/api/common/getfolderfiles?userid=' + userid + '&databaseid=' + databaseid)
                        .then(res => {

                            this.setState({
                                images: res.data[0]
                            });
                            this.setState({
                                dblink: res.data[1]
                            });
                            console.log(res.data[0]);
                        })
                        .catch(err => {
                            console.log(err);
                        });
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    removeFiles(e) {


        var checkedArray = [];
        var userid = this.props.match.params.userid;
        var databaseid = this.state.dblink;
        var folder = window.eventclass;
        console.log(folder);
        $("input:checkbox[name=test]:checked").each(function() {
            checkedArray.push($(this).val());
            this.checked = false;
        });
        if (checkedArray.length > 0) {
            const data = {
                userid: userid,
                databaseid: databaseid,
                checkedArray: checkedArray,
                folder: folder
            }
            console.log(data);
            axios
                .post('https://web.mybiocalculus.com:5000/api/common/removeFiles', data)
                .then(res => {
                    console.log('Files removed');
                    axios
                        .get('https://web.mybiocalculus.com:5000/api/common/getfolderfiles?userid=' + userid + '&databaseid=' + databaseid)
                        .then(res => {
                            //  console.log(res.data[0]);
                            this.setState({
                                images: res.data[0]
                            });
                            this.setState({
                                dblink: res.data[1]
                            });

                        })
                        .catch(err => {
                            console.log(err);
                        });
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }
    removeAll(e) {
        if (window.confirm('Do you want to delete all the ECG Event Strips?')) {


            console.log("remove all");


            var userid = this.props.match.params.userid;
            const data = {
                userid: userid

            }

            axios
                .post('https://web.mybiocalculus.com:5000/api/common/removeAll', data)
                .then(res => {
                    console.log('Removed');
                })
                .catch(err => {
                    console.log(err);
                });
        }



    }
    deleteVerify(e) {
        if (window.confirm('Do you want to delete this Dataset?')) {


            console.log("remove all");


            var userid = this.props.match.params.userid;
            var databaseid = this.state.dblink;
            const data = {
                userid: userid,
                databaseid: databaseid
            }


            axios
                .get(apiUrl + 'api/common/getLatestDatabaseId?userid=' + this.props.match.params.userid)
                .then(res => {

                    var latestDatabaseid = res.data;
                    axios
                        .get('https://web.mybiocalculus.com:5000/api/common/deletedataset?userid=' + userid + '&databaseid=' + databaseid + '&latestdatabaseid=' + latestDatabaseid)
                        .then(res => {
                            console.log('Removed');
                        })
                        .catch(err => {
                            console.log(err);
                        });

                })
                .catch(err => {
                    console.log(err);
                });


        }



    }
    selectAll(e) {
        console.log("select all files");
        var searchDiv = document.getElementById('portfolio');
        var selBtn = document.getElementById('SA');
        //console.log(searchDiv);
        /* var cds= document.getElementById('currentDataSet');
         var fds = document.getElementById('fullDataSet');*/
        //console.log(fds);

        if (window.selectAll == 0) {
            selBtn.childNodes[0].text = "Deselect All";
            for (var i = 0; i < searchDiv.childNodes.length; i++) {

                if (searchDiv.childNodes[i].childNodes[1].className == window.eventclass) {
                    searchDiv.childNodes[i].childNodes[1].checked = "checked";

                }


            }
            window.selectAll = 1;
        } else if (window.selectAll == 1) {
            selBtn.childNodes[0].text = "Select All";
            for (var i = 0; i < searchDiv.childNodes.length; i++) {

                if (searchDiv.childNodes[i].childNodes[1].className == window.eventclass) {
                    searchDiv.childNodes[i].childNodes[1].checked = "";

                }


            }
            window.selectAll = 0;

        }

    }
    addImageToReport(e) {
        console.log('addImageToReport');
        var checkedArray = [];
        var userid = this.props.match.params.userid;
        var databaseid = this.state.dblink;
        var folder = window.eventclass;
        console.log(folder);
        $("input:checkbox[name=test]:checked").each(function() {
            checkedArray.push($(this).val());
            this.checked = false;

        });
        if (checkedArray.length > 0) {
            const data = {
                userid: userid,
                databaseid: databaseid,
                checkedArray: checkedArray,
                folder: folder
            }
            console.log(data);
            axios
                .post('https://web.mybiocalculus.com:5000/api/common/reCategorizeImg', data)
                .then(res => {
                    console.log(res.data);

                })
                .catch(err => {
                    console.log(err);
                });
        }
    }
    filter(e) {
        var selected = e.currentTarget.id;
        if (selected == 'all') {
            $("#portfolio").children().show(1000);
            $("#filters a").removeClass('active');
            $("#" + e.currentTarget.id).addClass('active');
            window.eventclass = e.currentTarget.id;
        } else {
            $("#portfolio").children().hide(1000);
            var thisclass = "#portfolio ." + selected;
            $("#portfolio ." + selected).show(1000);
            $("#filters a").removeClass('active');
            $("#" + e.currentTarget.id + " a").addClass('active');
            window.eventclass = e.currentTarget.id;
        }
    }
    resumeVerify() {
        var userid = this.props.match.params.userid;
        window.resumeFlag = 1;
        /* console.log('top',$('#selWrap').position().top);
         console.log('top',$('#container20').position().top-($('#selWrap').position().top+53.6833333));*/

        axios
            .get(apiUrl + 'api/patients/getResumeDetails?userid=' + this.props.match.params.userid)
            .then(res => {
                console.log(res.data.stripvalue_resume);
                this.setState({
                    databaseid: res.data.db_resume
                });
                /* this.setState({
                     start: res.data.stripvalue_resume
                 });
                 this.setState({
                     end: res.data.stripvalue_resume + 4
                 });*/
                window.stripXValue = res.data.xvalue_resume - (res.data.stripvalue_resume * 15000);
                window.container = res.data.stripvalue_resume;

                $('#selWrap').scrollTop($('#container' + res.data.stripvalue_resume).position().top - ($('#selWrap').position().top));


                //  $('#selWrap').scrollTop($('#span').offset().top);
                // this.renderResumeDataOnly(userid,res.data,1);
                //this.nextBtnLink(userid,res.data);
                //this.prevBtnLink(userid,res.data);       
            })
            .catch(err => {
                console.log(err);
            });

    }
    saveVerify() {
        var userid = this.props.match.params.userid;
        axios
            .get(apiUrl + 'api/patients/saveChartDetails?userid=' + this.props.match.params.userid)
            .then(res => {
                console.log(res.data.db_resume);
                this.setState({
                    databaseid: res.data.db_resume
                });
                this.setState({
                    start: res.data.stripvalue_resume
                });
                this.setState({
                    databaseid: res.data.stripvalue_resume + 4

                });
                this.renderNewChart(userid, res.data.db_resume, 1, this.state.start, this.state.end);
                // this.renderResumeDataOnly(userid,res.data,1);
                //this.nextBtnLink(userid,res.data);
                //this.prevBtnLink(userid,res.data);       
            })
            .catch(err => {
                console.log(err);
            });

    }
    renderNewSmallCharts() {

        var j = this.state.start;
        var datasetMnt = j + 10;
        var start = this.state.start;
        var end = this.state.end;
        var userid = this.props.match.params.userid;

        for (var i = 0; i < this.state.charts.length; i++) {
            this.state.charts[i].destroy();
        }

        var i = 0;
        this.state.charts = [];
        var dis = this;
        var charts = [];
        var myVar = setInterval(function() {

            charts[i] = new Highcharts.Chart('container' + j, {
                credits: {
                    enabled: false
                },
                chart: {
                    height: 4 + '%',
                    resetZoomButton: {
                        theme: {
                            display: 'none'
                        },
                    },
                    events: {
                        click: function(e) {
                            /* Do something with the event */

                            clickFunction(e, this); // ...which means you can access it from inside the click handler function
                        }
                    },
                    zoomType: '',
                },

                navigator: {
                    enabled: false
                },

                xAxis: [{
                    min: 0,
                    max: deviceFrequency,
                    labels: {
                        enabled: false
                    },
                    visible: false

                }],
                yAxis: [{
                    min: gainMin,
                    max: gainMax,
                    gridLineWidth: 0,
                    minorGridLineWidth: 0,
                    minorTicks: false,
                    labels: {
                        enabled: false
                    },
                    title: {
                        enabled: false
                    },
                }],
                rangeSelector: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        turboThreshold: 0,
                    },
                },
                title: {
                    text: ''
                },

                series: [{
                    type: 'coloredline',
                    data: [],
                    color: 'black',
                    allowPointSelect: false,
                    animation: false,
                    enableMouseTracking: false,
                    lineWidth: 1,
                    marker: {
                        enabled: false
                    },
                    shadow: false,
                    stickyTracking: false,
                    showInLegend: false
                }]
            });


            j = j + 1;
            i = i + 1;
            console.log('datasetMnt', datasetMnt);
            if (j > datasetMnt - 1) {
                clearInterval(myVar);
            }
        }, 5);

        this.setState({
            charts: charts
        });


        var newThis = this,
            val;

        var databaseid = this.state.databaseid;
        var mainEcgArray, mainActivityArray = [];
        var result3, colorArray = [];
        var chart;
        var parr = [],
            tarr = [],
            qarr = [],
            rarr = [],
            sarr = [],
            ponarr = [],
            poffarr = [],
            tonarr = [],
            toffarr = [],
            jarr = [];
        var peakColorArray = [];
        peakColorArray['Undiagnosed'] = '#FCDF85';
        peakColorArray['Normal Sinus Rhythm'] = '#98FB98';
        peakColorArray['Sinus Tachycardia'] = '#3EFFDE';
        peakColorArray['PAC'] = '#4950EB';
        peakColorArray['PVC'] = '#FF45DC';
        peakColorArray['P absent'] = '#AEB9BD';
        peakColorArray['Sinus Bradycardia'] = '#CFFF0F';
        peakColorArray['Sinus Pause'] = '#FF5B05';
        peakColorArray['Irregular RR'] = '#A4C3FF';
        peakColorArray['Ventricular Tachycardia'] = '#F0A7A1';
        peakColorArray['Irregular PR'] = '#BBEB8F';
        peakColorArray['Short QT'] = '#FFD14F';
        peakColorArray['Prolonged QT'] = '#7C2F09';
        peakColorArray['AFib'] = '#A96BFF';

        function chunkPeakArray(peakArray, start) {
            let presults = [];
            var minIndex = 0;


            /*if(window.tab=='event'){

               start=window.pageIndex;
            }
            else
            {
                start=this.state.start;
            }*/
            var breakVal = (start + 1) * deviceFrequency; //165000
            var z;
            var temRpeakindex;
            presults.push([]);



            for (var i = 0; i < peakArray.length; i++) {
                /*console.log('start',start);
                console.log(peakArray[i].rpeakindex);*/
                if (start * deviceFrequency < peakArray[i].rpeakindex) {
                    if (peakArray[i].rpeakindex > breakVal) {
                        presults.push([]);
                        minIndex = minIndex + 1;
                        breakVal = breakVal + deviceFrequency;
                    }

                    temRpeakindex = parseInt(peakArray[i].rpeakindex) - ((minIndex + start) * deviceFrequency);
                    presults[minIndex].push({
                        x: temRpeakindex,
                        y: parseFloat(0.8),
                        color: peakColorArray[peakArray[i].arrhythmia],
                        beat: parseInt(peakArray[i].indexcounter),
                        hr: parseInt(peakArray[i].heartrate),
                        rr: parseInt(peakArray[i].rr_interval),
                        arrhythmia: peakArray[i].arrhythmia,
                        peaktime: peakArray[i].peaktime
                    });
                }
            }
            return presults;
        }

        function chunkMarkerArray(markArrayX, markArrayY, start = 0) {

            var ppeakIndex = markArrayX.split(',');

            var ppeakAmp = markArrayY.split(',');
            let mresults = [];

            var minIndex = 0;
            var breakVal = (start + 1) * deviceFrequency;
            var z;
            var temindex;
            mresults.push([]);
            for (var i = 0; i < ppeakIndex.length; i++) {
                if (ppeakIndex[i] > start * deviceFrequency) {
                    if (ppeakIndex[i] > breakVal) {
                        mresults.push([]);
                        minIndex = minIndex + 1;
                        breakVal = breakVal + deviceFrequency;
                    }
                    temindex = parseInt(ppeakIndex[i]) - ((minIndex + start) * deviceFrequency);
                    mresults[minIndex].push([temindex, parseFloat(ppeakAmp[i])]);
                }
            }
            return mresults;
        }

        function clickFunction(event, that) {
            console.log('prevChart', window.prevChart);
            var containerId = that.renderTo.id; //got the Container ID
            window.container = containerId.replace('container', '');
            window.chartid = parseInt(containerId.replace('container', '')) - newThis.state.start; //container id stored in global variable
            window.stripXValue = event.chartX; //got the location in the xaxis
           
            //console.log('DataIndex', window.dataArrInd);

            //var beats = JSON.parse(document.getElementById("beatSlected").value);

            //console.log(beatArr);


            /*************Remove Plotband***********************/

            if (window.beatArr.length > 0 && window.chartid != window.prevChart) {
                for (var i = 0; i < window.beatArr.length; i++) {
                    var j = parseInt(window.container)  * deviceFrequency;
                    var xBeat = parseInt(window.beatArr[i]) - j;


                    console.log('xBeat', xBeat);
                    newThis.state.chart.xAxis[0].removePlotBand('plotband-' + xBeat);
                }
                //document.getElementById("beatSlected").value='[]';
                // beatArr=[];
            }


            /*************Remove Plotband***********************/

            if (newThis.state.charts[window.chartid]) {
                val = newThis.state.charts[window.chartid].xAxis[0].toValue(event.chartX);

            }


            //console.log(selfNew.state.mainEcgArray);
            var sliceStart = window.chartid * deviceFrequency;
            var sliceEnd = sliceStart + deviceFrequency + 1;
            var sliceActStart = window.chartid * 60;
            var sliceActEnd = sliceActStart + 61;
            console.log('sliceStart',sliceStart);
            console.log('sliceStart',sliceEnd);
            var ecgArray = newThis.state.mainEcgArray.slice(sliceStart, sliceEnd);
            var curActArray = newThis.state.mainActivityArray.slice(sliceActStart, sliceActEnd);
 
          
            if (val > ecgArray.length) {
                val = ecgArray.length - 500;
            }




            var acti = 125;
            var actarr = [];
            var tttarr1 = [];
            console.log('container',window.container);
            /*console.log(newThis.state.rarr[window.dataArrInd]);*/

            var colorArray = ['#98FB98', '#f1e4e4', '#f7e0a3', '#f09c67'];

            for (var i = 0; i < curActArray.length; i++) {
                actarr.push({
                    x: acti,
                    y: 0.5,
                    color: colorArray[curActArray[i]]
                });
                acti = acti + 250;
            }
              

            
            newThis.state.chart.series[0].setData(ecgArray, false);
            newThis.state.chart.series[1].setData(actarr, false);
            newThis.state.chart.series[2].setData(newThis.state.result3[window.chartid], false);
            /*newThis.state.chart.series[3].update({
                data: newThis.state.parr[window.container],
            }, false);
            newThis.state.chart.series[4].update({
                data: newThis.state.qarr[window.container],
            }, false);
            newThis.state.chart.series[5].update({
                data: newThis.state.rarr[window.container],
            }, false);
            newThis.state.chart.series[6].update({
                data: newThis.state.sarr[window.container],
            }, false);
            newThis.state.chart.series[7].update({
                data: newThis.state.tarr[window.container],
            }, false);
            newThis.state.chart.series[8].update({
                data: newThis.state.ponarr[window.container],
            }, false);
            newThis.state.chart.series[9].update({
                data: newThis.state.poffarr[window.container],
            }, false);
            newThis.state.chart.series[10].update({
                data: newThis.state.tonarr[window.container],
            }, false);
            newThis.state.chart.series[11].update({
                data: newThis.state.toffarr[window.container],
            }, false);
            newThis.state.chart.series[12].update({
                data: newThis.state.jarr[window.container],
            }, true);
*/



            if (newThis.state.charts[window.prevChart] != undefined) {
                if (newThis.state.charts[window.prevChart].draggablePlotLine != undefined) {
                    newThis.state.charts[window.prevChart].draggablePlotLine.destroy();
                }
            }
            var chart1 = that;

            window.prevChart = window.chartid;

            var lineWidth = 2


            chart1.draggablePlotLine = chart1.renderer.rect(event.chartX - newThis.state.charts[window.chartid].xAxis[0].toPixels(25), 10, 50, 40, 5)
                .attr({
                    'stroke-width': 2,
                    opacity: 0.5,
                    fill: 'grey',
                    zIndex: 3
                })
                .add();
            console.log("a i clickFunction");

            if (newThis.state.charts[0].container.onmousemove != undefined) {
                newThis.state.charts[0].container.onmousemove = function(e) {
                    return false;
                }
            }

            newThis.state.charts[window.chartid].container.onmousemove = function(e) {
                if (chart1.draggablePlotLine != undefined) {
                    if (chart1.draggablePlotLine.drag) {

                        let normalizedEvent = chart1.pointer.normalize(e),
                            extremes = {
                                left: chart1.plotLeft,
                                right: chart1.plotLeft + chart1.plotWidth
                            };

                        if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                            var pointValue = chart1.xAxis[0].toValue(e.chartX);
                            if (pointValue - 500 <= 0) {
                                newThis.state.chart.xAxis[0].setExtremes(0, pointValue + 1000, true, false);
                            } else {
                                newThis.state.chart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                            }
                            chart1.draggablePlotLine.attr({
                                x: e.chartX
                            })
                        }
                    }
                }
            }

            newThis.state.charts[window.chartid].draggablePlotLine.element.onmousedown = function() {
                chart1.draggablePlotLine.drag = true;
            }

            newThis.state.charts[window.chartid].draggablePlotLine.element.onmouseup = function() {
                chart1.draggablePlotLine.drag = false;
            }
            if (val - 500 <= 0) {
                newThis.state.chart.xAxis[0].setExtremes(0, val + 1000, true, false);
            } else {
                newThis.state.chart.xAxis[0].setExtremes(val - 500, val + 500);
            }
        }

        function setDataOnly(res, self) {
            /* console.log('Initial res',window.mainArray);
              console.log('start',self.state.start);
              console.log('end',self.state.end);*/

            var timeCounter = self.state.start;
            if (res != undefined) {
                var ind = 0;
                for (var i = 0; i < window.arrAxios.length; i++) {
                    if (window.mainArray[window.arrAxios[i]].length == 0) {
                        window.mainArray[window.arrAxios[i]].push(res[ind][0]);
                        ind = ind + 1;
                    }


                }
                res = [];
                for (var i = self.state.start; i <= self.state.end; i++) {

                    res.push(window.mainArray[i][0]);
                    kk = kk + 1;
                }

            } else {
                var kk = 0;
                var res = [];


                for (var i = self.state.start; i <= self.state.end; i++) {
                    //console.log('i value',i);
                    res.push(window.mainArray[i][0]);
                    kk = kk + 1;
                }

            }

            //console.log('FInal res',window.mainArray);


            if (!self.preEcgValues) {
                self.preEcgValues = res;

                self.ecgValues = res.map(e => e[0]).reduce((a, b) => a.concat(b));
                self.activityValues = res.map(e => e[1]).reduce((a, b) => a.concat(b));
                self.peakValues = res.map(e => e[2]).reduce((a, b) => a.concat(b));
            } else {


                if (self.state.scrollStatus === 'next') {


                    self.preEcgValues = self.preEcgValues.filter(
                        (e, i) => (i > self.state.limitScrollStart - 1)
                    );
                    //console.log("preEcgValues",self.preEcgValues.length);

                    self.preEcgValues = self.preEcgValues.concat(res)

                } else if (self.state.scrollStatus === 'prev') {
                    self.preEcgValues = self.preEcgValues.filter(
                        (e, i) => (i < self.state.limitScrollStart + 3)
                    );
                    // console.log("preValues",self.preEcgValues.length);
                    self.preEcgValues = res.concat(self.preEcgValues)
                } else {


                    self.preEcgValues = window.mainArray[timeCounter].filter(
                        (e, i) => (i < 0)
                    );

                    // console.log("preEcgValues",self.preEcgValues.length);
                    self.preEcgValues = self.preEcgValues.concat(res);
                    //console.log("preEcgValues",self.preEcgValues.length);
                    //console.log("preEcgValues",self.preEcgValues);
                }

                //console.log("preEcgValues",self.preEcgValues.length);
                self.ecgValues = self.preEcgValues.map(e => e[0]).reduce((a, b) => a.concat(b));
                self.activityValues = self.preEcgValues.map(e => e[1]).reduce((a, b) => a.concat(b));

                self.peakValues = self.preEcgValues.map(e => e[2]).reduce((a, b) => a.concat(b));


            }



            mainEcgArray = self.ecgValues;
            mainActivityArray = self.activityValues;

            self.setState({
                mainEcgArray: mainEcgArray
            });
            self.setState({
                mainActivityArray: mainActivityArray
            });



            result3 = chunkPeakArray(self.peakValues, self.state.start);

            self.setState({
                result3: result3
            })
            console.log(self.state.result3);
            window.result3 = result3;

            var nsrarr = [],
                starr = [],
                unarr = [],
                sbarr = [],
                vtarr = [],
                sparr = [],
                lqtarr = [],
                sqtarr = [],
                irrrarr = [],
                irprarr = [],
                afarr = [],
                pacarr = [],
                pvcarr = [],
                paarr = [],
                parr = [],
                defarr = [];

            var i = 0;
            var t = 0;
            var j = 1;

            var stcolor = "black",
                uncolor = "black",
                sbcolor = "black",
                vtcolor = "black",
                spcolor = "black",
                lqcolor = "black",
                sqcolor = "black",
                irrrcolor = "black",
                irprcolor = "black",
                afcolor = "black",
                paccolor = "black",
                pvccolour = "black",
                pacolour = "black",
                defcolour = "black";
            var nsrcolor = "black";
            var currentDataSet = [];
            var self = self;

            var beatInd = self.state.start;
            var myVar2 = setInterval(function() {

                var sliceStartI = i * deviceFrequency;
                var sliceEndI = sliceStartI + deviceFrequency + 1;

                currentDataSet = self.state.mainEcgArray.slice(sliceStartI, sliceEndI);


               /* if (self.state.result3[i]) {



                    var time1 = document.createTextNode(self.state.result3[i][0].peaktime);

                    document.getElementById('span' + timeCounter).innerHTML = "";
                    document.getElementById('span' + timeCounter).appendChild(time1);



                    timeCounter = timeCounter + 1;
                }*/
                var k = 0;
                /***********color***********/
                nsrarr.push([]);
                starr.push([]);
                unarr.push([]);
                sbarr.push([]);
                vtarr.push([]);
                sparr.push([]);
                lqtarr.push([]);
                sqtarr.push([]);
                irrrarr.push([]);
                irprarr.push([]);
                afarr.push([]);
                pacarr.push([]);
                pvcarr.push([]);
                paarr.push([]);
                defarr.push([]);
                for (var j = 0; j < currentDataSet.length; j++) {




                  
                    defarr[i].push({
                        x: j,
                        y: parseFloat(currentDataSet[j]),
                        segmentColor: 'black'
                    });

                }


                /***********color**********/

               

                self.state.charts[i].series[0].setData(defarr[i], true);
                if (window.mainArray[beatInd].length == 0) {
                    window.mainArray.splice(beatInd, 1, defarr[i]);
                }
                console.log('MainArray',defarr[i]);
                i = i + 1;
                j = j + 1;
                beatInd = beatInd + 1;
                if (i > 9) {
                     
                     
                    clearInterval(myVar2);

                }
            }, 5);
            console.log('window.tab', window.tab);
           


        }
        window.arrAxios = [];
        var count = 0
        for (var ii = start; ii <= end; ii++) {
            if (window.mainArray[ii].length == 0) {

                window.arrAxios.push(ii);

            }
        }
        var newArrAxios = [];

        if (window.arrAxios.length > 0) {




            var newArrAxiosRes = [];
            this.getRepos(userid, databaseid).then(value => {


                    for (var ij = 0; ij < window.arrAxios.length; ij++) {
                        newArrAxiosRes[ij] = value[ij].data;


                    }

                    setDataOnly(newArrAxiosRes, this);
                    if (window.resumeFlag == 1) {
                        this.renderResumeChart(userid, databaseid, window.container, window.stripXValue);
                        window.resumeFlag = 0;
                    }
                }


            );

        } else {
            setDataOnly(newArrAxios, this)
        }

    }


    async getRepos(userid, databaseid) {

        var ops = [];

        for (var ii = 0; ii < window.arrAxios.length; ii++) {
            let op = axios.get(apiUrl + 'api/common/getEcgNewData?start=' + window.arrAxios[ii] + '&end=' + window.arrAxios[ii] + '&userid=' + userid + '&databaseid=' + databaseid);
            ops.push(op);
        }

        let res = await axios.all(ops);

        return res;
    }

    callDataAPIs() {
        var userid = this.props.match.params.userid;
        var databaseid = this.props.match.params.databaseid;
        axios
            .get('https://web.mybiocalculus.com:5000/api/common/getStripCount?userid=' + userid + '&databaseid=' + databaseid)
            .then(res => {
                window.sc = parseInt(res.data);

                for (var i = 0; i < window.sc; i++) {
                    window.mainArray.push([]);
                }
            })
            .catch(err => {
                console.log(err)
            });

        if (typeof databaseid === 'undefined') {
            databaseid = "0";
            axios
                .get(apiUrl + 'api/common/getLatestDatabaseId?userid=' + this.props.match.params.userid)
                .then(res => {
                    this.setState({
                        databaseid: res.data
                    });
                    this.renderNewChart(userid, res.data, 1);
                    //this.nextBtnLink(userid,res.data);
                    //this.prevBtnLink(userid,res.data);       
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            this.setState({
                databaseid: databaseid
            });

            this.renderNewChart(userid, databaseid, 1, this.state.start, this.state.end);
            //this.nextBtnLink(userid,databaseid);
            //this.prevBtnLink(userid,databaseid); 


        }

        var setPatientReportStatus = res => {

            this.state.patientReportStatus = res;
            this.setState({
                reportStatus: res.data
            })
        }
        if (Object.keys(this.state.patientReportStatus).length > 0) {
            setPatientReportStatus(this.state.patientReportStatus)
        } else {

            axios
                .get(apiUrl + 'api/patients/patientReportStatus?userid=' + this.props.match.params.userid)
                .then(
                    setPatientReportStatus
                )
                .catch(err => {
                    console.log(err);
                });
        }
        var setPatientMenuTree = res => {
            this.state.patientMenuTree = res;
            this.setState({
                treemenu: res.data
            })
        }
        if (Object.keys(this.state.patientMenuTree).length > 0) {
            setPatientMenuTree(this.state.patientMenuTree)
        } else {
            axios
                .get(apiUrl + 'api/common/patinentmenutreeNew?userid=' + this.props.match.params.userid)
                .then(
                    setPatientMenuTree
                )
                .catch(err => {
                    console.log(err);
                });
        }

        if (this.state.reportLinkStatus == 0) {
            axios
                .get('https://web.mybiocalculus.com:5000/api/common/getReport?userid=' + this.props.match.params.userid)
                .then(res => {
                    this.state.reportLinkStatus = 1;
                    if (res.data != '') {
                        this.setState({
                            reoprtLink: res.data
                        })
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }


        this.checkFullVerify();
    }

    

    superImposeData(e) {
        var beats = window.beatArr;
        var databaseid = this.props.match.params.databaseid;
        var userid = this.props.match.params.userid;
        var last_beat = beats[beats.length - 1];

        if (!last_beat) {
            return;
        } else {
            axios
                .get('https://web.mybiocalculus.com/html/Welcome/superimpose/' + userid + '/' + databaseid + '/' + last_beat)
                .then(res => {
                    if (res.data != null) {
                        console.log(res.data);
                    } else {
                        console.log('nothing');
                    }
                })
                .catch(err => {
                    console.log(err);
                });
            
            var minus_val = (window.container) * deviceFrequency;
            for (var i = 0; i < beats.length; i++) {
                this.state.chart.xAxis[0].removePlotBand('plotband-' + (beats[i] - minus_val))
            }
            window.beatArr = [];
        }
    }
    analyzeDataset(e) { 
    var userid = this.props.match.params.userid;
   	var databaseid = this.props.match.params.databaseid;
    e.currentTarget.innerHTML="Analyzing...";
    //const userid = this.props.match.params.userid;
    axios
        .get('https://web.mybiocalculus.com/html/welcome/analyzeDataset/?userid=' + userid + '&&databaseid=' + databaseid+ '&&url=' + window.analyzeLink)
        .then(res => {
          console.log(res.data);   
          window.location.href = '/';   
        })
        .catch(err => {
            console.log(err);
        });
  }
    _onSelect(e)
  {
    
    if(e.value=='RPeaks')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/rpeaks_codes/analyze_last_correct.py';
    }
    else if(e.value=='Classic')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classic_peak/analyze_last_correct.py';
    }
    else if(e.value=='Classical')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classical_code/analyze_last_correct.py';
    }
    else if(e.value=='Classic Negative')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classic_negpeak/analyze_last_correct.py';
    }
    else if(e.value=='AI')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/ai_codes/analyze_last_correct.py';
    }
    else if(e.value=='AIcodes')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/saroja_aicodes/analyze_last_correct.py';
    }
   
  }
    render() {    
      //selWrap
      const options = [
          'RPeaks', 'Classic', 'Classical','Classic Negative', 'AI','AIcodes'
        ];
		const defaultOption = options[0];
       if(localStorage.jwtToken==undefined) { window.location.href = '/'; }
      const userid = this.props.match.params.userid; 
      var databaseid = this.props.match.params.databaseid;
      if (typeof databaseid === 'undefined'){
        databaseid="0";
      }
      return (
      <div className="wrapper theme-1-active box-layout pimary-color-green">
        <input type="hidden" value={this.state.nextDbId} id="nextDbId" />
        <input type="hidden" value={this.state.prevDbId} id="prevDbId" />
        <TopNav profileLink={this.state.profileLink} userid={userid} databaseid={databaseid}  />
        <SideBarGraph userid={userid} menus={this.state.menus} treemenu={this.state.treemenu} databaseid={databaseid} />
        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper" style={{ marginTop: "3%"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="panel panel-default card-view graphMainCont">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <div  className="pills-struct">
                        
                        <div className="clearfix"></div>
                        <div className="tab-content" id="myTabContent_6">
                        <div  id="chart_beats" className="tab-pane active in " role="tabpanel">
                            <div className="beat" id="beat">
                              <div className="panel-body">
                                
                                <div className="row">
                                    

                                    <div className="col-sm-1">
                                      
                                    </div>
                                    <div className="col-sm-5 text-right">
                                      
                                    </div>
                                </div>
                                <div id="tt_container"></div>
                                <div className="text-right">
                                   
                                  <select id="gainValue" onChange={this.gainBtn.bind(this)}>
                                    <option value="1">1X</option>
                                    <option value="2">2X</option>
                                    <option value="3">3X</option>
                                  </select>  
                                </div>
                                <input type="hidden" name="beatSlected" id="beatSlected" value="[]"/>
                                <br/>
                               
                                  <div id="selWrap" className="sel" onScroll={this.listenScrollEvent.bind(this)}></div>
                                
                                  
                               
                                
                                <input type="hidden" name="selectedChart" id="selectedChart" value={this.state.selectedChart}/>          
                              </div>
                            </div>  
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>       
          </div>
        </div>
      </div>
      )
    }
}

DoctorUnanalysed.propTypes = {
    
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps)(DoctorUnanalysed);