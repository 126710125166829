import React, {Component} from 'react';
import classnames from 'classnames';
import axios from 'axios';
import apiUrl from '../../config/config';
import jwt_decode from 'jwt-decode';
import $ from "jquery";


import TopNav from '../../components/common/TopNav';

import EventTab from './EventTab';
import BeatsTab from './BeatsTab';
import SummaryTab from './SummaryTab';
import HeartRateTab from './HeartRateTab';
import SideBarGraph from './SideBarGraph';
import DoctorFindingsTab from './DoctorFindingsTab';


class UserChart extends Component {

  constructor() {
    super();
    this.state = {
      menus : [
        {},
      ],
      profileLink : '/user-profile',
      treemenu:[],
      clickedPI:'',
      rpeaks:[],
      databaseid:'',
      reoprtLink:'#',
      reportStatus:''
    };
    this.updateState = this.updateState.bind(this);
    this.updateRpeaks = this.updateRpeaks.bind(this);
    this.regenerateReport = this.regenerateReport.bind(this);
  }


  updateState(e) {
    console.log(e.currentTarget.id);
    var clickedPI = e.currentTarget.id.toString();
    var clickedPI_ = clickedPI.substring(0, 2)+':'+clickedPI.substring(2, 4)+':'+clickedPI.substring(4, 6)
    var userid = this.props.match.params.userid; 
    var databaseid = this.props.match.params.databaseid;
    if (typeof databaseid === 'undefined'){
      databaseid="0";
    }
    const data = {
      userid : userid,
      databaseid : databaseid,
      clickedPI_:clickedPI_
    };
    axios
      .post(apiUrl+'api/common/getRpeakIndex',data)
      .then(res => {
        console.log(res.data.rpeakindex);
          this.setState({
            clickedPI:res.data.rpeakindex
          })
      })
      .catch(err => 
        console.log(err)
      );
  }
  updateRpeaks(data) {
    this.setState({
      rpeaks:data
    })
  }

  regenerateReport(e) {
    const userid = this.props.match.params.userid;
    axios
        .get('https://web.mybiocalculus.com/html/welcome/regenerateReoprt/'+userid)
        .then(res => {
          console.log(res.data);      
        })
        .catch(err => {
            console.log(err);
        });
  }
  

  componentDidMount() {
    var databaseid = this.props.match.params.databaseid;
    const decoded = jwt_decode(localStorage.jwtToken);
    var userid = decoded.id;
    if (typeof databaseid === 'undefined') {
      axios
        .get(apiUrl+'api/common/getLatestDatabaseId?userid='+userid)
        .then(res => {
          this.setState({
            databaseid:res.data
          })       
        })
        .catch(err => {
            console.log(err);
        });
    } else {
      this.setState({
        databaseid:databaseid
      })
    }

    axios
      .get(apiUrl+'api/patients/patientReportStatus?userid='+userid)
      .then(res => {
        console.log(res.data);
        this.setState({
          reportStatus:res.data
        })       
      })
      .catch(err => {
        console.log(err);
      });

    axios
        .get(apiUrl+'api/common/patinentmenutree?userid='+userid)
        .then(res => {
          this.setState({
            treemenu:res.data
          })       
        })
        .catch(err => {
            console.log(err);
        });
    axios
        .get(apiUrl+'api/common/getReport?userid='+userid)
        .then(res => {
          console.log(res.data);   
          if(res.data!='') {
            this.setState({
              reoprtLink:res.data
            })  
          }   
        })
        .catch(err => {
            console.log(err);
        });
  }
 
  render() {
    const decoded = jwt_decode(localStorage.jwtToken);
    var userid = decoded.id;
    var databaseid = this.props.match.params.databaseid;
    if (typeof databaseid === 'undefined'){
      databaseid="0";
    }
    return (
    <div className="wrapper theme-1-active box-layout pimary-color-green">
      <TopNav profileLink={this.state.profileLink}/>
      <SideBarGraph userid={userid} menus={this.state.menus} treemenu={this.state.treemenu}  databaseid={databaseid} />
      <div className="right-sidebar-backdrop"></div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row heading-bg">
            <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
              <ol className="breadcrumb">
                <li><a href="index.html">Home </a></li>
                <li><a href="#"><span>Patient</span></a></li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="panel panel-default card-view">
                <div className="panel-wrapper collapse in">
                  <div className="panel-body">
                    <div  className="pills-struct">
                      <ul role="tablist" className="nav nav-pills" id="myTabs_6">
                        <li className="active" role="presentation">
                          <a aria-expanded="true"  data-toggle="tab" role="tab" id="chart_tab_1" href="#chart_events">Events</a>
                        </li>
                        <li role="presentation" className="">
                          <a  data-toggle="tab" id="chart_tab_2" role="tab" href="#chart_beats" aria-expanded="false">Beats</a>
                        </li>   
                        <li role="presentation" className="">
                          <a  data-toggle="tab" id="chart_tab_3" role="tab" href="#chart_summary" aria-expanded="false">Summary</a>
                        </li>
                        <li role="presentation" className="">
                          <a  data-toggle="tab" id="chart_tab_4" role="tab" href="#chart_rate" aria-expanded="false">Heartrate</a>
                        </li>            
                        { this.state.reportStatus >=4 &&
                          <React.Fragment>
                            <li role="presentation" id="chart_tab_report" className="pull-right">
                              <a  target="_blank" href={this.state.reoprtLink}>View Report</a>
                            </li>  
                          </React.Fragment>
                        } 
                        <li role="presentation" className="pull-right">
                          <a  data-toggle="tab" id="chart_tab_6" role="tab" href="#chart_findings" aria-expanded="false">Doctor Findings</a>
                        </li>    
                      </ul>
                      <p className="text-right" id="databaseidCont">Database Id :: {this.state.databaseid}</p>
                      <div className="clearfix"></div>
                      <div className="tab-content" id="myTabContent_6">
                        <div  id="chart_events" className="tab-pane fade active in" role="tabpanel">
                          <EventTab updateState={this.updateState} userid={userid} databaseid={databaseid} />
                        </div>
                        <div  id="chart_beats" className="tab-pane fade" role="tabpanel">
                          <BeatsTab updateRpeaks={this.updateRpeaks}  clickedPI={this.state.clickedPI} userid={userid} databaseid={databaseid} />
                        </div>
                        <div  id="chart_summary" className="tab-pane fade" role="tabpanel">
                          <SummaryTab userid={userid} rpeaks={this.state.rpeaks}/>
                        </div>
                        <div  id="chart_rate" className="tab-pane fade" role="tabpanel">
                          <HeartRateTab userid={userid}/>
                        </div>
                        <div  id="chart_findings" className="tab-pane fade" role="tabpanel">
                          <DoctorFindingsTab userid={userid} databaseid={databaseid}/>
                        </div>
                        <div  id="chart_report" className="tab-pane fade" role="tabpanel">
                          <p>Report</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>       
        </div>
      </div>
    </div>
    )
  }
}

export default UserChart;