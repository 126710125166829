import React, {Component} from 'react';
import axios from 'axios';
import apiUrl from '../../config/config';

class SideBarVerifier extends Component {


  	constructor(props) {
	    super(props);

	    this.state = {
            test:'test',
            patientId:''
        };
        this.contextMenu=this.contextMenu.bind(this);
	}	

    componentDidMount(){
        axios
            .get(apiUrl+'api/patients/patientUniquId?userid='+this.props.userid)
            .then(res => {
              this.setState({
                patientId:res.data.device_id
              })       
            })
            .catch(err => {
                console.log(err);
            });
    }

 
      contextMenu(e) {
          e.preventDefault();
        
        var res = e.currentTarget.href.split("/");
        console.log("res[4]");
        console.log(res[5]);
        this.props.onSelectModal(res[5]);
    }

  render() { 
    const userid = this.props.userid;
    var databaseid = this.props.databaseid;
    var cdate = databaseid.split(' ');
    return ( 
    	<div className="fixed-sidebar-left">
            <ul className="nav navbar-nav side-nav nicescroll-bar">
                {
                    this.props.menus.map(function(menu,i) { 
                        return  <li key={i}>
                                    <a href={menu.url} data-toggle="collapse" data-target="#dashboard_dr">
                                        <div className="pull-left">
                                            <i className="zmdi zmdi-window-maximize mr-20"></i><span className="right-nav-text">{menu.title}</span>
                                        </div>
                                        <div className="pull-right"></div>
                                        <div className="clearfix"></div>
                                    </a>
                                </li>;
                    })
                }
                    <li>
                        <a href="#" data-toggle="collapse" data-target="#app_dr">
                            <div className="pull-left"><i className="zmdi zmdi-account-add mr-20"></i><span className="right-nav-text">{this.state.patientId}</span></div>
                            <div className="pull-right"><i className="zmdi zmdi-caret-down"></i></div>
                            <div className="clearfix"></div>
                        </a>
                        <ul id="app_dr" className="collapse collapse-level-1">
                            {
                                Object.keys(this.props.treemenu).map((val,key) => {
                                    var mainActClass = '';
                                    if(cdate[0]==val){
                                        mainActClass="treeActive";
                                    }
                                    if(databaseid=='0' && key==0){
                                        mainActClass="treeActive";
                                    }
                                    return    <li key={key}>
                                                <a href="#" className={mainActClass} data-toggle="collapse" data-target={'#'+key}>{val}<div className="pull-right"><i className="zmdi zmdi-caret-down text-green"></i></div><div className="clearfix"></div></a>
                                                <ul id={key} className="collapse collapse-level-2">
                                                    {this.props.treemenu[val].map((value,key1)=>{
                                                        var MainTime = value['date'].split('T');
                                                        var time = MainTime[1].split('+');
                                                        var actClass = '';
                                                        var verifyLabel = '';
                                                        if(val+" "+time[0] == databaseid) {
                                                            actClass="treeActive";
                                                        }
                                                        if(databaseid=='0' && key1==0 && key==0){
                                                            actClass="treeActive";
                                                        }
                                                        if(value['verifyStatus'] && value['verifyStatus']==1){
                                                            verifyLabel = <i className="zmdi zmdi-check-circle treeCheck"></i>;
                                                        }
                                                        return <li key={key1} className={actClass}>
                                                                    <a onContextMenu={this.contextMenu} href={"/verifier-chart/"+userid+"/"+val+" "+time[0]} >
                                                                        <div className="pull-left"><i className="zmdi zmdi-file mr-20 text-green"></i><span className="right-nav-text text-green">{time[0]}</span>{verifyLabel}</div>
                                                                        <div className="clearfix"></div>
                                                                    </a>
                                                                </li>;
                                                    })}
                                                </ul>
                                            </li>;
                                })
                            }
                        </ul>
                    </li>
                
            </ul>
        </div>
    )
  }
}



export default SideBarVerifier;