import React, {Component} from 'react';
import axios from 'axios';
import $ from "jquery";


class EventTab extends Component {

    constructor() {
        super();
        this.state = {
            menus : [
                {},
            ],
            images:{},
            dblink:''
        };
        this.onDoubleClick = this.onDoubleClick.bind(this);

    }

    onDoubleClick(e) {
      var rpeaktime = e.currentTarget.value;
      this.props.updateState(rpeaktime);
    }

    splitFn(value){
      var res = value.split("_");
      var name = res[0].substring(0, 4)+'-'+res[0].substring(4, 6)+'-'+res[0].substring(6, 8)+' :: '+res[1].substring(0, 2)+':'+res[1].substring(2, 4)+':'+res[1].substring(4, 6);
      res[0]=name;
      return res;
    }


    filter(e){ 
      var selected = e.currentTarget.id;
      if(selected=='all'){
        $("#portfolio").children().show(1000);
        $("#filters a").removeClass('active');
        $("#"+e.currentTarget.id).addClass('active');
      } else {
        $("#portfolio").children().hide(1000); 
        var thisclass = "#portfolio ."+selected; 
        $("#portfolio ."+selected).show(1000); 
        $("#filters a").removeClass('active');
        $("#"+e.currentTarget.id+" a").addClass('active');
      }
    }

    componentDidMount(){
      var userid = this.props.userid;
      var databaseid = this.props.databaseid;
      axios
        .get('http://34.93.36.252:5000/api/common/getfolderfiles?userid='+userid+'&databaseid='+databaseid)
        .then(res => { 
          this.setState({
            images:res.data[0]
          });
          this.setState({
            dblink:res.data[1]
          });

        })
        .catch(err => {
            console.log(err);
        });
    }
        
  render() {
    var userid = this.props.userid;
    var databaseid = this.props.databaseid;
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="">
            <div className="panel-wrapper collapse in">
              <div className="panel-body">
                <h5 className="panel-title txt-dark cd">Cardiac Event Detected</h5>
                <div className="filter-wrap mb-10">
                  <ul id="filters">
                    <li><a id="all" href="#" onClick={this.filter.bind(this)} data-filter="*" className="active">all</a></li> 
                    {
                      Object.keys(this.state.images).map((val,key) => {
                        return <li key={key} id={val} value={val}  onClick={this.filter.bind(this)} ><a href="#" data-filter={"."+val}>{val}</a></li>
                      })
                    }
                  </ul>
                  <hr/>
                  <div className="clearfix"></div>
                </div>
                <div className="gallery-wrap">
                  <div className="portfolio-wrap project-gallery">
                    <div id="portfolio" className="row portf auto-construct  project-gallery">
                    {
                      Object.keys(this.state.images).map((val,key) => {
                        return <React.Fragment key={key}>
                        { 
                          this.state.images[val].map((value,key)=>{
                            var tempvalue = this.splitFn(value);
                            return  <div key={key} onDoubleClick={this.props.updateState} id={tempvalue[1]} className={"col-sm-2 item tall "+val}   data-src="dist/img/gallery/mock1.jpg" data-sub-html="" > 
                                      <p>{tempvalue[0]}</p>
                                      <input type="checkbox" id={value} value={val+"|"+value} className={val} name="test"/>
                                      <label htmlFor={value}>
                                        <img className="img-responsive" src={"http://34.93.36.252/assets/dist/user_records/"+userid+"/"+val+"/cropped_image/"+this.state.dblink+"/"+value}  alt="Image description" />
                                      </label>
                                    </div>;
                          })
                        }
                        </React.Fragment>
                      })
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    )
  }
}

export default EventTab;