import React, {Component} from 'react';
import classnames from 'classnames';
import axios from 'axios';
import apiUrl from '../../config/config';



class ResetPassword extends Component {

    constructor() {
        super();
        this.state = {
            password:'',
            confirmPassword:'',
            errors: {},
            status: 1,
            userid:'',
            userType:''
        };
    }

    onChange = e => {
        var err = this.state.errors;
        delete err[e.target.name];
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = e => {
      e.preventDefault();
      this.setState({errors: {}});
      const data = {
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
        userid: this.state.userid
      };
      var url,redirectUrl = "";
      if(this.state.userType==1){
        url = "api/patients/resetPassword";
        redirectUrl = "/user-login";
      } else if( this.state.userType == 2) {
        url = "api/users/resetPassword";
        redirectUrl = "/";
      }
      axios
        .post(apiUrl+url,data)
        .then(res => {
          console.log(res.data);  
          this.props.history.push(redirectUrl); 
        })
        .catch(err => {
          this.setState({errors: err.response.data});
            console.log(err.response.data);
        });
    }
    componentDidMount() {
      console.log(window.location.href);
      var url_string = window.location.href; 
      var url = new URL(url_string);
      var token = url.searchParams.get("token");
      console.log(token);
      axios
        .get(apiUrl+'api/patients/checkToken?token='+token)
        .then(res => {
          console.log(res.data);   
          if(res.data==null){
            this.props.history.push('/');
          } else {
            if(res.data.status==true) {
              this.setState({status: 2});
              this.setState({userid: res.data.userid});
              this.setState({userType: res.data.type});
              
            } else {
              this.setState({status: 3});
            }
          }
        })
        .catch(err => {
          this.setState({errors: err.response.data});
            console.log(err.response.data);
        });
    }


  render() {
    const { errors } = this.state;
    
    return (
    <div className="wrapper pa-0">
       <div className="page-wrapper pa-0 ma-0 auth-page">
          <div className="container-fluid">
          <div className="table-struct full-width full-height">
            <div className="table-cell vertical-align-middle auth-form-wrap">
              <div className="auth-form  ml-auto mr-auto no-float">
                <div className="row">
                  <div className="col-sm-12 col-xs-12">
                    <div className="sp-logo-wrap text-center pa-0 mb-30">
                      <a href="index.html">
                        <img className="brand-img mr-10" src="https://app.mybiocalculus.com/assets/dist/img/logo.png" alt="brand" />
                        <span className="brand-text">Biocalculus</span>
                      </a>
                    </div>
                    <div className="mb-30">
                      <h3 className="text-center txt-dark mb-10">Reset Password</h3>
                    </div>  
                    {this.state.status == 2 &&
                    <div className="form-wrap">
                      <form  name="resetpassword" id="resetpassword" onSubmit={this.onSubmit}>
                        <div className="form-group">
                          <label className="pull-left control-label mb-10" htmlFor="exampleInputpwd_2">New Password</label>
                          <input 
                            type="password" 
                            className={classnames('form-control',{
                                            'errors' : errors.password
                                        })}  
                            required="" 
                            id="password" 
                            name="password" 
                            onChange={this.onChange}
                            value={this.state.password}
                            placeholder="Enter New pwd"/>
                          <input type="hidden" name="userid" value="<?php echo $user_id?>"/>
                        </div>
                        <div className="form-group">
                          <label className="pull-left control-label mb-10" htmlFor="exampleInputpwd_3">Confirm Password</label>
                          <input 
                            type="password" 
                            className={classnames('form-control',{
                                            'errors' : errors.confirmPassword
                                        })}  
                            required="" 
                            name="confirmPassword"
                            onChange={this.onChange}
                            value={this.state.confirmPassword}
                            id="confirm_password" 
                            placeholder="Re-Enter pwd"/>
                        </div>
                        <div className="form-group text-center">
                          <button type="submit" id="reset_password" className="btn btn-info btn-success btn-rounded">Reset</button>
                        </div>
                      </form>
                    </div>
                    }
                    {this.state.status == 3 &&
                    <div className="form-wrap">
                      <h3 className="text-center txt-dark mb-10">Link expiered</h3>
                    </div>
                    }
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
       </div>
    </div>
    )
  }
}



export default ResetPassword;