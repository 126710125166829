import React, {Component} from 'react';
import axios from 'axios';
import $ from "jquery";

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
//import boost from 'highcharts/modules/boost';

import apiUrl from '../../config/config';


class BeatsTab extends Component {

    constructor() {
        super();
        this.state = {
            data: [],
            menus : [
                {},
            ],
            chart:'',
            result:[],
            result2:[],
            result3:[],
            charts:[],
            nsrarr:[],
            starr:[],
            unarr:[],
            sbarr:[],
            vtarr:[],
            sparr:[],
            lqtarr:[],
            sqtarr:[],
            irrrarr:[],
            irprarr:[],
            afarr:[],
            pacarr:[],
            pvcarr:[],
            paarr:[],
            defarr:[],
            selectedChart:0
        };

    }

    


    componentDidMount(){
        var arr=[],charts=[],arr2=[], peakarr=[],barClick=0,pointValue,tempChart,tempCont=0;
        var nsrarr=[],starr=[],unarr=[],sbarr=[],vtarr=[],sparr=[],lqtarr=[],sqtarr=[],irrrarr=[],irprarr=[],afarr=[],pacarr=[],pvcarr=[],paarr=[],beatArr=[],defarr=[],plotband=[],piec;
        var maximumval;var b;var js_array=[];var chart; var minVal,maxVal,val,val2;
        var result,result2,result3,colorArray=[];
        var rpeakArray = [];
        var peakColorArray= [];
            peakColorArray['Undiagnosed'] = '#FCDF85';
            peakColorArray['Normal Sinus Rhythm'] = '#98FB98';
            peakColorArray['Sinus Tachycardia'] = '#3EFFDE';
            peakColorArray['PAC'] = '#4950EB';
            peakColorArray['PVC'] = '#FF45DC';
            peakColorArray['P absent'] = '#AEB9BD';
            peakColorArray['Sinus Bradycardia'] = '#FFD14F';
            peakColorArray['Sinus Pause'] = '#FF5B05';
            peakColorArray['Irregular RR'] = '#A4C3FF';
            peakColorArray['Ventricular Tachycardia'] = '#F0A7A1';
            peakColorArray['Irregular PR'] = '#BBEB8F';
            peakColorArray['Short QT'] = '#CFFF0F';
            peakColorArray['Prolonged QT'] = '#7C2F09';
            peakColorArray['AFib'] = '#A96BFF';
        var userid = this.props.userid;   
        var databaseid = this.props.databaseid;
        //Split the array in chunks with given size
        function chunkArray(myArray, chunk_size) {
            let results = [];
            
            while (myArray.length) {
                results.push(myArray.splice(0, chunk_size))
            }

            return results;
        };

        function chunkPeakArray(peakArray){
            let presults = [];
            var minIndex = 0;
            var breakVal = 15000;
            var z;
            var temRpeakindex
            presults.push( [] );
            for (var i = 0; i < peakArray.length; i++) {
              if (peakArray[i].rpeakindex > breakVal) {
                presults.push( [] );
                minIndex = minIndex+1;
                breakVal = breakVal+15000;
              }
              temRpeakindex = parseInt(peakArray[i].rpeakindex)-(minIndex*15000);
              presults[minIndex].push({ x: temRpeakindex, y: parseFloat(0.8), color:peakColorArray[peakArray[i].arrhythmia], beat: parseInt(peakArray[i].indexcounter), hr: parseInt(peakArray[i].heartrate), rr: parseInt(peakArray[i].rr_interval),arrhythmia:peakArray[i].arrhythmia,peaktime:peakArray[i].peaktime });
            }
            return presults;
        }

        function createSchartContainer() {
          var t=0;
          var stcolor= "black",uncolor= "black",sbcolor= "black",vtcolor= "black",spcolor= "black",lqcolor= "black",sqcolor= "black",irrrcolor= "black",irprcolor= "black",afcolor= "black",paccolor= "black",pvccolour= "black",pacolour= "black",defcolour="black";
          var nsrcolor = "black";
          for(var i=0; i < result.length; i++){
            var newDiv = document.createElement('div');
                newDiv.id = 'container'+i;
                newDiv.style = 'min-width: 200px; height: 60px; margin: 0 auto';
            var newSpan = document.createElement('div');
                newSpan.id='span'+i;
                newSpan.style='color:#000;';
            var time1 = document.createTextNode(result3[i][0].peaktime);
                newSpan.appendChild(time1);
            document.getElementById('sel').appendChild(newSpan);
            document.getElementById('sel').appendChild(newDiv);

            var arrayIndex = 0;
            var k=0;
                    
            nsrarr.push([]);
            starr.push([]);
            unarr.push([]);
            sbarr.push([]);
            vtarr.push([]);
            sparr.push([]);
            lqtarr.push([]);
            sqtarr.push([]);
            irrrarr.push([]);
            irprarr.push([]);
            afarr.push([]);
            pacarr.push([]);
            pvcarr.push([]);
            paarr.push([]);
            defarr.push([]);

            for (var j = 0; j < result[i].length; j++) {
              if( j+70==result3[i][k].x || result3[i][k].x<70)
              { 
                if(result3[i][k].arrhythmia == 'Normal Sinus Rhythm'){
                  nsrcolor = "#98FB98";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Sinus Tachycardia'){
                  nsrcolor = "black";
                  stcolor = "#3EFFDE";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Sinus Bradycardia'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "#FFD14F";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Sinus Pause'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "#FF5B05";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'PAC'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "#4950EB";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'PVC'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "#FF45DC";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Ventricular Tachycardia'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "#F0A7A1";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Afib'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "#A96BFF";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'P absent'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "#AEB9BD";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Irregular RR'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "#A4C3FF";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                else if(result3[i][k].arrhythmia == 'Irregular PR'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "#BBEB8F";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                else if(result3[i][k].arrhythmia == 'Short QT'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "#CFFF0F";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                else if(result3[i][k].arrhythmia == 'Prolonged QT'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "#7C2F09";
                  defcolour = "black";
                }
                else if(result3[i][k].arrhythmia == 'Undiagnosed'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "#FCDF85";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                else{
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                if(k < result3[i].length-1){
                  k++;
                }
              } 
              nsrarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: nsrcolor
              });

              defarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: 'black'
              });
              starr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: stcolor
              });
              unarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: uncolor
              });
              sbarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: sbcolor
              });
              sqtarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: sqcolor
              });
              vtarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: vtcolor
              });
              sparr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: spcolor
              });
              pvcarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: pvccolour
              });
              irrrarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: irrrcolor
              });
              irprarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: irprcolor
              });
              afarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: afcolor
              });
              pacarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: paccolor
              });
              paarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: pacolour
              });
                      // pacarr[i].push({
                      //   x:j,
                      //   y:parseFloat(result[i][j]),
                      //   segmentColor: pacolour
                      // });
            }
          }
        }

        function renderSmallCharts() {
          var i=0;
            var myVar= setInterval(function(){ 
              charts[i] = new Highcharts.Chart('container'+i, {
                  credits: {
                      enabled: false
                  },
                  chart: {
                      height: 5 + '%',
                      resetZoomButton: {
                          theme: {
                              display: 'none'
                          },

                      },
                      events: {
                        click(event)
                        { console.log(event);
                          var containerId = this.renderTo.id;
                          var currentIndex = containerId.replace('container','');
                          console.log(currentIndex);
                          var prevChart = document.getElementById("selectedChart").value;
                          var beats = JSON.parse(document.getElementById("beatSlected").value);
                          if(beats.length>0) {
                            for (var i = 0; i < beats.length; i++) {

                              var xBeat = parseInt(beats[i])-(15000*prevChart)
                              chart.xAxis[0].removePlotBand('plotband-'+xBeat) ;
                              // console.log(beatArr);
                              // var index = beatArr.indexOf((beats[i]));
                              // if (index > -1) {
                              //     beatArr.splice(index, 1);
                              // }            
                            }
                            document.getElementById("beatSlected").value='[]';
                          }
                          console.log(document.getElementById("beatSlected").value);

                          val= charts[currentIndex].xAxis[0].toValue(event.chartX);

                          if(val-500<=0)
                          {
                             chart.xAxis[0].setExtremes(0,val+1000,true,false);
                          }
                          else{
                              chart.xAxis[0].setExtremes(val-500,val+500);
                          }

                          var clickIndex ;
                          clickIndex = currentIndex; 
                          // console.log(clickIndex);
                          // console.log(result3[clickIndex]);
                          var acti= 125;
                          var actarr = [];
                           var tttarr1 = [];
                          // var tttarr2 = [];

                          // for (var i = 0; i < 15000; i++) {
                          //     tttarr1.push({ x:i,y: result[0][i],color:'#FCDF85'});                         
                          // }
                          // for (var i = 7500; i < result[0].length; i++) {
                          //     tttarr2.push({ x:i,y: result[0][i]});                         
                          // }
                          // console.log(tttarr1);

                          for (var i = 0; i < result2[clickIndex].length; i++) {
                              actarr.push({ x: acti, y: 0.5, color: colorArray[result2[clickIndex][i]] });
                              acti = acti+250;
                          }
                          
                          chart.series[0].setData(result[clickIndex],true);
                          chart.series[1].setData(actarr,true);
                          chart.series[2].setData(result3[clickIndex],true);
                          // chart.series[0].update({
                          //   data: result[clickIndex],
                          // });
                          // chart.series[1].update({
                          //   data: actarr,
                          // });
                          // chart.series[2].update({
                          //   data: result3[clickIndex],
                          // });


                          tempChart = charts[document.getElementById("selectedChart").value];
                          if(tempChart!=undefined){
                            if (tempChart.draggablePlotLine!=undefined) {
                        
                            tempChart.draggablePlotLine.destroy();
                            }
                          }
                          var chart1 = this
                          tempCont=currentIndex;
                          document.getElementById("selectedChart").value= tempCont;
                          var lineWidth = 2

                          chart1.draggablePlotLine = chart1.renderer.rect(event.chartX-charts[currentIndex].xAxis[0].toPixels(25), 10, 50, 40, 5)
                          .attr({
                              'stroke-width': 2,
                              opacity:0.5,
                              fill: 'grey',
                              zIndex: 3
                          })
                          .add();

                          if(charts[0].container.onmousemove!=undefined)
                          {
                            charts[0].container.onmousemove = function(e) {return false;}
                          }

                          charts[currentIndex].container.onmousemove = function(e) {
                            if (chart1.draggablePlotLine.drag) {

                                let normalizedEvent = chart1.pointer.normalize(e),
                                    extremes = {
                                        left: chart1.plotLeft,
                                        right: chart1.plotLeft + chart1.plotWidth
                                    };

                                if (
                                    e.chartX >= extremes.left &&
                                    e.chartX <= extremes.right
                                ) {
                                    pointValue = chart1.xAxis[0].toValue(e.chartX);
                                    if (pointValue - 500 <= 0) {
                                        chart.xAxis[0].setExtremes(0, pointValue + 1000, true, false);
                                    } else {
                                        chart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                                    }
                                    chart1.draggablePlotLine.attr({
                                        x: e.chartX

                                    })
                                }
                            }
                        }

                        },
                      },
                      zoomType: '',
                  },

                  navigator: {
                      enabled: false
                  },

                  xAxis:[{
                      min:0,
                      max:15000,
                      lineWidth: 0,
                      minorGridLineWidth: 0,
                      lineColor: 'transparent',
                      scrollbar: {
                        enabled: true,
                        showFull: false
                      },
                      labels: {
                        enabled: false
                      },
                      minorTickLength: 0,
                      tickLength: 0, visible: false

                  }],
                  yAxis: [
                      {
                          min: -0.002,
                          max: 0.002,
                          gridLineWidth: 0,
                          minorGridLineWidth: 0,
                          minorTicks: false,

                          labels: {
                              enabled: false
                          },
                          title: {
                              enabled: false
                          },

                      }
                  ],
                  rangeSelector: {
                      enabled: false
                  },
                  plotOptions: {
                      series: {
                          turboThreshold: 15000,
                      },

                  },

                  title: {
                      text: ''
                  },

                  series: [{
                      type: 'coloredline',
                      data: defarr[i],
                      allowPointSelect: false,
                      animation: false,
                      enableMouseTracking: false,
                      lineWidth: 1,
                      marker: {
                          enabled: false
                      },
                      shadow: false,
                      stickyTracking: false,
                      showInLegend: false


                  }]
               });
              i=i+1;
              if(i>defarr.length-1)
              { 
                clearInterval(myVar);
              }

            },5);
        }

        // Function to plot the background graph
        function renderMainChart() {

          maximumval = 1000;
          b = 0;

          // Create the chart
          chart = new Highcharts.StockChart('tt_container', {


              exporting: {
                  enabled: false
              },
              scrollbar: {
                  enabled: false
              },
              credits: {
                  enabled: false
              },
              chart: {

                  type: 'coloredline',
                  height: 40 + '%',

                  resetZoomButton: {
                      theme: {
                          display: 'none'
                      },

                  },
                  events: {
                      click: function(event) {
                          var k = 0,
                              plotbi = 0;
                          var t = Math.floor(event.xAxis[0]['value']);
                          var closestArray = [];
                          var j  = tempCont*15000; 


                          chart.update({
                              xAxis: {
                                plotBands: plotband
                              }
                          });

                          for (var i = 0; i < result3[tempCont].length; i++) {
                              closestArray[i] = result3[tempCont][i].x;
                          }
                          

                          var closest = closestArray.reduce(function(prev, curr) {
                              return (Math.abs(curr - t) < Math.abs(prev - t) ? curr : prev);
                          });

                          beatArr = JSON.parse($('#beatSlected').val());
                          var check = $.inArray((closest+j), beatArr);

                          if (check == -1) {
                            plotband.push({
                              color: '#ff9999',
                              from: closest - 100,
                              to: closest + 100,
                              id: 'plotband-' + closest
                            });

                            chart.update({
                              xAxis: {
                                plotBands: plotband
                              }
                            });

                            beatArr.push((closest+j));
                          } else {
                              var index = beatArr.indexOf((closest+j));
                              if (index > -1) {
                                  beatArr.splice(index, 1);
                              }
                              chart.xAxis[0].removePlotBand('plotband-' + closest);
                          }
                          $('#beatSlected').val(JSON.stringify(beatArr));
                      },
                  },
                  zoomType: '',
              },

              navigator: {
                  enabled: false
              },

              xAxis: [{
                  min: 0, //setting min to 0
                  max: 1000,
                  events: {

                      setExtremes: function(e) {
                          val = e.min + 500;
                          tempChart = charts[document.getElementById("selectedChart").value];
                          if (tempChart != undefined) {
                              if (tempChart.draggablePlotLine != undefined) {
                                  val2 = tempChart.xAxis[0].toPixels(val);


                                  tempChart.draggablePlotLine.attr({
                                      x: val2

                                  })



                              }
                          }
                      },
                  },
                  scrollbar: {
                      enabled: true
                  },
                  labels: {
                      enabled: false
                  },

                  tickInterval: 50,
                  minorTicks: true,
                  minorTickInterval: 10,
                  gridLineWidth: 1,
                  gridLineColor: '#D3D3D3'

              }],
              yAxis: [{
                      min: 0,
                      max: 10,
                      reversed: true,
                      gridLineWidth: 0,
                      minorGridLineWidth: 0,
                      minorTicks: false,

                      labels: {
                          enabled: true
                      },
                      title: {
                          enabled: false
                      },
                      visible: false

                  },
                  {
                      min: -0.002,
                      max: 0.002,
                      tickPositions: [-0.0020, -0.0015, -0.0010, -0.0005, 0.0000, 0.0005, 0.0010, 0.0015, 0.0020],
                      tickInterval: 0.0005,
                      gridLineWidth: 3,
                      minorGridLineWidth: 1,
                      minorTicks: true,
                      minorTickInterval: 0.0001,

                      gridLineWidth: 2,
                      gridLineColor: '#D3D3D3',
                      labels: {
                          enabled: false
                      },
                      title: {
                          enabled: false
                      },

                  },
                  {
                      min: 0,
                      max: 10,
                      gridLineWidth: 0,
                      minorGridLineWidth: 0,
                      minorTicks: false,

                      labels: {
                          enabled: true
                      },
                      title: {
                          enabled: false
                      },
                      visible: false

                  }
              ],
              rangeSelector: {
                  enabled: false
              },
              plotOptions: {
                  series: {
                      turboThreshold: 15500,
                      colorByPoint: false
                  },

              },

              title: {
                  text: ''
              },

              series: [{


                  data: [],
                  yAxis: 1,
                  xAxis: 0,
                  color: 'black',
                  states: {
                      inactive: {
                          opacity: 1
                      }
                  },

                  allowPointSelect: false,
                  animation: false,
                  enableMouseTracking: false,
                  lineWidth: 1,
                  marker: {
                      enabled: false
                  },
                  shadow: false,
                  stickyTracking: false,
                  showInLegend: false


              }]
          });
          chart.addSeries({

              yAxis: 0,

              type: 'column',

              data: [],
              pointWidth: 400,
              inverted: true,
              tooltip: {
                  enabled: false
              },
              states: {

                  inactive: {
                      opacity: 1
                  }
              },

              allowPointSelect: false,
              animation: false,
              enableMouseTracking: false,
              lineWidth: 1,

              shadow: false,
              stickyTracking: false,
              showInLegend: false

          }, true);
          chart.addSeries({
              dataLabels: [{
                  enabled: true,
                  inside: true,
                  align: 'left',
                  format: 'Beat:{point.beat}; HR:{point.hr}; RR:{point.rr}'
              }],
              yAxis: 2,
              type: 'column',
              turboThreshold: 10000,
              data: [],
              pointWidth: 250,
              tooltip: {
                  enabled: false
              },
              point: {
                  events: {
                      click: function() {

                      },


                  }
              },
              states: {

                  inactive: {
                      opacity: 1
                  }
              },

              marker: {
                  enabled: false
              },

              showInLegend: false
          }, true);

          return chart;
        };


        var mainchat = renderMainChart();
        this.setState({
              chart: mainchat
        });
        
        axios
          .get(apiUrl+'api/common/getEcgValues?userid='+userid+'&databaseid='+databaseid)
          .then(res => {  
            console.log('loaded');
            this.props.updateRpeaks(res.data[1]);
            result = chunkArray(res.data[0].ecgvalue, 15000);
            result2 = chunkArray(res.data[0].activityvalue, 60);
            result3 = chunkPeakArray(res.data[1]);
            console.log(result3);
            this.setState({
              result: result
            });
            this.setState({
              result2: result2
            });
            this.setState({
              result3: result3
            });

            var acti= 125;
            var counteri=249;
            var counter = 0;
            var t=0;
            var actarr=[];
            colorArray = ['#98FB98','#f1e4e4','#f7e0a3','#f09c67']

            for (var i = 0; i < result2[0].length; i++) {
              //console.log(colorArray[result2[0][i]]);
              actarr.push({ x: acti, y: 0.5, color: colorArray[result2[0][i]] });
              acti = acti+250;
            }
            
            
            createSchartContainer();

            renderSmallCharts();

            this.setState({
              charts: charts
            });
            this.setState({
              nsrarr: nsrarr
            });
            this.setState({
              starr: starr
            });
            this.setState({
              sbarr: sbarr
            });
            this.setState({
              sparr: sparr
            });
            this.setState({
              pacarr: pacarr
            });
            this.setState({
              pvcarr: pvcarr
            });
            this.setState({
              vtarr: vtarr
            });
            this.setState({
              afarr: afarr
            });
            this.setState({
              paarr: paarr
            });
            this.setState({
              irrrarr: irrrarr
            });
            this.setState({
              irprarr: irprarr
            });
            this.setState({
              sqtarr: sqtarr
            });
            this.setState({
              paarr: paarr
            });
            this.setState({
              unarr: unarr
            });
            
            
            chart.series[0].update({
              data: result[0],
            });

            chart.series[1].update({
              data: actarr,
            });

            //console.log(res.data);
            chart.series[2].update({
              data: result3[0],
            });

            
            
          })
          .catch(err => {
          });
    }

    componentWillReceiveProps(nextProps) { 
      if(nextProps.clickedPI) {
        console.log(nextProps.clickedPI);
        document.getElementById('chart_tab_2').click();
        if(nextProps.clickedPI>15000){
          var stripIndex = Math.floor(nextProps.clickedPI/15000)-1;
        } else {
          var stripIndex = 0;
        }
        var stripX = Math.floor(nextProps.clickedPI%15000);
        console.log(stripIndex+" "+stripX);
        var tempChart;
        var colorArray = ['#98FB98','#f1e4e4','#f7e0a3','#f09c67'];

        var val = stripX;

        if (val - 500 <= 0) {
          this.state.chart.xAxis[0].setExtremes(0, val + 1000, true, false);
        } else {
          this.state.chart.xAxis[0].setExtremes(val - 500, val + 500);
        }

        var clickIndex;
        clickIndex = stripIndex;
        var acti = 125;
        var actarr = [];
        var tttarr1 = [];

        for (var i = 0; i < this.state.result2[clickIndex].length; i++) {
          actarr.push({
            x: acti,
            y: 0.5,
            color: colorArray[this.state.result2[clickIndex][i]]
          });
          acti = acti + 250;
        }

        var prevChart = document.getElementById("selectedChart").value;
        var beats = JSON.parse(document.getElementById("beatSlected").value);
        if(beats.length>0) {
          for (var i = 0; i < beats.length; i++) {
            var xBeat = parseInt(beats[i])-(15000*prevChart)
            this.state.chart.xAxis[0].removePlotBand('plotband-'+xBeat) ;          
          }
          document.getElementById("beatSlected").value='[]';
        }

        this.state.chart.series[0].setData(this.state.result[clickIndex],true);
        this.state.chart.series[1].setData(actarr,true);
        this.state.chart.series[2].setData(this.state.result3[clickIndex],true);
        
        // this.state.chart.series[0].update({
        //   data: this.state.result[clickIndex],
        // });
        // this.state.chart.series[1].update({
        //   data: actarr,
        // });
        // this.state.chart.series[2].update({
        //   data: this.state.result3[clickIndex],
        // });

        var chart1 = this.state.charts[stripIndex];
        var tempChart = this.state.charts[document.getElementById("selectedChart").value];
        //document.getElementById("selectedChart").value= '8';
        this.setState({['selectedChart']:clickIndex});
        var lineWidth = 2

        if (tempChart != undefined) {
          if (tempChart.draggablePlotLine != undefined) {

            tempChart.draggablePlotLine.destroy();
          }
        }
        
        this.state.charts[stripIndex].draggablePlotLine = this.state.charts[stripIndex].renderer.rect(this.state.charts[stripIndex].xAxis[0].toPixels(stripX)-this.state.charts[stripIndex].xAxis[0].toPixels(25), 10, 50, 40, 5)
        .attr({
          'stroke-width': 2,
          opacity:0.5,
          fill: 'grey',
          zIndex: 3
        })
        .add();

        if (this.state.charts[stripIndex].container.onmousemove != undefined) {
          this.state.charts[stripIndex].container.onmousemove = function(e) {
            return false;
          }
        }

        this.state.charts[stripIndex].container.onmousemove = function(e) {
          if (chart1.draggablePlotLine.drag) {

            let normalizedEvent = chart1.pointer.normalize(e),
            extremes = {
              left: chart1.plotLeft,
              right: chart1.plotLeft + chart1.plotWidth
            };

            if (e.chartX >= extremes.left &&  e.chartX <= extremes.right) {
              var pointValue = chart1.xAxis[0].toValue(e.chartX);
              if (pointValue - 500 <= 0) {
                this.state.chart.xAxis[0].setExtremes(0, pointValue + 1000, true, false);
              } else {
                this.state.chart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
              }
              chart1.draggablePlotLine.attr({
                x: e.chartX
              })
            }
          }
        } 

        document.getElementById("container"+clickIndex).scrollIntoView();
    //     $('html, body').animate({
    //     scrollTop: $("#container"+clickIndex).offset().top
    // }, 2000);
      }  
    }

    colorGraph(e) { 
        var selector = e.currentTarget.value;
        if(selector == 'Normal Sinus Rhythm'){
          var it=0;
          var currentChart = this.state.charts;
          var currentNsrarr = this.state.nsrarr;
          var mainArr = this.state.result;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentNsrarr[it],true);  
            it=it+1;
            if(it>mainArr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Sinus Tachycardia'){
          var it=0;
          var currentChart = this.state.charts;
          var currentStarr = this.state.starr;
          var mainArr = this.state.result;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentStarr[it],true);  
            it=it+1;
            if(it>mainArr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Sinus Bradycardia'){
          var it=0;
          var currentChart = this.state.charts;
          var currentSbarr = this.state.sbarr;
          var mainArr = this.state.result;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentSbarr[it],true);  
            it=it+1;
            if(it>mainArr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Sinus Pause'){
          var it=0;
          var currentChart = this.state.charts;
          var currentSparr = this.state.sparr;
          var mainArr = this.state.result;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentSparr[it],true);  
            it=it+1;
            if(it>mainArr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'PAC'){
          var it=0;
          var currentChart = this.state.charts;
          var currentPacarr = this.state.pacarr;
          var mainArr = this.state.result;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentPacarr[it],true);  
            it=it+1;
            if(it>mainArr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'PVC'){
          var it=0;
          var currentChart = this.state.charts;
          var currentPvcarr = this.state.pvcarr;
          var mainArr = this.state.result;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentPvcarr[it],true);  
            it=it+1;
            if(it>mainArr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Ventricular Tachycardia'){
          var it=0;
          var currentChart = this.state.charts;
          var currentVtcarr = this.state.vtarr;
          var mainArr = this.state.result;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentVtcarr[it],true);  
            it=it+1;
            if(it>mainArr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'AFib'){
          var it=0;
          var currentChart = this.state.charts;
          var currentAfcarr = this.state.afarr;
          var mainArr = this.state.result;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentAfcarr[it],true);  
            it=it+1;
            if(it>mainArr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'P Absent'){
          var it=0;
          var currentChart = this.state.charts;
          var currentPacarr = this.state.paarr;
          var mainArr = this.state.result;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentPacarr[it],true);  
            it=it+1;
            if(it>mainArr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Irregular RR'){
          var it=0;
          var currentChart = this.state.charts;
          var currentIrrrarr = this.state.irrrarr;
          var mainArr = this.state.result;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentIrrrarr[it],true);  
            it=it+1;
            if(it>mainArr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Irregular PR'){
          var it=0;
          var currentChart = this.state.charts;
          var currentIrprarr = this.state.irprarr;
          var mainArr = this.state.result;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentIrprarr[it],true);  
            it=it+1;
            if(it>mainArr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Short QT'){
          var it=0;
          var currentChart = this.state.charts;
          var currentSqtarr = this.state.sqtarr;
          var mainArr = this.state.result;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentSqtarr[it],true);  
            it=it+1;
            if(it>mainArr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Prolonged QT'){
          var it=0;
          var currentChart = this.state.charts;
          var currentPaarr = this.state.paarr;
          var mainArr = this.state.result;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentPaarr[it],true);  
            it=it+1;
            if(it>mainArr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Undiagnosed'){
          var it=0;
          var currentChart = this.state.charts;
          var currentUnarr = this.state.unarr;
          var mainArr = this.state.result;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentUnarr[it],true);  
            it=it+1;
            if(it>mainArr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }

    };

    gainBtn(e) {
      var buttonId = e.currentTarget.id;
      var gainValue = document.getElementById('gainValue').value;
      console.log(gainValue);
      if (buttonId == 'gainButton') {
        var menuToggle = 'menu2';
        var showBtn = 'rgainButton';
        var hideBtn = 'gainButton';
        document.getElementById("gainValue").style.display = 'none';
        document.getElementById(hideBtn).style.display = 'none'; //step 2 :additional feature hide button
        document.getElementById(showBtn).style.display = 'inline-block'; //step 3:additional feature show button
        var it=0;
        var charts = this.state.charts;
        var arCount = this.state.result.length-1;
        var scaleUp= setInterval(function(){ 
          if(gainValue==2){
            charts[it].update({
              yAxis: [
                {
                  min: -0.0010,
                  max: 0.0010
                }],    
            });
          }else if(gainValue==3){
            charts[it].update({
              yAxis: [
                {
                  min: -0.0005,
                  max: 0.0005
                }],    
            });
          }
       
          it=it+1;
          if(it>arCount)
          {
            clearInterval(scaleUp);
          }
        },5);
      } else {
        var menuToggle = 'menu2';
        var showBtn = 'gainButton';
        var hideBtn = 'rgainButton';
        document.getElementById(hideBtn).style.display = 'none'; //step 2 :additional feature hide button
        document.getElementById(showBtn).style.display = 'inline-block'; //step 3:additional feature show button
        document.getElementById("gainValue").style.display = 'inline-block';
        var it=0;
        var charts = this.state.charts;
        var arCount = this.state.result.length-1;
        var scaleUp= setInterval(function(){ 
          charts[it].update({
            yAxis: [
              {
                min: -0.0020,
                max: 0.0020
              }],    
          });
       
          it=it+1;
          if(it>arCount)
          {
            clearInterval(scaleUp);
          }
        },5);
      }
    }

  render() {
    return (
      <div className="beat" id="beat">
        <div className="panel-body">
          <div className="row">
              <div className="col-sm-6">
                <h2 className="panel-title txt-dark rechead">Cardiac Event Detected</h2><br/>
                <button className="arythmia-btn nsr-btn" value='Normal Sinus Rhythm' onClick={this.colorGraph.bind(this)}>NSR</button>
                <button className="arythmia-btn st-btn" value='Sinus Tachycardia' onClick={this.colorGraph.bind(this)}>ST</button>
                <button className="arythmia-btn sb-btn" value='Sinus Bradycardia' onClick={this.colorGraph.bind(this)}>SB</button>
                <button className="arythmia-btn pac-btn" value='PAC' onClick={this.colorGraph.bind(this)}>PAC</button>
                <button className="arythmia-btn pvc-btn" value='PVC' onClick={this.colorGraph.bind(this)}>PVC</button>
                <button className="arythmia-btn vt-btn" value='Ventricular Tachycardia' onClick={this.colorGraph.bind(this)}>VT</button>
                <button className="arythmia-btn af-btn" value='AFib' onClick={this.colorGraph.bind(this)}>AF</button>
                <button className="arythmia-btn sp-btn" value='Sinus Pause' onClick={this.colorGraph.bind(this)}>SP</button>
                <button className="arythmia-btn pa-btn" value='P Absent' onClick={this.colorGraph.bind(this)}>PA</button>
                <button className="arythmia-btn irr-btn" value='Irregular RR' onClick={this.colorGraph.bind(this)}>IRR</button>
                <button className="arythmia-btn ipr-btn" value='Irregular PR' onClick={this.colorGraph.bind(this)}>IPR</button>
                <button className="arythmia-btn sqt-btn" value='Short QT' onClick={this.colorGraph.bind(this)}>SQT</button>
                <button className="arythmia-btn lqt-btn" value='Prolonged QT' onClick={this.colorGraph.bind(this)}>LQT</button>
                <button className="arythmia-btn un-btn" value='Undiagnosed' onClick={this.colorGraph.bind(this)}>UN</button>
                
              </div>
          </div>
          <div id="tt_container"></div>
          <div className="text-right">
            <button id="gainButton" onClick={this.gainBtn.bind(this)} className="sideviewtoggle myButton">Gain</button>
            <select id="gainValue">
              <option value="2">2X</option>
              <option value="3">3X</option>
            </select>
            <button id="rgainButton" onClick={this.gainBtn.bind(this)} className="sideviewtoggle myButton">Reset</button> 
          </div>
          <br/>
          <input type="hidden" name="beatSlected" id="beatSlected" value="[]"/>
          <div id="sel" className="sel"></div>
          <input type="hidden" name="selectedChart" id="selectedChart" value={this.state.selectedChart}/>          
        </div>
      </div>    
    )
  }
}

export default BeatsTab;