import React, {Component} from 'react';
import classnames from 'classnames';
import axios from 'axios';
import apiUrl from '../../config/config';
import $ from "jquery";

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import TopNav from '../../components/common/TopNav';

import SummaryTab from './SummaryTab';
import HeartRateTab from './HeartRateTab';
import SettingsTab from './SettingsTab';
import SideBarGraph from './SideBarGraph';
import AddFindingsTab from './AddFindingsTab';

require('highcharts-multicolor-series')(Highcharts);

class Main extends Component {

  constructor() {
    super();
    this.state = {
      menus : [
        {title:'Patient List',url:'/doctor-home'},
      ],
      profileLink : '/doctor-profile',
      treemenu:[],
      clickedPI:'',
      rpeaks:[],
      databaseid:'',
      reoprtLink:'#',
      reportStatus:'',
      images:{},
      dblink:'',
      nextDbId:'',
      prevDbId:'',
            chart:'',
            result:[],
            result2:[],
            result3:[],
            charts:[],
            nsrarr:[],
            starr:[],
            unarr:[],
            sbarr:[],
            vtarr:[],
            sparr:[],
            lqtarr:[],
            sqtarr:[],
            irrrarr:[],
            irprarr:[],
            afarr:[],
            pacarr:[],
            pvcarr:[],
            paarr:[],
            defarr:[],
            selectedChart:0
    };
    this.updateState = this.updateState.bind(this);
    this.updateRpeaks = this.updateRpeaks.bind(this);
    this.regenerateReport = this.regenerateReport.bind(this);
    this.newDataClick = this.newDataClick.bind(this);
  }


    updateState(e) {
      console.log(e.currentTarget.id);
      var clickedPI = e.currentTarget.id.toString();
      var clickedPI_ = clickedPI.substring(0, 2)+':'+clickedPI.substring(2, 4)+':'+clickedPI.substring(4, 6)
      var userid = this.props.match.params.userid; 
      var databaseid = this.props.match.params.databaseid;
      if (typeof databaseid === 'undefined'){
        databaseid="0";
      }
      const data = {
        userid : userid,
        databaseid : databaseid,
        clickedPI_:clickedPI_
      };
      axios
        .post(apiUrl+'api/common/getRpeakIndex',data)
        .then(res => {
          // console.log(res.data.rpeakindex);
          //   this.setState({
          //     clickedPI:res.data.rpeakindex
          //   })
          var clickedPI = res.data.rpeakindex;
          document.getElementById('chart_tab_2').click();
          if(clickedPI>15000){
            var stripIndex = Math.floor(clickedPI/15000);
          } else {
            var stripIndex = 0;
          }
          var stripX = Math.floor(clickedPI%15000);
          console.log(stripIndex+" "+stripX);
          var tempChart;
          var colorArray = ['#98FB98','#f1e4e4','#f7e0a3','#f09c67'];

          var val = stripX;

          if (val - 500 <= 0) {
            this.state.chart.xAxis[0].setExtremes(0, val + 1000, true, false);
          } else {
            this.state.chart.xAxis[0].setExtremes(val - 500, val + 500);
          }

          var clickIndex;
          clickIndex = stripIndex;
          var acti = 125;
          var actarr = [];
          var tttarr1 = [];

          for (var i = 0; i < this.state.result2[clickIndex].length; i++) {
            actarr.push({
              x: acti,
              y: 0.5,
              color: colorArray[this.state.result2[clickIndex][i]]
            });
            acti = acti + 250;
          }

          var prevChart = document.getElementById("selectedChart").value;
          var beats = JSON.parse(document.getElementById("beatSlected").value);
          if(beats.length>0) {
            for (var i = 0; i < beats.length; i++) {
              var xBeat = parseInt(beats[i])-(15000*prevChart)
              this.state.chart.xAxis[0].removePlotBand('plotband-'+xBeat) ;          
            }
            document.getElementById("beatSlected").value='[]';
          }

          this.state.chart.series[0].setData(this.state.result[clickIndex],true);
          this.state.chart.series[1].setData(actarr,true);
          this.state.chart.series[2].setData(this.state.result3[clickIndex],true);         

          var chart1 = this.state.charts[stripIndex];
          var tempChart = this.state.charts[document.getElementById("selectedChart").value];

          this.setState({['selectedChart']:clickIndex});
          var lineWidth = 2

          if (tempChart != undefined) {
            if (tempChart.draggablePlotLine != undefined) {

              tempChart.draggablePlotLine.destroy();
            }
          }
          
          this.state.charts[stripIndex].draggablePlotLine = this.state.charts[stripIndex].renderer.rect(this.state.charts[stripIndex].xAxis[0].toPixels(stripX)-this.state.charts[stripIndex].xAxis[0].toPixels(25), 10, 50, 40, 5)
          .attr({
            'stroke-width': 2,
            opacity:0.5,
            fill: 'grey',
            zIndex: 3
          })
          .add();

          if (this.state.charts[stripIndex].container.onmousemove != undefined) {
            this.state.charts[stripIndex].container.onmousemove = function(e) {
              return false;
            }
          }

          this.state.charts[stripIndex].container.onmousemove = function(e) {
            if (chart1.draggablePlotLine.drag) {

              let normalizedEvent = chart1.pointer.normalize(e),
              extremes = {
                left: chart1.plotLeft,
                right: chart1.plotLeft + chart1.plotWidth
              };

              if (e.chartX >= extremes.left &&  e.chartX <= extremes.right) {
                var pointValue = chart1.xAxis[0].toValue(e.chartX);
                if (pointValue - 500 <= 0) {
                  this.state.chart.xAxis[0].setExtremes(0, pointValue + 1000, true, false);
                } else {
                  this.state.chart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                }
                chart1.draggablePlotLine.attr({
                  x: e.chartX
                })
              }
            }
          }
          $('#sel').animate({ // animate your right div
            scrollTop: clickIndex*81 // to the position of the target 
          }, 400); 
        })
        .catch(err => 
          console.log(err)
        );
    }

    updateRpeaks(data) {
      this.setState({
        rpeaks:data
      })
    }

    regenerateReport(e) {
      const userid = this.props.match.params.userid;
      axios
          .get('http://34.93.36.252/html/welcome/regenerateReoprt/'+userid)
          .then(res => {
            console.log(res.data);      
          })
          .catch(err => {
              console.log(err);
          });
    }



    splitFn(value){
      var res = value.split("_");
      var name = res[0].substring(0, 4)+'-'+res[0].substring(4, 6)+'-'+res[0].substring(6, 8)+' :: '+res[1].substring(0, 2)+':'+res[1].substring(2, 4)+':'+res[1].substring(4, 6);
      res[0]=name;
      return res;
    }

    recategorizeImg(e){
      var checkedArray=[];
      var userid = this.props.match.params.userid;
      var databaseid = this.state.dblink;
      var folder = e.currentTarget.value;
      $("input:checkbox[name=test]:checked").each(function(){
          checkedArray.push($(this).val());
      });
      if(checkedArray.length>0){
        const data = {
          userid:userid,
          databaseid:databaseid,
          checkedArray:checkedArray,
          folder:folder
        }
        axios
          .post('http://34.93.36.252:5000/api/common/reCategorize',data)
          .then(res => { 
            console.log(res.data);         
          })
          .catch(err => {
              console.log(err);
          });
      }
    }

    filter(e){ 
      var selected = e.currentTarget.id;
      if(selected=='all'){
        $("#portfolio").children().show(1000);
        $("#filters a").removeClass('active');
        $("#"+e.currentTarget.id).addClass('active');
      } else {
        $("#portfolio").children().hide(1000); 
        var thisclass = "#portfolio ."+selected; 
        $("#portfolio ."+selected).show(1000); 
        $("#filters a").removeClass('active');
        $("#"+e.currentTarget.id+" a").addClass('active');
      }
    }

    resetGraph(e) {
        var it=0;
        var currentChart = this.state.charts;
        var currentNsrarr = this.state.defarr;
        var myRGVar= setInterval(function(){ 
          currentChart[it].series[0].setData(currentNsrarr[it],true);  
          it=it+1;
          if(it>currentNsrarr.length-1)
          { 
            clearInterval(myRGVar);
          }

        },10);
    }

    colorGraph(e) { 
        var selector = e.currentTarget.value;
        if(selector == 'Normal Sinus Rhythm'){
          var it=0;
          var currentChart = this.state.charts;
          var currentNsrarr = this.state.nsrarr;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentNsrarr[it],true);  
            it=it+1;
            if(it>currentNsrarr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Sinus Tachycardia'){
          var it=0;
          var currentChart = this.state.charts;
          var currentStarr = this.state.starr;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentStarr[it],true);  
            it=it+1;
            if(it>currentStarr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Sinus Bradycardia'){
          var it=0;
          var currentChart = this.state.charts;
          var currentSbarr = this.state.sbarr;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentSbarr[it],true);  
            it=it+1;
            if(it>currentSbarr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Sinus Pause'){
          var it=0;
          var currentChart = this.state.charts;
          var currentSparr = this.state.sparr;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentSparr[it],true);  
            it=it+1;
            if(it>currentSparr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'PAC'){
          var it=0;
          var currentChart = this.state.charts;
          var currentPacarr = this.state.pacarr;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentPacarr[it],true);  
            it=it+1;
            if(it>currentPacarr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'PVC'){
          var it=0;
          var currentChart = this.state.charts;
          var currentPvcarr = this.state.pvcarr;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentPvcarr[it],true);  
            it=it+1;
            if(it>currentPvcarr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Ventricular Tachycardia'){
          var it=0;
          var currentChart = this.state.charts;
          var currentVtcarr = this.state.vtarr;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentVtcarr[it],true);  
            it=it+1;
            if(it>currentVtcarr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'AFib'){
          var it=0;
          var currentChart = this.state.charts;
          var currentAfcarr = this.state.afarr;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentAfcarr[it],true);  
            it=it+1;
            if(it>currentAfcarr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'P Absent'){
          var it=0;
          var currentChart = this.state.charts;
          var currentPacarr = this.state.paarr;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentPacarr[it],true);  
            it=it+1;
            if(it>currentPacarr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Irregular RR'){
          var it=0;
          var currentChart = this.state.charts;
          var currentIrrrarr = this.state.irrrarr;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentIrrrarr[it],true);  
            it=it+1;
            if(it>currentIrrrarr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Irregular PR'){
          var it=0;
          var currentChart = this.state.charts;
          var currentIrprarr = this.state.irprarr;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentIrprarr[it],true);  
            it=it+1;
            if(it>currentIrprarr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Short QT'){
          var it=0;
          var currentChart = this.state.charts;
          var currentSqtarr = this.state.sqtarr;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentSqtarr[it],true);  
            it=it+1;
            if(it>currentSqtarr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Prolonged QT'){
          var it=0;
          var currentChart = this.state.charts;
          var currentPaarr = this.state.paarr;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentPaarr[it],true);  
            it=it+1;
            if(it>currentPaarr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }
        else if(selector == 'Undiagnosed'){
          var it=0;
          var currentChart = this.state.charts;
          var currentUnarr = this.state.unarr;
          var myVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentUnarr[it],true);  
            it=it+1;
            if(it>currentUnarr.length-1)
            { 
              clearInterval(myVar);
            }

          },10);
        }

    };

    recategorize(e) { 

      var beats = JSON.parse(document.getElementById("beatSlected").value);
      var databaseid = this.state.databaseid;
      var userid = this.props.match.params.userid;
      var arrhythmia = e.currentTarget.value;
      console.log(beats);
      if(beats.length>0) {

        const data = {
          databaseid: databaseid,
          beats: beats,
          arrhythmia:arrhythmia,
          userid: userid
        };
        axios
        .post(apiUrl+'api/common/updateArrhythmia',data)
        .then(res => {
          var currentChart = document.getElementById("selectedChart").value;
          var minus_val = currentChart*15000;
            console.log(res);
            for (var i = 0; i < beats.length; i++) {
              this.state.chart.xAxis[0].removePlotBand('plotband-'+(beats[i]-minus_val))             
            }
            document.getElementById("beatSlected").value="[]";
        })
        .catch(err =>{ 
            console.log(err)
        });
      }
    } 

    gainBtn(e) {
      var buttonId = e.currentTarget.id;
      var gainValue = document.getElementById('gainValue').value;
      console.log(gainValue);
      var it=0;
      var charts = this.state.charts;
      var arCount = this.state.result.length-1;
      var scaleUp= setInterval(function(){ 
          if(gainValue==1){
            charts[it].update({
              yAxis: [
                {
                  min: -0.0020,
                  max: 0.0020
                }],    
            });
          }else if(gainValue==2){
            charts[it].update({
              yAxis: [
                {
                  min: -0.0010,
                  max: 0.0010
                }],    
            });
          }else if(gainValue==3){
            charts[it].update({
              yAxis: [
                {
                  min: -0.0005,
                  max: 0.0005
                }],    
            });
          }
       
          it=it+1;
          if(it>arCount)
          {
            clearInterval(scaleUp);
          }
        },5);
      // if (buttonId == 'gainButton') {
      //   var menuToggle = 'menu2';
      //   var showBtn = 'rgainButton';
      //   var hideBtn = 'gainButton';
      //   document.getElementById("gainValue").style.display = 'none';
      //   document.getElementById(hideBtn).style.display = 'none'; //step 2 :additional feature hide button
      //   document.getElementById(showBtn).style.display = 'inline-block'; //step 3:additional feature show button
      //   var it=0;
      //   var charts = this.state.charts;
      //   var arCount = this.state.result.length-1;
      //   var scaleUp= setInterval(function(){ 
      //     if(gainValue==2){
      //       charts[it].update({
      //         yAxis: [
      //           {
      //             min: -0.0010,
      //             max: 0.0010
      //           }],    
      //       });
      //     }else if(gainValue==3){
      //       charts[it].update({
      //         yAxis: [
      //           {
      //             min: -0.0005,
      //             max: 0.0005
      //           }],    
      //       });
      //     }
       
      //     it=it+1;
      //     if(it>arCount)
      //     {
      //       clearInterval(scaleUp);
      //     }
      //   },5);
      // } else {
      //   var menuToggle = 'menu2';
      //   var showBtn = 'gainButton';
      //   var hideBtn = 'rgainButton';
      //   document.getElementById(hideBtn).style.display = 'none'; //step 2 :additional feature hide button
      //   document.getElementById(showBtn).style.display = 'inline-block'; //step 3:additional feature show button
      //   document.getElementById("gainValue").style.display = 'inline-block';
      //   var it=0;
      //   var charts = this.state.charts;
      //   var arCount = this.state.result.length-1;
      //   var scaleUp= setInterval(function(){ 
      //     charts[it].update({
      //       yAxis: [
      //         {
      //           min: -0.0020,
      //           max: 0.0020
      //         }],    
      //     });
       
      //     it=it+1;
      //     if(it>arCount)
      //     {
      //       clearInterval(scaleUp);
      //     }
      //   },5);
      // }
    }

    renderNewChart(userid,dbId,step) {

      var arr=[],charts=[],arr2=[], peakarr=[],barClick=0,pointValue,tempChart,tempCont=0;
      var nsrarr=[],starr=[],unarr=[],sbarr=[],vtarr=[],sparr=[],lqtarr=[],sqtarr=[],irrrarr=[],irprarr=[],afarr=[],pacarr=[],pvcarr=[],paarr=[],beatArr=[],defarr=[],plotband=[],piec;
      var maximumval;var b;var js_array=[];var chart; var minVal,maxVal,val,val2;
      var result,result2,result3,colorArray=[];
      var rpeakArray = [];
      var peakColorArray= [];
          peakColorArray['Undiagnosed'] = '#FCDF85';
          peakColorArray['Normal Sinus Rhythm'] = '#98FB98';
          peakColorArray['Sinus Tachycardia'] = '#3EFFDE';
          peakColorArray['PAC'] = '#4950EB';
          peakColorArray['PVC'] = '#FF45DC';
          peakColorArray['P absent'] = '#AEB9BD';
          peakColorArray['Sinus Bradycardia'] = '#FFD14F';
          peakColorArray['Sinus Pause'] = '#FF5B05';
          peakColorArray['Irregular RR'] = '#A4C3FF';
          peakColorArray['Ventricular Tachycardia'] = '#F0A7A1';
          peakColorArray['Irregular PR'] = '#BBEB8F';
          peakColorArray['Short QT'] = '#CFFF0F';
          peakColorArray['Prolonged QT'] = '#7C2F09';
          peakColorArray['AFib'] = '#A96BFF';

      var userid = userid;
      var databaseid = dbId;

      //Split the array in chunks with given size
      function chunkArray(myArray, chunk_size) {
            let results = [];
            
            while (myArray.length) {
                results.push(myArray.splice(0, chunk_size))
            }

            return results;
      };

      function chunkPeakArray(peakArray){
            let presults = [];
            var minIndex = 0;
            var breakVal = 15000;
            var z;
            var temRpeakindex
            presults.push( [] );
            for (var i = 0; i < peakArray.length; i++) {
              if (peakArray[i].rpeakindex > breakVal) {
                presults.push( [] );
                minIndex = minIndex+1;
                breakVal = breakVal+15000;
              }
              temRpeakindex = parseInt(peakArray[i].rpeakindex)-(minIndex*15000);
              presults[minIndex].push({ x: temRpeakindex, y: parseFloat(0.8), color:peakColorArray[peakArray[i].arrhythmia], beat: parseInt(peakArray[i].indexcounter), hr: parseInt(peakArray[i].heartrate), rr: parseInt(peakArray[i].rr_interval),arrhythmia:peakArray[i].arrhythmia,peaktime:peakArray[i].peaktime });
            }
            return presults;
      }

      function createSchartContainer() {
          var t=0;
          var stcolor= "black",uncolor= "black",sbcolor= "black",vtcolor= "black",spcolor= "black",lqcolor= "black",sqcolor= "black",irrrcolor= "black",irprcolor= "black",afcolor= "black",paccolor= "black",pvccolour= "black",pacolour= "black",defcolour="black";
          var nsrcolor = "black";
          for(var i=0; i < result.length; i++){
            var newDiv = document.createElement('div');
                newDiv.id = 'container'+i;
                newDiv.style = 'min-width: 200px; height: 60px; margin: 0 auto';
            var newSpan = document.createElement('div');
                newSpan.id='span'+i;
                newSpan.style='color:#000;';
            var time1 = document.createTextNode(result3[i][0].peaktime);
                newSpan.appendChild(time1);
            document.getElementById('sel').appendChild(newSpan);
            document.getElementById('sel').appendChild(newDiv);

            var arrayIndex = 0;
            var k=0;
                    
            nsrarr.push([]);
            starr.push([]);
            unarr.push([]);
            sbarr.push([]);
            vtarr.push([]);
            sparr.push([]);
            lqtarr.push([]);
            sqtarr.push([]);
            irrrarr.push([]);
            irprarr.push([]);
            afarr.push([]);
            pacarr.push([]);
            pvcarr.push([]);
            paarr.push([]);
            defarr.push([]);

            for (var j = 0; j < result[i].length; j++) {
              if( j+70==result3[i][k].x || result3[i][k].x<70)
              { 
                if(result3[i][k].arrhythmia == 'Normal Sinus Rhythm'){
                  nsrcolor = "#98FB98";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Sinus Tachycardia'){
                  nsrcolor = "black";
                  stcolor = "#3EFFDE";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Sinus Bradycardia'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "#FFD14F";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Sinus Pause'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "#FF5B05";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'PAC'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "#4950EB";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'PVC'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "#FF45DC";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Ventricular Tachycardia'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "#F0A7A1";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Afib'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "#A96BFF";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'P absent'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "#AEB9BD";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Irregular RR'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "#A4C3FF";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                else if(result3[i][k].arrhythmia == 'Irregular PR'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "#BBEB8F";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                else if(result3[i][k].arrhythmia == 'Short QT'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "#CFFF0F";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                else if(result3[i][k].arrhythmia == 'Prolonged QT'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "#7C2F09";
                  defcolour = "black";
                }
                else if(result3[i][k].arrhythmia == 'Undiagnosed'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "#FCDF85";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                else{
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                if(k < result3[i].length-1){
                  k++;
                }
              } 
              nsrarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: nsrcolor
              });

              defarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: 'black'
              });
              starr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: stcolor
              });
              unarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: uncolor
              });
              sbarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: sbcolor
              });
              sqtarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: sqcolor
              });
              vtarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: vtcolor
              });
              sparr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: spcolor
              });
              pvcarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: pvccolour
              });
              irrrarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: irrrcolor
              });
              irprarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: irprcolor
              });
              afarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: afcolor
              });
              pacarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: paccolor
              });
              paarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: pacolour
              });
                      // pacarr[i].push({
                      //   x:j,
                      //   y:parseFloat(result[i][j]),
                      //   segmentColor: pacolour
                      // });
            }
          }
      }

      function renderSmallCharts() {
          var i=0;

          var t=0;
          var stcolor= "black",uncolor= "black",sbcolor= "black",vtcolor= "black",spcolor= "black",lqcolor= "black",sqcolor= "black",irrrcolor= "black",irprcolor= "black",afcolor= "black",paccolor= "black",pvccolour= "black",pacolour= "black",defcolour="black";
          var nsrcolor = "black";

          var myVar= setInterval(function(){ 

            var newDiv = document.createElement('div');
                newDiv.id = 'container'+i;
                newDiv.style = 'min-width: 200px; height: 60px; margin: 0 auto';
            var newSpan = document.createElement('div');
                newSpan.id='span'+i;
                newSpan.style='color:#000;';
            var time1 = document.createTextNode(result3[i][0].peaktime);
                newSpan.appendChild(time1);
            document.getElementById('sel').appendChild(newSpan);
            document.getElementById('sel').appendChild(newDiv);

            var arrayIndex = 0;
            var k=0;
                    
            nsrarr.push([]);
            starr.push([]);
            unarr.push([]);
            sbarr.push([]);
            vtarr.push([]);
            sparr.push([]);
            lqtarr.push([]);
            sqtarr.push([]);
            irrrarr.push([]);
            irprarr.push([]);
            afarr.push([]);
            pacarr.push([]);
            pvcarr.push([]);
            paarr.push([]);
            defarr.push([]);

            for (var j = 0; j < result[i].length; j++) {
              if( j+70==result3[i][k].x || result3[i][k].x<70)
              { 
                if(result3[i][k].arrhythmia == 'Normal Sinus Rhythm'){
                  nsrcolor = "#98FB98";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Sinus Tachycardia'){
                  nsrcolor = "black";
                  stcolor = "#3EFFDE";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Sinus Bradycardia'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "#FFD14F";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Sinus Pause'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "#FF5B05";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'PAC'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "#4950EB";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'PVC'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "#FF45DC";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Ventricular Tachycardia'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "#F0A7A1";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Afib'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "#A96BFF";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'P absent'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "#AEB9BD";
                  defcolour = "black";
                }else if(result3[i][k].arrhythmia == 'Irregular RR'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "#A4C3FF";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                else if(result3[i][k].arrhythmia == 'Irregular PR'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "#BBEB8F";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                else if(result3[i][k].arrhythmia == 'Short QT'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "#CFFF0F";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                else if(result3[i][k].arrhythmia == 'Prolonged QT'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "#7C2F09";
                  defcolour = "black";
                }
                else if(result3[i][k].arrhythmia == 'Undiagnosed'){
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "#FCDF85";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                else{
                  nsrcolor = "black";
                  stcolor = "black";
                  uncolor = "black";
                  sbcolor = "black";
                  vtcolor = "black";
                  spcolor = "black";
                  lqcolor = "black";
                  sqcolor = "black";
                  irrrcolor = "black";
                  irprcolor = "black";
                  afcolor = "black";
                  paccolor = "black";
                  pvccolour = "black";
                  pacolour = "black";
                  defcolour = "black";
                }
                if(k < result3[i].length-1){
                  k++;
                }
              } 
              nsrarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: nsrcolor
              });

              defarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: 'black'
              });
              starr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: stcolor
              });
              unarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: uncolor
              });
              sbarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: sbcolor
              });
              sqtarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: sqcolor
              });
              vtarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: vtcolor
              });
              sparr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: spcolor
              });
              pvcarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: pvccolour
              });
              irrrarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: irrrcolor
              });
              irprarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: irprcolor
              });
              afarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: afcolor
              });
              pacarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: paccolor
              });
              paarr[i].push({
                x:j,
                y:parseFloat(result[i][j]),
                segmentColor: pacolour
              });
                      // pacarr[i].push({
                      //   x:j,
                      //   y:parseFloat(result[i][j]),
                      //   segmentColor: pacolour
                      // });
            }


            charts[i] = new Highcharts.Chart('container'+i, {
              credits: {
                enabled: false
              },
              chart: {
                height: 5 + '%',
                resetZoomButton: {
                theme: {
                  display: 'none'
                },
              },
              events: {
                click(event)
                { 
                  var containerId = this.renderTo.id;
                  var currentIndex = containerId.replace('container','');
                  var prevChart = document.getElementById("selectedChart").value;
                  var beats = JSON.parse(document.getElementById("beatSlected").value);
                  if(beats.length>0 && currentIndex !=prevChart) {
                    for (var i = 0; i < beats.length; i++) {
                      var xBeat = parseInt(beats[i])-(15000*prevChart)
                      chart.xAxis[0].removePlotBand('plotband-'+xBeat) ;          
                    }
                    document.getElementById("beatSlected").value='[]';
                  }

                  val= charts[currentIndex].xAxis[0].toValue(event.chartX);

                  if(val-500<=0)
                  {
                    chart.xAxis[0].setExtremes(0,val+1000,true,false);
                  }
                  else{
                    chart.xAxis[0].setExtremes(val-500,val+500);
                  }

                  var clickIndex ;
                  clickIndex = currentIndex; 

                  var acti= 125;
                  var actarr = [];
                  var tttarr1 = [];

                  for (var i = 0; i < result2[clickIndex].length; i++) {
                    actarr.push({ x: acti, y: 0.5, color: colorArray[result2[clickIndex][i]] });
                    acti = acti+250;
                  }
                  console.log(result[clickIndex]);         
                  chart.series[0].setData(result[clickIndex],true);
                  chart.series[1].setData(actarr,true);
                  chart.series[2].setData(result3[clickIndex],true);

                  tempChart = charts[document.getElementById("selectedChart").value];
                  if(tempChart!=undefined){
                    if (tempChart.draggablePlotLine!=undefined) {
                      tempChart.draggablePlotLine.destroy();
                    }
                  }
                  var chart1 = this
                  tempCont=currentIndex;
                  document.getElementById("selectedChart").value= tempCont;
                  var lineWidth = 2

                  chart1.draggablePlotLine = chart1.renderer.rect(event.chartX-charts[currentIndex].xAxis[0].toPixels(25), 10, 50, 40, 5)
                    .attr({
                      'stroke-width': 2,
                      opacity:0.5,
                      fill: 'grey',
                      zIndex: 3
                    })
                    .add();

                    if(charts[0].container.onmousemove!=undefined)
                    {
                      charts[0].container.onmousemove = function(e) {return false;}
                    }

                    charts[currentIndex].container.onmousemove = function(e) {
                      if (chart1.draggablePlotLine.drag) {

                        let normalizedEvent = chart1.pointer.normalize(e),
                        extremes = {
                          left: chart1.plotLeft,
                          right: chart1.plotLeft + chart1.plotWidth
                        };

                        if (
                          e.chartX >= extremes.left &&
                          e.chartX <= extremes.right
                        ) {
                          pointValue = chart1.xAxis[0].toValue(e.chartX);
                          if (pointValue - 500 <= 0) {
                            chart.xAxis[0].setExtremes(0, pointValue + 1000, true, false);
                          } else {
                            chart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                          }
                          chart1.draggablePlotLine.attr({
                            x: e.chartX
                          })
                        }
                      }
                    }

                    charts[currentIndex].draggablePlotLine.element.onmousedown = function() {
                      chart1.draggablePlotLine.drag = true;
                    }

                    charts[currentIndex].draggablePlotLine.element.onmouseup = function() {
                      chart1.draggablePlotLine.drag = false;
                    }

                  },
                },
                zoomType: '',
              },

              navigator: {
                enabled: false
              },

              xAxis:[{
                min:0,
                max:15000,
                lineWidth: 0,
                minorGridLineWidth: 0,
                lineColor: 'transparent',
                scrollbar: {
                  enabled: true,
                  showFull: false
                },
                labels: {
                  enabled: false
                },
                minorTickLength: 0,
                tickLength: 0, visible: false

              }],
              yAxis: [{
                min: -0.002,
                max: 0.002,
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                minorTicks: false,
                labels: {
                  enabled: false
                },
                title: {
                  enabled: false
                },
              }],
              rangeSelector: {
                enabled: false
              },
              plotOptions: {
                series: {
                  turboThreshold: 15000,
                },
              },
              title: {
                text: ''
              },

              series: [{
                type: 'coloredline',
                data: result[i],
                color: 'black',
                allowPointSelect: false,
                animation: false,
                enableMouseTracking: false,
                lineWidth: 1,
                marker: {
                  enabled: false
                },
                shadow: false,
                  stickyTracking: false,
                  showInLegend: false
                }]
              });
            
            if(i==0) {
              console.log(charts[0]);
              charts[0].draggablePlotLine = charts[0].renderer.rect(charts[0].xAxis[0].toPixels(50)-charts[0].xAxis[0].toPixels(25), 10, 50, 40, 5)
              .attr({
                'stroke-width': 2,
                opacity:0.5,
                fill: 'grey',
                zIndex: 3
              })
              .add();

              if (charts[0].container.onmousemove != undefined) {
                charts[0].container.onmousemove = function(e) {
                  return false;
                }
              }
              var stripIndex = 0;
              charts[stripIndex].container.onmousemove = function(e) {
                if (charts[stripIndex].draggablePlotLine.drag) {

                  let normalizedEvent = charts[stripIndex].pointer.normalize(e),
                  extremes = {
                    left: charts[stripIndex].plotLeft,
                    right: charts[stripIndex].plotLeft + charts[stripIndex].plotWidth
                  };

                  if (e.chartX >= extremes.left &&  e.chartX <= extremes.right) {
                    var pointValue = charts[stripIndex].xAxis[0].toValue(e.chartX);
                    if (pointValue - 500 <= 0) {
                      chart.xAxis[0].setExtremes(0, pointValue + 1000, true, false);
                    } else {
                      chart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                    }
                    charts[stripIndex].draggablePlotLine.attr({
                      x: e.chartX
                    })
                  }
                }
              }
            }
            i=i+1;
            if(i>result.length-1)
            { 
              clearInterval(myVar);
            }
          },7);
      }

      // Function to plot the background graph
      function renderMainChart() {

          maximumval = 1000;
          b = 0;

          // Create the chart
          chart = new Highcharts.StockChart('tt_container', {
              exporting: {
                  enabled: false
              },
              scrollbar: {
                  enabled: false
              },
              credits: {
                  enabled: false
              },
              chart: {

                  type: 'coloredline',
                  height: 40 + '%',

                  resetZoomButton: {
                      theme: {
                          display: 'none'
                      },

                  },
                  events: {
                      click: function(event) {
                          var k = 0,
                              plotbi = 0;
                          var t = Math.floor(event.xAxis[0]['value']);
                          var closestArray = [];
                          var j  = tempCont*15000; 


                          chart.update({
                              xAxis: {
                                plotBands: plotband
                              }
                          });

                          for (var i = 0; i < result3[tempCont].length; i++) {
                              closestArray[i] = result3[tempCont][i].x;
                          }
                          

                          var closest = closestArray.reduce(function(prev, curr) {
                              return (Math.abs(curr - t) < Math.abs(prev - t) ? curr : prev);
                          });

                          beatArr = JSON.parse($('#beatSlected').val());
                          var check = $.inArray((closest+j), beatArr);

                          if (check == -1) {
                            plotband.push({
                              color: '#ff9999',
                              from: closest - 100,
                              to: closest + 100,
                              id: 'plotband-' + closest
                            });

                            chart.update({
                              xAxis: {
                                plotBands: plotband
                              }
                            });

                            beatArr.push((closest+j));
                          } else {
                              var index = beatArr.indexOf((closest+j));
                              if (index > -1) {
                                  beatArr.splice(index, 1);
                              }
                              chart.xAxis[0].removePlotBand('plotband-' + closest);
                          }
                          $('#beatSlected').val(JSON.stringify(beatArr));
                      },
                  },
                  zoomType: '',
              },

              navigator: {
                  enabled: false
              },

              xAxis: [{
                  min: 0, //setting min to 0
                  max: 1000,
                  events: {

                      setExtremes: function(e) {
                          val = e.min + 500;
                          tempChart = charts[document.getElementById("selectedChart").value];
                          if (tempChart != undefined) {
                              if (tempChart.draggablePlotLine != undefined) {
                                  val2 = tempChart.xAxis[0].toPixels(val);


                                  tempChart.draggablePlotLine.attr({
                                      x: val2

                                  })



                              }
                          }
                      },
                  },
                  scrollbar: {
                      enabled: true
                  },
                  labels: {
                      enabled: false
                  },

                  tickInterval: 50,
                  minorTicks: true,
                  minorTickInterval: 10,
                  gridLineWidth: 1,
                  gridLineColor: '#D3D3D3'

              }],
              yAxis: [{
                      min: 0,
                      max: 10,
                      reversed: true,
                      gridLineWidth: 0,
                      minorGridLineWidth: 0,
                      minorTicks: false,

                      labels: {
                          enabled: true
                      },
                      title: {
                          enabled: false
                      },
                      visible: false

                  },
                  {
                      min: -0.002,
                      max: 0.002,
                      tickPositions: [-0.0020, -0.0015, -0.0010, -0.0005, 0.0000, 0.0005, 0.0010, 0.0015, 0.0020],
                      tickInterval: 0.0005,
                      gridLineWidth: 3,
                      minorGridLineWidth: 1,
                      minorTicks: true,
                      minorTickInterval: 0.0001,

                      gridLineWidth: 2,
                      gridLineColor: '#D3D3D3',
                      labels: {
                          enabled: false
                      },
                      title: {
                          enabled: false
                      },

                  },
                  {
                      min: 0,
                      max: 10,
                      gridLineWidth: 0,
                      minorGridLineWidth: 0,
                      minorTicks: false,

                      labels: {
                          enabled: true
                      },
                      title: {
                          enabled: false
                      },
                      visible: false

                  }
              ],
              rangeSelector: {
                  enabled: false
              },
              plotOptions: {
                  series: {
                      turboThreshold: 15500,
                      colorByPoint: false
                  },

              },

              title: {
                  text: ''
              },

              series: [{


                  data: [],
                  yAxis: 1,
                  xAxis: 0,
                  color: 'black',
                  states: {
                      inactive: {
                          opacity: 1
                      }
                  },

                  allowPointSelect: false,
                  animation: false,
                  enableMouseTracking: false,
                  lineWidth: 1,
                  marker: {
                      enabled: false
                  },
                  shadow: false,
                  stickyTracking: false,
                  showInLegend: false


              }]
          });
          chart.showLoading(' Please wait while the ECG is being loaded  <i class="fa fa-spin fa-spinner"></i>');
          chart.addSeries({

              yAxis: 0,

              type: 'column',

              data: [],
              pointWidth: 400,
              inverted: true,
              tooltip: {
                  enabled: false
              },
              states: {

                  inactive: {
                      opacity: 1
                  }
              },

              allowPointSelect: false,
              animation: false,
              enableMouseTracking: false,
              lineWidth: 1,

              shadow: false,
              stickyTracking: false,
              showInLegend: false

          }, true);
          chart.addSeries({
              dataLabels: [{
                  enabled: true,
                  inside: true,
                  align: 'left',
                  format: 'Beat:{point.beat}; HR:{point.hr}; RR:{point.rr}'
              }],
              yAxis: 2,
              type: 'column',
              turboThreshold: 10000,
              data: [],
              pointWidth: 250,
              tooltip: {
                  enabled: false
              },
              point: {
                  events: {
                      click: function() {

                      },


                  }
              },
              states: {

                  inactive: {
                      opacity: 1
                  }
              },

              marker: {
                  enabled: false
              },

              showInLegend: false
          }, true);

          return chart;
      };

      if(step==2) {
        this.state.chart.destroy();
        this.setState({
          databaseid:databaseid
        });
      }
      document.getElementById('sel').innerHTML ="";
      var mainchat = renderMainChart();
            
      this.setState({
        result: []
      });
      this.setState({
        result2: []
      });
      this.setState({
        result3: []
      });
      this.setState({
        defarr: []
      });
      this.setState({
        nsrarr: []
      });
      this.setState({
        starr: []
      });
      this.setState({
        sbarr: []
      });
      this.setState({
        sparr: []
      });
      this.setState({
        pacarr: []
      });
      this.setState({
        pvcarr: []
      });
      this.setState({
        vtarr: []
      });
      this.setState({
        afarr: []
      });
      this.setState({
        paarr: []
      });
      this.setState({
        irrrarr: []
      });
      this.setState({
        irprarr: []
      });
      this.setState({
        sqtarr: []
      });
      this.setState({
        paarr: []
      });
      this.setState({
        unarr: []
      });



      axios
        .get(apiUrl+'api/common/getEcgValues?userid='+userid+'&databaseid='+databaseid)
        .then(res => {  
            console.log('loaded');
            this.updateRpeaks(res.data[1]);
            result = chunkArray(res.data[0].ecgvalue, 15000);
            result2 = chunkArray(res.data[0].activityvalue, 60);
            result3 = chunkPeakArray(res.data[1]);
            

            var acti= 125;
            var counteri=249;
            var counter = 0;
            var t=0;
            var actarr=[];
            colorArray = ['#98FB98','#f1e4e4','#f7e0a3','#f09c67']

            for (var i = 0; i < result2[0].length; i++) {
              actarr.push({ x: acti, y: 0.5, color: colorArray[result2[0][i]] });
              acti = acti+250;
            }
            for(var k=0;k<this.state.charts.length;k++){
              this.state.charts[k].destroy();
            }

            //createSchartContainer();
            chart.hideLoading();
            chart.series[0].setData(result[0],true);
            chart.series[1].setData(actarr,true);
            chart.series[2].setData(result3[0],true);
            renderSmallCharts();
            if(step==1) {
              

              
            }
            this.setState({
              result: result
            });
            this.setState({
              result2: result2
            });
            this.setState({
              result3: result3
            });
            this.setState({
              charts: charts
            });
            this.setState({
              chart: chart
            });
            this.setState({
              defarr: defarr
            });
            this.setState({
              nsrarr: nsrarr
            });
            this.setState({
              starr: starr
            });
            this.setState({
              sbarr: sbarr
            });
            this.setState({
              sparr: sparr
            });
            this.setState({
              pacarr: pacarr
            });
            this.setState({
              pvcarr: pvcarr
            });
            this.setState({
              vtarr: vtarr
            });
            this.setState({
              afarr: afarr
            });
            this.setState({
              paarr: paarr
            });
            this.setState({
              irrrarr: irrrarr
            });
            this.setState({
              irprarr: irprarr
            });
            this.setState({
              sqtarr: sqtarr
            });
            this.setState({
              paarr: paarr
            });
            this.setState({
              unarr: unarr
            });
            
                 
        })
        .catch(err => {
        });

      axios
        .get('http://34.93.36.252:5000/api/common/getfolderfiles?userid='+userid+'&databaseid='+databaseid)
        .then(res => {
          alert('hello'); 
          this.setState({
            images:res.data[0]
          });
          this.setState({
            dblink:res.data[1]
          });
          console.log(this.state.images);
          console.log(this.state.dblink);
        })
        .catch(err => {
            console.log(err);
        }); 
    }

    nextBtnLink(userid,dbid) {
      axios
        .get(apiUrl+'api/common/getNext?userid='+userid+'&databaseid='+dbid)
        .then(res => {
          if(res.data!=null){
            document.getElementById("graphNext").style.display = 'inline-block';
            this.setState({
                nextDbId:res.data.ecgvalue_id
            })
          } else {
            document.getElementById("graphNext").style.display = 'none';
          }        
        })
        .catch(err => {
          console.log(err);
        });
    } 

    prevBtnLink(userid,dbid) {
      axios
        .get(apiUrl+'api/common/getPrev?userid='+userid+'&databaseid='+dbid)
        .then(res => {
          if(res.data!=null){
            document.getElementById("graphPrev").style.display = 'inline-block';
            this.setState({
                prevDbId:res.data.ecgvalue_id
            })
          } else {
            document.getElementById("graphPrev").style.display = 'none';
          }       
        })
        .catch(err => {
          console.log(err);
        });
    }  

    newDataClick(e) {
      //alert('test');
      var buttonId = e.currentTarget.id;
      var databaseid;
      var userid = this.props.match.params.userid;
      if(buttonId=='nextBtn'){
        databaseid = this.state.nextDbId;
      } else if(buttonId=='prevBtn') {
        databaseid = this.state.prevDbId;
      }
      document.getElementById("graphPrev").style.display = 'none';
      document.getElementById("graphNext").style.display = 'none';
      this.renderNewChart(userid,databaseid,2);
      this.nextBtnLink(userid,databaseid);
      this.prevBtnLink(userid,databaseid);
    }
  

  componentDidMount() {
    var userid = this.props.match.params.userid;
    var databaseid = this.props.match.params.databaseid;
    if (typeof databaseid === 'undefined') {
       databaseid="0";
      axios
        .get(apiUrl+'api/common/getLatestDatabaseId?userid='+this.props.match.params.userid)
        .then(res => {
          this.setState({
            databaseid:res.data
          })       
        })
        .catch(err => {
            console.log(err);
        });
    } else {
      this.setState({
        databaseid:databaseid
      })
    }

    axios
      .get(apiUrl+'api/patients/patientReportStatus?userid='+this.props.match.params.userid)
      .then(res => {
        console.log(res.data);
        this.setState({
          reportStatus:res.data
        })       
      })
      .catch(err => {
        console.log(err);
      });

    axios
        .get(apiUrl+'api/common/patinentmenutree?userid='+this.props.match.params.userid)
        .then(res => {
          this.setState({
            treemenu:res.data
          })       
        })
        .catch(err => {
            console.log(err);
        });
    axios
        .get(apiUrl+'api/common/getReport?userid='+this.props.match.params.userid)
        .then(res => {
          console.log(res.data);   
          if(res.data!='') {
            this.setState({
              reoprtLink:res.data
            })  
          }   
        })
        .catch(err => {
            console.log(err);
        });

    this.renderNewChart(userid,databaseid,1);
    this.nextBtnLink(userid,databaseid);
    this.prevBtnLink(userid,databaseid);
       
  }
 
  render() {
    const userid = this.props.match.params.userid; 
    var databaseid = this.props.match.params.databaseid;
    if (typeof databaseid === 'undefined'){
      databaseid="0";
    }
    return (
    <div className="wrapper theme-1-active box-layout pimary-color-green">
      <input type="hidden" value={this.state.nextDbId} id="nextDbId" />
      <input type="hidden" value={this.state.prevDbId} id="prevDbId" />
      <TopNav profileLink={this.state.profileLink}/>
      <SideBarGraph userid={userid} menus={this.state.menus} treemenu={this.state.treemenu} databaseid={databaseid} />
      <div className="right-sidebar-backdrop"></div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="panel panel-default card-view">
                <div className="panel-wrapper collapse in">
                  <div className="panel-body">
                    <div  className="pills-struct">
                      <ul role="tablist" className="nav nav-pills" id="myTabs_6">
                        <li className="active" role="presentation">
                          <a aria-expanded="true"  data-toggle="tab" role="tab" id="chart_tab_1" href="#chart_events">Events</a>
                        </li>
                        <li role="presentation" className="">
                          <a  data-toggle="tab" id="chart_tab_2" role="tab" href="#chart_beats" aria-expanded="false">Beats</a>
                        </li>   
                        <li role="presentation" className="">
                          <a  data-toggle="tab" id="chart_tab_3" role="tab" href="#chart_summary" aria-expanded="false">Summary</a>
                        </li>
                        <li role="presentation" className="">
                          <a  data-toggle="tab" id="chart_tab_4" role="tab" href="#chart_rate" aria-expanded="false">Heartrate</a>
                        </li>            
                        { this.state.reportStatus >=4 &&
                          <React.Fragment>
                            <li role="presentation" id="chart_tab_report" className="pull-right">
                              <a  target="_blank" href={this.state.reoprtLink}>View Report</a>
                            </li>  
                            <li role="presentation" className="pull-right">
                              <a  data-toggle="tab" onClick={this.regenerateReport.bind(this)} id="chart_tab_7" role="tab" href="#chart_regenerate-" aria-expanded="false">Regenerate</a>
                            </li> 
                          </React.Fragment>
                        } 
                        <li role="presentation" className="pull-right">
                          <a  data-toggle="tab" id="chart_tab_6" role="tab" href="#chart_findings" aria-expanded="false">Add Findings</a>
                        </li> 
                        <li role="presentation" className="pull-right">
                          <a  data-toggle="tab" id="chart_tab_5" role="tab" href="#chart_settings" aria-expanded="false">Settings</a>
                        </li>      
                      </ul>
                      <p className="text-right" id="databaseidCont">Database Id :: {this.state.databaseid}</p>
                      <div  className="row">
                        <div className="col-md-6">
                          <div id="graphPrev" className="displayNone"><button id="prevBtn" onClick={this.newDataClick.bind(this)}>Previos</button></div>
                        </div>
                        <div className="col-md-6 text-right">
                          <div id="graphNext" className="displayNone"><button id="nextBtn" onClick={this.newDataClick.bind(this)}>Next</button></div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="tab-content" id="myTabContent_6">
                        <div  id="chart_events" className="tab-pane fade active in" role="tabpanel">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="">
                                <div className="panel-wrapper collapse in">
                                  <div className="panel-body">
                                    <h5 className="panel-title txt-dark cd">Cardiac Event Detected</h5>
                                    <div className="filter-wrap mb-10">
                                      <ul id="filters">
                                        <li><a id="all" href="#" onClick={this.filter.bind(this)} data-filter="*" className="active">all</a></li> 
                                        {
                                          Object.keys(this.state.images).map((val,key) => {

                                            return <li key={key} id={val} value={val}  onClick={this.filter.bind(this)} ><a href="#" data-filter={"."+val}>{val}</a></li>
                                          })

                                        }
                                      </ul>
                                      <hr/>
                                      <div className="clearfix"></div>
                                    </div>
                                    <h5 className="panel-title txt-dark rechead">Recategorize To</h5>
                                    <div className="row mt-5 mt-5hed">
                                      <div className="col-sm-12">  
                                        <button className="arythmia-btn nsr-btn" value='nsr' onClick={this.recategorizeImg.bind(this)}>NSR</button>
                                        <button className="arythmia-btn st-btn" value='st' onClick={this.recategorizeImg.bind(this)}>ST</button>
                                        <button className="arythmia-btn sb-btn" value='sb' onClick={this.recategorizeImg.bind(this)}>SB</button>
                                        <button className="arythmia-btn pac-btn" value='pac' onClick={this.recategorizeImg.bind(this)}>PAC</button>
                                        <button className="arythmia-btn pvc-btn" value='pvc' onClick={this.recategorizeImg.bind(this)}>PVC</button>
                                        <button className="arythmia-btn vt-btn" value='vt' onClick={this.recategorizeImg.bind(this)}>VT</button>
                                        <button className="arythmia-btn af-btn" value='af' onClick={this.recategorizeImg.bind(this)}>AF</button>
                                        <button className="arythmia-btn sp-btn" value='sp' onClick={this.recategorizeImg.bind(this)}>SP</button>
                                        <button className="arythmia-btn pa-btn" value='pa' onClick={this.recategorizeImg.bind(this)}>PA</button>
                                        <button className="arythmia-btn irr-btn" value='irr' onClick={this.recategorizeImg.bind(this)}>IRR</button>
                                        <button className="arythmia-btn ipr-btn" value='ipr' onClick={this.recategorizeImg.bind(this)}>IPR</button>
                                        <button className="arythmia-btn sqt-btn" value='sqt' onClick={this.recategorizeImg.bind(this)}>SQT</button>
                                        <button className="arythmia-btn lqt-btn" value='lqt' onClick={this.recategorizeImg.bind(this)}>LQT</button>
                                        <button className="arythmia-btn un-btn" value='un' onClick={this.recategorizeImg.bind(this)}>UN</button>                                               
                                      </div>              
                                    </div>
                                    <div className="gallery-wrap">
                                      <div className="portfolio-wrap project-gallery">
                                        <div id="portfolio" className="row portf auto-construct  project-gallery">
                                        {
                                          Object.keys(this.state.images).map((val,key) => {
                                            return <React.Fragment key={key}>
                                            { 
                                              this.state.images[val].map((value,key)=>{
                                                var tempvalue = this.splitFn(value);
                                                return  <div key={key} onDoubleClick={this.updateState} id={tempvalue[1]} className={"col-sm-2 item tall "+val}   data-src="dist/img/gallery/mock1.jpg" data-sub-html="" > 
                                                          <p>{tempvalue[0]}</p>
                                                          <input type="checkbox" id={value} value={val+"|"+value} className={val} name="test"/>
                                                          <label htmlFor={value}>
                                                            <img className="img-responsive" src={"http://localhost:5000/assets/dist/user_records/"+userid+"/"+val+"/cropped_image/"+this.state.dblink+"/"+value}  alt="Image description" />
                                                          </label>
                                                        </div>;
                                              })
                                            }
                                            </React.Fragment>
                                          })
                                        }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>
                        <div  id="chart_beats" className="tab-pane fade" role="tabpanel">
                          <div className="beat" id="beat">
                            <div className="panel-body">
                              <div className="row">
                                  <div className="col-sm-6">
                                    <h2 className="panel-title txt-dark rechead">Cardiac Event Detected</h2><br/>
                                    <button className="arythmia-btn nsr-btn" value='Normal Sinus Rhythm' onClick={this.colorGraph.bind(this)}>NSR</button>
                                    <button className="arythmia-btn st-btn" value='Sinus Tachycardia' onClick={this.colorGraph.bind(this)}>ST</button>
                                    <button className="arythmia-btn sb-btn" value='Sinus Bradycardia' onClick={this.colorGraph.bind(this)}>SB</button>
                                    <button className="arythmia-btn pac-btn" value='PAC' onClick={this.colorGraph.bind(this)}>PAC</button>
                                    <button className="arythmia-btn pvc-btn" value='PVC' onClick={this.colorGraph.bind(this)}>PVC</button>
                                    <button className="arythmia-btn vt-btn" value='Ventricular Tachycardia' onClick={this.colorGraph.bind(this)}>VT</button>
                                    <button className="arythmia-btn af-btn" value='AFib' onClick={this.colorGraph.bind(this)}>AF</button>
                                    <button className="arythmia-btn sp-btn" value='Sinus Pause' onClick={this.colorGraph.bind(this)}>SP</button>
                                    <button className="arythmia-btn pa-btn" value='P Absent' onClick={this.colorGraph.bind(this)}>PA</button>
                                    <button className="arythmia-btn irr-btn" value='Irregular RR' onClick={this.colorGraph.bind(this)}>IRR</button>
                                    <button className="arythmia-btn ipr-btn" value='Irregular PR' onClick={this.colorGraph.bind(this)}>IPR</button>
                                    <button className="arythmia-btn sqt-btn" value='Short QT' onClick={this.colorGraph.bind(this)}>SQT</button>
                                    <button className="arythmia-btn lqt-btn" value='Prolonged QT' onClick={this.colorGraph.bind(this)}>LQT</button>
                                    <button className="arythmia-btn un-btn" value='Undiagnosed' onClick={this.colorGraph.bind(this)}>UN</button>
                                    <button className="arythmia-btn" onClick={this.resetGraph.bind(this)}>Reset</button>
                                  </div>
                                  <div className="col-sm-6 text-right">
                                    <h2 className="panel-title txt-dark rechead">Recategorize To</h2><br/>
                                    <button className="arythmia-btn" value='Normal Sinus Rhythm' onClick={this.recategorize.bind(this)}>NSR</button>
                                    <button className="arythmia-btn" value='Sinus Tachycardia' onClick={this.recategorize.bind(this)}>ST</button>
                                    <button className="arythmia-btn" value='Sinus Bradycardia' onClick={this.recategorize.bind(this)}>SB</button>
                                    <button className="arythmia-btn" value='PAC' onClick={this.recategorize.bind(this)}>PAC</button>
                                    <button className="arythmia-btn" value='PVC' onClick={this.recategorize.bind(this)}>PVC</button>
                                    <button className="arythmia-btn" value='Ventricular Tachycardia' onClick={this.recategorize.bind(this)}>VT</button>
                                    <button className="arythmia-btn" value='AFib' onClick={this.recategorize.bind(this)}>AF</button>
                                    <button className="arythmia-btn" value='Sinus Pause' onClick={this.recategorize.bind(this)}>SP</button>
                                    <button className="arythmia-btn" value='P Absent' onClick={this.recategorize.bind(this)}>PA</button>
                                    <button className="arythmia-btn" value='Irregular RR' onClick={this.recategorize.bind(this)}>IRR</button>
                                    <button className="arythmia-btn" value='Irregular PR' onClick={this.recategorize.bind(this)}>IPR</button>
                                    <button className="arythmia-btn" value='Short QT' onClick={this.recategorize.bind(this)}>SQT</button>
                                    <button className="arythmia-btn" value='Prolonged QT' onClick={this.recategorize.bind(this)}>LQT</button>
                                    <button className="arythmia-btn" value='Undiagnosed' onClick={this.recategorize.bind(this)}>UN</button>
                                  </div>
                              </div>
                              <div id="tt_container"></div>
                              <div className="text-right">
                                <select id="gainValue" onChange={this.gainBtn.bind(this)}>
                                  <option value="1">1X</option>
                                  <option value="2">2X</option>
                                  <option value="3">3X</option>
                                </select> 
                              </div>
                              <br/>
                              <input type="hidden" name="beatSlected" id="beatSlected" value="[]"/>
                              <div id="sel" className="sel"></div>
                              <input type="hidden" name="selectedChart" id="selectedChart" value={this.state.selectedChart}/>          
                            </div>
                          </div>  
                        </div>
                        <div  id="chart_summary" className="tab-pane fade" role="tabpanel">
                          <SummaryTab userid={userid} rpeaks={this.state.rpeaks}/>
                        </div>
                        <div  id="chart_rate" className="tab-pane fade" role="tabpanel">
                          <HeartRateTab userid={userid}/>
                        </div>
                        <div  id="chart_settings" className="tab-pane fade" role="tabpanel">
                          <SettingsTab userid={userid}/>
                        </div>
                        <div  id="chart_findings" className="tab-pane fade" role="tabpanel">
                          <AddFindingsTab userid={userid} databaseid={databaseid}/>
                        </div>
                        <div  id="chart_regenerate" className="tab-pane fade" role="tabpanel">
                          <p>Regenerate</p>
                        </div>
                        <div  id="chart_report" className="tab-pane fade" role="tabpanel">
                          <p>Report</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>       
        </div>
      </div>
    </div>
    )
  }
}

export default Main;