import React, {Component} from 'react';

import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import apiUrl from '../../config/config';

class AddFindingsTab extends Component {

  constructor() {
    super();
    this.state = {
      findings:"",
      userid:''
    };
     this.generateReport = this.generateReport.bind(this);
     this.handleChange=this.handleChange.bind(this);
  }

  onSubmit = e => {
    e.preventDefault();

    const settings = {
      userid:this.state.userid,
      findings:this.state.findings
    };

    //console.log('settings',settings);
    axios
      .post(apiUrl+'api/common/updateDoctorFindings',settings)
      .then(res => {
          
          this.generateReport();
          setTimeout(function() { window.location.reload(false); }, 5000);

      })
      .catch(err => {
          console.log(err); 
      });
  }  

  componentDidMount(){
    var userid = this.props.userid;
    this.setState({ userid: userid });
    axios
      .get(apiUrl+'api/common/getPatientFindings?userid='+userid)
      .then(res => {  
        if(res.data.findings){
          this.setState({
            findings:res.data.findings
          })
        }
      })
      .catch(err => {
        console.log(err);
      });
     
  }

handleChange(content, editor) {
 this.setState({
            findings:content
          })
}
  
  generateReport() {
        const userid = this.props.userid;
        //e.currentTarget.innerHTML = "PLease Wait...";
        //
        alert('Regenerating Report');
        axios

            .get('https://web.mybiocalculus.com/html/welcome/new/' + userid)
            .then(res => {
              
            })
            .catch(err => {

            });
    }
        
  render() {
    var userid = this.props.userid;
    var databaseid = this.props.databaseid.replace(/:/g, '_');
   
  
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-default card-view">
            <div className="panel-wrapper collapse in">
              <div className="panel-body">
                <p>Please Mention Findings</p>
                <br/>
                <form name="General_addfindings_form" onSubmit={this.onSubmit}>
                    <Editor
                     apiKey='i1o30ha8awwrlsokjkw3jt67edf65ste5q8pul2ktzlrfilf'
                      value={this.state.findings}
                      init={{
                        height: 500,
                        menubar: false,
                         branding: false
                      }}
                      onEditorChange={this.handleChange}
                    />
                  { this.props.reportStatus <=10 &&
                    <button type="submit" name="submit" className="btn btn-primary btn-form display-4 generalsetting">APPROVE</button>
                  }
                </form>


              </div>
            </div>
          </div>
        </div>
      </div> 
    )
  }
}

export default AddFindingsTab;