import React, {Component} from 'react';

class SideBar extends Component {


  	constructor(props) {
	    super(props);

	    this.state = {};
	}	

    componentDidMount(){
    
    }

  render() { 
    return ( 
    	<div className="fixed-sidebar-left">
            <ul className="nav navbar-nav side-nav nicescroll-bar">
                {
                    this.props.menus.map(function(menu,i) { 
                        return  <li key={i}>
                                    <a href={menu.url} data-toggle="collapse" data-target="#dashboard_dr">
                                        <div className="pull-left">
                                            <i className="zmdi zmdi-window-maximize mr-20"></i><span className="right-nav-text">{menu.title}</span>
                                        </div>
                                        <div className="pull-right"></div>
                                        <div className="clearfix"></div>
                                    </a>
                                </li>;
                    })
                }
            </ul>
        </div>
    )
  }
}



export default SideBar;