import React, {Component} from 'react';
import classnames from 'classnames';
import apiUrl from '../../config/config';
import axios from 'axios';


class SettingsTab extends Component {

  constructor() {
    super();
    this.state = {
      menus : [
        {},
      ],
      nor_minhr:"",
      nor_maxhr:"",
      qtcformula:"",
      qtcvalue_min:"",
      qtcvaluemax:"",
      qrswidth_min:"",
      qrswidth_max:"",
      qrsamp_min:"",
      qrsamp_max:"",
      stelevation:"",
      pwidth_min:"",
      pwidth_max:"",
      pamp_min:"",
      pamp_max:"",
      stdepression:"",
      twidth_min:"",
      twidth_max:"",
      tamp_min:"",
      tamp_max:"",
      printerval_min:"",
      printerval_max:"",
      tachyhr_lower:"",
      tachyhr_upper:"",
      bradyhr_lower:"",
      bradyhr_upper:"",
      pauselength:"",
      graph_gen:"",
      errors: {},
      graph_index:"",
      graph_info:true,
      graph_arr:"",
      userid:''
    };

  }

  onSubmit = e => {
    e.preventDefault();
    var val;
    if(this.state.graph_info=="true")
    {
      val=true;
      
    }
    else if(this.state.graph_info=="false")
    {
      val=false;
     
    }
    this.setState({graph_info: val});
    console.log('this.state.graph_info',typeof this.state.graph_info);
    const settings = {
      userid:this.state.userid,
      nor_minhr: this.state.nor_minhr,
      nor_maxhr: this.state.nor_maxhr,
      qtcformula: this.state.qtcformula,
      qtcvalue_min: this.state.qtcvalue_min,
      qtcvaluemax: this.state.qtcvaluemax,
      qrswidth_min: this.state.qrswidth_min,
      qrswidth_max: this.state.qrswidth_max,
      qrsamp_min: this.state.qrsamp_min,
      qrsamp_max: this.state.qrsamp_max,
      stelevation: this.state.stelevation,
      pwidth_min: this.state.pwidth_min,
      pwidth_max: this.state.pwidth_max,
      pamp_min: this.state.pamp_min,
      pamp_max: this.state.pamp_max,
      stdepression: this.state.stdepression,
      twidth_min: this.state.twidth_min,
      twidth_max: this.state.twidth_max,
      tamp_min: this.state.tamp_min,
      tamp_max: this.state.tamp_max,
      printerval_min: this.state.printerval_min,
      printerval_max: this.state.printerval_max,
      tachyhr_lower: this.state.nor_maxhr,
      tachyhr_upper: this.state.tachyhr_upper,
      bradyhr_lower: this.state.bradyhr_lower,
      bradyhr_upper: this.state.nor_minhr,
      pauselength: this.state.pauselength,
      graph_gen: this.state.graph_gen,
       graph_index: this.state.graph_index,
      graph_info:this.state.graph_info,
      graph_arr: this.state.graph_arr,
    };

    console.log("displaySettings",settings);
    axios
      .post(apiUrl+'api/patients/updateSettings',settings)
      .then(res => {
        
      })
      .catch(err => {
          console.log(err); 
      });
  }

  onChange = e => {
    var err = this.state.errors;
    delete err[e.target.name];
     

    if(e.target.name=='graph_info')
    {
    var val = e.target.value=='true'?true:false;
    
    this.setState({ [e.target.name]: val});
    
    console.log(typeof this.state.graph_info);
  }
  else
  {
    this.setState({ [e.target.name]: e.target.value });
  }
  }

  componentDidMount() {
    var userid = this.props.userid;
    this.setState({ userid: userid });
    axios
      .get(apiUrl+'api/patients/settings?userid='+userid)
      .then(res => {
        var data = res.data;
        this.setState(data);
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="panel-wrapper collapse in">
       <form name="General_setting_form" onSubmit={this.onSubmit}>
          <div className="row row-sm-offset">
             <br/>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >Normal Heart Rate Min</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.nor_minhr
                   })} 
                   name="nor_minhr" 
                   required="" 
                   id="nor_minhr" 
                   onChange={this.onChange}
                   value={this.state.nor_minhr} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >Normal Heart Rate Max</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.nor_maxhr
                   })} 
                   name="nor_maxhr" 
                   required="" 
                   id="nor_maxhr" 
                   onChange={this.onChange}
                   value={this.state.nor_maxhr} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >QTC Formula</label>
                   <select 
                   name="qtcformula" 
                   className={classnames('form-control',{
                   'errors' : errors.qtcformula
                   })} 
                   value={this.state.qtcformula}
                   onChange={this.onChange}
                   >
                   <option value="Bazett">Bazett </option>
                   <option value="Fridericia">Fridericia</option>
                   <option value="Framingham">Framingham</option>
                   <option value="Hodges">Hodges</option>
                   </select>
                </div>
             </div>
          </div>
          <div className="row row-sm-offset">
             <br/>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >QTC Value Min</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.qtcvalue_min
                   })} 
                   name="qtcvalue_min" 
                   required="" 
                   id="qtcvalue_min" 
                   onChange={this.onChange}
                   value={this.state.qtcvalue_min} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >QTC Value Max</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.qtcvaluemax
                   })} 
                   name="qtcvaluemax" 
                   required="" 
                   id="qtcvaluemax" 
                   onChange={this.onChange}
                   value={this.state.qtcvaluemax} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >QRS Width Min(ms) </label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.qrswidth_min
                   })} 
                   name="qrswidth_min" 
                   required="" 
                   id="qrswidth_min" 
                   onChange={this.onChange}
                   value={this.state.qrswidth_min} 
                   />
                </div>
             </div>
          </div>
          <div className="row row-sm-offset">
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >QRS Width Max (ms) </label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.qrswidth_max
                   })} 
                   name="qrswidth_max" 
                   required="" 
                   id="qrswidth_max" 
                   onChange={this.onChange}
                   value={this.state.qrswidth_max} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7">QRS Amplitude Min (mv)</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.qrsamp_min
                   })} 
                   name="qrsamp_min" 
                   required="" 
                   id="qrsamp_min" 
                   onChange={this.onChange}
                   value={this.state.qrsamp_min} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7">QRS Amplitude Max (mv)</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.qrsamp_max
                   })} 
                   name="qrsamp_max" 
                   required="" 
                   id="qrsamp_max" 
                   onChange={this.onChange}
                   value={this.state.qrsamp_max} 
                   />
                </div>
             </div>
          </div>
          <div className="row row-sm-offset">
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >ST Elevation (mm)</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.stelevation
                   })} 
                   name="stelevation" 
                   required="" 
                   id="stelevation" 
                   onChange={this.onChange}
                   value={this.state.stelevation} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >P Width Min (ms)</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.pwidth_min
                   })} 
                   name="pwidth_min" 
                   required="" 
                   id="pwidth_min" 
                   onChange={this.onChange}
                   value={this.state.pwidth_min} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7">P Width Max (ms)</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.pwidth_max
                   })} 
                   name="pwidth_max" 
                   required="" 
                   id="pwidth_max" 
                   onChange={this.onChange}
                   value={this.state.pwidth_max} 
                   />
                </div>
             </div>
          </div>
          <div className="row row-sm-offset">
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >P Amplitude Min (mv)</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.pamp_min
                   })} 
                   name="pamp_min" 
                   required="" 
                   id="pamp_min" 
                   onChange={this.onChange}
                   value={this.state.pamp_min} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >P Amplitude Max (mv)</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.pamp_max
                   })} 
                   name="pamp_max" 
                   required="" 
                   id="pamp_max" 
                   onChange={this.onChange}
                   value={this.state.pamp_max} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >T Width Min (ms) </label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.twidth_min
                   })} 
                   name="twidth_min" 
                   required="" 
                   id="twidth_min" 
                   onChange={this.onChange}
                   value={this.state.twidth_min} 
                   />
                </div>
             </div>
          </div>
          <div className="row row-sm-offset">
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7">T Width Max (ms)</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.twidth_max
                   })} 
                   name="twidth_max" 
                   required="" 
                   id="twidth_max" 
                   onChange={this.onChange}
                   value={this.state.twidth_max} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >T Amplitude Min  (mv)</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.tamp_min
                   })} 
                   name="tamp_min" 
                   required="" 
                   id="tamp_min" 
                   onChange={this.onChange}
                   value={this.state.tamp_min} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >T Amplitude Max (mv)</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.tamp_max
                   })} 
                   name="tamp_max" 
                   required="" 
                   id="tamp_max" 
                   onChange={this.onChange}
                   value={this.state.tamp_max} 
                   />
                </div>
             </div>
          </div>
          <div className="row row-sm-offset">
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >PR Interval  Min </label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.printerval_min
                   })} 
                   name="printerval_min" 
                   required="" 
                   id="printerval_min" 
                   onChange={this.onChange}
                   value={this.state.printerval_min} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >PR Interval  Max </label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.printerval_max
                   })} 
                   name="printerval_max" 
                   required="" 
                   id="printerval_max" 
                   onChange={this.onChange}
                   value={this.state.printerval_max} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7">Tachy Lower Limit </label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.nor_maxhr
                   })} 
                   name="nor_maxhr" 
                   required="" 
                   id="nor_maxhr" 
                   onChange={this.onChange}
                   value={this.state.nor_maxhr} 
                   />
                </div>
             </div>
          </div>
          <div className="row row-sm-offset">
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7">Tachy Upper Limit  </label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.tachyhr_upper
                   })} 
                   name="tachyhr_upper" 
                   required="" 
                   id="tachyhr_upper" 
                   onChange={this.onChange}
                   value={this.state.tachyhr_upper} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7">Brady Lower Limit </label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.bradyhr_lower
                   })} 
                   name="bradyhr_lower" 
                   required="" 
                   id="bradyhr_lower" 
                   onChange={this.onChange}
                   value={this.state.bradyhr_lower} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7">Brady Upper Limit  </label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.nor_minhr
                   })} 
                   name="nor_minhr" 
                   required="" 
                   id="nor_minhr2" 
                   onChange={this.onChange}
                   value={this.state.nor_minhr} 
                   />
                </div>
             </div>
          </div>
          <div className="row row-sm-offset">
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7">ST Depression (mm)</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.stdepression
                   })} 
                   name="stdepression" 
                   required="" 
                   id="stdepression" 
                   onChange={this.onChange}
                   value={this.state.stdepression} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7">Pause Length (s)  </label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.pauselength
                   })} 
                   name="pauselength" 
                   required="" 
                   id="pauselength" 
                   onChange={this.onChange}
                   value={this.state.pauselength} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7">Graphs To Be Generated(no:)  </label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.graph_gen
                   })} 
                   name="graph_gen" 
                   required="" 
                   id="graph_gen" 
                   onChange={this.onChange}
                   value={this.state.graph_gen} 
                   />
                </div>
             </div>
          </div>
          <div className="row row-sm-offset">
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="graph_set"  >Graph Settings</label>
                   </div>
                   </div>
                   </div>
           <div className="row row-sm-offset">
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >Graph Index</label>
                   <input 
                   type="text" 
                   className={classnames('form-control',{
                   'errors' : errors.graph_index
                   })} 
                   name="graph_index" 
                   required="" 
                   id="graph_index" 
                   onChange={this.onChange}
                   value={this.state.graph_index} 
                   />
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >Graph Info</label>
                   <select 
                   name="graph_info" 
                   className={classnames('form-control',{
                   'errors' : errors.graph_info
                   })} 
                   value={this.state.graph_info}
                   onChange={this.onChange}
                   >
                   <option value="true">True </option>
                   <option value="false">False</option>
                   
                   </select>
                </div>
             </div>
             <div className="col-md-4 multi-horizontal">
                <div className="form-group">
                   <label className="form-control-label mbr-fonts-style display-7" >Graph Arr</label>
                   <select 
                   name="graph_arr" 
                   className={classnames('form-control',{
                   'errors' : errors.graph_arr
                   })} 
                   value={this.state.graph_arr}
                   onChange={this.onChange}
                   >
                   <option value="vt">vt </option>
                   <option value="all">all</option>
                   <option value="sp">sp</option>
                   <option value="af">af</option>
                   <option value="bi_pvc">bi_pvc</option>
                   <option value="tri_pvc">tri_pvc</option>
                   <option value="run_pvc">run_pvc</option>
                   <option value="run_pac">run_pac</option>
                   <option value="triplet_pvc">triplet_pvc</option>
                   <option value="triplet_pac">triplet_pac</option>
                   <option value="couplet_pvc">couplet_pvc</option>
                   <option value="couplet_pac">couplet_pac</option>
                   <option value="pac">pac</option>
                   <option value="pvc">pvc</option>
                   <option value="bi_pac">bi_pac</option>
                   <option value="tri_pac">tri_pac</option>
                   
                   <option value="st">st</option>
                   
                   <option value="sb">sb</option>
                   <option value="min">min</option>
                   <option value="max">max</option>
                   <option value="at">at</option>
                   <option value="chb">chb</option>
                   <option value="1avb">1avb</option>
                   <option value="2avb">2avb</option>
                   </select>
                </div>
             </div>
             
          </div>
          <span className="input-group-btn">
          
            <button href="" type="submit" name="submit" className="btn btn-primary btn-form display-4 generalsetting">SUBMIT</button>
          
          </span>
       </form>
    </div>
    )
  }
}

export default SettingsTab;