import React, {Component} from 'react';
import classnames from 'classnames';
import axios from 'axios';
import apiUrl from '../../config/config';
import $ from "jquery";

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import SideBarGraph from '../verifier/SideBarVerifier';

require('highcharts-multicolor-series')(Highcharts);

class NewChart extends Component {

    constructor() {
      super();
      this.state = {
        menus : [
          {title:'Patient List',url:'/verifier-home'},
        ],
        profileLink : '/verifier-profile',
        treemenu:[],
        clickedPI:'',
        databaseid:'',
        currentTab : 'chart_tab_2',

        mainEcgArray: [],
        chart:[],
      };
    }

    setTab(e) {
      var currentTab = e.currentTarget.id;
      this.setState({currentTab:currentTab});
    }

    renderNewChart(userid,dbId,step) {
      var chart,beatArray = [];
      var charts = [];
      var colorArray,tempChart = [];
      var tempCont=0,pointValue,datasetMnt = 0;
      var peakColorArray= [];
          peakColorArray['Undiagnosed'] = '#FCDF85';
          peakColorArray['Normal Sinus Rhythm'] = '#98FB98';
          peakColorArray['Sinus Tachycardia'] = '#3EFFDE';
          peakColorArray['PAC'] = '#4950EB';
          peakColorArray['PVC'] = '#FF45DC';
          peakColorArray['P absent'] = '#AEB9BD';
          peakColorArray['Sinus Bradycardia'] = '#FFD14F';
          peakColorArray['Sinus Pause'] = '#FF5B05';
          peakColorArray['Irregular RR'] = '#A4C3FF';
          peakColorArray['Ventricular Tachycardia'] = '#F0A7A1';
          peakColorArray['Irregular PR'] = '#BBEB8F';
          peakColorArray['Short QT'] = '#CFFF0F';
          peakColorArray['Prolonged QT'] = '#7C2F09';
          peakColorArray['AFib'] = '#A96BFF';

      var mainEcgArray,mainActivityArray = [];
          
      function chunkPeakArray(peakArray){
        var minIndex = 0;
        var breakVal = 15000;
        var z;
        var temRpeakindex
        beatArray.push( [] );
        for (var i = 0; i < peakArray.length; i++) {
          if (peakArray[i].rpeakindex > breakVal) {
            beatArray.push( [] );
            minIndex = minIndex+1;
            breakVal = breakVal+15000;
          }
          temRpeakindex = parseInt(peakArray[i].rpeakindex)-(minIndex*15000);
          beatArray[minIndex].push({ x: temRpeakindex, y: parseFloat(0.8), color:peakColorArray[peakArray[i].arrhythmia], beat: parseInt(peakArray[i].indexcounter), hr: parseInt(peakArray[i].heartrate), rr: parseInt(peakArray[i].rr_interval),arrhythmia:peakArray[i].arrhythmia,peaktime:peakArray[i].peaktime });
        }
        return true;
      }

      function renderSmallCharts() { 
          var i = 0;

          var t = 0;
          var val;

          var currentDataSet = [];

          var myVar = setInterval(function() {

              var sliceStartI = i * 15000;
              var sliceEndI = sliceStartI + 15001;

              currentDataSet = mainEcgArray.slice(sliceStartI, sliceEndI);

              var newDiv = document.createElement('div');
              newDiv.id = 'container' + i;
              newDiv.style = 'min-width: 200px; height: 60px; margin: 0 auto';
              var newSpan = document.createElement('div');
              newSpan.id = 'span' + i;
              newSpan.style = 'color:#000;';
              var time1 = document.createTextNode(beatArray[i][0].peaktime);
              //var time1 = document.createTextNode('12:12:12');
              if (i != 0)
                  newSpan.appendChild(time1);
              document.getElementById('sel').appendChild(newSpan);
              document.getElementById('sel').appendChild(newDiv);

              var arrayIndex = 0;
              var k = 0;

              charts[i] = new Highcharts.Chart('container' + i, {
                  credits: {
                      enabled: false
                  },
                  chart: {
                      height: 5 + '%',
                      resetZoomButton: {
                          theme: {
                              display: 'none'
                          },
                      },
                      events: {
                          click(event) {
                              var containerId = this.renderTo.id;
                              var currentIndex = containerId.replace('container', '');
                              var prevChart = document.getElementById("selectedChart").value;
                              var beats = JSON.parse(document.getElementById("beatSlected").value);
                              console.log(currentIndex + " " + prevChart)
                              if (currentIndex == prevChart) {
                                  val = charts[currentIndex].xAxis[0].toValue(event.chartX);
                                  if (val - 500 <= 0) {
                                      chart.xAxis[0].setExtremes(0, val + 1000, true, false);
                                  } else {
                                      chart.xAxis[0].setExtremes(val - 500, val + 500);
                                  }
                              } else {
                                  if (beats.length > 0 && currentIndex != prevChart) {
                                      for (var i = 0; i < beats.length; i++) {
                                          var xBeat = parseInt(beats[i]) - (15000 * prevChart)
                                          chart.xAxis[0].removePlotBand('plotband-' + xBeat);
                                      }
                                      document.getElementById("beatSlected").value = '[]';
                                  }

                                  val = charts[currentIndex].xAxis[0].toValue(event.chartX);

                                  var clickIndex;
                                  clickIndex = currentIndex;

                                  var sliceStart = clickIndex * 15000;
                                  var sliceEnd = sliceStart + 15001;
                                  var sliceActStart = clickIndex * 60;
                                  var sliceActEnd = sliceActStart + 61;
                                  var ecgArray = mainEcgArray.slice(sliceStart, sliceEnd);
                                  var curActArray = mainActivityArray.slice(sliceActStart, sliceActEnd);
                                  var acti = 125;
                                  var actarr = [];
                                  var tttarr1 = [];

                                  if (val > ecgArray.length) {
                                      val = ecgArray.length - 500;
                                  }

                                  for (var i = 0; i < curActArray.length; i++) {
                                      actarr.push({
                                          x: acti,
                                          y: 0.5,
                                          color: colorArray[mainActivityArray.slice(sliceActStart, sliceActEnd)[i]]
                                      });
                                      acti = acti + 250;
                                  }

                                  chart.series[0].setData(ecgArray, true);
                                  chart.series[1].setData(actarr, true);
                                  chart.series[2].setData(beatArray[clickIndex], true);
                                  
                                  tempChart = charts[document.getElementById("selectedChart").value];
                                  if (tempChart != undefined) {
                                      if (tempChart.draggablePlotLine != undefined) {
                                          tempChart.draggablePlotLine.destroy();
                                      }
                                  }
                                  var chart1 = this
                                  tempCont = currentIndex;
                                  document.getElementById("selectedChart").value = tempCont;
                                  var lineWidth = 2

                                  chart1.draggablePlotLine = chart1.renderer.rect(event.chartX - charts[currentIndex].xAxis[0].toPixels(25), 10, 50, 40, 5)
                                      .attr({
                                          'stroke-width': 2,
                                          opacity: 0.5,
                                          fill: 'grey',
                                          zIndex: 3
                                      })
                                      .add();

                                  if (charts[0].container.onmousemove != undefined) {
                                      charts[0].container.onmousemove = function(e) {
                                          return false;
                                      }
                                  }

                                  charts[currentIndex].container.onmousemove = function(e) {
                                      if (chart1.draggablePlotLine.drag) {

                                          let normalizedEvent = chart1.pointer.normalize(e),
                                              extremes = {
                                                  left: chart1.plotLeft,
                                                  right: chart1.plotLeft + chart1.plotWidth
                                              };

                                          if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                                              pointValue = chart1.xAxis[0].toValue(e.chartX);
                                              if (pointValue - 500 <= 0) {
                                                  chart.xAxis[0].setExtremes(0, pointValue + 1000, true, false);
                                              } else {
                                                  chart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                                              }
                                              chart1.draggablePlotLine.attr({
                                                  x: e.chartX
                                              })
                                          }
                                      }
                                  }

                                  charts[currentIndex].draggablePlotLine.element.onmousedown = function() {
                                      chart1.draggablePlotLine.drag = true;
                                  }

                                  charts[currentIndex].draggablePlotLine.element.onmouseup = function() {
                                      chart1.draggablePlotLine.drag = false;
                                  }
                                  if (val - 500 <= 0) {
                                      chart.xAxis[0].setExtremes(0, val + 1000, true, false);
                                  } else {
                                      chart.xAxis[0].setExtremes(val - 500, val + 500);
                                  }
                              }
                          },
                      },
                      zoomType: '',
                  },

                  navigator: {
                      enabled: false
                  },

                  xAxis: [{
                      min: 0,
                      max: 15000,
                      lineWidth: 0,
                      minorGridLineWidth: 0,
                      lineColor: 'transparent',
                      scrollbar: {
                          enabled: true,
                          showFull: false
                      },
                      labels: {
                          enabled: false
                      },
                      minorTickLength: 0,
                      tickLength: 0,
                      visible: false

                  }],
                  yAxis: [{
                      min: -0.002,
                      max: 0.002,
                      gridLineWidth: 0,
                      minorGridLineWidth: 0,
                      minorTicks: false,
                      labels: {
                          enabled: false
                      },
                      title: {
                          enabled: false
                      },
                  }],
                  rangeSelector: {
                      enabled: false
                  },
                  plotOptions: {
                      series: {
                          turboThreshold: 15000,
                      },
                  },
                  title: {
                      text: ''
                  },

                  series: [{
                      type: 'coloredline',
                      data: currentDataSet,
                      color: 'black',
                      allowPointSelect: false,
                      animation: false,
                      enableMouseTracking: false,
                      lineWidth: 1,
                      marker: {
                          enabled: false
                      },
                      shadow: false,
                      stickyTracking: false,
                      showInLegend: false
                  }]
              });

              if (i == 0) {
                  charts[0].draggablePlotLine = charts[0].renderer.rect(charts[0].xAxis[0].toPixels(50) - charts[0].xAxis[0].toPixels(25), 10, 50, 40, 5)
                      .attr({
                          'stroke-width': 2,
                          opacity: 0.5,
                          fill: 'grey',
                          zIndex: 3
                      })
                      .add();

                  if (charts[0].container.onmousemove != undefined) {
                      charts[0].container.onmousemove = function(e) {
                          return false;
                      }
                  }
                  var stripIndex = 0;
                  charts[stripIndex].container.onmousemove = function(e) {
                      if (charts[stripIndex].draggablePlotLine.drag) {

                          let normalizedEvent = charts[stripIndex].pointer.normalize(e),
                              extremes = {
                                  left: charts[stripIndex].plotLeft,
                                  right: charts[stripIndex].plotLeft + charts[stripIndex].plotWidth
                              };

                          if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                              var pointValue = charts[stripIndex].xAxis[0].toValue(e.chartX);
                              if (pointValue - 500 <= 0) {
                                  chart.xAxis[0].setExtremes(0, pointValue + 1000, true, false);
                              } else {
                                  chart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                              }
                              charts[stripIndex].draggablePlotLine.attr({
                                  x: e.chartX
                              })
                          }
                      }
                  }
                  charts[stripIndex].draggablePlotLine.element.onmousedown = function() {
                      charts[stripIndex].draggablePlotLine.drag = true;
                  }

                  charts[stripIndex].draggablePlotLine.element.onmouseup = function() {
                      charts[stripIndex].draggablePlotLine.drag = false;
                  }
              }
              i = i + 1;
              if (i > 4) {
                  clearInterval(myVar);
              }
          }, 5);
      }

      function renderMainChart() {
        // Create the chart
        chart = new Highcharts.StockChart('tt_container', {
            exporting: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            chart: {

                type: 'coloredline',
                height: 40 + '%',

                resetZoomButton: {
                    theme: {
                        display: 'none'
                    },

                },
                events: {},
                zoomType: '',
            },

            navigator: {
                enabled: false
            },

            xAxis: [{
                min: 0, //setting min to 0
                max: 1000,
                events: {},
                scrollbar: {
                    enabled: true
                },
                labels: {
                    enabled: false
                },

                tickInterval: 50,
                minorTicks: true,
                minorTickInterval: 10,
                gridLineWidth: 1,
                gridLineColor: '#D3D3D3'

            }],
            yAxis: [{
                    min: 0,
                    max: 10,
                    reversed: true,
                    gridLineWidth: 0,
                    minorGridLineWidth: 0,
                    minorTicks: false,

                    labels: {
                        enabled: true
                    },
                    title: {
                        enabled: false
                    },
                    visible: false

                },
                {
                    min: -0.002,
                    max: 0.002,
                    tickPositions: [-0.0020, -0.0015, -0.0010, -0.0005, 0.0000, 0.0005, 0.0010, 0.0015, 0.0020],
                    tickInterval: 0.0005,
                    gridLineWidth: 3,
                    minorGridLineWidth: 1,
                    minorTicks: true,
                    minorTickInterval: 0.0001,

                    gridLineWidth: 2,
                    gridLineColor: '#D3D3D3',
                    labels: {
                        enabled: false
                    },
                    title: {
                        enabled: false
                    },

                },
                {
                    min: 0,
                    max: 10,
                    gridLineWidth: 0,
                    minorGridLineWidth: 0,
                    minorTicks: false,

                    labels: {
                        enabled: true
                    },
                    title: {
                        enabled: false
                    },
                    visible: false

                }
            ],
            rangeSelector: {
                enabled: false
            },
            plotOptions: {
                series: {
                    turboThreshold: 15500,
                    colorByPoint: false
                },

            },

            title: {
                text: ''
            },

            series: [{


                data: [],
                yAxis: 1,
                xAxis: 0,
                color: 'black',
                states: {
                    inactive: {
                        opacity: 1
                    }
                },

                allowPointSelect: false,
                animation: false,
                enableMouseTracking: false,
                lineWidth: 1,
                marker: {
                    enabled: false
                },
                shadow: false,
                stickyTracking: false,
                showInLegend: false


            }]
        });

        chart.showLoading(' Please wait while the ECG is being loaded  <i class="fa fa-spin fa-spinner"></i>');
        chart.addSeries({

            yAxis: 0,

            type: 'column',

            data: [],
            pointWidth: 400,
            inverted: true,
            tooltip: {
                enabled: false
            },
            states: {

                inactive: {
                    opacity: 1
                }
            },

            allowPointSelect: false,
            animation: false,
            enableMouseTracking: false,
            lineWidth: 1,

            shadow: false,
            stickyTracking: false,
            showInLegend: false
        }, true);
        chart.addSeries({
            dataLabels: [{
                enabled: true,
                inside: true,
                align: 'left',
                format: 'Beat:{point.beat}; HR:{point.hr}; RR:{point.rr}'
            }],
            yAxis: 2,
            type: 'column',
            turboThreshold: 10000,
            data: [],
            pointWidth: 250,
            tooltip: {
                enabled: false
            },
            point: {
                events: {
                    click: function() {

                    },


                }
            },
            states: {

                inactive: {
                    opacity: 1
                }
            },
            allowPointSelect: false,
            animation: false,
            enableMouseTracking: false,
            marker: {
                enabled: false
            },

            showInLegend: false
        }, true);
        chart.addSeries({
            name: 'P Peak',
            yAxis: 1,
            data: [],
            lineWidth: 0,
            marker: {
                enabled: true,
                radius: 4,
                symbol: 'triangle'
            },
            tooltip: {
                enabled: false
            },
            states: {
                hover: {
                    lineWidthPlus: 0
                },
                inactive: {
                    opacity: 1
                }
            },
            enableMouseTracking: false,
        }, true);
        chart.addSeries({
            name: 'Q Peak',
            yAxis: 1,
            data: [],

            lineWidth: 0,
            marker: {
                enabled: true,
                radius: 4,

                symbol: 'circle'
            },

            tooltip: {

                enabled: false

            },
            states: {
                hover: {
                    lineWidthPlus: 0

                },
                inactive: {
                    opacity: 1
                }
            },
            enableMouseTracking: false,
        }, true);
        chart.addSeries({
            name: 'R Peak',
            yAxis: 1,
            data: [],

            lineWidth: 0,
            marker: {
                enabled: true,
                radius: 4,

                symbol: 'circle'
            },

            tooltip: {

                enabled: false

            },
            states: {
                hover: {
                    lineWidthPlus: 0

                },
                inactive: {
                    opacity: 1
                }
            },
            enableMouseTracking: false,
        }, true);
        chart.addSeries({
            name: 'S Peak',
            yAxis: 1,
            data: [],

            lineWidth: 0,
            marker: {
                enabled: true,
                radius: 4,

                symbol: 'circle'
            },

            tooltip: {

                enabled: false

            },
            states: {
                hover: {
                    lineWidthPlus: 0

                },
                inactive: {
                    opacity: 1
                }
            },
            enableMouseTracking: false,
        }, true);
        chart.addSeries({
            name: 'T Peak',
            yAxis: 1,
            data: [],

            lineWidth: 0,
            marker: {
                enabled: true,
                radius: 4,

                symbol: 'circle'
            },

            tooltip: {

                enabled: false

            },
            states: {
                hover: {
                    lineWidthPlus: 0

                },
                inactive: {
                    opacity: 1
                }
            },
            enableMouseTracking: false,
        }, true);
        chart.addSeries({
            name: 'P On Peak',
            yAxis: 1,
            data: [],

            lineWidth: 0,
            marker: {
                enabled: true,
                radius: 4,

                symbol: 'circle'
            },

            tooltip: {

                enabled: false

            },
            states: {
                hover: {
                    lineWidthPlus: 0

                },
                inactive: {
                    opacity: 1
                }
            },
            enableMouseTracking: false,
        }, true);
        chart.addSeries({
            name: 'P Off Peak',
            yAxis: 1,
            data: [],

            lineWidth: 0,
            marker: {
                enabled: true,
                radius: 4,

                symbol: 'circle'
            },

            tooltip: {

                enabled: false

            },
            states: {
                hover: {
                    lineWidthPlus: 0

                },
                inactive: {
                    opacity: 1
                }
            },
            enableMouseTracking: false,
        }, true);
        chart.addSeries({
            name: 'T On Peak',
            yAxis: 1,
            data: [],

            lineWidth: 0,
            marker: {
                enabled: true,
                radius: 4,

                symbol: 'circle'
            },

            tooltip: {

                enabled: false

            },
            states: {
                hover: {
                    lineWidthPlus: 0

                },
                inactive: {
                    opacity: 1
                }
            },
            enableMouseTracking: false,
        }, true);
        chart.addSeries({
            name: 'T Off Peak',
            yAxis: 1,
            data: [],

            lineWidth: 0,
            marker: {
                enabled: true,
                radius: 4,

                symbol: 'circle'
            },

            tooltip: {

                enabled: false

            },
            states: {
                hover: {
                    lineWidthPlus: 0

                },
                inactive: {
                    opacity: 1
                }
            },
            enableMouseTracking: false,
        }, true);
        chart.addSeries({
            name: 'J Peak',
            yAxis: 1,
            data: [],

            lineWidth: 0,
            marker: {
                enabled: true,
                radius: 4,

                symbol: 'circle'
            },

            tooltip: {

                enabled: false

            },
            states: {
                hover: {
                    lineWidthPlus: 0

                },
                inactive: {
                    opacity: 1
                }
            },
            enableMouseTracking: false,
        }, true);
        chart.addSeries({
            name: 'Place Holder',
            yAxis: 1,
            data: [],

            lineWidth: 0,
            marker: {
                enabled: true,
                radius: 4,

                symbol: 'circle'
            },

            tooltip: {

                enabled: false

            },
            states: {
                hover: {
                    lineWidthPlus: 0

                },
                inactive: {
                    opacity: 1
                }
            },
            enableMouseTracking: false,
        }, true);
        return true;
      }

      var userid = userid;
      var databaseid = dbId;
      renderMainChart();
      axios
        .get(apiUrl+'api/common/getEcgData?userid='+userid+'&databaseid='+databaseid)
        .then(res => {
          // this.setState({
          //   mainEcgArray: res.data[0]
          // });
          // this.setState({
          //   mainActivityArray: res.data[1]
          // });
          if(res.data[0].length%15000>0){
            datasetMnt = Math.floor(res.data[0].length/15000) + 1;
          } else {
            datasetMnt = Math.floor(res.data[0].length/15000);
          }

          mainEcgArray = res.data[0];
          mainActivityArray = res.data[1];

          chart.hideLoading();
          chart.series[0].setData(res.data[0].slice(0, 15001),true);

          colorArray = ['#98FB98','#f1e4e4','#f7e0a3','#f09c67']
          var curDataArray = res.data[1].slice(0, 61);
          var actarr = [];
          var acti= 125;
          for (var i = 0; i < curDataArray.length; i++) {
            actarr.push({ x: acti, y: 0.5, color: colorArray[curDataArray[i]] });
            acti = acti+250;
          }
          curDataArray = [];

          var interval1= setInterval(function(){
            chart.series[1].setData(actarr,true);
            actarr = [];
            clearInterval(interval1);
            interval1 = false;
          },100);
          chunkPeakArray(res.data[2]);
          console.log(beatArray);
          var interval2= setInterval(function(){
            if(!interval1){
              chart.series[2].setData(beatArray[0],true);
              clearInterval(interval2);
              interval2 = false;
            }
          },100);
          var intervalSchart= setInterval(function(){
            if(!interval2){
              renderSmallCharts();
              clearInterval(intervalSchart);
              intervalSchart = false;
            }
          },1000);

        })
        .catch(err => {
          console.log(err);
        });


    }

    componentDidMount() {      
      var userid = this.props.match.params.userid;
      var databaseid = this.props.match.params.databaseid;
      this.setState({
        databaseid:databaseid
      });
      this.renderNewChart(userid,databaseid,1);
    }

    render() {
      const userid = this.props.match.params.userid; 
      var databaseid = this.props.match.params.databaseid;
      const { chartOptions } = this.state;
      return (
      <div className="wrapper theme-1-active box-layout pimary-color-green">
        <input type="hidden" value={this.state.nextDbId} id="nextDbId" />
        <input type="hidden" value={this.state.prevDbId} id="prevDbId" />
        <SideBarGraph userid={userid} menus={this.state.menus} treemenu={this.state.treemenu} databaseid={databaseid} />
        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="panel panel-default card-view graphMainCont">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <div  className="pills-struct">
                        <ul role="tablist" className="nav nav-pills" id="myTabs_6">
                          <li role="presentation" className="active">
                            <a  data-toggle="tab" id="chart_tab_2" onClick={this.setTab.bind(this)} role="tab" href="#chart_beats" aria-expanded="false">Beats</a>
                          </li>        
                        </ul>
                        <div className="clearfix"></div>
                        <div className="tab-content" id="myTabContent_6">
                          <div  id="chart_beats" className="tab-pane fade active in" role="tabpanel">
                            <div className="beat" id="beat">
                              <div className="panel-body">
                                <div id="tt_container"></div>
                                <input type="hidden" name="beatSlected" id="beatSlected" value="[]"/>
                                <div id="sel" className="sel">
                                <div></div>
                                </div>
                                <input type="hidden" name="selectedChart" id="selectedChart" value={this.state.selectedChart}/>          
                              </div>
                            </div>  
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>       
          </div>
        </div>
      </div>
      )
    }
}

export default NewChart;