import isEmpty from '../validation/is-empty'

import { SET_CURRENT_USER } from '../actions/types';
import { UPDATE_PROFILE_IMAGE } from '../actions/types';

const initialState = {
	isAuthenticated: false,
	type:0,
	user: {},
	profileImage:''
}

export default function(state= initialState,action) {
	switch(action.type){
		case SET_CURRENT_USER:
			return {
				...state,
				isAuthenticated: !isEmpty(action.payload),
				user: action.payload,
				type:action.payload.type,
				profileImage:action.payload.profileImage,
			}
		case UPDATE_PROFILE_IMAGE:
			return {
				...state,
				profileImage:action.payload,
			}
		default:
			return state;
	}
}