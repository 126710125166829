import React, {Component} from 'react';
import axios from 'axios';
import apiUrl from '../../config/config';
import { MDBDataTable } from 'mdbreact';
import $ from "jquery";

import TopNav from '../../components/common/TopNav';
import SideBar from '../../components/common/SideBar';


class AdminHome extends Component {
  constructor() {
    super();
    this.state = {
        menus : [
            {title:'Doctors List',url:'/doctors-list'},
            {title:'Verifiers List',url:'/verifiers-list'},
            {title:'Patient List',url:'/admin-home'},
        ],
        profileLink : 'admin-profile',
        patients : [],
    };
  }

  componentWillReceiveProps(nextProps) { 
  	
  }

  componentDidMount(){
    axios
        .get(apiUrl+'api/patients/adminpatients')
        .then(res => {       
            this.setState({
              patients: res.data
            });
        })
        .catch(err => {
        });

    $( document ).ready(function() {
        $("#mbdTable").on('click', '.activatePatient', function () {
            var month = $( this ).parent().parent().parent().find(".subscriptionMonth").val();
            var day = $( this ).parent().parent().parent().find(".subscriptionDay").val();
            var patientId = $( this ).parent().find(".patientHId").val();
            if(month>0 || day >0) {
                const data = {
                    month : month,
                    day : day,
                    patientId : patientId
                }
                axios
                .post(apiUrl+'api/patients/activatePatient',data)
                .then(res => {       
                    var fdate = res.data.split('T');
                    var splitDate = fdate[0].split("-");
                    var subscription_ends = splitDate[2]+"-"+(splitDate[1])+"-"+splitDate[0]
                    $( this ).text('Activated');
                    $( this ).removeClass("activatePatient");
                    $( this ).removeClass("btn-danger");
                    $( this ).addClass("btn-success");
                    $( this ).parent().parent().parent().find(".subPlanContainer").html("Expires on "+subscription_ends);
                })
                .catch(err => {
                });
            }
        });
    });
  }


  render() {

    const data = {
      columns: [
        {
          label: 'SL NO',
          field: 'slno',
          sort: 'asc',
          width: 50
        },
        {
          label: 'PATIENT ID',
          field: 'patientid',
          sort: 'asc',
          width: 100
        },
        {
          label: 'NAME',
          field: 'name',
          sort: 'asc',
          width: 100
        },
        {
          label: 'DAYS',
          field: 'days',
          sort: 'disabled',
          width: 150
        },
        {
          label: 'SUBSCRIBE',
          field: 'subscribe',
          sort: 'disabled',
          width: 100
        }
      ],
      rows: []
    };

    var cdate = new Date();
    this.state.patients.map(function(patient,i) {
        var days;
        var subscribe;

        if (cdate <  new Date(patient.subscription_ends)) {
            var fdate = patient.subscription_ends.split('T');
            var splitDate = fdate[0].split("-");
            var subscription_ends = splitDate[2]+"-"+(splitDate[1])+"-"+splitDate[0]
            days = "Expires on "+subscription_ends;
            subscribe = <center> 
                            <button type="button" className="btn btn-success">Activated</button>
                        </center>;
        } else {
            days = <div className="subPlanContainer">
                        <select id="" className="form-control subscriptionMonth">
                            <option value="0">0 Month</option>
                            <option value="1">1 Month</option>
                            <option value="2">2 Months</option>
                            <option value="3">3 Months</option>
                            <option value="4">4 Months</option>
                            <option value="5">5 Months</option>
                            <option value="6">6 Months</option>
                            <option value="7">7 Months</option>
                            <option value="8">8 Months</option>
                            <option value="9">9 Months</option>
                            <option value="10">10 Months</option>
                            <option value="11">11 Months</option>
                            <option value="12">12 Months</option>
                        </select>
                        <select id="days_0" className="form-control subscriptionDay">
                            <option value="0">Days</option>
                            <option value="1">1 Day</option>
                            <option value="2">2 Days</option>
                            <option value="3">3 Days</option>
                            <option value="4">4 Days</option>
                            <option value="5">5 Days</option>
                            <option value="6">6 Days</option>
                            <option value="7">7 Days</option>
                            <option value="8">8 Days</option>
                            <option value="9">9 Days</option>
                            <option value="10">10 Days</option>
                            <option value="11">11 Days</option>
                            <option value="12">12 Days</option>
                            <option value="13">13 Days</option>
                            <option value="14">14 Days</option>
                            <option value="15">15 Days</option>
                            <option value="16">16 Days</option>
                            <option value="17">17 Days</option>
                            <option value="18">18 Days</option>
                            <option value="19">19 Days</option>
                            <option value="20">20 Days</option>
                            <option value="21">21 Days</option>
                            <option value="22">22 Days</option>
                            <option value="23">23 Days</option>
                            <option value="24">24 Days</option>
                            <option value="25">25 Days</option>
                            <option value="26">26 Days</option>
                            <option value="27">27 Days</option>
                            <option value="28">28 Days</option>
                            <option value="29">29 Days</option>
                            <option value="30">30 Days</option>
                        </select>
                    </div>;
                subscribe = <center> 
                                <input type="hidden" value={patient._id} className="patientHId" />
                                <button type="button" className="btn btn-danger activatePatient">Activate</button>
                            </center>;
        }
        
        data.rows.push({
            slno: i+1,
            patientid:patient.device_id,
            name:patient.name,
            days: days ,
            subscribe:  subscribe
        });
    })

    return (
        <div className="wrapper theme-1-active box-layout pimary-color-green">
        <TopNav profileLink={this.state.profileLink}/>
        <SideBar menus={this.state.menus}/>
        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper">
            <div className="container-fluid">
                <div className="row heading-bg">
                    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                      <h5 className="txt-dark">Patient List</h5>
                    </div>
                    <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                      <ol className="breadcrumb">
                        <li><a href="index.html">Home </a></li>
                        <li><a href="#"><span>Patients</span></a></li>
                      </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-default card-view">
                            <div className="panel-heading">
                                <div className="pull-left">
                                    <h6 className="panel-title txt-dark">Patient List</h6>
                                </div>
                                <div className="clearfix">
                                    <a href="/add-patient">
                                        <button type="button" className="btn btn-success btn-txt-blue"><i className="fa fa-plus"></i> Add patient</button>
                                    </a>    
                                </div>
                            </div>
                            <div className="panel-wrapper collapse in">
                                <div className="panel-body">
                                    <MDBDataTable
                                      noBottomColumns
                                      data={data}
                                      id="mbdTable"
                                    />
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}


export default AdminHome;