import React, {Component} from 'react';
import axios from 'axios';
import $ from "jquery";
import apiUrl from '../../config/config';

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./worker.js";
import { FormatAlignCenter } from '@material-ui/icons';
let worker12;
class VerifierSummary extends Component {

    constructor() {
    
  

       
       
        super();
        this.state = {
            menus : [
                {},
            ],
            nsr:'',
            st:'',
            sb:'',
            sp:'',
            pac:'',
            pvc:'',
            vt:'',
            afib:'',
            pab:'',
            irrr:'',
            irpr:'',
            sqt:'',
            lqt:'',
            un:'',
            nsr_per:'',
            st_per:'',
            sb_per:'',
            sp_per:'',
            pac_per:'',
            pvc_per:'',
            vt_per:'',
            afib_per:'',
            pab_per:'',
            irrr_per:'',
            irpr_per:'',
            sqt_per:'',
            lqt_per:'',
            un_per:'',

            nsrData:'',
            stData:'',
            sbData:'',
            spData:'',
            pacData:'',
            pvcData:'',
            vtData:'',
            afibData:'',
            pabData:'',
            irrrData:'',
            irprData:'',
            sqtData:'',
            lqtData:'',
            unData:'',

            nsrmin:'',
            stmin:'',
            sbmin:'',
            spmin:'',
            pacmin:'',
            pvcmin:'',
            vtmin:'',
            afibmin:'',
            pabmin:'',
            irrrmin:'',
            irprmin:'',
            sqtmin:'',
            lqtmin:'',
            unmin:'',
            nsrmax:'',
            stmax:'',
            sbmax:'',
            spmax:'',
            pacmax:'',
            pvcmax:'',
            vtmax:'',
            afibmax:'',
            pabmax:'',
            irrrmax:'',
            irprmax:'',
            sqtmax:'',
            lqtmax:'',
            unmax:'',
            cspac:'',
            ccpac:'',
            ctpac:'',
            cspvc:'',
            ccpvc:'',
            ctpvc:'',
            crunpac:'',
            cbigpac:'',
            ctrigpac:'',
            ctrigpvc:'',
            crunpvc:'',
            cbigpvc:'',
             nsrhrc:'',
            sthrc:'',
            sbhrc:'',
            sphrc:'',
            pachrc:'',
            pvchrc:'',
            vthrc:'',
            afibhrc:'',
           pvcCount:'',
              pvcCs:'',
              pvcCc:'',
              pvcCt:'',
              pvcCrun:'',
              pvcCmin:'',
              pvcCmax:'',
               pacCount:'',
              pacCs:'',
              pacCc:'',
              pacCt:'',
              pacCrun:'',
              pacCmin:'',
              pacCmax:'',
              pacCbi:'',
              pacCtri:'',
              pvcCbi:'',
              pvcCtri:'',

            nsrhrper:'',
            sthrper:'',
            sbhrper:'',
            sphrper:'',
            pachrper:'',
            pvchrper:'',
            vthrper:'',
            afibhrper:'',
            

           
              normal_min:'',
              normal_max:'',

              
              sinust_min:'',
              sinust_max:'',

               
              sinusb_min:'',
              sinusb_max:'',

              
              vt_min:'',
              vt_max:'',

              
              afib_min:'',
              afib_max:'',

               
              pac_min:'',
              pac_max:'',

              
              pvc_min:'',
              pvc_max:'',

               
              sinus_pause_min:'',
              sinus_pause_max:'',

            nsr_perData:'',
            st_perData:'',
            sb_perData:'',
            sp_perData:'',
            pac_perData:'',
            pvc_perData:'',
            vt_perData:'',
            afib_perData:'',
            pab_perData:'',
            irrr_perData:'',
            irpr_perData:'',
            sqt_perData:'',
            lqt_perData:'',
            un_perData:'',

            activity_mfe: '',
            activity_mfe_period: '',
            activity_smfe: '',
            activity_smfe_period: '',
            activity_tmfe: '',
            activity_tmfe_period: '',
            inactivity_mfe: '',
            inactivity_mfe_period: '',
            inactivity_smfe: '',
            inactivity_smfe_period: '',
            inactivity_tmfe: '',
            inactivity_tmfe_period: '',
            name:'',
            device_id:'',
            gender:'',
            age:'',
            weight:'',
            height:'',
            dob:'',
            diabetes:'',
            hyper_tension:'',
            hyper_lipidemia:'',
            thyroid:'',
            drug_history:'',
            smoking:'',
            cardiac_surgery:'',
            table:''
        };
        this.get_age=this.get_age.bind(this);

    }

  componentDidMount(){

    
    var userid = this.props.userid;
    var databaseid = this.props.databaseid;
    var normal_full_data=0, sinus_full_data=0, sinus_brady_full_data=0,sinus_pause_full_data=0,pac_full_data=0,pvc_full_data=0,vt_full_data=0,afib_full_data=0,pab_full_data=0,irrr_full_data=0,irpr_full_data=0,sqt_full_data=0,lqt_full_data=0,un_full_data=0;
    var nsr_per,st_per,sb_per,sp_per,pac_per,pvc_per,vt_per,afib_per,pab_per,irrr_per,irpr_per,sqt_per,lqt_per,un_per;



    var detailsThis=this;
   


    // axios
    //   .get(apiUrl+'api/patients/details?userid='+userid)
    //   .then(res => {

    //     var data = res.data;
    //     var fdate = data.dob.split('T');
    //     var now = new Date();
    //     var birthdate = fdate[0].split("-");
    //     var dob = birthdate[2]+"-"+(birthdate[1])+"-"+birthdate[0]
    //     var born = new Date(birthdate[0],(birthdate[1]-1),birthdate[2]);
    //     var age=get_age(born,now); 

    //     this.setState({
    //       name: data.name,
    //       device_id: data.device_id,
    //       weight: data.weight+" "+data.weight_unit,
    //       height: data.height,
    //       diabetes:data.diabetes,
    //       hyper_tension:data.hyper_tension==true ? 'Yes' : 'No',
    //       hyper_lipidemia:data.hyper_lipidemia==true ? 'Yes' : 'No',
    //       thyroid:data.thyroid,
    //       drug_history:data.drug_history,
    //       smoking:data.smoking==true ? 'Yes' : 'No',
    //       cardiac_surgery:data.cardiac_surgery==true ? 'Yes' : 'No',
    //       gender:data.gender=="M" ? 'Male' : 'Female',
    //       dob:dob,
    //       age:age
    //     });
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });

    
      // axios
      //   .get(apiUrl+'api/common/getPatientTotalDetails?userid='+userid)
      //   .then(res => {
          
      //   })
      //   .catch(err => {
      //       console.log(err);
      //   });

      var pausedata=[],pvcdata=[],seriesData=[],sdnndataArray=[]; 
      // axios
      //   .get(apiUrl+'api/common/getGraphplot?userid='+userid)
      //   .then(res => { 
         
      //     if(res.data!=null) {
      //       for (var i = 0; i < res.data.data.length; i++) {
              
      //          sdnndataArray = res.data.data[i].split(",");
      //          console.log(sdnndataArray);
      //           pausedata.push(parseInt(sdnndataArray[6]));
      //           pvcdata.push(parseInt(sdnndataArray[5]));
      //           seriesData.push(sdnndataArray[0]+'/'+sdnndataArray[1]);
      //       }
      //     }
      //     if(pvcdata.length>0){
      //       var pvcChart=Highcharts.chart('pvcChart', {
      //         chart: {
      //           type: 'column',
      //           height:'50%',
      //           width:'400',
      //           styledMode: false
      //         },
      //         exporting: { enabled: false },
      //         credits: {
      //           enabled: false
      //         },
      //         title: {
      //             text: ''
      //         },
      //         xAxis: {
      //           min:0,
      //           max:10,
      //           scrollbar: {
      //             enabled: true,     
      //           },
      //           gridLineWidth: 0,   
      //           allowDecimals:false,
      //           labels: {
      //               step: 1,
      //               tickInterval:1,
      //           style: {
      //             color: 'black',
      //                fontSize: '12px'
      //           }, 
      //            formatter: function() { return seriesData[this.value];},

      //           }
      //         },
      //         yAxis: {
      //           min: 0,
      //           title: {
      //             text: ''
      //           },
      //           visible: true,
      //           labels: {
      //             enabled: true,       
      //           },
      //           gridLineWidth: 0,
      //           minorGridLineWidth: 0,
      //           style: {
      //             color: 'black',
      //             fontSize: '12px'
      //           }
      //         },
      //         plotOptions: {
      //           series: {
      //             colorByPoint: false,
      //             shadow:false,
      //             borderWidth:0,
      //           }
      //         },

      //         series: [{
      //           showInLegend: false,  
      //           data: pvcdata
      //         }]
      //       });
      //     } else {
      //       document.getElementById("pvcGraphData").style.display = "none";
      //     }
      //     if(pausedata.length>0){
      //       var pauseChart=Highcharts.chart('pauseChart', {
      //         chart: {
      //           type: 'column',
      //           height:'50%',
      //           width:'400'
      //         },
      //         exporting: { enabled: false },
      //         credits: {
      //           enabled: false
      //         },
      //         title: {
      //             text: ''
      //         },

      //         xAxis: {
      //           min:0,
      //           max:10,
      //           scrollbar: {
      //             enabled: true,
      //           },   
      //           allowDecimals:false,
      //           labels: {
      //             step: 1,
      //             tickInterval:1,
      //             style: {
      //               color: 'black',
      //               fontSize: '12px'
      //             }, gridLineWidth: 0,
      //             visible: false,
      //            formatter: function() { return seriesData[this.value];},
      //           }
      //         },
      //         yAxis: {             
      //           min: 0,
      //           title: {
      //             text: ''
      //           },
      //           visible: true,
      //           labels: {
      //             enabled: true,      
      //           },
      //           gridLineWidth: 0,
      //           style: {
      //             color: 'black',
      //             fontSize: '12px'
      //           }
      //         },
              
      //         plotOptions: {
      //           series: {
      //             colorByPoint: false,
      //             shadow:false,
      //             borderWidth:0,                
      //           }
      //         },
      //         series: [{
      //           showInLegend: false,  
      //           data: pausedata 
      //         }]
      //       });
      //     } else {
      //       document.getElementById("pauseGraphData").style.display = "none";
      //     }


      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
        var dailyThis=this;
       
        //  axios
        // .get(apiUrl+'api/common/getdailydeatils?userid='+userid)
        // .then(res => {
        //   this.setState({
        //   table: res.data
        // 	});
        //    for(var i=0;i<res.data.length;i++)
        //   {
            
        //   if(res.data[i].pac!=undefined)
        //  {
          
        //   const pac= res.data[i].pac.split(',');
         
        //   if(pac[0]!=undefined)
        //   {
        //     pacCount=pacCount+parseInt(pac[0]);
        //   }
        //   if(pac[10]!=undefined)
        //   {
        //     pacCs+=parseInt(pac[10]);
        //   }
        //   if(pac[11]!=undefined)
        //   {
        //    pacCc+=parseInt(pac[11]);
        //  }
        //  if(pac[12]!=undefined)
        //   {
        //    pacCt+=parseInt(pac[12]);
        //  }
        //  if(pac[13]!=undefined)
        //   {
        //    pacCrun+=parseInt(pac[13]);
        //  }
        //  if(pac[14]!=undefined)
        //   {
        //    pacCmin+=parseInt(pac[14]);
        //  }
        //  if(pac[15]!=undefined)
        //   {
        //    pacCmax+=parseInt(pac[15]);
        //  }
        //  if(pac[16]!=undefined)
        //   {
        //    pacCbi+=parseInt(pac[16]);
        //  }
        //  if(pac[17]!=undefined)
        //   {
        //    pacCtri+=parseInt(pac[17]);
        //  }
          
           
        //   }
          
         
        //  if(res.data[i].pvc!=undefined)
        //  {
         
        //   const pvc= res.data[i].pvc.split(',');
          
        //   if(pvc[0]!=undefined)
        //   {
        //     pvcCount=pvcCount+parseInt(pvc[0]);
        //   }
        //   if(pvc[10]!=undefined)
        //   {
        //     pvcCs+=parseInt(pvc[10]);
        //   }
        //   if(pvc[11]!=undefined)
        //   {
        //    pvcCc+=parseInt(pvc[11]);
        //  }
        //   if(pvc[12]!=undefined)
        //   {
        //    pvcCt+=parseInt(pvc[12]);
        //  }
        //   if(pvc[13]!=undefined)
        //   {
        //    pvcCrun+=parseInt(pvc[13]);
        //  }
        //   if(pvc[14]!=undefined)
        //   {
        //    pvcCmin+=parseInt(pvc[14]);
        //  }
        //   if(pvc[15]!=undefined)
        //   {
        //    pvcCmax+=parseInt(pvc[15]);
        //  }
        //  if(pvc[16]!=undefined)
        //   {
        //    pvcCbi+=parseInt(pvc[16]);
        //  }
        //   if(pvc[17]!=undefined)
        //   {
        //    pvcCtri+=parseInt(pvc[17]);
        //  }
          
           
        //   }
        //   }
         
        
          
        //   this.setState({
              
        //       pvcCount:pvcCount,
        //       pvcCs:pvcCs,
        //       pvcCc:pvcCc,
        //       pvcCt:pvcCt,
        //       pvcCrun:pvcCrun,
        //       pvcCmin:pvcCmin,
        //       pvcCmax:pvcCmax,
        //       pvcCbi:pvcCbi,
        //       pvcCtri:pvcCtri,
        //     });
        //   this.setState({
              
        //       pacCount:pacCount,
        //       pacCs:pacCs,
        //       pacCc:pacCc,
        //       pacCt:pacCt,
        //       pacCrun:pacCrun,
        //       pacCbi:pacCbi,
        //       pacCtri:pacCtri,
        //     });
        // })
        //  .catch(err => {
          
        // });   

      //   axios
      //   .get(apiUrl+'api/common/getHeartRates?userid='+userid+"&databaseid="+databaseid)
      //   .then(res => {
      //    if(res.data[0]!=undefined)
      //    {
      //    if(res.data[0].normal_sr!=undefined)
      //    {
      //    const normal_HR= res.data[0].normal_sr.split(',');
      //    const normal_hourly_count=normal_HR[0];
      //    const normal_hourly_per=normal_HR[1];
      //    const normal_max=normal_HR[2];
      //    const normal_min=normal_HR[4];
         
      //    this.setState({
      //         nsrhrc:normal_hourly_count,
      //         nsrhrper:normal_hourly_per,
      //         nsrmin:normal_min,
      //         nsrmax:normal_max,
      //       });

      //  }
      //  if(res.data[0].sinus_tc!=undefined)
      //    {
      //    const sinusT_HR= res.data[0].sinus_tc.split(',');
      //    const sinusT_hourly_count=sinusT_HR[0];
      //    const sinusT_hourly_per=sinusT_HR[1];
      //    const sinust_max=sinusT_HR[2];
      //    const sinust_min=sinusT_HR[4];
         
      //    this.setState({
      //         sthrc:sinusT_hourly_count,
      //         sthrper:sinusT_hourly_per,
      //         stmin:sinust_min,
      //         stmax:sinust_max,
      //       });
      //  }
      //  if(res.data[0].sinus_bc!=undefined)
      //    {
      //    const sinusB_HR= res.data[0].sinus_bc.split(',');
      //    const sinusB_hourly_count=sinusB_HR[0];
      //    const sinusB_hourly_per=sinusB_HR[1];
      //    const sinusb_max=sinusB_HR[2];
      //    const sinusb_min=sinusB_HR[4];
         
      //    this.setState({
      //         sbhrc:sinusB_hourly_count,
      //         sbhrper:sinusB_hourly_per,
      //         sbmin:sinusb_min,
      //         sbmax:sinusb_max,
      //       });
      //  }
      //  if(res.data[0].vt!=undefined)
      //    {
      //    const vt_HR= res.data[0].vt.split(',');
      //    const vt_hourly_count=vt_HR[0];
      //    const vt_hourly_per=vt_HR[1];
      //    const vt_max=vt_HR[2];
      //    const vt_min=vt_HR[4];
      //    this.setState({
      //         vthrc:vt_hourly_count,
      //         vthrper:vt_hourly_per,
      //         vtmin:vt_min,
      //         vtmax:vt_max,
      //       });
      //  }
      //  if(res.data[0].afib!=undefined)
      //    {
      //    const afib_HR= res.data[0].afib.split(',');
      //    const afib_hourly_count=afib_HR[0];
      //    const afib_hourly_per=afib_HR[1];
      //    const afib_max=afib_HR[2];
      //    const afib_min=afib_HR[4];
      //    this.setState({
      //         afibhrc:afib_hourly_count,
      //         afibhrper:afib_hourly_per,
      //         afibmin:afib_min,
      //         afibmax:afib_max,
      //       });
      //  }
      //  if(res.data[0].sinus_pause!=undefined)
      //    {
      //    const sinus_pause_HR= res.data[0].sinus_pause.split(',');
      //    const sinusp_hourly_count=sinus_pause_HR[0];
      //    const sinusp_hourly_per=sinus_pause_HR[1];
      //    const sinusp_max=sinus_pause_HR[2];
      //    const sinusp_min=sinus_pause_HR[4];
      //    this.setState({
      //         sphrc:sinusp_hourly_count,
      //         sphrper:sinusp_hourly_per,
      //         spmin:sinusp_min,
      //         spmax:sinusp_max,
      //       });
      //  }
      //  if(res.data[0].pac!=undefined)
      //    {
      //    const pac_HR= res.data[0].pac.split(',');
      //    const pac_hourly_count=pac_HR[0];
      //    const pac_hourly_per=pac_HR[1];
      //    const pac_max=pac_HR[2];
      //    const pac_min=pac_HR[4];
      //    const cspac=pac_HR[6];
      //    const ccpac=pac_HR[7];
      //    const ctpac=pac_HR[8];
      //    const crunpac=pac_HR[9];
      //    const cbigpac=pac_HR[10];
      //    const ctrigpac=pac_HR[11];
      //    this.setState({
      //         pachrc:pac_hourly_count,
      //         pachrper:pac_hourly_per,
      //         pacmin:pac_min,
      //         pacmax:pac_max,
      //         cspac:cspac,
      //         ccpac:ccpac,
      //         ctpac:ctpac,
      //         crunpac:crunpac,
      //         cbigpac:cbigpac,
      //         ctrigpac:ctrigpac,

      //       });
      //  }
      //  if(res.data[0].pvc!=undefined)
      //    {
      //    const pvc_HR= res.data[0].pvc.split(',');
      //    const pvc_hourly_count=pvc_HR[0];
      //    const pvc_hourly_per=pvc_HR[1];
      //    const pvc_max=pvc_HR[2];
      //    const pvc_min=pvc_HR[4];
      //    const cspvc=pvc_HR[6];
      //    const ccpvc=pvc_HR[7];
      //    const ctpvc=pvc_HR[8];
      //    const crunpvc=pvc_HR[9];
      //    const cbigpvc=pvc_HR[10];
      //    const ctrigpvc=pvc_HR[11];
      //    this.setState({
      //         pvchrc:pvc_hourly_count,
      //         pvchrper:pvc_hourly_per,
      //         pvcmin:pvc_min,
      //         pvcmax:pvc_max,
      //         cspvc:cspvc,
      //         ccpvc:ccpvc,
      //         ctpvc:ctpvc,
      //         crunpvc:crunpvc,
      //         cbigpvc:cbigpvc,
      //         ctrigpvc:ctrigpvc,
      //       });
      //  }
      
      //   }});
         


      var mmhrf=this;

       

    // axios
    //     .get(apiUrl+'api/common/getArrhythmiaCount?userid='+userid)
    //     .then(res => {
    //       var data =  res.data;
          
    //       if(data.total>0) {
  

    //         nsr_per=((data.nsr/data.total)*100);
    //         st_per=((data.st/data.total)*100);
    //         sb_per=((data.sb/data.total)*100);
    //         sp_per=((data.sp/data.total)*100);
    //         pac_per=((data.pac/data.total)*100);
    //         pvc_per=((data.pvc/data.total)*100);
    //         vt_per=((data.vt/data.total)*100);
    //         afib_per=((data.afib/data.total)*100);
    //         pab_per=((data.pabsent/data.total)*100);
    //         irrr_per=((data.irr/data.total)*100);
    //         irpr_per=((data.ipr/data.total)*100);
    //         sqt_per=((data.sqt/data.total)*100);
    //         lqt_per=((data.pqt/data.total)*100);
    //         un_per=((data.un/data.total)*100);

    //         this.setState({
    //           nsrData:data.nsr,
    //           stData:data.st,
    //           sbData:data.sb,
    //           spData:data.sp,
    //           pacData:data.pac,
    //           pvcData:data.pvc,
    //           vtData:data.vt,
    //           afibData:data.afib,
    //           pabData:data.pabsent,
    //           irrrData:data.irr,
    //           irprData:data.ipr,
    //           sqtData:data.sqt,
    //           lqtData:data.pqt,
    //           unData:data.un,
    //           nsr_perData:nsr_per.toFixed(2),
    //           st_perData:st_per.toFixed(2),
    //           sb_perData:sb_per.toFixed(2),
    //           sp_perData:sp_per.toFixed(2),
    //           pac_perData:pac_per.toFixed(2),
    //           pvc_perData:pvc_per.toFixed(2),
    //           vt_perData:vt_per.toFixed(2),
    //           afib_perData:afib_per.toFixed(2),
    //           pab_perData:pab_per.toFixed(2),
    //           irrr_perData:irrr_per.toFixed(2),
    //           irpr_perData:irpr_per.toFixed(2),
    //           sqt_perData:sqt_per.toFixed(2),
    //           lqt_perData:lqt_per.toFixed(2),
    //           un_perData:un_per.toFixed(2),
    //         });
    //       }
    //       var dataArray =[]; 
    //       if(nsr_per>0.1) {
    //         dataArray.push({
    //           name: 'Normal Sinus Rhythm',
    //           y: nsr_per,
    //           sliced: false,
    //           selected: true,
    //           color:'#3D761D'
    //         });
    //       }
    //       if(sb_per>0.1) {
    //         dataArray.push({
    //               name: 'Sinus Bradycardia',
    //               y: sb_per,
    //               color:'#FF9900'
    //             });
    //       }
    //       if(sp_per>0.1) {
    //         dataArray.push({
    //               name: 'Sinus Pause',
    //               y: sp_per,
    //               color:'#FF0000'
    //             });
    //       }
    //       if(vt_per>0.1) {
    //         dataArray.push({
    //               name: 'Ventricular Tachycardia',
    //               y: vt_per,
    //               color:'#A64D79'
    //             });
    //       }
    //       if(pac_per>0.1) {
    //         dataArray.push({
    //               name: 'PAC',
    //               y: pac_per,
    //               color:'#0000FF'
    //             });
    //       }
    //       if(pvc_per>0.1) {
    //         dataArray.push({
    //               name: 'PVC',
    //               y: pvc_per,
    //               color:'#FF00FF'
    //             });
    //       }
    //       if(sqt_per>0.1) {
    //         dataArray.push({
    //               name: '1AVB',
    //               y: sqt_per,
    //               color:'#980000'
    //             });
    //       }
    //       if(lqt_per>0.1) {
    //         dataArray.push({
    //               name: 'Long QT',
    //               y: lqt_per,
    //               color:'#46BDC6'
    //             });
    //       }
    //       if(un_per>0.1) {
    //         dataArray.push({
    //               name: 'Unclassified',
    //               y: un_per,
    //               color:'#FFD966'
    //             });
    //       }
    //       if(irpr_per>0.1) {
    //         dataArray.push({
    //               name: '2AVB',
    //               y: irpr_per,
    //               color:'#351C75'
    //             });
    //       }
    //       if(irrr_per>0.1) {
    //         dataArray.push({
    //               name: 'CHB',
    //               y: irrr_per,
    //               color:'#7F6000'
    //             });
    //       }
    //       if(pab_per>0.1) {
    //         dataArray.push({
    //               name: 'Atrial Tachycardia',
    //               y: pab_per,
    //               color:'#434343'
    //             });
    //       }
    //       if(st_per>0.1) {
    //         dataArray.push({
    //               name: 'Sinus Tachycardia',
    //               y: st_per,
    //               color:'#00FFFF '
    //             });
    //       }
    //       if(afib_per>=0.1) {
    //         dataArray.push({
    //               name: 'AFib',
    //               y: afib_per,
    //               color:'#9900FF'
    //             });
    //       }
    //       /*console.log('dataArray',dataArray);*/
    //       Highcharts.chart('chart_div_fullData', {
    //         chart: {
    //           plotBackgroundColor: null,
    //           plotBorderWidth: null,
    //           plotShadow: false,
    //           type: 'pie',
    //           height: '60%',
    //         },
    //         title: {
    //           text: ' '
    //         },
    //         credits: {
    //           enabled: false
    //         },
    //         tooltip: {
    //           pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    //         },
    //         plotOptions: {
    //           pie: {
    //             allowPointSelect: true,
    //             cursor: 'pointer',
    //             dataLabels: {
    //               enabled: true,
    //               format: '<b>{point.name}</b>: {point.percentage:.1f} %'
    //             }
    //           }
    //         },
    //         series: [{
    //           name: 'Brands',
    //           colorByPoint: true,
    //           data: dataArray
    //         }]
    //       });
    //     })
    //     .catch(err => {
          
    //     });     

  }
  toggleSame()
  {
    
  
    
    var searchDiv = document.getElementById('myTabs_111');
    var searchDiv1= document.getElementById('myTabs_1111');
  //  console.log(window.location.href.split('#')[0]);
    var cds= document.getElementById('currentDataSet');
    var fds = document.getElementById('fullDataSet');
    var cds1= document.getElementById('currentEventDataSet');
    var fds1 = document.getElementById('fullEventDataSet');
    
    for(var i=0;i<searchDiv.childNodes.length;i++)
    {
     // console.log(searchDiv.childNodes[i].childNodes[0].href);
      
      if(searchDiv.childNodes[i].childNodes[0].href == window.location.href.split('#')[0]+'#fullDataSet')
      {
        
        $(searchDiv.childNodes[i]).addClass('active');
        $(fds).addClass('active in');
        
      //console.log(searchDiv.childNodes[i]);
    }
    else {
        $(searchDiv.childNodes[i]).removeClass('active');
        $(cds).removeClass('active in');
        
        // console.log(searchDiv.childNodes[i]);
      }
       if(searchDiv1.childNodes[i].childNodes[0].href == window.location.href.split('#')[0]+'#fullEventDataSet')
      {
        
        $(searchDiv1.childNodes[i]).addClass('active');
        $(fds1).addClass('active in');
        
     
    }
    else {
        $(searchDiv1.childNodes[i]).removeClass('active');
        $(cds1).removeClass('active in');
        
        
      }
    }
   
  }
  toggleSameB()
  {

    
    var searchDiv = document.getElementById('myTabs_11');
     var searchDiv1= document.getElementById('myTabs_1111');
    var cds= document.getElementById('currentDataSetGraph');
    var fds = document.getElementById('fullDataSetGrapg');
     var cds1= document.getElementById('currentEventDataSet');
    var fds1 = document.getElementById('fullEventDataSet');

    for(var i=0;i<searchDiv.childNodes.length;i++)
    {
      if(searchDiv.childNodes[i].childNodes[0].href==window.location.href.split('#')[0]+'#fullDataSetGrapg')
      {
        
        $(searchDiv.childNodes[i]).addClass('active');
        $(fds).addClass('active in');
      
    }
    else {
        $(searchDiv.childNodes[i]).removeClass('active');
        $(cds).removeClass('active in');
      
      }
      if(searchDiv1.childNodes[i].childNodes[0].href == window.location.href.split('#')[0]+'#fullEventDataSet')
      {
        
        $(searchDiv1.childNodes[i]).addClass('active');
        $(fds1).addClass('active in');
        
     
    }
    else {
        $(searchDiv1.childNodes[i]).removeClass('active');
        $(cds1).removeClass('active in');
        
        
      }
    }
   
  }
  toggleSameC()
  {
    
 
    
    var searchDiv = document.getElementById('myTabs_111');
     var searchDiv1= document.getElementById('myTabs_1111');
    var cds= document.getElementById('currentDataSet');
    var fds = document.getElementById('fullDataSet');
    var cds1= document.getElementById('currentEventDataSet');
    var fds1 = document.getElementById('fullEventDataSet');
    for(var i=0;i<searchDiv.childNodes.length;i++)
    {
      if(searchDiv.childNodes[i].childNodes[0].href==window.location.href.split('#')[0]+'#currentDataSet')
      {
        
        $(searchDiv.childNodes[i]).addClass('active');
        $(cds).addClass('active in');
       
    }
    else {
        $(searchDiv.childNodes[i]).removeClass('active');
        $(fds).removeClass('active in');
       
      }
      if(searchDiv1.childNodes[i].childNodes[0].href == window.location.href.split('#')[0]+'#currentEventDataSet')
      {
        
        $(searchDiv1.childNodes[i]).addClass('active');
        $(cds1).addClass('active in');
        
   //   console.log(searchDiv1.childNodes[i]);
    }
    else {
        $(searchDiv1.childNodes[i]).removeClass('active');
        $(fds1).removeClass('active in');
        
      //  console.log(searchDiv1.childNodes[i]);
      }
    }
   
  }
   toggleSameF()
  {
    
    //get selected href
   // var href = this.attr('href');   
    
    var searchDiv = document.getElementById('myTabs_11');
     var searchDiv1= document.getElementById('myTabs_111');
    var cds= document.getElementById('currentDataSetGraph');
    var fds = document.getElementById('fullDataSetGrapg');
    var cds1= document.getElementById('currentDataSet');
    var fds1 = document.getElementById('fullDataSet');
    for(var i=0;i<searchDiv.childNodes.length;i++)
    {
      if(searchDiv.childNodes[i].childNodes[0].href==window.location.href.split('#')[0]+'#currentDataSetGraph')
      {
        
        $(searchDiv.childNodes[i]).addClass('active');
        $(cds).addClass('active in');
       
    }
    else {
        $(searchDiv.childNodes[i]).removeClass('active');
        $(fds).removeClass('active in');
       
      }
      if(searchDiv1.childNodes[i].childNodes[0].href == window.location.href.split('#')[0]+'#currentDataSet')
      {
        
        $(searchDiv1.childNodes[i]).addClass('active');
        $(cds1).addClass('active in');
        
    //  console.log(searchDiv1.childNodes[i]);
    }
    else {
        $(searchDiv1.childNodes[i]).removeClass('active');
        $(fds1).removeClass('active in');
        
   //     console.log(searchDiv1.childNodes[i]);
      }
    }
   
  }
  toggleSameE()
  {
    
    //get selected href
   // var href = this.attr('href');   
    
    var searchDiv = document.getElementById('myTabs_11');
    var searchDiv1= document.getElementById('myTabs_111');
    var cds= document.getElementById('currentDataSetGraph');
    var fds = document.getElementById('fullDataSetGrapg');
    var cds1= document.getElementById('currentDataSet');
    var fds1 = document.getElementById('fullDataSet');

    //console.log(fds);
    for(var i=0;i<searchDiv.childNodes.length;i++)
    {
      
      if(searchDiv.childNodes[i].childNodes[0].href == window.location.href.split('#')[0]+'#fullDataSetGrapg')
      {
        
        $(searchDiv.childNodes[i]).addClass('active');
        $(fds).addClass('active in');
        
      //console.log(searchDiv.childNodes[i]);
    }
    else {
        $(searchDiv.childNodes[i]).removeClass('active');
        $(cds).removeClass('active in');
        
        // console.log(searchDiv.childNodes[i]);
      }
       if(searchDiv1.childNodes[i].childNodes[0].href == window.location.href.split('#')[0]+'#fullDataSet')
      {
        
        $(searchDiv1.childNodes[i]).addClass('active');
        $(fds1).addClass('active in');
        
     
    }
    else {
        $(searchDiv1.childNodes[i]).removeClass('active');
        $(cds1).removeClass('active in');
        
        
      }
    }
   
  }
  toggleSameD()
  {
    
    //get selected href
   // var href = this.attr('href');   
    
    var searchDiv = document.getElementById('myTabs_11');
    var searchDiv1= document.getElementById('myTabs_1111');
    var cds= document.getElementById('currentDataSetGraph');
    var fds = document.getElementById('fullDataSetGrapg');
     var cds1= document.getElementById('currentEventDataSet');
    var fds1 = document.getElementById('fullEventDataSet');
   /* console.log(cds);
    console.log(fds);
    console.log(searchDiv.childNodes);*/
    for(var i=0;i<searchDiv.childNodes.length;i++)
    {
      if(searchDiv.childNodes[i].childNodes[0].href==window.location.href.split('#')[0]+'#currentDataSetGraph')
      {
        
        $(searchDiv.childNodes[i]).addClass('active');
        $(cds).addClass('active in');
      
    }
    else {
        $(searchDiv.childNodes[i]).removeClass('active');
        $(fds).removeClass('active in');
          
      }
      if(searchDiv1.childNodes[i].childNodes[0].href == window.location.href.split('#')[0]+'#currentEventDataSet')
      {
        
        $(searchDiv1.childNodes[i]).addClass('active');
        $(cds1).addClass('active in');
        
 //     console.log(searchDiv1.childNodes[i]);
    }
    else {
        $(searchDiv1.childNodes[i]).removeClass('active');
        $(fds1).removeClass('active in');
        
   //     console.log(searchDiv1.childNodes[i]);
      }
    }
   
  }
  componentWillReceiveProps(nextProps) { 
    var normal_full_data=0, sinus_full_data=0, sinus_brady_full_data=0,sinus_pause_full_data=0,pac_full_data=0,pvc_full_data=0,vt_full_data=0,afib_full_data=0,pab_full_data=0,irrr_full_data=0,irpr_full_data=0,sqt_full_data=0,lqt_full_data=0,un_full_data=0;
    var nsr_per,st_per,sb_per,sp_per,pac_per,pvc_per,vt_per,afib_per,pab_per,irrr_per,irpr_per,sqt_per,lqt_per,un_per;
    
    if(nextProps.rpeaks) {
      var dataout =  nextProps.rpeaks;
     
      if(dataout.length>0) {
        for (var i = 0; i < dataout.length; i++) {
          if(dataout[i]['arrhythmia']=='Normal Sinus Rhythm') {
            normal_full_data=normal_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Sinus Tachycardia') {
            sinus_full_data=sinus_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Sinus Bradycardia') {
            sinus_brady_full_data=sinus_brady_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Sinus Pause') {
            sinus_pause_full_data=sinus_pause_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='PAC') {
            
            pac_full_data=pac_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='PVC') {
            pvc_full_data=pvc_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Ventricular Tachycardia'){
            vt_full_data=vt_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='AFib') {
            afib_full_data=afib_full_data+1;
          } 
          else if(dataout[i]['arrhythmia']=='P absent') {
            pab_full_data=pab_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Irregular RR') {
            irrr_full_data=irrr_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Irregular PR') {
            irpr_full_data=irpr_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Short QT') {
            sqt_full_data=sqt_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Prolonged QT') {
            lqt_full_data=lqt_full_data+1;
          }
          else if(dataout[i]['arrhythmia']=='Undiagnosed') {
            un_full_data=un_full_data+1;
          }
         
        }

        nsr_per=((normal_full_data/dataout.length)*100);
        st_per=((sinus_full_data/dataout.length)*100);
        sb_per=((sinus_brady_full_data/dataout.length)*100);
        sp_per=((sinus_pause_full_data/dataout.length)*100);
        pac_per=((pac_full_data/dataout.length)*100);
        pvc_per=((pvc_full_data/dataout.length)*100);
        vt_per=((vt_full_data/dataout.length)*100);
        afib_per=((afib_full_data/dataout.length)*100);
        pab_per=((pab_full_data/dataout.length)*100);
        irrr_per=((irrr_full_data/dataout.length)*100);
        irpr_per=((irpr_full_data/dataout.length)*100);
        sqt_per=((sqt_full_data/dataout.length)*100);
        lqt_per=((lqt_full_data/dataout.length)*100);
        un_per=((un_full_data/dataout.length)*100);
        
        this.setState({
          nsr:normal_full_data,
          st:sinus_full_data,
          sb:sinus_brady_full_data,
          sp:sinus_pause_full_data,
          pac:pac_full_data,
          pvc:pvc_full_data,
          vt:vt_full_data,
          afib:afib_full_data,
          pab:pab_full_data,
          irrr:irrr_full_data,
          irpr:irpr_full_data,
          sqt:sqt_full_data,
          lqt:lqt_full_data,
          un:un_full_data,
          nsr_per:nsr_per.toFixed(2),
          st_per:st_per.toFixed(2),
          sb_per:sb_per.toFixed(2),
          sp_per:sp_per.toFixed(2),
          pac_per:pac_per.toFixed(2),
          pvc_per:pvc_per.toFixed(2),
          vt_per:vt_per.toFixed(2),
          afib_per:afib_per.toFixed(2),
          pab_per:pab_per.toFixed(2),
          irrr_per:irrr_per.toFixed(2),
          irpr_per:irpr_per.toFixed(2),
          sqt_per:sqt_per.toFixed(2),
          lqt_per:lqt_per.toFixed(2),
          un_per:un_per.toFixed(2),
        });

      }
      var dataArray =[]; 





          
      if(nsr_per>0.1) {
        dataArray.push({
          name: 'Normal Sinus Rhythmmm',
          y: nsr_per,
          color:'#3D761D'
        });
      }
      if(sb_per>0.1) {
        dataArray.push({
          name: 'Sinus Bradycardia',
          y: sb_per,
          color:'#FF9900'
        });
      }
      if(sp_per>0.1) {
        dataArray.push({
          name: 'Sinus Pause',
          y: sp_per,
          color:'#FF0000'
        });
      }
      if(vt_per>0.1) {
        dataArray.push({
          name: 'Ventricular Tachycardia',
          y: vt_per,
          color:'#A64D79'
        });
      }
      if(pac_per>0.1) {
        dataArray.push({
          name: 'PAC',
          y: pac_per,
          color:'#0000FF'
        });
      }
      if(pvc_per>0.1) {
        dataArray.push({
          name: 'PVC',
          y: pvc_per,
          color:'#FF00FF'
        });
      }
      if(sqt_per>0.1) {
        dataArray.push({
          name: '1AVB',
          y: sqt_per,
          color:'#980000'
        });
      }
      if(lqt_per>0.1) {
        dataArray.push({
          name: 'Long QT',
          y: lqt_per,
          color:'#46BDC6'
        });
      }
      if(un_per>0.1) {
        dataArray.push({
          name: 'Unclassified',
          y: un_per,
          color:'#FFD966'
        });
      }
      if(irpr_per>0.1) {
        dataArray.push({
          name: '2AVB',
          y: irpr_per,
          color:'#351C75'
        });
      }
      if(irrr_per>0.1) {
        dataArray.push({
          name: 'CHB',
          y: irrr_per,
          color:'#7F6000'
        });
      }
      if(pab_per>0.1) {
        dataArray.push({
          name: 'Atrial Tachycardia',
          y: pab_per,
          color:'#434343'
        });
      }
      if(st_per>0.1) {
        dataArray.push({
          name: 'Sinus Tachycardia',
          y: st_per,
          color:'#00FFFF '
        });
      }
      if(afib_per>0.1) {
        dataArray.push({
          name: 'AFib',
          y: afib_per,
          color:'#9900FF'
        });
      }
     
      Highcharts.chart('chart_div_full', {
        chart: {
          backgroundColor :'#d0d3e4',
          plotBackgroundColor: '#d0d3e4',
          plotShadow: false,
          type: 'pie',
          height: '60%',
        },
        title: {
          text: ' '
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                color: 'black',
                'textOutline': '0px contrast',
                'stroke-width': 0
            }
            }
          }
        },
        series: [{
          name: 'Brands',
          colorByPoint: true,
          data: dataArray
        }]
      });
    }
    var mmhrf=this;
    if(nextProps.arrCount){
      var data = nextProps.arrCount
      if(data.total>0) {
  

        nsr_per=((data.nsr/data.total)*100);
        st_per=((data.st/data.total)*100);
        sb_per=((data.sb/data.total)*100);
        sp_per=((data.sp/data.total)*100);
        pac_per=((data.pac/data.total)*100);
        pvc_per=((data.pvc/data.total)*100);
        vt_per=((data.vt/data.total)*100);
        afib_per=((data.afib/data.total)*100);
        pab_per=((data.pabsent/data.total)*100);
        irrr_per=((data.irr/data.total)*100);
        irpr_per=((data.ipr/data.total)*100);
        sqt_per=((data.sqt/data.total)*100);
        lqt_per=((data.pqt/data.total)*100);
        un_per=((data.un/data.total)*100);

        mmhrf.setState({
          nsrData:data.nsr,
          stData:data.st,
          sbData:data.sb,
          spData:data.sp,
          pacData:data.pac,
          pvcData:data.pvc,
          vtData:data.vt,
          afibData:data.afib,
          pabData:data.pabsent,
          irrrData:data.irr,
          irprData:data.ipr,
          sqtData:data.sqt,
          lqtData:data.pqt,
          unData:data.un,
          nsr_perData:nsr_per.toFixed(2),
          st_perData:st_per.toFixed(2),
          sb_perData:sb_per.toFixed(2),
          sp_perData:sp_per.toFixed(2),
          pac_perData:pac_per.toFixed(2),
          pvc_perData:pvc_per.toFixed(2),
          vt_perData:vt_per.toFixed(2),
          afib_perData:afib_per.toFixed(2),
          pab_perData:pab_per.toFixed(2),
          irrr_perData:irrr_per.toFixed(2),
          irpr_perData:irpr_per.toFixed(2),
          sqt_perData:sqt_per.toFixed(2),
          lqt_perData:lqt_per.toFixed(2),
          un_perData:un_per.toFixed(2),
        });
      }
      var dataArray =[]; 
      if(nsr_per>0.1) {
        dataArray.push({
          name: 'Normal Sinus Rhythm',
          y: nsr_per,
          sliced: false,
          selected: true,
          color:'#3D761D'
        });
      }
      if(sb_per>0.1) {
        dataArray.push({
              name: 'Sinus Bradycardia',
              y: sb_per,
              color:'#FF9900'
            });
      }
      if(sp_per>0.1) {
        dataArray.push({
              name: 'Sinus Pause',
              y: sp_per,
              color:'#FF0000'
            });
      }
      if(vt_per>0.1) {
        dataArray.push({
              name: 'Ventricular Tachycardia',
              y: vt_per,
              color:'#A64D79'
            });
      }
      if(pac_per>0.1) {
        dataArray.push({
              name: 'PAC',
              y: pac_per,
              color:'#0000FF'
            });
      }
      if(pvc_per>0.1) {
        dataArray.push({
              name: 'PVC',
              y: pvc_per,
              color:'#FF00FF'
            });
      }
      if(sqt_per>0.1) {
        dataArray.push({
              name: '1AVB',
              y: sqt_per,
              color:'#980000'
            });
      }
      if(lqt_per>0.1) {
        dataArray.push({
              name: 'Long QT',
              y: lqt_per,
              color:'#46BDC6'
            });
      }
      if(un_per>0.1) {
        dataArray.push({
              name: 'Unclassified',
              y: un_per,
              color:'#FFD966'
            });
      }
      if(irpr_per>0.1) {
        dataArray.push({
              name: '2AVB',
              y: irpr_per,
              color:'#351C75'
            });
      }
      if(irrr_per>0.1) {
        dataArray.push({
              name: 'CHB',
              y: irrr_per,
              color:'#7F6000'
            });
      }
      if(pab_per>0.1) {
        dataArray.push({
              name: 'Atrial Tachycardia',
              y: pab_per,
              color:'#434343'
            });
      }
      if(st_per>0.1) {
        dataArray.push({
              name: 'Sinus Tachycardia',
              y: st_per,
              color:'#00FFFF '
            });
      }
      if(afib_per>=0.1) {
        dataArray.push({
              name: 'AFib',
              y: afib_per,
              color:'#9900FF'
            });
      }
      /*console.log('dataArray',dataArray);*/
      Highcharts.chart('chart_div_fullData', {
        chart: {
          backgroundColor :'#d0d3e4',
          plotBackgroundColor: '#d0d3e4',
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: '60%',
        },
        title: {
          text: ' '
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                color: 'black',
                'textOutline': '0px contrast',
                'stroke-width': 0
            }
            }
          }
        },
        series: [{
          name: 'Brands',
          colorByPoint: true,
          data: dataArray
        }]
      });

    }
    if(nextProps.patientwrkdailydetails){
      var pacCount=0,pacCs=0,pacCc=0,pacCt=0,pacCrun=0,pacCmin=0,pacCmax=0,pacCbi=0,pacCtri=0,pvcCount=0,pvcCs=0,pvcCc=0,pvcCt=0,pvcCrun=0,pvcCmin=0,pvcCmax=0,pvcCbi=0,pvcCtri=0;
      var res=nextProps.patientwrkdailydetails;
      mmhrf.setState({
        table: res
        });
         for(var i=0;i<res.length;i++)
        {
          
        if(res[i].pac!=undefined)
       {
        
        const pac= res[i].pac.split(',');
       
        if(pac[0]!=undefined)
        {
          pacCount=pacCount+parseInt(pac[0]);
        }
        if(pac[10]!=undefined)
        {
          pacCs+=parseInt(pac[10]);
        }
        if(pac[11]!=undefined)
        {
         pacCc+=parseInt(pac[11]);
       }
       if(pac[12]!=undefined)
        {
         pacCt+=parseInt(pac[12]);
       }
       if(pac[13]!=undefined)
        {
         pacCrun+=parseInt(pac[13]);
       }
       if(pac[14]!=undefined)
        {
         pacCmin+=parseInt(pac[14]);
       }
       if(pac[15]!=undefined)
        {
         pacCmax+=parseInt(pac[15]);
       }
       if(pac[16]!=undefined)
        {
         pacCbi+=parseInt(pac[16]);
       }
       if(pac[17]!=undefined)
        {
         pacCtri+=parseInt(pac[17]);
       }
        
         
        }
        
       
       if(res[i].pvc!=undefined)
       {
       
        const pvc= res[i].pvc.split(',');
        
        if(pvc[0]!=undefined)
        {
          pvcCount=pvcCount+parseInt(pvc[0]);
        }
        if(pvc[10]!=undefined)
        {
          pvcCs+=parseInt(pvc[10]);
        }
        if(pvc[11]!=undefined)
        {
         pvcCc+=parseInt(pvc[11]);
       }
        if(pvc[12]!=undefined)
        {
         pvcCt+=parseInt(pvc[12]);
       }
        if(pvc[13]!=undefined)
        {
         pvcCrun+=parseInt(pvc[13]);
       }
        if(pvc[14]!=undefined)
        {
         pvcCmin+=parseInt(pvc[14]);
       }
        if(pvc[15]!=undefined)
        {
         pvcCmax+=parseInt(pvc[15]);
       }
       if(pvc[16]!=undefined)
        {
         pvcCbi+=parseInt(pvc[16]);
       }
        if(pvc[17]!=undefined)
        {
         pvcCtri+=parseInt(pvc[17]);
       }
        
         
        }
        }
       
      
        
        mmhrf.setState({
            
            pvcCount:pvcCount,
            pvcCs:pvcCs,
            pvcCc:pvcCc,
            pvcCt:pvcCt,
            pvcCrun:pvcCrun,
            pvcCmin:pvcCmin,
            pvcCmax:pvcCmax,
            pvcCbi:pvcCbi,
            pvcCtri:pvcCtri,
          });
          mmhrf.setState({
            
            pacCount:pacCount,
            pacCs:pacCs,
            pacCc:pacCc,
            pacCt:pacCt,
            pacCrun:pacCrun,
            pacCbi:pacCbi,
            pacCtri:pacCtri,
          });
    }
    if(nextProps.patientwrktotaldetails){
      var res=nextProps.patientwrktotaldetails;
      var active_arith_array= [];
      var active_arith_toarray= [];
      if(res != null){
        active_arith_toarray = res.active_arrhythmia.split(',');
        active_arith_array.shift();
        var act_arry_total = 0;
        var active_arith_garray = [];
        active_arith_array['Sinus Tachycardia'] = parseInt(active_arith_toarray[1]);
        active_arith_array['Sinus Bradycardia'] = parseInt(active_arith_toarray[2]);
        active_arith_array['Sinus Pause'] = parseInt(active_arith_toarray[3]);
        active_arith_array['PAC'] = parseInt(active_arith_toarray[4]);
        active_arith_array['PVC'] = parseInt(active_arith_toarray[5]);
        active_arith_array['VT'] = parseInt(active_arith_toarray[6]);
        active_arith_array['A Fib'] = parseInt(active_arith_toarray[7]);
        active_arith_array['P Absent'] = parseInt(active_arith_toarray[8]);
        active_arith_array['Irregular RR'] = parseInt(active_arith_toarray[9]);
        active_arith_array['Long QT'] = parseInt(active_arith_toarray[10]);
        active_arith_array['Short QT'] = parseInt(active_arith_toarray[11]);
        active_arith_array['Irregular PR'] = parseInt(active_arith_toarray[12]);

        var active_arith_array_sortable = [];
        for (var arr in active_arith_array) {
            active_arith_array_sortable.push([arr, active_arith_array[arr]]);
            act_arry_total = act_arry_total+active_arith_array[arr];
            active_arith_garray.push([parseInt(active_arith_array[arr])]);
        }

        active_arith_array_sortable.sort(function(a, b) {
            return a[1] - b[1];
        });
        active_arith_array_sortable.reverse();

        var actArrhy=Highcharts.chart('activityarrhythmia', {
          chart: {
              //backgroundColor:'white',
              plotBackgroundColor: '#d0d3e4',
              type: 'bar',
              height:'50%',
           width:'400'
          },
          
          exporting: { enabled: false },
          credits: {
            enabled: false
         },
          title: {
              text: ''
          },
            colors: ['#351C75','#980000','#46BDC6','#7F6000','#434343','#A64D79', '#FF00FF','#0000FF','#FF0000','#FF9900','#00FFFF','#3D761D'],
          xAxis: {
              categories: ['2AVB','1AVB','LQT','CHB','AT', 'VT', 'PVC','PAC','SP','SB','ST','NOR' ],
               labels: {
            style: {
              color: 'black',
              fontSize: '12px'
            },
            visible: false
          }

          },
          yAxis: {
             gridLineWidth: 0,
              min: 0,
              title: {
                  text: ''
              },
              visible: false,
                  
                  style: {
              color: 'black',
              fontSize: '12px'
            }
          },
          legend: {
              reversed: true
          },
          plotOptions: {
              series: {
                 colorByPoint: true,
                   pointPadding: 0,
                  groupPadding: 0,
                  shadow:false,
                  borderWidth:0,
                  dataLabels:{
                      enabled:true,
                      formatter: function() {
            
                       var pcnt = (this.y / act_arry_total) * 100;
                       return '<span style="font-size:12px;color:black;textOutline: 0px contrast;stroke-width: 0">'+ Highcharts.numberFormat(pcnt) +'</span style="font-size:12px;color:black;textOutline: 0px contrast;stroke-width: 0"> % >';
                          //return '<span style="font-size:12px;color:black;">'+ Highcharts.numberFormat(pcnt) +'</span> %';
                      }
                  }
              }
          },
          series: [{
               showInLegend: false,  
                   data: active_arith_garray.reverse()
          }]
        }); 



        var inactive_arith_array= [];
        var inactive_arith_toarray= [];
        inactive_arith_toarray = res.inactive_arrhythmia.split(',');
        var inact_arry_total = 0;
        var inactive_arith_garray = [];

        inactive_arith_array.shift();
        inactive_arith_array['Sinus Tachycardia'] = parseInt(inactive_arith_toarray[1]);
        inactive_arith_array['Sinus Bradycardia'] = parseInt(inactive_arith_toarray[2]);
        inactive_arith_array['Sinus Pause'] = parseInt(inactive_arith_toarray[3]);
        inactive_arith_array['PAC'] = parseInt(inactive_arith_toarray[4]);
        inactive_arith_array['PVC'] = parseInt(inactive_arith_toarray[5]);
        inactive_arith_array['VT'] = parseInt(inactive_arith_toarray[6]);
        inactive_arith_array['A Fib'] = parseInt(inactive_arith_toarray[7]);
        inactive_arith_array['P Absent'] = parseInt(inactive_arith_toarray[8]);
        inactive_arith_array['Irregular RR'] = parseInt(inactive_arith_toarray[9]);
        inactive_arith_array['Long QT'] = parseInt(inactive_arith_toarray[10]);
        inactive_arith_array['Short QT'] = parseInt(inactive_arith_toarray[11]);
        inactive_arith_array['Irregular PR'] = parseInt(inactive_arith_toarray[12]);

        var inactive_arith_array_sortable = [];
        for (var arr in inactive_arith_array) {
            inactive_arith_array_sortable.push([arr, inactive_arith_array[arr]]);
            inact_arry_total = inact_arry_total+inactive_arith_array[arr];
            inactive_arith_garray.push([parseInt(inactive_arith_array[arr])]);
        }

        inactive_arith_array_sortable.sort(function(a, b) {
            return a[1] - b[1];
        });
        inactive_arith_array_sortable.reverse();

        var actArrhy=Highcharts.chart('inactivityarrhythmia', {
          chart: {
            backgroundColor:'#d0d3e4',
            plotBackgroundColor: '#d0d3e4',
              type: 'bar',
              height:'50%',
           width:'400'
          },
          
          exporting: { enabled: false },
          credits: {
            enabled: false
         },
          title: {
              text: ''
          },
           colors: ['#351C75','#980000','#46BDC6','#7F6000','#434343','#A64D79', '#FF00FF','#0000FF','#FF0000','#FF9900','#00FFFF','#3D761D'],
          xAxis: {
              categories: ['2AVB','1AVB','LQT','CHB','AT','AT', 'VT', 'PVC','PAC','SP','SB','ST','NOR' ],
               labels: {
            style: {
              color: 'black',
              fontSize: '12px'
            },
            visible: false
          }

          },
          yAxis: {
             gridLineWidth: 0,
              min: 0,
              title: {
                  text: ''
              },
              visible: false,
                  style: {
              color: 'black',
              fontSize: '12px'
            }
          },
          legend: {
              reversed: true
          },
          plotOptions: {
              series: {
                 colorByPoint: true,
                   pointPadding: 0,
                  groupPadding: 0,
                  shadow:false,
                  borderWidth:0,
                  dataLabels:{
                      enabled:true,
                      formatter: function() {
                       
                       var pcnt = (this.y / inact_arry_total) * 100;
                          return '<span style="font-size:12px;color:black;textOutline: 0px contrast;stroke-width: 0">'+ Highcharts.numberFormat(pcnt) +'</span style="font-size:12px;color:black;textOutline: 0px contrast;stroke-width: 0"> % >';
                      }
                     
                  }
              }
          },
          series: [{
               showInLegend: false,  
                   data: inactive_arith_garray.reverse()
          }]
        }); 

        mmhrf.setState({
            activity_mfe: active_arith_array_sortable[0][0],
            activity_mfe_period: active_arith_array_sortable[0][1],
            activity_smfe: active_arith_array_sortable[1][0],
            activity_smfe_period: active_arith_array_sortable[1][1],
            activity_tmfe: active_arith_array_sortable[2][0],
            activity_tmfe_period: active_arith_array_sortable[2][1],
            inactivity_mfe: inactive_arith_array_sortable[0][0],
            inactivity_mfe_period: inactive_arith_array_sortable[0][1],
            inactivity_smfe: inactive_arith_array_sortable[1][0],
            inactivity_smfe_period: inactive_arith_array_sortable[1][1],
            inactivity_tmfe: inactive_arith_array_sortable[2][0],
            inactivity_tmfe_period: inactive_arith_array_sortable[2][1],
         });      

        

        var active_array = res.activity.split(',');
         var iactive_array = [];
         var datatotal= 0;
         for (var arr in res.activity.split(',')) {
          iactive_array.push(parseInt(active_array[arr]));
          datatotal = datatotal+parseInt(active_array[arr]);
         }

         var actTime=Highcharts.chart('activitytime', {
            chart: {
                backgroundColor:'#d0d3e4',
                plotBackgroundColor: '#d0d3e4',
                type: 'bar',
                height:'50%',
                
                width:'400'
            },
            exporting: { enabled: false },
             credits: {
              enabled: false
           },
           colors:  ['#f09c67', '#f7e0a3', '#f1e4e4', '#98FB98'],
            title: {
                text: ''
            },

            xAxis: {
             gridLineWidth: 0,

                categories: ['Intense', 'Moderate', 'Low', 'Inactivity'],
                labels: {
              style: {
                color: 'black',
                   fontSize: '12px'
              }, gridLineWidth: 0,
               visible: false

            }
            },
            yAxis: {
                
                min: 0,
                title: {
                    text: ''
                },
                 visible: false,
                    gridLineWidth: 0,
                    style: {
               color: 'black',
                   fontSize: '12px'
              }
            },
            
            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                    colorByPoint: true,
                    shadow:false,
                    borderWidth:0,
                    dataLabels:{
                        enabled:true,
                        formatter: function() {
                        var pcnt = (mmhrf.y / datatotal) * 100;
                        return '<span style="font-size:12px;color:black;textOutline: 0px contrast;stroke-width: 0">'+ Highcharts.numberFormat(pcnt) +'</span style="font-size:12px;color:black;textOutline: 0px contrast;stroke-width: 0"> % >';
                          //  return '<span style="font-size:12px;color:black;">'+ Highcharts.numberFormat(pcnt) +'</span> %';
                        }
                    }
                }
            },


            series: [{
                 showInLegend: false,  
                     data: iactive_array.reverse()
            }]
         }); 
      } else {
        document.getElementById("actTimeChart").style.display = "none";
        document.getElementById("actArrChart").style.display = "none";
        document.getElementById("inactArrChart").style.display = "none";
      }
    }
    if(nextProps.heartRatesData){
    var res= nextProps.heartRatesData;
    if(res[0]!=undefined)
          {
          if(res[0].normal_sr!=undefined)
          {
          const normal_HR= res[0].normal_sr.split(',');
          const normal_hourly_count=normal_HR[0];
          const normal_hourly_per=normal_HR[1];
          const normal_max=normal_HR[2];
          const normal_min=normal_HR[4];
          
          mmhrf.setState({
               nsrhrc:normal_hourly_count,
               nsrhrper:normal_hourly_per,
               nsrmin:normal_min,
               nsrmax:normal_max,
             });
 
        }
        if(res[0].sinus_tc!=undefined)
          {
          const sinusT_HR= res[0].sinus_tc.split(',');
          const sinusT_hourly_count=sinusT_HR[0];
          const sinusT_hourly_per=sinusT_HR[1];
          const sinust_max=sinusT_HR[2];
          const sinust_min=sinusT_HR[4];
          
          mmhrf.setState({
               sthrc:sinusT_hourly_count,
               sthrper:sinusT_hourly_per,
               stmin:sinust_min,
               stmax:sinust_max,
             });
        }
        if(res[0].sinus_bc!=undefined)
          {
          const sinusB_HR= res[0].sinus_bc.split(',');
          const sinusB_hourly_count=sinusB_HR[0];
          const sinusB_hourly_per=sinusB_HR[1];
          const sinusb_max=sinusB_HR[2];
          const sinusb_min=sinusB_HR[4];
          
          mmhrf.setState({
               sbhrc:sinusB_hourly_count,
               sbhrper:sinusB_hourly_per,
               sbmin:sinusb_min,
               sbmax:sinusb_max,
             });
        }
        if(res[0].vt!=undefined)
          {
          const vt_HR= res[0].vt.split(',');
          const vt_hourly_count=vt_HR[0];
          const vt_hourly_per=vt_HR[1];
          const vt_max=vt_HR[2];
          const vt_min=vt_HR[4];
          mmhrf.setState({
               vthrc:vt_hourly_count,
               vthrper:vt_hourly_per,
               vtmin:vt_min,
               vtmax:vt_max,
             });
        }
        if(res[0].afib!=undefined)
          {
          const afib_HR= res[0].afib.split(',');
          const afib_hourly_count=afib_HR[0];
          const afib_hourly_per=afib_HR[1];
          const afib_max=afib_HR[2];
          const afib_min=afib_HR[4];
          mmhrf.setState({
               afibhrc:afib_hourly_count,
               afibhrper:afib_hourly_per,
               afibmin:afib_min,
               afibmax:afib_max,
             });
        }
        if(res[0].sinus_pause!=undefined)
          {
          const sinus_pause_HR= res[0].sinus_pause.split(',');
          const sinusp_hourly_count=sinus_pause_HR[0];
          const sinusp_hourly_per=sinus_pause_HR[1];
          const sinusp_max=sinus_pause_HR[2];
          const sinusp_min=sinus_pause_HR[4];
          mmhrf.setState({
               sphrc:sinusp_hourly_count,
               sphrper:sinusp_hourly_per,
               spmin:sinusp_min,
               spmax:sinusp_max,
             });
        }
        if(res[0].pac!=undefined)
          {
          const pac_HR= res[0].pac.split(',');
          const pac_hourly_count=pac_HR[0];
          const pac_hourly_per=pac_HR[1];
          const pac_max=pac_HR[2];
          const pac_min=pac_HR[4];
          const cspac=pac_HR[6];
          const ccpac=pac_HR[7];
          const ctpac=pac_HR[8];
          const crunpac=pac_HR[9];
          const cbigpac=pac_HR[10];
          const ctrigpac=pac_HR[11];
          mmhrf.setState({
               pachrc:pac_hourly_count,
               pachrper:pac_hourly_per,
               pacmin:pac_min,
               pacmax:pac_max,
               cspac:cspac,
               ccpac:ccpac,
               ctpac:ctpac,
               crunpac:crunpac,
               cbigpac:cbigpac,
               ctrigpac:ctrigpac,
 
             });
        }
        if(res[0].pvc!=undefined)
          {
          const pvc_HR= res[0].pvc.split(',');
          const pvc_hourly_count=pvc_HR[0];
          const pvc_hourly_per=pvc_HR[1];
          const pvc_max=pvc_HR[2];
          const pvc_min=pvc_HR[4];
          const cspvc=pvc_HR[6];
          const ccpvc=pvc_HR[7];
          const ctpvc=pvc_HR[8];
          const crunpvc=pvc_HR[9];
          const cbigpvc=pvc_HR[10];
          const ctrigpvc=pvc_HR[11];
          mmhrf.setState({
               pvchrc:pvc_hourly_count,
               pvchrper:pvc_hourly_per,
               pvcmin:pvc_min,
               pvcmax:pvc_max,
               cspvc:cspvc,
               ccpvc:ccpvc,
               ctpvc:ctpvc,
               crunpvc:crunpvc,
               cbigpvc:cbigpvc,
               ctrigpvc:ctrigpvc,
             });
        }
       
         }
    }
    if(nextProps.patientwrkdetails)
    {
      var res= nextProps.patientwrkdetails;
      var fdate = res.dob.split('T');
        var now = new Date();
        var birthdate = fdate[0].split("-");
        var dob = birthdate[2]+"-"+(birthdate[1])+"-"+birthdate[0]
        var born = new Date(birthdate[0],(birthdate[1]-1),birthdate[2]);
        var age=mmhrf.get_age(born,now); 

        mmhrf.setState({
          name: res.name,
          device_id: res.device_id,
          weight: res.weight,//+" "+res.weight_unit,
          height: res.height,
          diabetes:res.diabetes,
          hyper_tension:res.hyper_tension==true ? 'Yes' : 'No',
          hyper_lipidemia:res.hyper_lipidemia==true ? 'Yes' : 'No',
          thyroid:res.thyroid,
          drug_history:res.drug_history,
          smoking:res.smoking==true ? 'Yes' : 'No',
          cardiac_surgery:res.cardiac_surgery==true ? 'Yes' : 'No',
          gender:res.gender=="M" ? 'Male' : 'Female',
          dob:dob,
          age:age
        });
    } 
   if(nextProps.minMaxHrCurr){
      
      var nsrHrArr=[],sbHrArr=[],stHrArr=[],spHrArr=[],pacHrArr=[],pvcHrArr=[],vtHrArr=[],afibHrArr=[],pabsentHrArr=[],irrHrArr=[],iprHrArr=[],sqtHrArr=[],lqtHrArr=[],unHrArr=[]
      var res= nextProps.minMaxHrCurr;

      for (var i = 0; i < res['nsr'].length; i++) {

        nsrHrArr[i]=res['nsr'][i]['heartrate'];
    }
    for (var i = 0; i < res['sb'].length; i++) {
    
        sbHrArr[i]=res['sb'][i]['heartrate'];
    }
    for (var i = 0; i < res['st'].length; i++) {
    
        stHrArr[i]=res['st'][i]['heartrate'];
    }
    for (var i = 0; i < res['sp'].length; i++) {
        spHrArr[i]=res['sp'][i]['heartrate'];
    }
    for (var i = 0; i < res['pac'].length; i++) {
        pacHrArr[i]=res['pac'][i]['heartrate'];
    }
    for (var i = 0; i < res['pvc'].length; i++) {
        pvcHrArr[i]=res['pvc'][i]['heartrate'];
    }
    for (var i = 0; i < res['vt'].length; i++) {
        vtHrArr[i]=res['vt'][i]['heartrate'];
    }
    for (var i = 0; i < res['afib'].length; i++) {
        afibHrArr[i]=res['afib'][i]['heartrate'];
    }
    
    if(res['nsr'].length>0)
    {
         const normal_max=Math.max.apply(Math, nsrHrArr.filter(Boolean));
         const normal_min=Math.min.apply(Math, nsrHrArr.filter(Boolean));
         
         mmhrf.setState({
              
              nsrmin:normal_min,
              nsrmax:normal_max,
            });
    }
    if(res['sb'].length>0)
    {
         const _max=Math.max.apply(Math, sbHrArr.filter(Boolean));
         const _min=Math.min.apply(Math, sbHrArr.filter(Boolean));
         
         mmhrf.setState({
              
              sbmin:_min,
              sbmax:_max,
            });
    }
    if(res['st'].length>0)
    {
         const _max=Math.max.apply(Math, stHrArr.filter(Boolean));
         const _min=Math.min.apply(Math, stHrArr.filter(Boolean));
         
         mmhrf.setState({
              
              stmin:_min,
              stmax:_max,
            });
    }
    if(res['sp'].length>0)
    {
         const _max=Math.max.apply(Math, spHrArr.filter(Boolean));
         const _min=Math.min.apply(Math, spHrArr.filter(Boolean));
         
         mmhrf.setState({
              
              spmin:_min,
              spmax:_max,
            });
    }
    if(res['pac'].length>0)
    {
         const _max=Math.max.apply(Math, pacHrArr.filter(Boolean));
         const _min=Math.min.apply(Math, pacHrArr.filter(Boolean));
         
         mmhrf.setState({
              
              pacmin:_min,
              pacmax:_max,
            });
    }
    if(res['pvc'].length>0)
    {
         const _max=Math.max.apply(Math, pvcHrArr.filter(Boolean));
         const _min=Math.min.apply(Math, pvcHrArr.filter(Boolean));
         
         mmhrf.setState({
              
              pvcmin:_min,
              pvcmax:_max,
            });
    }
    if(res['pvc'].length>0)
    {
         const _max=Math.max.apply(Math, pvcHrArr.filter(Boolean));
         const _min=Math.min.apply(Math, pvcHrArr.filter(Boolean));
         
         mmhrf.setState({
              
              pvcmin:_min,
              pvcmax:_max,
            });
    }
     if(res['vt'].length>0)
    {
         const _max=Math.max.apply(Math, vtHrArr.filter(Boolean));
         const _min=Math.min.apply(Math, vtHrArr.filter(Boolean));
         
         mmhrf.setState({
              
              vtmin:_min,
              vtmax:_max,
            });
    }
    if(res['afib'].length>0)
    {
         const _max=Math.max.apply(Math, afibHrArr.filter(Boolean));
         const _min=Math.min.apply(Math, afibHrArr.filter(Boolean));
         
         mmhrf.setState({
              
              afibmin:_min,
              afibmax:_max,
            });
    }

    }
    if(nextProps.minMaxHrFull){
      var nsrHrArrF=[],sbHrArrF=[],stHrArrF=[],spHrArrF=[],pacHrArrF=[],pvcHrArrF=[],vtHrArrF=[],afibHrArrF=[],pabsentHrArrF=[],irrHrArrF=[],iprHrArrF=[],sqtHrArrF=[],lqtHrArrF=[],unHrArrF=[];
      var res=nextProps.minMaxHrFull;
      for (var i = 0; i < res['nsr'].length; i++) {

        nsrHrArrF[i]=res['nsr'][i]['heartrate'];
      }
      for (var i = 0; i < res['sb'].length; i++) {
    
        sbHrArrF[i]=res['sb'][i]['heartrate'];
      }
      for (var i = 0; i < res['st'].length; i++) {
    
        stHrArrF[i]=res['st'][i]['heartrate'];
      }
      for (var i = 0; i < res['sp'].length; i++) {
        spHrArrF[i]=res['sp'][i]['heartrate'];
      }
      for (var i = 0; i < res['pac'].length; i++) {
        pacHrArrF[i]=res['pac'][i]['heartrate'];
      }
      for (var i = 0; i < res['pvc'].length; i++) {
        pvcHrArrF[i]=res['pvc'][i]['heartrate'];
      }
      for (var i = 0; i < res['vt'].length; i++) {
        vtHrArrF[i]=res['vt'][i]['heartrate'];
      }
      for (var i = 0; i < res['afib'].length; i++) {
        afibHrArrF[i]=res['afib'][i]['heartrate'];
      }
      for (var i = 0; i < res['pabsent'].length; i++) {
        pabsentHrArrF[i]=res['pabsent'][i]['heartrate'];
      }
    
      for (var i = 0; i < res['irr'].length; i++) {
        irrHrArrF[i]=res['irr'][i]['heartrate'];
      }
    
      for (var i = 0; i < res['ipr'].length; i++) {
        iprHrArrF[i]=res['ipr'][i]['heartrate'];
      }
    
      for (var i = 0; i < res['sqt'].length; i++) {
        sqtHrArrF[i]=res['sqt'][i]['heartrate'];
      }
     
      for (var i = 0; i < res['pqt'].length; i++) {
        lqtHrArrF[i]=res['pqt'][i]['heartrate'];
      }
    
      for (var i = 0; i < res['un'].length; i++) {
        
        unHrArrF[i]=res['un'][i]['heartrate'];
      }
    
      if(res['nsr'].length>0)
      {
         const normal_max=Math.max.apply(Math, nsrHrArrF.filter(Boolean));
       const normal_min=Math.min.apply(Math, nsrHrArrF.filter(Boolean));
       
       mmhrf.setState({
            
            normal_min:normal_min,
            normal_max:normal_max,
          });
      }
      if(res['sb'].length>0)
      {
         const _max=Math.max.apply(Math, sbHrArrF.filter(Boolean));
       const _min=Math.min.apply(Math, sbHrArrF.filter(Boolean));
       
       mmhrf.setState({
            
            sinusb_min:_min,
            sinusb_max:_max,
          });
      }
      if(res['st'].length>0)
      {
         const _max=Math.max.apply(Math, stHrArrF.filter(Boolean));
       const _min=Math.min.apply(Math, stHrArrF.filter(Boolean));
       
       mmhrf.setState({
            
            sinust_min:_min,
            sinust_max:_max,
          });
      }
      if(res['sp'].length>0)
      {
         const _max=Math.max.apply(Math, spHrArrF.filter(Boolean));
       const _min=Math.min.apply(Math, spHrArrF.filter(Boolean));
       
       mmhrf.setState({
            
            sinus_pause_min:_min,
            sinus_pause_max:_max,
          });
      }
      if(res['pac'].length>0)
      {
         const _max=Math.max.apply(Math, pacHrArrF.filter(Boolean));
       const _min=Math.min.apply(Math, pacHrArrF.filter(Boolean));
       
       mmhrf.setState({
            
            pac_min:_min,
            pac_max:_max,
          });
      }
      if(res['pvc'].length>0)
      {
         const _max=Math.max.apply(Math, pvcHrArrF.filter(Boolean));
       const _min=Math.min.apply(Math, pvcHrArrF.filter(Boolean));
      
       mmhrf.setState({
            
            pvc_min:_min,
            pvc_max:_max,
          });
      }
     
       if(res['vt'].length>0)
      {
         const _max=Math.max.apply(Math, vtHrArrF.filter(Boolean));
       const _min=Math.min.apply(Math, vtHrArrF.filter(Boolean));
      
       mmhrf.setState({
            
            vt_min:_min,
            vt_max:_max,
          });
      }
      if(res['afib'].length>0)
      {
         const _max=Math.max.apply(Math, afibHrArrF.filter(Boolean));
       const _min=Math.min.apply(Math, afibHrArrF.filter(Boolean));
    
       mmhrf.setState({
            
            afib_min:_min,
            afib_max:_max,
          });
      }
    

    }
  }
  get_age(born, now) {
    var birthday = new Date(now.getFullYear(), born.getMonth(), born.getDate());
    if (now >= birthday) 
      return now.getFullYear() - born.getFullYear();
    else
      return now.getFullYear() - born.getFullYear() - 1;
  }
  render() {
    return (
      <div className="summary" id="summary">
       <div className="">
          <div className="row">
             <div className="col-md-6">
                <div className="">
                   <div id="collapseExample1" className="panel-body txt-dark">
                      <div className="panel panel-success ">
                         <div className="panel-heading text-center" aria-expanded="true" data-toggle="collapse" href="#Events">Patient Details </div>
                         <div className="panel-body" id="Events">
                            <div className="row" id="summaryPatinetDetails">
                              <div className="col-md-6 col-xs-6 col-sm-6 ">
                                <div className="row">
                                  <div className="col-md-5"><strong>Name</strong></div>
                                  <div className="col-md-1 text-center">:</div>
                                  <div className="col-md-5">{this.state.name}</div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5"><strong>Patient Id</strong></div>
                                  <div className="col-md-1 text-center">:</div>
                                  <div className="col-md-5">{this.state.device_id}</div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5"><strong>Gender</strong></div>
                                  <div className="col-md-1 text-center">:</div>
                                  <div className="col-md-5">{this.state.gender}</div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5"><strong>Age</strong></div>
                                  <div className="col-md-1 text-center">:</div>
                                  <div className="col-md-5">{this.state.age}</div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5"><strong>Weight</strong></div>
                                  <div className="col-md-1 text-center">:</div>
                                  <div className="col-md-5">{this.state.weight}</div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5"><strong>Height</strong></div>
                                  <div className="col-md-1 text-center">:</div>
                                  <div className="col-md-5">{this.state.height}</div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5"><strong>DOB</strong></div>
                                  <div className="col-md-1 text-center">:</div>
                                  <div className="col-md-5">{this.state.dob}</div>
                                </div>
                              </div>

                              <div className="col-md-6 col-xs-6 col-sm-6">
                                <div className="row">
                                  <div className="col-md-6"><strong>Diabetes</strong></div>
                                  <div className="col-md-1 text-center">:</div>
                                  <div className="col-md-4">{this.state.diabetes}</div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6"><strong>Hypertension</strong></div>
                                  <div className="col-md-1 text-center">:</div>
                                  <div className="col-md-4">{this.state.hyper_tension}</div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6"><strong>Hyperlipidemia</strong></div>
                                  <div className="col-md-1 text-center">:</div>
                                  <div className="col-md-4">{this.state.hyper_lipidemia}</div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6"><strong>Thyroid</strong></div>
                                  <div className="col-md-1 text-center">:</div>
                                  <div className="col-md-4">{this.state.thyroid}</div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6"><strong>Drug History</strong></div>
                                  <div className="col-md-1 text-center">:</div>
                                  <div className="col-md-4">{this.state.drug_history}</div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6"><strong>Smoking Habit</strong></div>
                                  <div className="col-md-1 text-center">:</div>
                                  <div className="col-md-4">{this.state.smoking}</div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6"><strong>Cardiac Surgery</strong></div>
                                  <div className="col-md-1 text-center">:</div>
                                  <div className="col-md-4">{this.state.cardiac_surgery}</div>
                                </div>
                              </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             <div className="col-md-6">
                <div  className="tab-struct custom-tab-1">
                  <ul role="tablist" className="nav nav-tabs" id="myTabs_11">
                      <li className="active" role="presentation" id="myTabs_11_id11"><a aria-expanded="true"  data-toggle="tab" role="tab"  href="#currentDataSetGraph" onClick={() => this.toggleSameC()} id="myTabs_11_id1">Current</a></li>
                      <li role="presentation" className="" id="myTabs_11_id22"><a  data-toggle="tab"  role="tab" href="#fullDataSetGrapg" onClick={() => this.toggleSame()} aria-expanded="false" id="myTabs_11_id2">Full</a></li>
                  </ul>
                  <div className="tab-content" id="myTabContent_11">
                    <div  id="currentDataSetGraph" className="tab-pane fade active in" role="tabpanel">
                      <div id="chart_div_full"></div>    
                    </div>
                    <div  id="fullDataSetGrapg" className="tab-pane fade" role="tabpanel">
                      <div id="chart_div_fullData"></div>
                    </div>
                  </div>
                </div>
             </div>
          </div>
       </div>
       <div  className="pills-struct">
          <ul role="tablist" className="nav nav-tabs" id="myTabs_111">
              <li className="active" role="presentation"><a aria-expanded="true"  data-toggle="tab" role="tab" id="home_tab_11" href="#currentDataSet" onClick={() => this.toggleSameD()}>Current</a></li>
              <li role="presentation" className=""><a  data-toggle="tab" id="profile_tab_11" role="tab" href="#fullDataSet" onClick={() => this.toggleSameB()} aria-expanded="false">Full</a></li>
          </ul>
          <div className="tab-content" id="myTabContent_11">
              <div  id="currentDataSet" className="tab-pane fade active in" role="tabpanel">
                <table id="tablePreview table1" className="table  table-hover table-bordered table-responsive">
                  <thead>
                     <tr>
                        <th>ARRHYTHMIAS</th>
                        <th>BEATS</th>
                        <th>MIN HR</th>
                        <th>MAX HR</th>
                        <th>PERCENTAGE</th>
                     </tr>
                  </thead>
                  <tbody id="full_data" >
                   { this.state.nsr!="0"&& this.state.nsrmin!= "0" && this.state.nsrmax!="0" && this.state.nsr_per!="0.00" &&
                     <tr>
                        <th scope="row">Normal Sinus rhythm</th>
                        <td>{this.state.nsr}</td>
                        <td>{this.state.nsrmin}</td>
                        <td>{this.state.nsrmax}</td>
                        <td>{this.state.nsr_per}</td>
                     </tr>
                   }
                   { this.state.st!="0"&& this.state.stmin!= "0" && this.state.stmax!="0" && this.state.st_per!="0.00" &&
                     <tr>
                        <th scope="row">Sinus Tachycardia</th>
                        <td>{this.state.st}</td>
                        <td>{this.state.stmin}</td>
                        <td>{this.state.stmax}</td>
                        <td>{this.state.st_per}</td>
                     </tr>
                   }
                   { this.state.sb!="0"&& this.state.sbmin!= "0" && this.state.sbmax!="0" && this.state.sb_per!="0.00" &&
                     <tr>
                        <th scope="row">Sinus Bradycardia</th>
                        <td>{this.state.sb}</td>
                        <td>{this.state.sbmin}</td>
                        <td>{this.state.sbmax}</td>
                        <td>{this.state.sb_per}</td>
                     </tr>
                   }
                   { this.state.sp!="0"&& this.state.spmin!= "0" && this.state.spmax!="0" && this.state.sp_per!="0.00" &&
                     <tr>
                        <th scope="row">Sinus Pause</th>
                        <td>{this.state.sp}</td>
                        <td>{this.state.spmin}</td>
                        <td>{this.state.spmax}</td>
                        <td>{this.state.sp_per}</td>
                     </tr>
                   }
                   { this.state.pac!="0"&& this.state.pacmin!= "0" && this.state.pacmax!="0" && this.state.pac_per!="0.00" &&
                     <tr>
                        <th scope="row">PAC</th>
                        <td>{this.state.pac}</td>
                        <td>{this.state.pacmin}</td>
                        <td>{this.state.pacmax}</td>
                        <td>{this.state.pac_per}</td>
                     </tr>
                   }
                    { this.state.pvc!="0"&& this.state.pvcmin!= "0" && this.state.pvcmax!="0" && this.state.pvc_per!="0.00" &&
                     <tr>
                        <th scope="row">PVC</th>
                        <td>{this.state.pvc}</td>
                        <td>{this.state.pvcmin}</td>
                        <td>{this.state.pvcmax}</td>
                        <td>{this.state.pvc_per}</td>
                     </tr>
                   }
                      { this.state.vt!="0"&& this.state.vtmin!= "0" && this.state.vtmax!="0" && this.state.vt_per!="0.00" &&
                     <tr>
                    
                    
                  
                        <th scope="row">Ventricular Tachycardia</th>
                        <td>{this.state.vt}</td>
                        <td>{this.state.vtmin}</td>
                        <td>{this.state.vtmax}</td>
                        <td>{this.state.vt_per}</td>
                     </tr>
                   }
                    { this.state.afib!="0"&& this.state.afibmin!= "0" && this.state.afibmax!="0" && this.state.afib_per!="0.00" &&
                     <tr>
                        <th scope="row">AFib</th>
                        <td>{this.state.afib}</td>
                        <td>{this.state.afibmin}</td>
                        <td>{this.state.afibmax}</td>
                        <td>{this.state.afib_per}</td>
                     </tr>
                   }

                  </tbody>
                </table>
              </div>
              <div  id="fullDataSet" className="tab-pane fade" role="tabpanel">
                <table id="tablePreview2 table2" className="table  table-hover table-bordered table-responsive">
                  <thead>
                     <tr>
                        <th>ARRHYTHMIAS</th>
                        <th>BEATS</th>
                        <th>MIN HR</th>
                        <th>MAX HR</th>
                        <th>PERCENTAGE</th>
                     </tr>
                  </thead>
                  <tbody id="full_data" >
                   { this.state.nsrData!="0"&& this.state.normal_min!= "0" && this.state.normal_max!="0" && this.state.nsr_perData!="0.00" &&
                     <tr>
                        <th scope="row">Normal Sinus rhythm</th>
                        <td>{this.state.nsrData}</td>
                        <td>{this.state.normal_min}</td>
                        <td>{this.state.normal_max}</td>
                        <td>{this.state.nsr_perData}</td>
                     </tr>
                   }
                   { this.state.stData!="0"&& this.state.sinust_min!= "0" && this.state.sinust_max!="0" && this.state.st_perData!="0.00" &&
                     <tr>
                        <th scope="row">Sinus Tachycardia</th>
                        <td>{this.state.stData}</td>
                        <td>{this.state.sinust_min}</td>
                        <td>{this.state.sinust_max}</td>
                        <td>{this.state.st_perData}</td>
                     </tr>
                   }
                    { this.state.sbData!="0"&& this.state.sinusb_min!= "0" && this.state.sinusb_max!="0" && this.state.sb_perData!="0.00" &&
                     <tr>
                        <th scope="row">Sinus Bradycardia</th>
                        <td>{this.state.sbData}</td>
                        <td>{this.state.sinusb_min}</td>
                        <td>{this.state.sinusb_max}</td>
                        <td>{this.state.sb_perData}</td>
                     </tr>
                   }
                     { this.state.spData!="0"&& this.state.sinus_pause_min!= "0" && this.state.sinus_pause_max!="0" && this.state.sp_perData!="0.00" &&
                     <tr>
                        <th scope="row">Sinus Pause</th>
                        <td>{this.state.spData}</td>
                        <td>{this.state.sinus_pause_min}</td>
                        <td>{this.state.sinus_pause_max}</td>
                        <td>{this.state.sp_perData}</td>
                     </tr>
                   }
                    { this.state.pacData!="0"&& this.state.pac_min!= "0" && this.state.pac_max!="0" && this.state.pac_perData!="0.00" &&
                     <tr>
                        <th scope="row">PAC</th>
                        <td>{this.state.pacData}</td>
                        <td>{this.state.pac_min}</td>
                        <td>{this.state.pac_max}</td>
                        <td>{this.state.pac_perData}</td>
                     </tr>
                   }
                    { this.state.pvcData!="0"&& this.state.pvc_min!= "0" && this.state.pvc_max!="0" && this.state.pvc_perData!="0.00" &&
                     <tr>
                        <th scope="row">PVC</th>
                        <td>{this.state.pvcData}</td>
                        <td>{this.state.pvc_min}</td>
                        <td>{this.state.pvc_max}</td>
                        <td>{this.state.pvc_perData}</td>
                     </tr>
                   }
                    { this.state.vtData!="0"&& this.state.vt_min!= "0" && this.state.vt_max!="0" && this.state.vt_perData!="0.00" &&
                     <tr>
                        <th scope="row">Ventricular Tachycardia</th>
                        <td>{this.state.vtData}</td>
                        <td>{this.state.vt_min}</td>
                        <td>{this.state.vt_max}</td>
                        <td>{this.state.vt_perData}</td>
                     </tr>
                   }
                    { this.state.afibData!="0"&& this.state.afib_min!= "0" && this.state.afib_max!="0" && this.state.afib_perData!="0.00" &&
                     <tr>

                        <th scope="row">AFib</th>
                        <td>{this.state.afibData}</td>
                        <td>{this.state.afib_min}</td>
                        <td>{this.state.afib_max}</td>
                        <td>{this.state.afib_perData}</td>
                     </tr>
                   }
                  </tbody>
                </table>
              </div>
          </div>
       </div>
       <div  className="pills-struct">
          <ul role="tablist" className="nav nav-tabs" id="myTabs_1111">

              <li className="active" role="presentation"><a aria-expanded="true"  data-toggle="tab" role="tab" id="home_tab_111" href="#currentEventDataSet" onClick={() => this.toggleSameF()}>Current</a></li>
              <li role="presentation" className=""><a  data-toggle="tab" id="profile_tab_111" role="tab" href="#fullEventDataSet"  aria-expanded="false" onClick={() => this.toggleSameE()}>Full</a></li>
          </ul>
          <div className="tab-content" id="myTabContent_111">
              <div  id="currentEventDataSet" className="tab-pane fade active in" role="tabpanel">
                 <table id="tablePreview table1" className="table  table-hover table-bordered table-responsive ">
              <tbody>
                <tr>
                  <th colSpan="1">Event</th>
                  <th rowSpan="1">No Of Events</th>
                  <th rowSpan="1">Single</th>
                  <th rowSpan="1">Couplet</th>
                  <th rowSpan="1">Triplet</th>
                  <th rowSpan="1">Run</th>
                  <th rowSpan="1">Count Of Bigeminy</th>
                  <th rowSpan="1">Count Of Triigeminy</th>
                  
                  
                </tr>
               
                    <tr>
                             

                                 <td rowSpan="1">PVC</td>
                              
                              
                              <td>{this.state.pvc}</td>
                              <td>{this.state.cspvc}</td>
                              <td>{this.state.ccpvc}</td>
                              <td>{this.state.ctpvc}</td>
                              <td>{this.state.crunpvc}</td>
                              <td>{this.state.cbigpvc}</td>
                              <td>{this.state.ctrigpvc}</td>
                             
                              
                            </tr>
                            <tr>
                               <td rowSpan="1">PAC</td>
                              
                              
                              <td>{this.state.pachrc}</td>
                              <td>{this.state.cspac}</td>
                              <td>{this.state.ccpac}</td>
                              <td>{this.state.ctpac}</td>
                              <td>{this.state.crunpac}</td>
                              <td>{this.state.cbigpac}</td>
                              <td>{this.state.ctrigpac}</td>
                             
                              
                            </tr>
                 
                
               </tbody>
            </table>
              </div>
              <div  id="fullEventDataSet" className="tab-pane fade" role="tabpanel">
                <table id="tablePreview table1" className="table  table-hover table-bordered table-responsive ">
              <tbody>
                <tr>
                  <th colSpan="1">Event</th>
                  <th rowSpan="1">No Of Events</th>
                  <th rowSpan="1">Single</th>
                  <th rowSpan="1">Couplet</th>
                  <th rowSpan="1">Triplet</th>
                  <th rowSpan="1">Run</th>
                  <th rowSpan="1">Count Of Bigeminy</th>
                  <th rowSpan="1">Count Of Triigeminy</th>
                  
                  
                </tr>
               <tr>
                             

                                 <td rowSpan="1">PVC</td>
                              
                              
                             <td>{this.state.pvcCount}</td>
                              <td>{this.state.pvcCs}</td>
                              <td>{this.state.pvcCc}</td>
                              <td>{this.state.pvcCt}</td>
                              <td>{this.state.pvcCrun}</td>
                              <td>{this.state.pvcCbi}</td>
                              <td>{this.state.pvcCtri}</td>
                              
                            </tr>
                            <tr>
                               <td rowSpan="1">PAC</td>
                              
                              
                               <td>{this.state.pacCount}</td>
                              <td>{this.state.pacCs}</td>
                              <td>{this.state.pacCc}</td>
                              <td>{this.state.pacCt}</td>
                              <td>{this.state.pacCrun}</td>
                              <td>{this.state.pacCbi}</td>
                              <td>{this.state.pacCtri}</td>
                              
                            </tr>
               </tbody>
            </table>
              </div>
          </div>
       </div>
       <div className="">
          <div id="" className="panel-body txt-dark ">
             <div className="panel panel-success " style={{"marginBottom": "0px"}}>
                <div className="panel-heading collapsed" aria-expanded="false" data-toggle="collapse" href="#Events">CARDIAC EVENT DURING ACTIVITY </div>
                <br/>
                <div className="panel-body" id="summaryActivityEvents">
                   <div className="col-md-12">
                      <div className="col-md-7">
                        <div className="row">
                          <div className="col-md-5">Most Frequent Event</div>
                          <div className="col-md-1 text-center">:</div>
                          <div className="col-md-5">{this.state.activity_mfe}</div>
                        </div>
                        <div className="row">
                          <div className="col-md-5">Second Most Frequent Event</div>
                          <div className="col-md-1 text-center">:</div>
                          <div className="col-md-5">{this.state.activity_smfe}</div>
                        </div>
                        <div className="row">
                          <div className="col-md-5">Third Most Frequent Event</div>
                          <div className="col-md-1 text-center">:</div>
                          <div className="col-md-5">{this.state.activity_tmfe}</div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="row">
                          <div className="col-md-3">Episodes</div>
                          <div className="col-md-1 text-center">:</div>
                          <div className="col-md-5">{this.state.activity_mfe_period}</div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">Episodes</div>
                          <div className="col-md-1 text-center">:</div>
                          <div className="col-md-5">{this.state.activity_smfe_period}</div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">Episodes</div>
                          <div className="col-md-1 text-center">:</div>
                          <div className="col-md-5">{this.state.activity_tmfe_period}</div>
                        </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
          <div className="panel-body  txt-dark">
            <div className="panel panel-success" style={{"marginBottom": "0px"}}>
              <div className="panel-heading collapsed" aria-expanded="false" data-toggle="collapse" href="#Events">CARDIAC EVENT DURING INACTIVITY</div>
                <br/>
                <div className="panel-body" id="summaryInactivityEvents">
                  <div className="col-md-12">
                    <div className="col-md-7">
                      <div className="row">
                        <div className="col-md-5">Most Frequent Event</div>
                        <div className="col-md-1 text-center">:</div>
                        <div className="col-md-5">{this.state.inactivity_mfe}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-5">Second Most Frequent Event</div>
                        <div className="col-md-1 text-center">:</div>
                        <div className="col-md-5">{this.state.inactivity_smfe}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-5">Third Most Frequent Event</div>
                        <div className="col-md-1 text-center">:</div>
                        <div className="col-md-5">{this.state.inactivity_tmfe}</div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="row">
                        <div className="col-md-3">Episodes</div>
                        <div className="col-md-1 text-center">:</div>
                        <div className="col-md-5">{this.state.inactivity_mfe_period}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">Episodes</div>
                        <div className="col-md-1 text-center">:</div>
                        <div className="col-md-5">{this.state.inactivity_smfe_period}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">Episodes</div>
                        <div className="col-md-1 text-center">:</div>
                        <div className="col-md-5">{this.state.inactivity_tmfe_period}</div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
       </div>
       <div className="row activityGraphCont">
          <div className="col-md-4" id="actTimeChart">
             <h5 style={{ color: 'white',
            alignItems: "center",
            justifyContent: "center", 
              }
            }>Activity Time </h5>
             <br/>
             <div id="activitytime" ></div>
          </div>
          <div className="col-md-4" id="actArrChart">
            <h5 style={{ color: 'white',
            alignItems: "center",
            justifyContent: "center", 
              }
            }>Activity Arrhythmia </h5>
             <br/>
             <div id="activityarrhythmia"></div>
          </div>
          <div className="col-md-4" id="inactArrChart">
             <h5 style={{ color: 'white',
            alignItems: "center",
            justifyContent: "center", 
              }
            }>InActivity Arrhythmia </h5>
             <br/>
             <div id="inactivityarrhythmia" ></div>
          </div>
       </div>
       
       <div className="row">
          <div className="col-md-12">
            <table id="tablePreview table1" className="table  table-hover table-bordered table-responsive ">
              <tbody>
                <tr>
                  <th colSpan="2">Event</th>
                  <th rowSpan="1">No Of Events</th>
                  <th rowSpan="1">Single</th>
                  <th rowSpan="1">Couplet</th>
                  <th rowSpan="1">Triplet</th>
                  <th rowSpan="1">Run</th>
                  <th rowSpan="1">Count Of Bigeminy</th>
                  <th rowSpan="1">Count Of Triigeminy</th>
                  <th rowSpan="1">Max-HR</th>
                  <th rowSpan="1">Min-HR</th>
                  <th rowSpan="1">Run (&gt; 3 beats)</th>
                </tr>
                {
                  Object.keys(this.state.table).map((val,key) => {
                    var currentpvc = this.state.table[val].pvc.split(',');
                    return  <tr key={key}>
                              {val == 0 &&
                                 <td rowSpan={this.state.table.length}>PVC</td>
                              }
                              <td>Day {key+1}</td>
                              <td>{currentpvc[0]}</td>
                              <td>{currentpvc[10]}</td>
                              <td>{currentpvc[11]}</td>
                              <td>{currentpvc[12]}</td>
                              <td>{currentpvc[13]}</td>
                              <td>{currentpvc[16]}</td>
                              <td>{currentpvc[17]}</td>
                              <td>{currentpvc[14]}</td>
                              <td>{currentpvc[15]}</td>
                              <td>{currentpvc[13]}</td>
                            </tr>;
                  })
                }
                {
                  Object.keys(this.state.table).map((val,key) => {
                    var currentpac = this.state.table[val].pac.split(',');
                    return  <tr key={key}>
                              {val == 0 &&
                                 <td rowSpan={this.state.table.length}>PAC</td>
                              }
                              <td>Day {key+1}</td>
                              <td>{currentpac[0]}</td>
                              <td>{currentpac[10]}</td>
                              <td>{currentpac[11]}</td>
                              <td>{currentpac[12]}</td>
                              <td>{currentpac[13]}</td>
                              <td>{currentpac[16]}</td>
                              <td>{currentpac[17]}</td>
                              <td>{currentpac[14]}</td>
                              <td>{currentpac[15]}</td>
                              <td>{currentpac[13]}</td>
                            </tr>;
                  })
                }
               </tbody>
            </table>
          </div>
       </div>
    </div>
    )
  }
}

export default VerifierSummary;