import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginPatient } from '../../actions/authActions';
import classnames from 'classnames';


class UserLogin extends Component {

    constructor() {
        super();
        this.state = {
              user: '',
              password: '',
              errors: {}
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/user-home');
        }
    }

    componentWillReceiveProps(nextProps) { 
        if(nextProps.auth.isAuthenticated) {
            this.props.history.push('user-home');
        } 
        if(nextProps.errors) {
            console.log(nextProps.errors);
            this.setState({errors: nextProps.errors});
        } 
    }

    onChange = e => {
        var err = this.state.errors;
        delete err[e.target.name];
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = e => {
        
        e.preventDefault();

        const userData = {
          user: this.state.user,
          password: this.state.password,
        };
        this.props.loginPatient(userData);
    }


  render() {
    const { errors } = this.state;
    
    return (
    <div className="wrapper pa-0">
    	<header className="sp-header">
    		<div className="sp-logo-wrap pull-left">
    			<a href="/">
    				<img className="brand-img mr-10" src="dist/img/logo.png" alt="brand"/>
    				<span className="brand-text">Biocalculus</span>
    			</a>
    		</div>
    		<div className="form-group mb-0 pull-right">
    			<span className="inline-block pr-10"><a className="inline-block btn btn-info btn-success btn-rounded btn-outline" href="/login">Are you an Doctor?</a></span>
    			<a className="inline-block btn btn-info btn-success btn-rounded btn-outline txt-signup-link" href="/user-signup">Sign Up</a>
    		</div>
    		<div className="clearfix"></div>
    	</header>
    	
    	<div className="page-wrapper pa-0 ma-0 auth-page">
    		<div className="container-fluid">
    			<div className="table-struct full-width full-height">
    				<div className="table-cell vertical-align-middle auth-form-wrap">
    					<div className="auth-form  ml-auto mr-auto no-float">
    						<div className="row">
    							<div className="col-sm-12 col-xs-12">
    								<div className="mb-30">
    									<h3 className="text-center txt-dark mb-10">Sign in as User</h3>
    									<h6 className="text-center nonecase-font txt-grey">Enter your details below</h6>
                                        <p className="errorBox">{errors.subscription_ends}</p>
    								</div>  
                                    <div 
                                        className={classnames('mb-30 error-container',{
                                                    'error-container-show' : errors.result
                                                 })}
                                    >
                                        <h6 className="text-center nonecase-font txt-red">{errors.result}</h6>
                                    </div>
    								<div className="form-wrap">
    									<form method="post" onSubmit={this.onSubmit}>
    										<div className="form-group">
    											<label className="control-label mb-10" htmlFor="exampleInputEmail_2">Email Address/ Phone Number</label>
    											<input 
                                                    type="text" 
                                                    className={classnames('form-control',{
                                                        'errors' : errors.user
                                                    })} 
                                                    name="user" 
                                                    required="" 
                                                    id="user" 
                                                    onChange={this.onChange}
                                                    value={this.state.user}
                                                    placeholder="Email Address/ Phone Number" />
    										</div>
    										<div className="form-group">
    											<label className="pull-left control-label mb-10" htmlFor="exampleInputpwd_2">Password</label>
    											<a className="capitalize-font txt-primary block mb-10 pull-right font-12" href="/patient/forgot-password">forgot password ?</a>
    											<div className="clearfix"></div>
    											<input 
                                                    type="password" 
                                                    className={classnames('form-control',{
                                                        'errors' : errors.password
                                                    })} 
                                                    name="password" 
                                                    data-minlength="6" 
                                                    required="" 
                                                    id="password" 
                                                    placeholder="Enter password" 
                                                    onChange={this.onChange}
                                                    value={this.state.password}
                                                />
    										</div>
    										
    										<div className="form-group">
    											<div className="checkbox checkbox-primary pr-10 pull-left">
    												<input id="checkbox_2" required="" type="checkbox" />
    												<label htmlFor="checkbox_2"> Keep me logged in</label>
    											</div>
    											<div className="clearfix"></div>
    										</div>
    										<div className="form-group text-center">
    											<button type="submit" className="btn btn-info btn-success btn-rounded txt-signup-btn">sign
                                                 in</button>
    										</div>
    									</form>
    								</div>
    							</div>  
    						</div>
    					</div>
    				</div>
    			</div>
    		</div>
    	</div>
    </div>
    )
  }
}


UserLogin.propTypes = {
    loginPatient: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps, { loginPatient })(withRouter(UserLogin));