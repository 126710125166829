import React, {Component} from 'react';
import classnames from 'classnames';
import axios from 'axios';
import apiUrl from '../../config/config';
import $ from "jquery";

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import TopNav from '../../components/common/TopNav';

import SettingsTab from './SettingsTab';
import UnanalysedSideBarGraph from './UnanalysedSideBarGraph';

require('highcharts-multicolor-series')(Highcharts);

class Unanalysed extends Component {

    constructor() {
      super();
      this.state = {
        menus : [
          {title:'Patient List',url:'/doctor-home'},
        ],
        profileLink : '/doctor-profile',
        treemenu:[],
        rpeaks:[],
        databaseid:'',
        reoprtLink:'#',
        reportStatus:'',
        images:{},
        dblink:'',
        nextDbId:'',
        prevDbId:'',

        chart:'',
        result:[],
        result2:[],
        result3:[],
        charts:[],
        nsrarr:[],
        starr:[],
        unarr:[],
        sbarr:[],
        vtarr:[],
        sparr:[],
        lqtarr:[],
        sqtarr:[],
        irrrarr:[],
        irprarr:[],
        afarr:[],
        pacarr:[],
        pvcarr:[],
        paarr:[],
        defarr:[],
        selectedChart:0,

        mainEcgArray: [],
        mainActivityArray: [],
        datasetMnt:0,
        cuurentStrip : '',
        currentTab : 'chart_tab_2'
      };
      this.updateState = this.updateState.bind(this);
      this.updateRpeaks = this.updateRpeaks.bind(this);
      this.regenerateReport = this.regenerateReport.bind(this);
      this.lockReport = this.lockReport.bind(this);
      this.newDataClick = this.newDataClick.bind(this);
    }

    setTab(e) {
      var currentTab = e.currentTarget.id;
      this.setState({currentTab:currentTab});
    }

    updateState(e) {
      console.log(e.currentTarget.id);
      var clickedPI = e.currentTarget.id.toString();
      var clickedPI_ = clickedPI.substring(0, 2)+':'+clickedPI.substring(2, 4)+':'+clickedPI.substring(4, 6)
      var userid = this.props.match.params.userid; 
      var databaseid = this.props.match.params.databaseid;
      if (typeof databaseid === 'undefined'){
        databaseid="0";
      }
      const data = {
        userid : userid,
        databaseid : databaseid,
        clickedPI_:clickedPI_
      };
      axios
        .post(apiUrl+'api/common/getRpeakIndex',data)
        .then(res => {
          var clickedPI = res.data.rpeakindex;

          document.getElementById('chart_tab_2').click();
          if(clickedPI>15000){
            var stripIndex = Math.floor(clickedPI/15000);
          } else {
            var stripIndex = 0;
          }
          var sliceStart = stripIndex*15000;
          var sliceEnd = sliceStart + 15001;
          var sliceActStart = stripIndex*60;
          var sliceActEnd = sliceActStart + 61;
          var currentEcgArray = this.state.mainEcgArray.slice(sliceStart, sliceEnd);
          var currentActArray = this.state.mainActivityArray.slice(sliceActStart, sliceActEnd);

          var stripX = Math.floor(clickedPI%15000);
          console.log(stripIndex+" "+stripX);
          var tempChart;
          var colorArray = ['#98FB98','#f1e4e4','#f7e0a3','#f09c67'];

          var val = stripX;
          console.log(this.state.chart.xAxis[0]);
          var clickIndex;
          clickIndex = stripIndex;

          if (val - 500 <= 0) {
            this.state.chart.xAxis[0].setExtremes(val, val + 1000, true, false);
          } else {
            this.state.chart.xAxis[0].setExtremes(val - 500, val + 500);
          }

          
          var acti = 125;
          var actarr = [];
          var tttarr1 = [];

          for (var i = 0; i < currentActArray.length; i++) {
            actarr.push({
              x: acti,
              y: 0.5,
              color: colorArray[currentActArray[i]]
            });
            acti = acti + 250;
          }

          var prevChart = document.getElementById("selectedChart").value;
          var beats = JSON.parse(document.getElementById("beatSlected").value);
          if(beats.length>0) {
            for (var i = 0; i < beats.length; i++) {
              var xBeat = parseInt(beats[i])-(15000*prevChart)
              this.state.chart.xAxis[0].removePlotBand('plotband-'+xBeat) ;          
            }
            document.getElementById("beatSlected").value='[]';
          }

          this.state.chart.series[0].setData(currentEcgArray,true);
          this.state.chart.series[1].setData(actarr,true);
          //this.state.chart.series[2].setData(this.state.result3[clickIndex],true);         

          var chart1 = this.state.charts[stripIndex];
          var tempChart = this.state.charts[document.getElementById("selectedChart").value];

          this.setState({['selectedChart']:clickIndex});
          var lineWidth = 2

          if (tempChart != undefined) {
            if (tempChart.draggablePlotLine != undefined) {

              tempChart.draggablePlotLine.destroy();
            }
          }
          
          this.state.charts[stripIndex].draggablePlotLine = this.state.charts[stripIndex].renderer.rect(this.state.charts[stripIndex].xAxis[0].toPixels(stripX)-this.state.charts[stripIndex].xAxis[0].toPixels(25), 10, 50, 40, 5)
          .attr({
            'stroke-width': 2,
            opacity:0.5,
            fill: 'grey',
            zIndex: 3
          })
          .add();

          if (this.state.charts[stripIndex].container.onmousemove != undefined) {
            this.state.charts[stripIndex].container.onmousemove = function(e) {
              return false;
            }
          }
          var mainCChart = this.state.chart;
          this.state.charts[stripIndex].container.onmousemove = function(e) {
            if (chart1.draggablePlotLine.drag) {

              let normalizedEvent = chart1.pointer.normalize(e),
              extremes = {
                left: chart1.plotLeft,
                right: chart1.plotLeft + chart1.plotWidth
              };

              if (e.chartX >= extremes.left &&  e.chartX <= extremes.right) {
                var pointValue = chart1.xAxis[0].toValue(e.chartX);
                if (pointValue - 500 <= 0) {
                  mainCChart.xAxis[0].setExtremes(pointValue, pointValue + 1000, true, false);
                } else {
                  mainCChart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                }
                chart1.draggablePlotLine.attr({
                  x: e.chartX
                })
              }
            }
          }

          this.state.charts[stripIndex].draggablePlotLine.element.onmousedown = function() {
            chart1.draggablePlotLine.drag = true;
          }

          this.state.charts[stripIndex].draggablePlotLine.element.onmouseup = function() {
            chart1.draggablePlotLine.drag = false;
          }

          $('#sel').animate({ // animate your right div
            scrollTop: clickIndex*81 // to the position of the target 
          }, 400); 
        })
        .catch(err => 
          console.log(err)
        );
    }

    updateRpeaks(data) {
      this.setState({
        rpeaks:data
      })
    }

    lockReport(e) {
      const userid = this.props.match.params.userid;
      if (window.confirm('Are you sure you want lock this report?')) {
        console.log('test');
        const data = {
          userid:userid
        }
        axios
          .post('https://web.mybiocalculus.com:5000/api/patients/lockreport',data)
          .then(res => {
            console.log(res.data); 
            this.setState({
              reportStatus:7
            })     
          })
          .catch(err => {
              console.log(err);
          });
      }
    }

    regenerateReport(e) {
      const userid = this.props.match.params.userid;
      axios
          .get('https://web.mybiocalculus.com/html/welcome/regenerateReoprt/'+userid)
          .then(res => {
            console.log(res.data);      
          })
          .catch(err => {
              console.log(err);
          });
    }



    splitFn(value){
      var res = value.split("_");
      var name = res[0].substring(0, 4)+'-'+res[0].substring(4, 6)+'-'+res[0].substring(6, 8)+' :: '+res[1].substring(0, 2)+':'+res[1].substring(2, 4)+':'+res[1].substring(4, 6);
      res[0]=name;
      return res;
    }

    recategorizeImg(e){
      var checkedArray=[];
      var userid = this.props.match.params.userid;
      var databaseid = this.state.dblink;
      var folder = e.currentTarget.value;
      $("input:checkbox[name=test]:checked").each(function(){
          checkedArray.push($(this).val());
      });
      if(checkedArray.length>0){
        const data = {
          userid:userid,
          databaseid:databaseid,
          checkedArray:checkedArray,
          folder:folder
        }
        axios
          .post('https://web.mybiocalculus.com:5000/api/common/reCategorize',data)
          .then(res => { 
            console.log(res.data);         
          })
          .catch(err => {
              console.log(err);
          });
      }
    }

    resetGraph(e) {
        var it=0;
        var currentChart = this.state.charts;
        var currentNsrarr = this.state.defarr;
        var myRGVar= setInterval(function(){ 
          currentChart[it].series[0].setData(currentNsrarr[it],true);  
          it=it+1;
          if(it>currentNsrarr.length-1)
          { 
            clearInterval(myRGVar);
          }

        },10);
    }

    filter(e){ 
      var selected = e.currentTarget.id;
      if(selected=='all'){
        $("#portfolio").children().show(1000);
        $("#filters a").removeClass('active');
        $("#"+e.currentTarget.id).addClass('active');
      } else {
        $("#portfolio").children().hide(1000); 
        var thisclass = "#portfolio ."+selected; 
        $("#portfolio ."+selected).show(1000); 
        $("#filters a").removeClass('active');
        $("#"+e.currentTarget.id+" a").addClass('active');
      }
    }

    colorGraph(e) { 
        var selector = e.currentTarget.value;
        if(selector == 'Normal Sinus Rhythm'){
          var it=0;
          var currentChart = this.state.charts;
          var currentNsrarr = this.state.nsrarr;
          var myCGVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentNsrarr[it],true);  
            it=it+1;
            if(it>currentNsrarr.length-1)
            { 
              clearInterval(myCGVar);
            }

          },10);
        }
        else if(selector == 'Sinus Tachycardia'){
          var it=0;
          var currentChart = this.state.charts;
          var currentStarr = this.state.starr;
          var myCGVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentStarr[it],true);  
            it=it+1;
            if(it>currentStarr.length-1)
            { 
              clearInterval(myCGVar);
            }

          },10);
        }
        else if(selector == 'Sinus Bradycardia'){
          var it=0;
          var currentChart = this.state.charts;
          var currentSbarr = this.state.sbarr;
          var myCGVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentSbarr[it],true);  
            it=it+1;
            if(it>currentSbarr.length-1)
            { 
              clearInterval(myCGVar);
            }

          },10);
        }
        else if(selector == 'Sinus Pause'){
          var it=0;
          var currentChart = this.state.charts;
          var currentSparr = this.state.sparr;
          var myCGVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentSparr[it],true);  
            it=it+1;
            if(it>currentSparr.length-1)
            { 
              clearInterval(myCGVar);
            }

          },10);
        }
        else if(selector == 'PAC'){
          var it=0;
          var currentChart = this.state.charts;
          var currentPacarr = this.state.pacarr;
          var myCGVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentPacarr[it],true);  
            it=it+1;
            if(it>currentPacarr.length-1)
            { 
              clearInterval(myCGVar);
            }

          },10);
        }
        else if(selector == 'PVC'){
          var it=0;
          var currentChart = this.state.charts;
          var currentPvcarr = this.state.pvcarr;
          var myCGVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentPvcarr[it],true);  
            it=it+1;
            if(it>currentPvcarr.length-1)
            { 
              clearInterval(myCGVar);
            }

          },10);
        }
        else if(selector == 'Ventricular Tachycardia'){
          var it=0;
          var currentChart = this.state.charts;
          var currentVtcarr = this.state.vtarr;
          var myCGVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentVtcarr[it],true);  
            it=it+1;
            if(it>currentVtcarr.length-1)
            { 
              clearInterval(myCGVar);
            }

          },10);
        }
        else if(selector == 'AFib'){
          var it=0;
          var currentChart = this.state.charts;
          var currentAfcarr = this.state.afarr;
          var myCGVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentAfcarr[it],true);  
            it=it+1;
            if(it>currentAfcarr.length-1)
            { 
              clearInterval(myCGVar);
            }

          },10);
        }
        else if(selector == 'P Absent'){
          var it=0;
          var currentChart = this.state.charts;
          var currentPacarr = this.state.paarr;
          var myCGVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentPacarr[it],true);  
            it=it+1;
            if(it>currentPacarr.length-1)
            { 
              clearInterval(myCGVar);
            }

          },10);
        }
        else if(selector == 'Irregular RR'){
          var it=0;
          var currentChart = this.state.charts;
          var currentIrrrarr = this.state.irrrarr;
          var myCGVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentIrrrarr[it],true);  
            it=it+1;
            if(it>currentIrrrarr.length-1)
            { 
              clearInterval(myCGVar);
            }

          },10);
        }
        else if(selector == 'Irregular PR'){
          var it=0;
          var currentChart = this.state.charts;
          var currentIrprarr = this.state.irprarr;
          var myCGVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentIrprarr[it],true);  
            it=it+1;
            if(it>currentIrprarr.length-1)
            { 
              clearInterval(myCGVar);
            }

          },10);
        }
        else if(selector == 'Short QT'){
          var it=0;
          var currentChart = this.state.charts;
          var currentSqtarr = this.state.sqtarr;
          var myCGVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentSqtarr[it],true);  
            it=it+1;
            if(it>currentSqtarr.length-1)
            { 
              clearInterval(myCGVar);
            }

          },10);
        }
        else if(selector == 'Prolonged QT'){
          var it=0;
          var currentChart = this.state.charts;
          var currentPaarr = this.state.paarr;
          var myCGVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentPaarr[it],true);  
            it=it+1;
            if(it>currentPaarr.length-1)
            { 
              clearInterval(myCGVar);
            }

          },10);
        }
        else if(selector == 'Undiagnosed'){
          var it=0;
          var currentChart = this.state.charts;
          var currentUnarr = this.state.unarr;
          var myCGVar= setInterval(function(){ 
            currentChart[it].series[0].setData(currentUnarr[it],true);  
            it=it+1;
            if(it>currentUnarr.length-1)
            { 
              clearInterval(myCGVar);
            }

          },10);
        }

    };

    recategorize(e) { 

      var beats = JSON.parse(document.getElementById("beatSlected").value);
      var databaseid = this.state.databaseid;
      var userid = this.props.match.params.userid;
      var arrhythmia = e.currentTarget.value;
      console.log(beats);
      if(beats.length>0) {

        const data = {
          databaseid: databaseid,
          beats: beats,
          arrhythmia:arrhythmia,
          userid: userid
        };
        axios
        .post(apiUrl+'api/common/updateArrhythmia',data)
        .then(res => {
          var currentChart = document.getElementById("selectedChart").value;
          var minus_val = currentChart*15000;
            console.log(res);
            for (var i = 0; i < beats.length; i++) {
              this.state.chart.xAxis[0].removePlotBand('plotband-'+(beats[i]-minus_val))             
            }
            document.getElementById("beatSlected").value="[]";
        })
        .catch(err =>{ 
            console.log(err)
        });
      }
    } 

    gainBtn(e) {
      var buttonId = e.currentTarget.id;
      var gainValue = document.getElementById('gainValue').value;
      console.log(gainValue);
      var it=0;
      var charts = this.state.charts;
      var arCount = this.state.datasetMnt-1;
      var min,max;
      if(gainValue==1){
        min = -0.0020;
        max = 0.0020;
      }else if(gainValue==2){
        min = -0.0010;
        max = 0.0010;
      }else if(gainValue==3){
        min = -0.0005;
        max = 0.0005;
      }

      var scaleUp= setInterval(function(){ 
        charts[it].update({
          yAxis: [{
            min: min,
            max: max
          }],    
        })
        it=it+1;
        if(it>arCount)
        {
          clearInterval(scaleUp);
        }
      },5);
    }

    renderNewChart(userid,dbId,step) {

      var arr=[],charts=[],arr2=[], peakarr=[],barClick=0,pointValue,tempChart,tempCont=0;
      var nsrarr=[],starr=[],unarr=[],sbarr=[],vtarr=[],sparr=[],lqtarr=[],sqtarr=[],irrrarr=[],irprarr=[],afarr=[],pacarr=[],pvcarr=[],paarr=[],beatArr=[],defarr=[],plotband=[],piec;
      var maximumval;var b;var js_array=[];var chart; var minVal,maxVal,val,val2;
      var result,result2,result3,colorArray=[];
      var rpeakArray = [];
      var peakColorArray= [];
          peakColorArray['Undiagnosed'] = '#FCDF85';
          peakColorArray['Normal Sinus Rhythm'] = '#98FB98';
          peakColorArray['Sinus Tachycardia'] = '#3EFFDE';
          peakColorArray['PAC'] = '#4950EB';
          peakColorArray['PVC'] = '#FF45DC';
          peakColorArray['P absent'] = '#AEB9BD';
          peakColorArray['Sinus Bradycardia'] = '#FFD14F';
          peakColorArray['Sinus Pause'] = '#FF5B05';
          peakColorArray['Irregular RR'] = '#A4C3FF';
          peakColorArray['Ventricular Tachycardia'] = '#F0A7A1';
          peakColorArray['Irregular PR'] = '#BBEB8F';
          peakColorArray['Short QT'] = '#CFFF0F';
          peakColorArray['Prolonged QT'] = '#7C2F09';
          peakColorArray['AFib'] = '#A96BFF';

      var userid = userid;
      var databaseid = dbId;

      var mainEcgArray,mainActivityArray = [];
      var datasetMnt = 0;

      //Split the array in chunks with given size
      function chunkArray(myArray, chunk_size) {
            let results = [];
            
            while (myArray.length) {
                results.push(myArray.splice(0, chunk_size))
            }

            return results;
      };

      function chunkPeakArray(peakArray){
            let presults = [];
            var minIndex = 0;
            var breakVal = 15000;
            var z;
            var temRpeakindex
            presults.push( [] );
            for (var i = 0; i < peakArray.length; i++) {
              if (peakArray[i].rpeakindex > breakVal) {
                presults.push( [] );
                minIndex = minIndex+1;
                breakVal = breakVal+15000;
              }
              temRpeakindex = parseInt(peakArray[i].rpeakindex)-(minIndex*15000);
              presults[minIndex].push({ x: temRpeakindex, y: parseFloat(0.8), color:peakColorArray[peakArray[i].arrhythmia], beat: parseInt(peakArray[i].indexcounter), hr: parseInt(peakArray[i].heartrate), rr: parseInt(peakArray[i].rr_interval),arrhythmia:peakArray[i].arrhythmia,peaktime:peakArray[i].peaktime });
            }
            return presults;
      }

      function createSchartContainer() {
          var t=0;
          var stcolor= "black",uncolor= "black",sbcolor= "black",vtcolor= "black",spcolor= "black",lqcolor= "black",sqcolor= "black",irrrcolor= "black",irprcolor= "black",afcolor= "black",paccolor= "black",pvccolour= "black",pacolour= "black",defcolour="black";
          var nsrcolor = "black";
          for(var i=0; i < result.length; i++){
            var newDiv = document.createElement('div');
                newDiv.id = 'container'+i;
                newDiv.class = 'stripContainer';
                newDiv.style = 'min-width: 200px; height: 60px; margin: 0 auto';
            var newSpan = document.createElement('div');
                newSpan.id='span'+i;
                newSpan.style='color:#000;';
            var time1 = document.createTextNode(result3[i][0].peaktime);
                newSpan.appendChild(time1);
            document.getElementById('sel').appendChild(newSpan);
            document.getElementById('sel').appendChild(newDiv);

            var arrayIndex = 0;
            var k=0;
          }
      }

      function renderSmallCharts() {
          var i=0;

          var t=0;
          var stcolor= "black",uncolor= "black",sbcolor= "black",vtcolor= "black",spcolor= "black",lqcolor= "black",sqcolor= "black",irrrcolor= "black",irprcolor= "black",afcolor= "black",paccolor= "black",pvccolour= "black",pacolour= "black",defcolour="black";
          var nsrcolor = "black";
          var currentDataSet = [];

          var myVar= setInterval(function(){ 

            //currentDataSet = mainEcgArray.slice(i, 15000);
            var sliceStartI = i*15000;
            var sliceEndI = sliceStartI + 15001;

            currentDataSet = mainEcgArray.slice(sliceStartI, sliceEndI);

            var newDiv = document.createElement('div');
                newDiv.id = 'container'+i;
                newDiv.style = 'min-width: 200px; height: 60px; margin: 0 auto';
            var newSpan = document.createElement('div');
                newSpan.id='span'+i;
                newSpan.style='color:#000;';
            //var time1 = document.createTextNode(result3[i][0].peaktime);
            var time1 = document.createTextNode('12:12:12');
            if(i!=0)
              newSpan.appendChild(time1);
            document.getElementById('sel').appendChild(newSpan);
            document.getElementById('sel').appendChild(newDiv);

            var arrayIndex = 0;
            var k=0;

            charts[i] = new Highcharts.Chart('container'+i, {
              credits: {
                enabled: false
              },
              chart: {
                height: 5 + '%',
                resetZoomButton: {
                  theme: {
                    display: 'none'
                  },
                },
                events: {
                  click(event)
                  { 
                    var containerId = this.renderTo.id;
                    var currentIndex = containerId.replace('container','');
                    var prevChart = document.getElementById("selectedChart").value;
                    var beats = JSON.parse(document.getElementById("beatSlected").value);
                    if(beats.length>0 && currentIndex !=prevChart ) {
                      for (var i = 0; i < beats.length; i++) {
                        var xBeat = parseInt(beats[i])-(15000*prevChart)
                        chart.xAxis[0].removePlotBand('plotband-'+xBeat) ;          
                      }
                      document.getElementById("beatSlected").value='[]';
                    }

                    val= charts[currentIndex].xAxis[0].toValue(event.chartX);
                                    
                    var clickIndex ;
                    clickIndex = currentIndex; 

                    var sliceStart = clickIndex*15000;
                    var sliceEnd = sliceStart + 15001;
                    var sliceActStart = clickIndex*60;
                    var sliceActEnd = sliceActStart + 61;
                    var ecgArray = mainEcgArray.slice(sliceStart, sliceEnd);
                    var curActArray = mainActivityArray.slice(sliceActStart, sliceActEnd);
                    var acti= 125;
                    var actarr = [];
                    var tttarr1 = [];

                    if(val > ecgArray.length) {
                      val = ecgArray.length-500;
                    }

                    for (var i = 0; i < curActArray.length; i++) {
                      actarr.push({ x: acti, y: 0.5, color: colorArray[curActArray[i]] });
                      acti = acti+250;
                    }
                              
                    chart.series[0].setData(ecgArray,true);
                    chart.series[1].setData(actarr,true);
                    //chart.series[2].setData(result3[clickIndex],true);

                    tempChart = charts[document.getElementById("selectedChart").value];
                    if(tempChart!=undefined){
                      if (tempChart.draggablePlotLine!=undefined) {
                        tempChart.draggablePlotLine.destroy();
                      }
                    }
                    var chart1 = this
                    tempCont=currentIndex;
                    document.getElementById("selectedChart").value= tempCont;
                    var lineWidth = 2

                    chart1.draggablePlotLine = chart1.renderer.rect(event.chartX-charts[currentIndex].xAxis[0].toPixels(25), 10, 50, 40, 5)
                    .attr({
                      'stroke-width': 2,
                      opacity:0.5,
                      fill: 'grey',
                      zIndex: 3
                    })
                    .add();

                    if(charts[0].container.onmousemove!=undefined)
                    {
                      charts[0].container.onmousemove = function(e) {return false;}
                    }

                    charts[currentIndex].container.onmousemove = function(e) {
                      if (chart1.draggablePlotLine.drag) {

                        let normalizedEvent = chart1.pointer.normalize(e),
                        extremes = {
                          left: chart1.plotLeft,
                          right: chart1.plotLeft + chart1.plotWidth
                        };

                        if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                          pointValue = chart1.xAxis[0].toValue(e.chartX);
                          if (pointValue - 500 <= 0) {
                              chart.xAxis[0].setExtremes(pointValue, pointValue + 1000, true, false);
                          } else {
                            chart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                          }
                          chart1.draggablePlotLine.attr({
                            x: e.chartX
                          })
                        }
                      }
                    }

                    charts[currentIndex].draggablePlotLine.element.onmousedown = function() {
                      chart1.draggablePlotLine.drag = true;
                    }

                    charts[currentIndex].draggablePlotLine.element.onmouseup = function() {
                      chart1.draggablePlotLine.drag = false;
                    }
                    if(val-500<=0)
                    {
                      chart.xAxis[0].setExtremes(val,val+1000);
                    }
                    else{
                      chart.xAxis[0].setExtremes(val-500,val+500);
                    }
                  },
                },
                zoomType: '',
              },

              navigator: {
                enabled: false
              },

              xAxis:[{
                min:0,
                max:15000,
                lineWidth: 0,
                minorGridLineWidth: 0,
                lineColor: 'transparent',
                scrollbar: {
                  enabled: true,
                  showFull: false
                },
                labels: {
                  enabled: false
                },
                minorTickLength: 0,
                tickLength: 0, visible: false

              }],
              yAxis: [{
                min: -0.002,
                max: 0.002,
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                minorTicks: false,
                labels: {
                  enabled: false
                },
                title: {
                  enabled: false
                },
              }],
              rangeSelector: {
                enabled: false
              },
              plotOptions: {
                series: {
                  turboThreshold: 15000,
                },
              },
              title: {
                text: ''
              },

              series: [{
                type: 'coloredline',
                data: currentDataSet,
                color: 'black',
                allowPointSelect: false,
                animation: false,
                enableMouseTracking: false,
                lineWidth: 1,
                marker: {
                  enabled: false
                },
                shadow: false,
                  stickyTracking: false,
                  showInLegend: false
                }]
              });

            if(i==0) {
              charts[0].draggablePlotLine = charts[0].renderer.rect(charts[0].xAxis[0].toPixels(50)-charts[0].xAxis[0].toPixels(25), 10, 50, 40, 5)
              .attr({
                'stroke-width': 2,
                opacity:0.5,
                fill: 'grey',
                zIndex: 3
              })
              .add();

              if (charts[0].container.onmousemove != undefined) {
                charts[0].container.onmousemove = function(e) {
                  return false;
                }
              }
              var stripIndex = 0;
              charts[stripIndex].container.onmousemove = function(e) {
                if (charts[stripIndex].draggablePlotLine.drag) {

                  let normalizedEvent = charts[stripIndex].pointer.normalize(e),
                  extremes = {
                    left: charts[stripIndex].plotLeft,
                    right: charts[stripIndex].plotLeft + charts[stripIndex].plotWidth
                  };

                  if (e.chartX >= extremes.left &&  e.chartX <= extremes.right) {
                    var pointValue = charts[stripIndex].xAxis[0].toValue(e.chartX);
                    if (pointValue - 500 <= 0) {
                      chart.xAxis[0].setExtremes(pointValue, pointValue + 1000, true, false);
                    } else {
                      chart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                    }
                    charts[stripIndex].draggablePlotLine.attr({
                      x: e.chartX
                    })
                  }
                }
              }
            }
            i=i+1;
            if(i>datasetMnt-1)
            { 
              clearInterval(myVar);
            }
          },5);
      }

      // Function to plot the background graph
      function renderMainChart() {

          maximumval = 1000;
          b = 0;

          // Create the chart
          chart = new Highcharts.StockChart('tt_container', {
              exporting: {
                  enabled: false
              },
              scrollbar: {
                  enabled: false
              },
              credits: {
                  enabled: false
              },
              chart: {
                  type: 'coloredline',
                  height: 40 + '%',

                  resetZoomButton: {
                      theme: {
                          display: 'none'
                      },

                  },
                  events: {
                    click: function(event) {
                      if (event.shiftKey) {

                        var k = 0,
                            plotbi = 0;
                        var t = Math.floor(event.xAxis[0]['value']);
                        var closestArray = [];
                        var multiArray = [];
                        var comStart,comEnd;
                        tempCont = document.getElementById("selectedChart").value;
                        var j  = tempCont*15000; 
                        console.log(tempCont);
                        beatArr = JSON.parse($('#beatSlected').val());
                        for (var i = 0; i < result3[tempCont].length; i++) {
                          closestArray[i] = result3[tempCont][i].x;
                        }
                        var closest = closestArray.reduce(function(prev, curr) {
                          return (Math.abs(curr - t) < Math.abs(prev - t) ? curr : prev);
                        });
                        console.log(closest);
                        if(beatArr.length==1) {
                          if(closest>beatArr[0]-j) {
                            comEnd = closest;
                            comStart = beatArr[0]-j;
                          } else{
                            comStart = closest;
                            comEnd = beatArr[0]-j;
                          }
                          console.log(comStart+" "+comEnd);
                          for (var i = 0; i < closestArray.length; i++) {
                            if (closestArray[i] >= comStart && closestArray[i] <= comEnd) {
                              plotband.push({
                                color: '#ff9999',
                                from: closestArray[i] - 100,
                                to: closestArray[i] + 100,
                                id: 'plotband-' + closestArray[i]
                              });
                              multiArray.push((closestArray[i]+j));
                            }
                          }
                          chart.update({
                            xAxis: {
                              plotBands: plotband
                            }
                          });
                          $('#beatSlected').val(JSON.stringify(multiArray));
                          console.log(closestArray);
                          console.log(multiArray);
                        } else {
                          chart.update({
                            xAxis: {
                              plotBands: []
                            }
                          });
                      
                          beatArr = [];
                          plotband = [];
                          var check = $.inArray((closest+j), beatArr);

                          if (check == -1) {
                            plotband.push({
                              color: '#ff9999',
                              from: closest - 100,
                              to: closest + 100,
                              id: 'plotband-' + closest
                            });
                            chart.update({
                              xAxis: {
                                plotBands: plotband
                              }
                            });
                            beatArr.push((closest+j));
                          } else {
                            var index = beatArr.indexOf((closest+j));
                            if (index > -1) {
                              beatArr.splice(index, 1);
                            }
                            chart.xAxis[0].removePlotBand('plotband-' + closest);
                          }
                          $('#beatSlected').val(JSON.stringify(beatArr));
                        }
                      } else {
                        var k = 0,
                            plotbi = 0;
                        var t = Math.floor(event.xAxis[0]['value']);
                        var closestArray = [];
                        tempCont = document.getElementById("selectedChart").value;
                        var j  = tempCont*15000; 

                        // chart.update({
                        //   xAxis: {
                        //     plotBands: plotband
                        //   }
                        // });

                        for (var i = 0; i < result3[tempCont].length; i++) {
                          closestArray[i] = result3[tempCont][i].x;
                        }
                            
                        var closest = closestArray.reduce(function(prev, curr) {
                          return (Math.abs(curr - t) < Math.abs(prev - t) ? curr : prev);
                        });

                        beatArr = JSON.parse($('#beatSlected').val());
                        var check = $.inArray((closest+j), beatArr);

                        if (check == -1) {
                          plotband.push({
                            color: '#ff9999',
                            from: closest - 100,
                            to: closest + 100,
                            id: 'plotband-' + closest
                          });
                          chart.update({
                            xAxis: {
                              plotBands: plotband
                            }
                          });
                          beatArr.push((closest+j));
                        } else {
                          var index = beatArr.indexOf((closest+j));
                          if (index > -1) {
                            beatArr.splice(index, 1);
                          }
                          chart.xAxis[0].removePlotBand('plotband-' + closest);
                        }
                        $('#beatSlected').val(JSON.stringify(beatArr));
                      }
                    },
                  },
                  zoomType: '',
              },

              navigator: {
                  enabled: false
              },

              xAxis: [{
                  min: 0, //setting min to 0
                  max: 1000,
                  events: {

                      setExtremes: function(e) {
                          //val = e.min + 500; 
                          if(e.min <= 500) {
                            val = e.min;
                          } else {
                            val = e.min + 500; 
                          }
                          
                          tempChart = charts[document.getElementById("selectedChart").value];
                          if (tempChart != undefined) {
                              if (tempChart.draggablePlotLine != undefined) {
                                  val2 = tempChart.xAxis[0].toPixels(val);


                                  tempChart.draggablePlotLine.attr({
                                      x: val2

                                  })



                              }
                          }
                      },
                  },
                  scrollbar: {
                      enabled: true
                  },
                  labels: {
                      enabled: false
                  },

                  tickInterval: 50,
                  minorTicks: true,
                  minorTickInterval: 10,
                  gridLineWidth: 1,
                  gridLineColor: '#D3D3D3'

              }],
              yAxis: [{
                      min: 0,
                      max: 10,
                      reversed: true,
                      gridLineWidth: 0,
                      minorGridLineWidth: 0,
                      minorTicks: false,

                      labels: {
                          enabled: true
                      },
                      title: {
                          enabled: false
                      },
                      visible: false

                  },
                  {
                      min: -0.002,
                      max: 0.002,
                      tickPositions: [-0.0020, -0.0015, -0.0010, -0.0005, 0.0000, 0.0005, 0.0010, 0.0015, 0.0020],
                      tickInterval: 0.0005,
                      gridLineWidth: 3,
                      minorGridLineWidth: 1,
                      minorTicks: true,
                      minorTickInterval: 0.0001,

                      gridLineWidth: 2,
                      gridLineColor: '#D3D3D3',
                      labels: {
                          enabled: false
                      },
                      title: {
                          enabled: false
                      },

                  },
                  {
                      min: 0,
                      max: 10,
                      gridLineWidth: 0,
                      minorGridLineWidth: 0,
                      minorTicks: false,

                      labels: {
                          enabled: true
                      },
                      title: {
                          enabled: false
                      },
                      visible: false

                  }
              ],
              rangeSelector: {
                  enabled: false
              },
              plotOptions: {
                  series: {
                      turboThreshold: 15500,
                      colorByPoint: false
                  },

              },

              title: {
                  text: ''
              },

              series: [{


                  data: [],
                  yAxis: 1,
                  xAxis: 0,
                  color: 'black',
                  states: {
                      inactive: {
                          opacity: 1
                      }
                  },

                  allowPointSelect: false,
                  animation: false,
                  enableMouseTracking: false,
                  lineWidth: 1,
                  marker: {
                      enabled: false
                  },
                  shadow: false,
                  stickyTracking: false,
                  showInLegend: false


              }]
          });
          chart.showLoading(' Please wait while the ECG is being loaded  <i class="fa fa-spin fa-spinner"></i>');
          chart.addSeries({

              yAxis: 0,

              type: 'column',

              data: [],
              pointWidth: 400,
              inverted: true,
              tooltip: {
                  enabled: false
              },
              states: {

                  inactive: {
                      opacity: 1
                  }
              },

              allowPointSelect: false,
              animation: false,
              enableMouseTracking: false,
              lineWidth: 1,

              shadow: false,
              stickyTracking: false,
              showInLegend: false

          }, true);
          chart.addSeries({
              dataLabels: [{
                  enabled: true,
                  inside: true,
                  align: 'left',
                  format: 'Beat:{point.beat}; HR:{point.hr}; RR:{point.rr}'
              }],
              yAxis: 2,
              type: 'column',
              turboThreshold: 10000,
              data: [],
              pointWidth: 250,
              tooltip: {
                  enabled: false
              },
              point: {
                  events: {
                      click: function() {

                      },


                  }
              },
              states: {

                  inactive: {
                      opacity: 1
                  }
              },
              allowPointSelect: false,
              animation: false,
              enableMouseTracking: false,
              marker: {
                  enabled: false
              },

              showInLegend: false
          }, true);

          return chart;
      };


      if(step==2) {
        this.state.chart.destroy();
        this.setState({
          databaseid:databaseid
        });
      }
      document.getElementById('sel').innerHTML ="";
      var mainchat = renderMainChart();       
            
      this.setState({
        result: []
      });
      this.setState({
        result2: []
      });
      this.setState({
        result3: []
      });
      this.setState({
        defarr: []
      });
      this.setState({
        nsrarr: []
      });
      this.setState({
        starr: []
      });
      this.setState({
        sbarr: []
      });
      this.setState({
        sparr: []
      });
      this.setState({
        pacarr: []
      });
      this.setState({
        pvcarr: []
      });
      this.setState({
        vtarr: []
      });
      this.setState({
        afarr: []
      });
      this.setState({
        paarr: []
      });
      this.setState({
        irrrarr: []
      });
      this.setState({
        irprarr: []
      });
      this.setState({
        sqtarr: []
      });
      this.setState({
        paarr: []
      });
      this.setState({
        unarr: []
      });
      for(var k=0;k<this.state.charts.length;k++){
        this.state.charts[k].destroy();
      }
      axios
        .get(apiUrl+'api/common/getEcgData?userid='+userid+'&databaseid='+databaseid)
        .then(res => {  
          console.log(res.data[0].length);
          if(res.data[0].length%15000>0){
            datasetMnt = Math.floor(res.data[0].length/15000) + 1;
          } else {
            datasetMnt = Math.floor(res.data[0].length/15000);
          }

          mainEcgArray = res.data[0];
          mainActivityArray = res.data[1];
          console.log(datasetMnt);
          this.updateRpeaks(res.data[2]);
          //result3 = chunkPeakArray(res.data[2]);
          //console.log(result3);
          var acti= 125;
          var counteri=249;
          var counter = 0;
          var t=0;
          var actarr=[];
          colorArray = ['#98FB98','#f1e4e4','#f7e0a3','#f09c67']
          var curDataArray = res.data[1].slice(0, 60);

          for (var i = 0; i < curDataArray.length; i++) {
            actarr.push({ x: acti, y: 0.5, color: colorArray[curDataArray[i]] });
            acti = acti+250;
          }

          chart.hideLoading();
          
          chart.series[0].setData(res.data[0].slice(0, 15000),true);

          var interval1= setInterval(function(){
            chart.series[1].setData(actarr,true);
            clearInterval(interval1);
            interval1 = false;
          },100);

          var interval2= setInterval(function(){
            if(!interval1){
              //chart.series[2].setData(result3[0],true);
              clearInterval(interval2);
              interval2 = false;
            }
          },100);

          var intervalSchart= setInterval(function(){
            if(!interval2){
              renderSmallCharts();
              clearInterval(intervalSchart);
              intervalSchart = false;
            }
          },1000);

          this.setState({
            datasetMnt: datasetMnt
          });
          this.setState({
            mainEcgArray: mainEcgArray
          });
          this.setState({
            mainActivityArray: mainActivityArray
          });
          this.setState({
            result3: result3
          });

          this.setState({
            charts: charts
          });
          this.setState({
            chart: chart
          });
          this.setState({
              defarr: defarr
          });
          this.setState({
            nsrarr: nsrarr
          });
          this.setState({
            starr: starr
          });
          this.setState({
            sbarr: sbarr
          });
          this.setState({
            sparr: sparr
          });
          this.setState({
            pacarr: pacarr
          });
          this.setState({
            pvcarr: pvcarr
          });
          this.setState({
            vtarr: vtarr
          });
          this.setState({
            afarr: afarr
          });
          this.setState({
            paarr: paarr
          });
          this.setState({
            irrrarr: irrrarr
          });
          this.setState({
            irprarr: irprarr
          });
          this.setState({
            sqtarr: sqtarr
          });
          this.setState({
            paarr: paarr
          });
          this.setState({
            unarr: unarr
          });
        })
        .catch(err => {
        });  

 

      axios
        .get('https://web.mybiocalculus.com:5000/api/common/getfolderfiles?userid='+userid+'&databaseid='+databaseid)
        .then(res => { 
          this.setState({
            images:res.data[0]
          });
          this.setState({
            dblink:res.data[1]
          });

        })
        .catch(err => {
            console.log(err);
        }); 
    }

    nextBtnLink(userid,dbid) {
      axios
        .get(apiUrl+'api/common/getNext?userid='+userid+'&databaseid='+dbid)
        .then(res => {
          if(res.data!=null){
            document.getElementById("graphNext").style.display = 'inline-block';
            this.setState({
                nextDbId:res.data.ecgvalue_id
            })
          } else {
            document.getElementById("graphNext").style.display = 'none';
          }        
        })
        .catch(err => {
          console.log(err);
        });
    } 

    prevBtnLink(userid,dbid) {
      axios
        .get(apiUrl+'api/common/getPrev?userid='+userid+'&databaseid='+dbid)
        .then(res => {
          if(res.data!=null){
            document.getElementById("graphPrev").style.display = 'inline-block';
            this.setState({
                prevDbId:res.data.ecgvalue_id
            })
          } else {
            document.getElementById("graphPrev").style.display = 'none';
          }       
        })
        .catch(err => {
          console.log(err);
        });
    }  

    newDataClick(e) {
      //alert('test');
      var buttonId = e.currentTarget.id;
      var databaseid;
      var userid = this.props.match.params.userid;
      if(buttonId=='nextBtn'){
        databaseid = this.state.nextDbId;
      } else if(buttonId=='prevBtn') {
        databaseid = this.state.prevDbId;
      }
      document.getElementById("graphPrev").style.display = 'none';
      document.getElementById("graphNext").style.display = 'none';
      
      this.renderNewChart(userid,databaseid,2);
      //this.nextBtnLink(userid,databaseid);
      //this.prevBtnLink(userid,databaseid); 
    }
  

  componentDidMount() {
    var userid = this.props.match.params.userid;
    var databaseid = this.props.match.params.databaseid;
    if (typeof databaseid === 'undefined') {
       databaseid="0";
      axios
        .get(apiUrl+'api/common/getLatestDatabaseId?userid='+this.props.match.params.userid)
        .then(res => {
          this.setState({
            databaseid:res.data
          });
          this.renderNewChart(userid,res.data,1);
          //this.nextBtnLink(userid,databaseid);
          //this.prevBtnLink(userid,databaseid);       
        })
        .catch(err => {
            console.log(err);
        });
    } else {
      this.setState({
        databaseid:databaseid
      });
      this.renderNewChart(userid,databaseid,1);
      //this.nextBtnLink(userid,databaseid);
      //this.prevBtnLink(userid,databaseid); 


      $(document).keydown((e) => {
        if(this.state.currentTab == 'chart_tab_2') { 
          let chart = this.state.chart.xAxis[0]
          let extremes = chart.getExtremes()
          let zoomLevel = extremes.max - extremes.min
          switch(e.which) {
              case 65:
              case 37: // left
                var minL = extremes.min - zoomLevel;
                var maxL = extremes.min;
                if(minL<=0){
                  maxL = 1000
                  minL = 0
                }
                if(extremes.min == 0) {
                  var e = $.Event("keydown");

                  // e.which is used to set the keycode
                  e.which = 38; // it is down
                  $("body").trigger(e);
                } else {
                  chart.setExtremes(minL, maxL)
                }
              break;

              case 87:
              case 38: // up             
                var cuurentChart = document.getElementById("selectedChart").value;              
                var stripIndex = parseInt(cuurentChart)-1;
                if(stripIndex >= 0) {
                  var sliceStart = stripIndex*15000;
                  var sliceEnd = sliceStart + 15001;
                  var sliceActStart = stripIndex*60;
                  var sliceActEnd = sliceActStart + 61;
                  var currentEcgArray = this.state.mainEcgArray.slice(sliceStart, sliceEnd);
                  var currentActArray = this.state.mainActivityArray.slice(sliceActStart, sliceActEnd);

                  var stripX = 100;
                  var tempChart;
                  var colorArray = ['#98FB98','#f1e4e4','#f7e0a3','#f09c67'];

                  var val = stripX;
                  var clickIndex;
                  clickIndex = stripIndex;

                  this.state.chart.xAxis[0].setExtremes(0, 1000);
 
                  var acti = 125;
                  var actarr = [];
                  var tttarr1 = [];

                  for (var i = 0; i < currentActArray.length; i++) {
                    actarr.push({
                      x: acti,
                      y: 0.5,
                      color: colorArray[currentActArray[i]]
                    });
                    acti = acti + 250;
                  }

                  var prevChart = document.getElementById("selectedChart").value;
                  var beats = JSON.parse(document.getElementById("beatSlected").value);
                  if(beats.length>0) {
                    for (var i = 0; i < beats.length; i++) {
                      var xBeat = parseInt(beats[i])-(15000*prevChart)
                      this.state.chart.xAxis[0].removePlotBand('plotband-'+xBeat) ;          
                    }
                    document.getElementById("beatSlected").value='[]';
                  }

                  this.state.chart.series[0].setData(currentEcgArray,true);
                  this.state.chart.series[1].setData(actarr,true);
                  //this.state.chart.series[2].setData(this.state.result3[clickIndex],true);         

                  var chart1 = this.state.charts[stripIndex];
                  var tempChart = this.state.charts[document.getElementById("selectedChart").value];

                  this.setState({['selectedChart']:clickIndex});
                  var lineWidth = 2

                  if (tempChart != undefined) {
                    if (tempChart.draggablePlotLine != undefined) {

                      tempChart.draggablePlotLine.destroy();
                    }
                  }
                  
                  this.state.charts[stripIndex].draggablePlotLine = this.state.charts[stripIndex].renderer.rect(this.state.charts[stripIndex].xAxis[0].toPixels(stripX)-this.state.charts[stripIndex].xAxis[0].toPixels(25), 10, 50, 40, 5)
                  .attr({
                    'stroke-width': 2,
                    opacity:0.5,
                    fill: 'grey',
                    zIndex: 3
                  })
                  .add();

                  if (this.state.charts[stripIndex].container.onmousemove != undefined) {
                    this.state.charts[stripIndex].container.onmousemove = function(e) {
                      return false;
                    }
                  }
                  var mainCChart = this.state.chart;
                  this.state.charts[stripIndex].container.onmousemove = function(e) {
                    if (chart1.draggablePlotLine.drag) {

                      let normalizedEvent = chart1.pointer.normalize(e),
                      extremes = {
                        left: chart1.plotLeft,
                        right: chart1.plotLeft + chart1.plotWidth
                      };

                      if (e.chartX >= extremes.left &&  e.chartX <= extremes.right) {
                        var pointValue = chart1.xAxis[0].toValue(e.chartX);
                        if (pointValue - 500 <= 0) {
                          mainCChart.xAxis[0].setExtremes(pointValue, pointValue + 1000, true, false);
                        } else {
                          mainCChart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                        }
                        chart1.draggablePlotLine.attr({
                          x: e.chartX
                        })
                      }
                    }
                  }

                  this.state.charts[stripIndex].draggablePlotLine.element.onmousedown = function() {
                    chart1.draggablePlotLine.drag = true;
                  }

                  this.state.charts[stripIndex].draggablePlotLine.element.onmouseup = function() {
                    chart1.draggablePlotLine.drag = false;
                  }
                  $('#sel').animate({ // animate your right div
                    scrollTop: clickIndex*81 // to the position of the target 
                  }, 400);
                }
              break;

              case 68:
              case 39: // right
                var min = extremes.max;
                var max = extremes.max + zoomLevel;
                var stripIndex = document.getElementById("selectedChart").value;
                var sliceStart = stripIndex*15000;
                var sliceEnd = sliceStart + 15001;
                var currentEcgArray = this.state.mainEcgArray.slice(sliceStart, sliceEnd);
                console.log(currentEcgArray.length)
                if((max)>=15000){
                  max = 14999
                  min = 14000
                } else if((max)>currentEcgArray.length) {
                  max = currentEcgArray.length;
                  min = currentEcgArray.length - zoomLevel;
                }
                if(extremes.max == 14999) {
                  var e = $.Event("keydown");

                  // e.which is used to set the keycode
                  e.which = 40; // it is down
                  $("body").trigger(e);
                } else {
                  chart.setExtremes(min, max)
                }
              break;

              case 83:
              case 40: // down   
                var cuurentChart = document.getElementById("selectedChart").value;              
                var stripIndex = parseInt(cuurentChart)+1;
                var total = this.state.charts.length;
                if(stripIndex<total) {
                  var sliceStart = stripIndex*15000;
                  var sliceEnd = sliceStart + 15001;
                  var sliceActStart = stripIndex*60;
                  var sliceActEnd = sliceActStart + 61;
                  var currentEcgArray = this.state.mainEcgArray.slice(sliceStart, sliceEnd);
                  var currentActArray = this.state.mainActivityArray.slice(sliceActStart, sliceActEnd);

                  var stripX = 0;
                  var tempChart;
                  var colorArray = ['#98FB98','#f1e4e4','#f7e0a3','#f09c67'];

                  var val = stripX;
                  var clickIndex;
                  clickIndex = stripIndex;

                  this.state.chart.xAxis[0].setExtremes(0, 1000);

                  var acti = 125;
                  var actarr = [];
                  var tttarr1 = [];

                  for (var i = 0; i < currentActArray.length; i++) {
                    actarr.push({
                      x: acti,
                      y: 0.5,
                      color: colorArray[currentActArray[i]]
                    });
                    acti = acti + 250;
                  }

                  var prevChart = document.getElementById("selectedChart").value;
                  var beats = JSON.parse(document.getElementById("beatSlected").value);
                  if(beats.length>0) {
                    for (var i = 0; i < beats.length; i++) {
                      var xBeat = parseInt(beats[i])-(15000*prevChart)
                      this.state.chart.xAxis[0].removePlotBand('plotband-'+xBeat) ;          
                    }
                    document.getElementById("beatSlected").value='[]';
                  }

                  this.state.chart.series[0].setData(currentEcgArray,true);
                  this.state.chart.series[1].setData(actarr,true);
                  //this.state.chart.series[2].setData(this.state.result3[clickIndex],true);         

                  var chart1 = this.state.charts[stripIndex];
                  var tempChart = this.state.charts[document.getElementById("selectedChart").value];

                  this.setState({['selectedChart']:clickIndex});
                  var lineWidth = 2

                  if (tempChart != undefined) {
                    if (tempChart.draggablePlotLine != undefined) {

                      tempChart.draggablePlotLine.destroy();
                    }
                  }
                  
                  this.state.charts[stripIndex].draggablePlotLine = this.state.charts[stripIndex].renderer.rect(this.state.charts[stripIndex].xAxis[0].toPixels(stripX)-this.state.charts[stripIndex].xAxis[0].toPixels(25), 10, 50, 40, 5)
                  .attr({
                    'stroke-width': 2,
                    opacity:0.5,
                    fill: 'grey',
                    zIndex: 3
                  })
                  .add();

                  if (this.state.charts[stripIndex].container.onmousemove != undefined) {
                    this.state.charts[stripIndex].container.onmousemove = function(e) {
                      return false;
                    }
                  }
                  var mainCChart = this.state.chart;
                  this.state.charts[stripIndex].container.onmousemove = function(e) {
                    if (chart1.draggablePlotLine.drag) {

                      let normalizedEvent = chart1.pointer.normalize(e),
                      extremes = {
                        left: chart1.plotLeft,
                        right: chart1.plotLeft + chart1.plotWidth
                      };

                      if (e.chartX >= extremes.left &&  e.chartX <= extremes.right) {
                        var pointValue = chart1.xAxis[0].toValue(e.chartX);
                        if (pointValue - 500 <= 0) {
                          mainCChart.xAxis[0].setExtremes(pointValue, pointValue + 1000, true, false);
                        } else {
                          mainCChart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                        }
                        chart1.draggablePlotLine.attr({
                          x: e.chartX
                        })
                      }
                    }
                  }

                  this.state.charts[stripIndex].draggablePlotLine.element.onmousedown = function() {
                    chart1.draggablePlotLine.drag = true;
                  }

                  this.state.charts[stripIndex].draggablePlotLine.element.onmouseup = function() {
                    chart1.draggablePlotLine.drag = false;
                  }
                  $('#sel').animate({ // animate your right div
                    scrollTop: clickIndex*81 // to the position of the target 
                  }, 400);
                }
              break;

              default: return; // exit this handler for other keys
          }
          e.preventDefault(); // prevent the default action (scroll / move caret)
        }
      });
    }

    axios
      .get(apiUrl+'api/patients/patientReportStatus?userid='+this.props.match.params.userid)
      .then(res => {
        console.log(res.data);
        this.setState({
          reportStatus:res.data
        })       
      })
      .catch(err => {
        console.log(err);
      });

    axios
        .get(apiUrl+'api/common/patinentmenutree?userid='+this.props.match.params.userid)
        .then(res => {
          this.setState({
            treemenu:res.data
          })      
        })
        .catch(err => {
          console.log(err);
        });

    axios
        .get(apiUrl+'api/common/getReport?userid='+this.props.match.params.userid)
        .then(res => {
          console.log(res.data);   
          if(res.data!='') {
            this.setState({
              reoprtLink:res.data
            })  
          }   
        })
        .catch(err => {
            console.log(err);
        });     
  }
 
  render() {
    const userid = this.props.match.params.userid; 
    var databaseid = this.props.match.params.databaseid;
    if (typeof databaseid === 'undefined'){
      databaseid="0";
    }
    return (
    <div className="wrapper theme-1-active box-layout pimary-color-green">
      <input type="hidden" value={this.state.nextDbId} id="nextDbId" />
      <input type="hidden" value={this.state.prevDbId} id="prevDbId" />
      <TopNav profileLink={this.state.profileLink}/>
      <UnanalysedSideBarGraph userid={userid} menus={this.state.menus}  treemenu={this.state.treemenu} databaseid={databaseid} />
      <div className="right-sidebar-backdrop"></div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="panel panel-default card-view graphMainCont">
                <div className="panel-wrapper collapse in">
                  <div className="panel-body">
                    <div  className="pills-struct">
                      <ul role="tablist" className="nav nav-pills" id="myTabs_6">
                        <li role="presentation" className="active">
                          <a  data-toggle="tab" id="chart_tab_2" onClick={this.setTab.bind(this)} role="tab" href="#chart_beats" aria-expanded="false">Beats</a>
                        </li>  
                        <li role="presentation" className="pull-right">
                          <a  data-toggle="tab" id="chart_tab_5" onClick={this.setTab.bind(this)} role="tab" href="#chart_settings" aria-expanded="false">Settings</a>
                        </li>      
                      </ul>
                      <div  className="row">
                        <div className="col-md-4">
                          <div id="graphPrev" className="displayNone"><button id="prevBtn" className="arythmia-btn" onClick={this.newDataClick.bind(this)}>Previous</button></div>
                        </div>
                        <div className="col-md-4 text-center">Database Id :: {this.state.databaseid}</div>
                        <div className="col-md-4 text-right">
                          <div id="graphNext" className="displayNone"><button id="nextBtn" className="arythmia-btn" onClick={this.newDataClick.bind(this)}>Next</button></div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="tab-content" id="myTabContent_6">
                        <div  id="chart_beats" className="tab-pane fade active in" role="tabpanel">
                          <div className="beat" id="beat">
                            <div className="panel-body">
                              <div id="tt_container"></div>
                              <div className="text-right">
                                <select id="gainValue" onChange={this.gainBtn.bind(this)}>
                                  <option value="1">1X</option>
                                  <option value="2">2X</option>
                                  <option value="3">3X</option>
                                </select> 
                              </div>
                              <input type="hidden" name="beatSlected" id="beatSlected" value="[]"/>
                              <input type="hidden" name="selectedChart" id="selectedChart" value={this.state.selectedChart}/> 
                              <div id="sel" className="sel"></div>         
                            </div>
                          </div>  
                        </div>
                        <div  id="chart_settings" className="tab-pane fade" role="tabpanel">
                          <SettingsTab userid={userid} reportStatus={this.state.reportStatus}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>       
        </div>
      </div>
    </div>
    )
  }
}

export default Unanalysed;